define("b3i-analytics-web-app/templates/components/multi-option-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z2mHtIKk",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"multi-option-select-wrapper \",[34,6]]]],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[34,5]],[2,\":\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"multi-option-select\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"span\"],[16,0,[30,[36,2],[[30,[36,1],[[32,1,[\"value\"]],[35,0]],null],\"active\"],null]],[4,[38,3],[[32,0],\"optionClicked\",[32,1,[\"value\"]]],null],[12],[1,[32,1,[\"display\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[11,\"span\"],[16,0,[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"active\"],null]],[4,[38,3],[[32,0],\"optionClicked\",[32,1]],null],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedOption\",\"eq\",\"if\",\"action\",\"multiValues\",\"label\",\"wrapperClass\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/multi-option-select.hbs"
    }
  });

  _exports.default = _default;
});