define("b3i-analytics-web-app/templates/components/split-bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rEYEa62k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"split-bar-chart-wrapper\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"chart-label-row\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"chart-label-left\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[34,0]],[2,\":\"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"data\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"chart-label-right\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[34,2]],[2,\":\"],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"data\"],[12],[1,[34,3]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"chart-bar\"],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"fill-\",[34,4]]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"leftLabel\",\"leftData\",\"rightLabel\",\"rightData\",\"percentFill\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/split-bar-chart.hbs"
    }
  });

  _exports.default = _default;
});