define("b3i-analytics-web-app/utils/future-mega-projection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = futureMegaProjection;

  function futureMegaProjection(yearsAhead, isDirects, team) {
    var futureProjection = 0;
    var currentYear = isDirects ? team.get('megaGrantFutureCurrentYearDirects') : team.get('megaGrantFutureCurrentYearIndirects');
    var oneYearAhead = isDirects ? team.get('megaGrantFutureOneYearAheadDirects') : team.get('megaGrantFutureOneYearAheadIndirects');

    switch (yearsAhead) {
      case 0:
        futureProjection += currentYear / 2;
        break;

      case 1:
        futureProjection += currentYear;
        futureProjection += oneYearAhead / 2;
        break;

      default:
        // Assume mega grants renew indefinitely
        futureProjection += currentYear + oneYearAhead;
    } //Ammoritze the mega grants over 5 years


    futureProjection = futureProjection / 5;
    return futureProjection;
  }
});