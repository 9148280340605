define("b3i-analytics-web-app/models/sponsor", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    team: (0, _model.belongsTo)('team'),
    originalValue: (0, _model.attr)('string'),
    newValue: (0, _model.attr)('string'),
    displayValue: (0, _model.attr)('string'),
    isTrainingAward: (0, _model.attr)('boolean')
  });

  _exports.default = _default;
});