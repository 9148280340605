define("b3i-analytics-web-app/components/select-grouped-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showOptions: false,
    actions: {
      optionChanged: function optionChanged(option) {
        this.set('selectedOption', option.value);

        if (this.optionChanged) {
          this.optionChanged(option);
        }

        this.toggleProperty('showOptions');
      },
      toggleShowOptions: function toggleShowOptions() {
        this.set('showOptions', !this.disabled && !this.showOptions);
      },
      hideOptions: function hideOptions() {
        this.set('showOptions', false);
      }
    }
  });

  _exports.default = _default;
});