define("b3i-analytics-web-app/models/mega-grant", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    isNew: (0, _model.attr)('boolean'),
    isOverride: (0, _model.attr)('boolean'),
    isOverrideObserver: Ember.observer('isOverride', function () {
      var isOverride = this.isOverride;

      if (isOverride) {
        this.set('renews', false);
      }
    }),
    excludeFromHedgeChallenge: (0, _model.attr)('boolean'),
    duration: (0, _model.attr)('number'),
    renews: (0, _model.attr)('boolean'),
    departmentName: (0, _model.attr)('string'),
    principalInvestigatorFirstName: (0, _model.attr)('string'),
    principalInvestigatorLastName: (0, _model.attr)('string'),
    principalInvestigatorName: Ember.computed('principalInvestigatorFirstName', 'principalInvestigatorLastName', function () {
      return "".concat(this.principalInvestigatorLastName, ", ").concat(this.principalInvestigatorFirstName);
    }),
    principalInvestigatorId: (0, _model.attr)('string'),
    cellLinkData: Ember.computed('isNew', 'principalInvestigatorId', 'principalInvestigatorName', 'principalInvestigatorLastName', function () {
      return {
        id: this.principalInvestigatorId,
        name: this.principalInvestigatorName,
        link: 'app.departments.principal-investigator',
        isNew: this.isNew
      };
    }),
    hasDollarChanges: (0, _model.attr)('boolean'),
    previousDirects: (0, _model.attr)('number'),
    previousIndirects: (0, _model.attr)('number'),
    previousTotal: Ember.computed('previousDirects', 'previousIndirects', function () {
      return this.previousDirects + this.previousIndirects;
    }),
    directsTableValue: Ember.computed('success.directs', 'previousDirects', 'hasDollarChanges', function () {
      //If there are no recorded changes then there should not be any variance
      var variance = this.hasDollarChanges ? this.get('success.directs') - this.previousDirects : 0;
      var value = {
        value: this.get('success.directs'),
        variance: variance
      };
      return value;
    }),
    indirectsTableValue: Ember.computed('success.indirects', 'previousIndirects', 'hasDollarChanges', function () {
      //If there are no recorded changes then there should not be any variance
      var variance = this.hasDollarChanges ? this.get('success.indirects') - this.previousIndirects : 0;
      var value = {
        value: this.get('success.indirects'),
        variance: variance
      };
      return value;
    }),
    totalTableValue: Ember.computed('success.total', 'previousTotal', 'hasDollarChanges', function () {
      //If there are no recorded changes then there should not be any variance
      var variance = this.hasDollarChanges ? this.get('success.total') - this.previousTotal : 0;
      var value = {
        value: this.get('success.total'),
        variance: variance
      };
      return value;
    }),
    remainingBalanceDirects: (0, _model.attr)('number'),
    remainingBalanceIndirects: (0, _model.attr)('number'),
    ptsCurrentYearDirects: (0, _model.attr)('number'),
    ptsCurrentYearIndirects: (0, _model.attr)('number'),
    ptsOneYearAheadDirects: (0, _model.attr)('number'),
    ptsOneYearAheadIndirects: (0, _model.attr)('number'),
    ptsTwoYearsAheadDirects: (0, _model.attr)('number'),
    ptsTwoYearsAheadIndirects: (0, _model.attr)('number'),
    ptsThreeYearsAheadDirects: (0, _model.attr)('number'),
    ptsThreeYearsAheadIndirects: (0, _model.attr)('number'),
    ptsFourYearsAheadDirects: (0, _model.attr)('number'),
    ptsFourYearsAheadIndirects: (0, _model.attr)('number'),
    success: (0, _model.belongsTo)('success', {
      async: false
    }),
    proposal: (0, _model.belongsTo)('proposal', {
      async: false
    }),
    team: (0, _model.belongsTo)('team', {
      async: false
    }),
    //local
    isSaving: (0, _model.attr)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});