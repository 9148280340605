define("b3i-analytics-web-app/components/table-pi-projections", ["exports", "b3i-analytics-web-app/mixins/table-common"], function (_exports, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    year: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    columns: Ember.computed(function () {
      return [{
        label: '',
        sortable: false,
        subColumns: [{
          label: '',
          sortable: false,
          valuePath: 'grantType',
          cellClassNames: 'grey-bg row-label',
          width: this.isExporting ? '100px' : '150px'
        }, {
          label: '',
          sortable: false,
          valuePath: 'directsOrIndirects',
          cellClassNames: 'column-group-right grey-bg row-label'
        }]
      }, {
        label: 'Actual',
        sortable: false,
        subColumns: [{
          label: "".concat(this.get('year.fiscalYear') - 5),
          sortable: false,
          valuePath: 'actualsFiveYearsAgo',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-left',
          align: 'right'
        }, {
          label: "".concat(this.get('year.fiscalYear') - 4),
          sortable: false,
          valuePath: 'actualsFourYearsAgo',
          cellComponent: 'cell-money',
          align: 'right'
        }, {
          label: "".concat(this.get('year.fiscalYear') - 3),
          sortable: false,
          valuePath: 'actualsThreeYearsAgo',
          cellComponent: 'cell-money',
          align: 'right'
        }, {
          label: "".concat(this.get('year.fiscalYear') - 2),
          sortable: false,
          valuePath: 'actualsTwoYearsAgo',
          cellComponent: 'cell-money',
          align: 'right'
        }, {
          label: "".concat(this.get('year.fiscalYear') - 1),
          sortable: false,
          valuePath: 'actualsOneYearAgo',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right',
          align: 'right'
        }]
      }, {
        label: "Potential to Spend and Earn Indirects as of ".concat(this.get('currentUser.asOfDate')),
        sortable: false,
        subColumns: [{
          label: "".concat(this.get('year.fiscalYear')),
          sortable: false,
          valuePath: 'ptsCurrentYear',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-left',
          align: 'right'
        }, {
          label: "".concat(parseInt(this.get('year.fiscalYear')) + 1),
          sortable: false,
          valuePath: 'ptsOneYearAhead',
          cellComponent: 'cell-money',
          align: 'right'
        }, {
          label: "".concat(parseInt(this.get('year.fiscalYear')) + 2),
          sortable: false,
          valuePath: 'ptsTwoYearsAhead',
          cellComponent: 'cell-money',
          align: 'right'
        }, {
          label: "".concat(parseInt(this.get('year.fiscalYear')) + 3),
          sortable: false,
          valuePath: 'ptsThreeYearsAhead',
          cellComponent: 'cell-money',
          align: 'right'
        }, {
          label: "".concat(parseInt(this.get('year.fiscalYear')) + 4),
          sortable: false,
          valuePath: 'ptsFourYearsAhead',
          cellComponent: 'cell-money',
          align: 'right'
        }]
      }];
    }),
    // Add classes to projections table for styling
    rowObserver: Ember.observer('table', function () {
      var table = this.table;
      var rows = table.get('rows');
      rows.forEach(function (row, index) {
        if (index % 2 === 0) {
          Ember.set(row, 'classNames', 'top-row');
        } else {
          Ember.set(row, 'classNames', 'bottom-row');
        }

        if (index < 2 || index > 7) {
          if (index % 2 === 0) {
            Ember.set(row, 'classNames', 'row-grey-bg top-row');
          } else {
            Ember.set(row, 'classNames', 'row-grey-bg bottom-row');
          }
        }

        if (index === 1) {
          Ember.set(row, 'classNames', 'row-grey-bg row-border-bottom bottom-row');
        }

        if (index === 8) {
          Ember.set(row, 'classNames', 'row-grey-bg row-border-top top-row');
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});