define("b3i-analytics-web-app/components/table-projections-core-mega-prior", ["exports", "b3i-analytics-web-app/mixins/table-common"], function (_exports, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    year: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        label: '',
        sortable: false,
        valuePath: 'type'
      }, {
        label: '',
        sortable: false,
        valuePath: 'directsOrIndirects'
      }, {
        align: 'right',
        label: "".concat(this.get('year.fiscalYear') - 5),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsFiveYearsAgo'
      }, {
        align: 'right',
        label: "".concat(this.get('year.fiscalYear') - 4),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsFourYearsAgo'
      }, {
        align: 'right',
        label: "".concat(this.get('year.fiscalYear') - 3),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsThreeYearsAgo'
      }, {
        align: 'right',
        label: "".concat(this.get('year.fiscalYear') - 2),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsTwoYearsAgo'
      }, {
        align: 'right',
        label: "".concat(this.get('year.fiscalYear') - 1),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsOneYearAgo'
      }];
    }),
    // Add classes to projections table for styling
    rowObserver: Ember.observer('table.rows.@each', 'selectedGrantType', function () {
      var table = this.table;
      var rows = table.get('rows');
      var grant = this.selectedGrantType.toLowerCase();
      rows.forEach(function (row, index) {
        var rowClass = '';

        if (index === 2 || index === 4) {
          rowClass += ' row-border-top';
        }

        if (grant !== 'all') {
          if (index > 1) {
            rowClass += ' dark-grey-bg dark-grey-text';
          }
        }

        Ember.set(row, 'classNames', rowClass);
      });
    }).on('init')
  });

  _exports.default = _default;
});