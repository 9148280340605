define("b3i-analytics-web-app/templates/components/modal-edit-user-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HE+UBpOC",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-wrapper\",[],[[\"@title\",\"@close\"],[[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"stacked-field-wrapper\"],[12],[2,\"\\n    \"],[8,\"validated-text-input\",[],[[\"@value\",\"@label\",\"@placeholder\"],[[34,3],\"Name:\",\"Type a name...\"]],null],[2,\"\\n    \"],[8,\"validated-text-input\",[],[[\"@value\",\"@label\",\"@placeholder\"],[[34,4],\"Email Address:\",\"Type an email address...\"]],null],[2,\"\\n    \"],[8,\"dropdown-wrapper\",[],[[\"@optionChanged\",\"@selectedOption\",\"@options\",\"@label\"],[[30,[36,5],[[32,0],\"roleOptionChanged\"],null],[34,6],[34,7],\"System Role\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn-primary\"],[4,[38,5],[[32,0],[35,8],[35,3],[35,4],[35,6]],null],[12],[2,\"Save\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,9],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n        \"],[8,\"error-message\",[],[[\"@errors\"],[[34,0]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errors\",\"title\",\"close\",\"name\",\"email\",\"action\",\"systemRole\",\"roleOptions\",\"saveAction\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/modal-edit-user-admin.hbs"
    }
  });

  _exports.default = _default;
});