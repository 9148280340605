define("b3i-analytics-web-app/components/table-users-admin", ["exports", "b3i-analytics-web-app/mixins/table-common", "moment"], function (_exports, _tableCommon, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_tableCommon.default, (_dec = Ember.computed(), (_obj = {
    get columns() {
      return [{
        label: 'Name',
        sortable: false,
        valuePath: 'nameComma',
        width: '200px' //valuePath: 'cellLinkData',
        //width: '150px',
        //cellComponent: 'cell-link'

      }, {
        label: 'Email',
        sortable: false,
        valuePath: 'email',
        width: '220px'
      }, {
        label: 'System Role',
        sortable: false,
        valuePath: 'systemRoleString',
        width: '120px'
      }, {
        label: 'Instance Access',
        sortable: false,
        valuePath: 'instanceAccessCount',
        width: '120px'
      }, {
        label: 'Last Activity',
        sortable: false,
        valuePath: 'lastActivity',
        width: '100px',
        format: function format(value) {
          if (value) {
            return (0, _moment.default)(value).format('MM-DD-YY HH:mm:ss');
          }
        }
      }, {
        label: '',
        cellClassNames: 'edit-user',
        sortable: false,
        align: 'center',
        valuePath: 'id',
        cellComponent: 'cell-user-admin',
        width: '200px'
      }];
    }

  }, (_applyDecoratedDescriptor(_obj, "columns", [_dec], Object.getOwnPropertyDescriptor(_obj, "columns"), _obj)), _obj)));

  _exports.default = _default;
});