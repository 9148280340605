define("b3i-analytics-web-app/mixins/current-year-projections", ["exports", "b3i-analytics-web-app/utils/format-percentage"], function (_exports, _formatPercentage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TWOYEARAVG = "2 Yr Avg";
  var THREEYRAVG = "3 Yr Avg";
  var PRIORYEAR = "Prior Yr";
  var YTD = "YTD";
  var YTDREVISED = "Revert";
  var STAY = "Stay";
  var SPLIT = 'Split';
  var REVERT = 'Revert';

  var _default = Ember.Mixin.create({
    //region Core Conversion Ratios Default Settings
    coreConversionRatiosSettingsOpenCurrentYearNih: PRIORYEAR,
    coreConversionRatiosSettingsRevisedCurrentYearNih: STAY,
    coreConversionRatiosSettingsTransitionNextYearNih: STAY,
    coreConversionRatiosSettingsOpenCurrentYearOtherFed: PRIORYEAR,
    coreConversionRatiosSettingsRevisedCurrentYearOtherFed: STAY,
    coreConversionRatiosSettingsTransitionNextYearOtherFed: STAY,
    coreConversionRatiosSettingsOpenCurrentYearFedFlowThru: PRIORYEAR,
    coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru: STAY,
    coreConversionRatiosSettingsTransitionNextYearFedFlowThru: STAY,
    coreConversionRatiosSettingsOpenCurrentYearNonFed: PRIORYEAR,
    coreConversionRatiosSettingsRevisedCurrentYearNonFed: STAY,
    coreConversionRatiosSettingsTransitionNextYearNonFed: STAY,
    coreConversionRatiosSettingsOpenCurrentYearSponsor5: PRIORYEAR,
    coreConversionRatiosSettingsRevisedCurrentYearSponsor5: STAY,
    coreConversionRatiosSettingsTransitionNextYearSponsor5: STAY,
    coreConversionRatiosSettingsOpenCurrentYearSponsor6: PRIORYEAR,
    coreConversionRatiosSettingsRevisedCurrentYearSponsor6: STAY,
    coreConversionRatiosSettingsTransitionNextYearSponsor6: STAY,
    coreConversionRatiosSettingsOpenCurrentYearSponsor7: PRIORYEAR,
    coreConversionRatiosSettingsRevisedCurrentYearSponsor7: STAY,
    coreConversionRatiosSettingsTransitionNextYearSponsor7: STAY,
    //endregion
    //region Core Proposals Default Settings
    coreProposalsSettingsOpenCurrentYearNih: PRIORYEAR,
    coreProposalsSettingsRevisedCurrentYearNih: STAY,
    coreProposalsSettingsTransitionNextYearNih: STAY,
    coreProposalsSettingsOpenCurrentYearOtherFed: PRIORYEAR,
    coreProposalsSettingsRevisedCurrentYearOtherFed: STAY,
    coreProposalsSettingsTransitionNextYearOtherFed: STAY,
    coreProposalsSettingsOpenCurrentYearFedFlowThru: PRIORYEAR,
    coreProposalsSettingsRevisedCurrentYearFedFlowThru: STAY,
    coreProposalsSettingsTransitionNextYearFedFlowThru: STAY,
    coreProposalsSettingsOpenCurrentYearNonFed: PRIORYEAR,
    coreProposalsSettingsRevisedCurrentYearNonFed: STAY,
    coreProposalsSettingsTransitionNextYearNonFed: STAY,
    coreProposalsSettingsOpenCurrentYearSponsor5: PRIORYEAR,
    coreProposalsSettingsRevisedCurrentYearSponsor5: STAY,
    coreProposalsSettingsTransitionNextYearSponsor5: STAY,
    coreProposalsSettingsOpenCurrentYearSponsor6: PRIORYEAR,
    coreProposalsSettingsRevisedCurrentYearSponsor6: STAY,
    coreProposalsSettingsTransitionNextYearSponsor6: STAY,
    coreProposalsSettingsOpenCurrentYearSponsor7: PRIORYEAR,
    coreProposalsSettingsRevisedCurrentYearSponsor7: STAY,
    coreProposalsSettingsTransitionNextYearSponsor7: STAY,
    //endregion
    //region Core Conversion Ratios Settings Calculations
    coreConversionRatiosOpenCurrentYearNihDirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoNihDirects,coreConversionRatiosTwoYearAvgNihDirects,coreConversionRatiosThreeYearAvgNihDirects}', 'coreConversionRatiosSettingsOpenCurrentYearNih', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearNih, this.get('model.projection.coreConversionRatiosOneYearAgoNihDirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgNihDirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgNihDirects'));
    }),
    coreConversionRatiosOpenCurrentYearNihIndirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoNihIndirects,coreConversionRatiosTwoYearAvgNihIndirects,coreConversionRatiosThreeYearAvgNihIndirects}', 'coreConversionRatiosSettingsOpenCurrentYearNih', 'coreConversionRatiosOpenCurrentYearNihDirects', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearNih, this.get('model.projection.coreConversionRatiosOneYearAgoNihIndirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgNihIndirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgNihIndirects'), true, this.coreConversionRatiosOpenCurrentYearNihDirects);
    }),
    coreConversionRatiosOpenCurrentYearOtherFedDirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoOtherFedDirects,coreConversionRatiosTwoYearAvgOtherFedDirects,coreConversionRatiosThreeYearAvgOtherFedDirects}', 'coreConversionRatiosSettingsOpenCurrentYearOtherFed', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearOtherFed, this.get('model.projection.coreConversionRatiosOneYearAgoOtherFedDirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgOtherFedDirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgOtherFedDirects'));
    }),
    coreConversionRatiosOpenCurrentYearOtherFedIndirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoOtherFedIndirects,coreConversionRatiosTwoYearAvgOtherFedIndirects,coreConversionRatiosThreeYearAvgOtherFedIndirects}', 'coreConversionRatiosSettingsOpenCurrentYearOtherFed', 'coreConversionRatiosOpenCurrentYearOtherFedDirects', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearOtherFed, this.get('model.projection.coreConversionRatiosOneYearAgoOtherFedIndirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgOtherFedIndirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgOtherFedIndirects'), true, this.coreConversionRatiosOpenCurrentYearOtherFedDirects);
    }),
    coreConversionRatiosOpenCurrentYearFedFlowThruDirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoFedFlowThruDirects,coreConversionRatiosTwoYearAvgFedFlowThruDirects,coreConversionRatiosThreeYearAvgFedFlowThruDirects}', 'coreConversionRatiosSettingsOpenCurrentYearFedFlowThru', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearFedFlowThru, this.get('model.projection.coreConversionRatiosOneYearAgoFedFlowThruDirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgFedFlowThruDirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgFedFlowThruDirects'));
    }),
    coreConversionRatiosOpenCurrentYearFedFlowThruIndirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoFedFlowThruIndirects,coreConversionRatiosTwoYearAvgFedFlowThruIndirects,coreConversionRatiosThreeYearAvgFedFlowThruIndirects}', 'coreConversionRatiosSettingsOpenCurrentYearFedFlowThru', 'coreConversionRatiosOpenCurrentYearFedFlowThruDirects', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearFedFlowThru, this.get('model.projection.coreConversionRatiosOneYearAgoFedFlowThruIndirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgFedFlowThruIndirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgFedFlowThruIndirects'), true, this.coreConversionRatiosOpenCurrentYearFedFlowThruDirects);
    }),
    coreConversionRatiosOpenCurrentYearNonFedDirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoNonFedDirects,coreConversionRatiosTwoYearAvgNonFedDirects,coreConversionRatiosThreeYearAvgNonFedDirects}', 'coreConversionRatiosSettingsOpenCurrentYearNonFed', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearNonFed, this.get('model.projection.coreConversionRatiosOneYearAgoNonFedDirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgNonFedDirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgNonFedDirects'));
    }),
    coreConversionRatiosOpenCurrentYearNonFedIndirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoNonFedIndirects,coreConversionRatiosTwoYearAvgNonFedIndirects,coreConversionRatiosThreeYearAvgNonFedIndirects}', 'coreConversionRatiosSettingsOpenCurrentYearNonFed', 'coreConversionRatiosOpenCurrentYearNonFedDirect', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearNonFed, this.get('model.projection.coreConversionRatiosOneYearAgoNonFedIndirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgNonFedIndirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgNonFedIndirects'), true, this.coreConversionRatiosOpenCurrentYearNonFedDirects);
    }),
    coreConversionRatiosOpenCurrentYearSponsor5Directs: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoSponsor5Directs,coreConversionRatiosTwoYearAvgSponsor5Directs,coreConversionRatiosThreeYearAvgSponsor5Directs}', 'coreConversionRatiosSettingsOpenCurrentYearSponsor5', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearSponsor5, this.get('model.projection.coreConversionRatiosOneYearAgoSponsor5Directs'), this.get('model.projection.coreConversionRatiosTwoYearAvgSponsor5Directs'), this.get('model.projection.coreConversionRatiosThreeYearAvgSponsor5Directs'));
    }),
    coreConversionRatiosOpenCurrentYearSponsor5Indirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoSponsor5Indirects,coreConversionRatiosTwoYearAvgSponsor5Indirects,coreConversionRatiosThreeYearAvgSponsor5Indirects}', 'coreConversionRatiosSettingsOpenCurrentYearSponsor5', 'coreConversionRatiosOpenCurrentYearSponsor5Direct', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearSponsor5, this.get('model.projection.coreConversionRatiosOneYearAgoSponsor5Indirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgSponsor5Indirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgSponsor5Indirects'), true, this.coreConversionRatiosOpenCurrentYearSponsor5Directs);
    }),
    coreConversionRatiosOpenCurrentYearSponsor6Directs: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoSponsor6Directs,coreConversionRatiosTwoYearAvgSponsor6Directs,coreConversionRatiosThreeYearAvgSponsor6Directs}', 'coreConversionRatiosSettingsOpenCurrentYearSponsor6', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearSponsor6, this.get('model.projection.coreConversionRatiosOneYearAgoSponsor6Directs'), this.get('model.projection.coreConversionRatiosTwoYearAvgSponsor6Directs'), this.get('model.projection.coreConversionRatiosThreeYearAvgSponsor6Directs'));
    }),
    coreConversionRatiosOpenCurrentYearSponsor6Indirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoSponsor6Indirects,coreConversionRatiosTwoYearAvgSponsor6Indirects,coreConversionRatiosThreeYearAvgSponsor6Indirects}', 'coreConversionRatiosSettingsOpenCurrentYearSponsor6', 'coreConversionRatiosOpenCurrentYearSponsor6Direct', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearSponsor6, this.get('model.projection.coreConversionRatiosOneYearAgoSponsor6Indirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgSponsor6Indirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgSponsor6Indirects'), true, this.coreConversionRatiosOpenCurrentYearSponsor6Directs);
    }),
    coreConversionRatiosOpenCurrentYearSponsor7Directs: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoSponsor7Directs,coreConversionRatiosTwoYearAvgSponsor7Directs,coreConversionRatiosThreeYearAvgSponsor7Directs}', 'coreConversionRatiosSettingsOpenCurrentYearSponsor7', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearSponsor7, this.get('model.projection.coreConversionRatiosOneYearAgoSponsor7Directs'), this.get('model.projection.coreConversionRatiosTwoYearAvgSponsor7Directs'), this.get('model.projection.coreConversionRatiosThreeYearAvgSponsor7Directs'));
    }),
    coreConversionRatiosOpenCurrentYearSponsor7Indirects: Ember.computed('model.projection.{coreConversionRatiosOneYearAgoSponsor7Indirects,coreConversionRatiosTwoYearAvgSponsor7Indirects,coreConversionRatiosThreeYearAvgSponsor7Indirects}', 'coreConversionRatiosSettingsOpenCurrentYearSponsor7', 'coreConversionRatiosOpenCurrentYearSponsor7Direct', function () {
      return this.currentYearCoreConversionRatiosOpenValue(this.coreConversionRatiosSettingsOpenCurrentYearSponsor7, this.get('model.projection.coreConversionRatiosOneYearAgoSponsor7Indirects'), this.get('model.projection.coreConversionRatiosTwoYearAvgSponsor7Indirects'), this.get('model.projection.coreConversionRatiosThreeYearAvgSponsor7Indirects'), true, this.coreConversionRatiosOpenCurrentYearSponsor7Directs);
    }),
    coreConversionRatiosRevisedCurrentYearNihDirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearNihDirects,coreProposalsYearEndNihDirects,coreProposalsCurrentYearTotalNihDirects,successesYtdNihDirects}', 'coreConversionRatiosOpenCurrentYearNihDirects', 'coreConversionRatiosSettingsRevisedCurrentYearNih', 'coreProposalsOpenCurrentYearNihDirects', 'coreProposalsRevisedCurrentYearNihDirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearNih, this.coreConversionRatiosOpenCurrentYearNihDirects, this.get('model.projection.coreConversionRatiosCurrentYearNihDirects'), this.get('model.projection.coreProposalsYearEndNihDirects'), this.get('model.projection.coreProposalsCurrentYearTotalNihDirects'), this.get('model.projection.successesYtdNihDirects'), this.get('model.projection.coreProposalsCurrentYearYtdNihDirects'), this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects'), this.coreProposalsOpenCurrentYearNihDirects, this.coreProposalsRevisedCurrentYearNihDirects);
    }),
    coreConversionRatiosRevisedCurrentYearNihIndirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearNihIndirects,coreProposalsYearEndNihIndirects,coreProposalsCurrentYearTotalNihIndirects,successesYtdNihIndirects}', 'coreConversionRatiosOpenCurrentYearNihIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearNih', 'coreProposalsOpenCurrentYearNihIndirects', 'coreProposalsRevisedCurrentYearNihIndirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearNih, this.coreConversionRatiosOpenCurrentYearNihIndirects, this.get('model.projection.coreConversionRatiosCurrentYearNihIndirects'), this.get('model.projection.coreProposalsYearEndNihIndirects'), this.get('model.projection.coreProposalsCurrentYearTotalNihIndirects'), this.get('model.projection.successesYtdNihIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdNihIndirects'), this.get('model.projection.yoneYtwoDerivativeRatiosNihIndirects'), this.coreProposalsOpenCurrentYearNihIndirects, this.coreProposalsRevisedCurrentYearNihIndirects);
    }),
    coreConversionRatiosRevisedCurrentYearNonFedDirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearNonFedDirects,coreProposalsYearEndNonFedDirects,coreProposalsCurrentYearTotalNonFedDirects,successesYtdNonFedDirects}', 'coreConversionRatiosOpenCurrentYearNonFedDirects', 'coreConversionRatiosSettingsRevisedCurrentYearNonFed', 'coreProposalsOpenCurrentYearNonFedDirects', 'coreProposalsRevisedCurrentYearNonFedDirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearNonFed, this.coreConversionRatiosOpenCurrentYearNonFedDirects, this.get('model.projection.coreConversionRatiosCurrentYearNonFedDirects'), this.get('model.projection.coreProposalsYearEndNonFedDirects'), this.get('model.projection.coreProposalsCurrentYearTotalNonFedDirects'), this.get('model.projection.successesYtdNonFedDirects'), this.get('model.projection.coreProposalsCurrentYearYtdNonFedDirects'), this.get('model.projection.yoneYtwoDerivativeRatiosNonFedDirects'), this.coreProposalsOpenCurrentYearNonFedDirects, this.coreProposalsRevisedCurrentYearNonFedDirects);
    }),
    coreConversionRatiosRevisedCurrentYearNonFedIndirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearNonFedIndirects,coreProposalsYearEndNonFedIndirects,coreProposalsCurrentYearTotalNonFedIndirects,successesYtdNonFedIndirects}', 'coreConversionRatiosOpenCurrentYearNonFedIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearNonFed', 'coreConversionRatiosRevisedCurrentYearNonFedIndirects', 'coreProposalsOpenCurrentYearNonFedIndirects', 'coreProposalsRevisedCurrentYearNonFedIndirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearNonFed, this.coreConversionRatiosOpenCurrentYearNonFedIndirects, this.get('model.projection.coreConversionRatiosCurrentYearNonFedIndirects'), this.get('model.projection.coreProposalsYearEndNonFedIndirects'), this.get('model.projection.coreProposalsCurrentYearTotalNonFedIndirects'), this.get('model.projection.successesYtdNonFedIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdNonFedIndirects'), this.get('model.projection.yoneYtwoDerivativeRatiosNonFedIndirects'), this.coreProposalsOpenCurrentYearNonFedIndirects, this.coreProposalsRevisedCurrentYearNonFedIndirects);
    }),
    coreConversionRatiosRevisedCurrentYearFedFlowThruDirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearFedFlowThruDirects,coreProposalsYearEndFedFlowThruDirects,coreProposalsCurrentYearTotalFedFlowThruDirects,successesYtdFedFlowThruDirects}', 'coreConversionRatiosOpenCurrentYearFedFlowThruDirects', 'coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru', 'coreProposalsOpenCurrentYearFedFlowThruDirects', 'coreProposalsRevisedCurrentYearFedFlowThruDirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru, this.coreConversionRatiosOpenCurrentYearFedFlowThruDirects, this.get('model.projection.coreConversionRatiosCurrentYearFedFlowThruDirects'), this.get('model.projection.coreProposalsYearEndFedFlowThruDirects'), this.get('model.projection.coreProposalsCurrentYearTotalFedFlowThruDirects'), this.get('model.projection.successesYtdFedFlowThruDirects'), this.get('model.projection.coreProposalsCurrentYearYtdFedFlowThruDirects'), this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruDirects'), this.coreProposalsOpenCurrentYearFedFlowThruDirects, this.coreProposalsRevisedCurrentYearFedFlowThruDirects);
    }),
    coreConversionRatiosRevisedCurrentYearFedFlowThruIndirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearFedFlowThruIndirects,coreProposalsYearEndFedFlowThruIndirects,coreProposalsCurrentYearTotalFedFlowThruIndirects,successsesYtdFedFlowThruIndirects}', 'coreConversionRatiosOpenCurrentYearFedFlowThruIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru', 'coreConversionRatiosRevisedCurrentYearFedFlowThruDirects', 'coreProposalsOpenCurrentYearFedFlowThruIndirects', 'coreProposalsRevisedCurrentYearFedFlowThruIndirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru, this.coreConversionRatiosOpenCurrentYearFedFlowThruIndirects, this.get('model.projection.coreConversionRatiosCurrentYearFedFlowThruIndirects'), this.get('model.projection.coreProposalsYearEndFedFlowThruIndirects'), this.get('model.projection.coreProposalsCurrentYearTotalFedFlowThruIndirects'), this.get('model.projection.successesYtdFedFlowThruIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdFedFlowThruIndirects'), this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruIndirects'), this.coreProposalsOpenCurrentYearFedFlowThruIndirects, this.coreProposalsRevisedCurrentYearFedFlowThruIndirects);
    }),
    coreConversionRatiosRevisedCurrentYearOtherFedDirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearOtherFedDirects,coreProposalsYearEndOtherFedDirects,coreProposalsCurrentYearTotalOtherFedDirects,successesYtdOtherFedDirects}', 'coreConversionRatiosOpenCurrentYearOtherFedDirects', 'coreConversionRatiosSettingsRevisedCurrentYearOtherFed', 'coreProposalsOpenCurrentYearOtherFedDirects', 'coreProposalsRevisedCurrentYearOtherFedDirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearOtherFed, this.coreConversionRatiosOpenCurrentYearOtherFedDirects, this.get('model.projection.coreConversionRatiosCurrentYearOtherFedDirects'), this.get('model.projection.coreProposalsYearEndOtherFedDirects'), this.get('model.projection.coreProposalsCurrentYearTotalOtherFedDirects'), this.get('model.projection.successesYtdOtherFedDirects'), this.get('model.projection.coreProposalsCurrentYearYtdOtherFedDirects'), this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedDirects'), this.coreProposalsOpenCurrentYearOtherFedDirects, this.coreProposalsRevisedCurrentYearOtherFedDirects);
    }),
    coreConversionRatiosRevisedCurrentYearOtherFedIndirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearOtherFedIndirects,coreProposalsYearEndOtherFedIndirects,coreProposalsCurrentYearTotalOtherFedIndirects,successesYtdOtherFedIndirects}', 'coreConversionRatiosOpenCurrentYearOtherFedIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearOtherFed', 'coreProposalsOpenCurrentYearOtherFedIndirects', 'coreProposalsRevisedCurrentYearOtherFedIndirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearOtherFed, this.coreConversionRatiosOpenCurrentYearOtherFedIndirects, this.get('model.projection.coreConversionRatiosCurrentYearOtherFedIndirects'), this.get('model.projection.coreProposalsYearEndOtherFedIndirects'), this.get('model.projection.coreProposalsCurrentYearTotalOtherFedIndirects'), this.get('model.projection.successesYtdOtherFedIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdOtherFedIndirects'), this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedIndirects'), this.coreProposalsOpenCurrentYearOtherFedIndirects, this.coreProposalsRevisedCurrentYearOtherFedIndirects);
    }),
    coreConversionRatiosRevisedCurrentYearSponsor5Directs: Ember.computed('model.projection.{coreConversionRatiosCurrentYearSponsor5Directs,coreProposalsYearEndSponsor5Directs,coreProposalsCurrentYearTotalSponsor5Directs,successesYtdSponsor5Directs}', 'coreConversionRatiosOpenCurrentYearSponsor5Directs', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor5', 'coreProposalsOpenCurrentYearSponsor5Directs', 'coreProposalsRevisedCurrentYearSponsor5Directs', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor5, this.coreConversionRatiosOpenCurrentYearSponsor5Directs, this.get('model.projection.coreConversionRatiosCurrentYearSponsor5Directs'), this.get('model.projection.coreProposalsYearEndSponsor5Directs'), this.get('model.projection.coreProposalsCurrentYearTotalSponsor5Directs'), this.get('model.projection.successesYtdSponsor5Directs'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor5Directs'), this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Directs'), this.coreProposalsOpenCurrentYearSponsor5Directs, this.coreProposalsRevisedCurrentYearSponsor5Directs);
    }),
    coreConversionRatiosRevisedCurrentYearSponsor5Indirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearSponsor5Indirects,coreProposalsYearEndSponsor5Indirects,coreProposalsCurrentYearTotalSponsor5Indirects,successesYtdSponsor5Indirects}', 'coreConversionRatiosOpenCurrentYearSponsor5Indirects', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor5', 'coreProposalsOpenCurrentYearSponsor5Indirects', 'coreProposalsRevisedCurrentYearSponsor5Indirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor5, this.coreConversionRatiosOpenCurrentYearSponsor5Indirects, this.get('model.projection.coreConversionRatiosCurrentYearSponsor5Indirects'), this.get('model.projection.coreProposalsYearEndSponsor5Indirects'), this.get('model.projection.coreProposalsCurrentYearTotalSponsor5Indirects'), this.get('model.projection.successesYtdSponsor5Indirects'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor5Indirects'), this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Indirects'), this.coreProposalsOpenCurrentYearSponsor5Indirects, this.coreProposalsRevisedCurrentYearSponsor5Indirects);
    }),
    coreConversionRatiosRevisedCurrentYearSponsor6Directs: Ember.computed('model.projection.{coreConversionRatiosCurrentYearSponsor6Directs,coreProposalsYearEndSponsor6Directs,coreProposalsCurrentYearTotalSponsor6Directs,successesYtdSponsor6Directs}', 'coreConversionRatiosOpenCurrentYearSponsor6Directs', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor6', 'coreProposalsOpenCurrentYearSponsor6Directs', 'coreProposalsRevisedCurrentYearSponsor6Directs', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor6, this.coreConversionRatiosOpenCurrentYearSponsor6Directs, this.get('model.projection.coreConversionRatiosCurrentYearSponsor6Directs'), this.get('model.projection.coreProposalsYearEndSponsor6Directs'), this.get('model.projection.coreProposalsCurrentYearTotalSponsor6Directs'), this.get('model.projection.successesYtdSponsor6Directs'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor6Directs'), this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Directs'), this.coreProposalsOpenCurrentYearSponsor6Directs, this.coreProposalsRevisedCurrentYearSponsor6Directs);
    }),
    coreConversionRatiosRevisedCurrentYearSponsor6Indirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearSponsor6Indirects,coreProposalsYearEndSponsor6Indirects,coreProposalsCurrentYearTotalSponsor6Indirects,successesYtdSponsor6Indirects}', 'coreConversionRatiosOpenCurrentYearSponsor6Indirects', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor6', 'coreProposalsOpenCurrentYearSponsor6Indirects', 'coreProposalsRevisedCurrentYearSponsor6Indirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor6, this.coreConversionRatiosOpenCurrentYearSponsor6Indirects, this.get('model.projection.coreConversionRatiosCurrentYearSponsor6Indirects'), this.get('model.projection.coreProposalsYearEndSponsor6Indirects'), this.get('model.projection.coreProposalsCurrentYearTotalSponsor6Indirects'), this.get('model.projection.successesYtdSponsor6Indirects'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor6Indirects'), this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Indirects'), this.coreProposalsOpenCurrentYearSponsor6Indirects, this.coreProposalsRevisedCurrentYearSponsor6Indirects);
    }),
    coreConversionRatiosRevisedCurrentYearSponsor7Directs: Ember.computed('model.projection.{coreConversionRatiosCurrentYearSponsor7Directs,coreProposalsYearEndSponsor7Directs,coreProposalsCurrentYearTotalSponsor7Directs,successesYtdSponsor7Directs}', 'coreConversionRatiosOpenCurrentYearSponsor7Directs', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor7', 'coreProposalsOpenCurrentYearSponsor7Directs', 'coreProposalsRevisedCurrentYearSponsor7Directs', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor7, this.coreConversionRatiosOpenCurrentYearSponsor7Directs, this.get('model.projection.coreConversionRatiosCurrentYearSponsor7Directs'), this.get('model.projection.coreProposalsYearEndSponsor7Directs'), this.get('model.projection.coreProposalsCurrentYearTotalSponsor7Directs'), this.get('model.projection.successesYtdSponsor7Directs'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor7Directs'), this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Directs'), this.coreProposalsOpenCurrentYearSponsor7Directs, this.coreProposalsRevisedCurrentYearSponsor7Directs);
    }),
    coreConversionRatiosRevisedCurrentYearSponsor7Indirects: Ember.computed('model.projection.{coreConversionRatiosCurrentYearSponsor7Indirects,coreProposalsYearEndSponsor7Indirects,coreProposalsCurrentYearTotalSponsor7Indirects,successesYtdSponsor7Indirects}', 'coreConversionRatiosOpenCurrentYearSponsor7Indirects', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor7', 'coreProposalsOpenCurrentYearSponsor7Indirects', 'coreProposalsRevisedCurrentYearSponsor7Indirects', function () {
      return this.currentYearCoreConversionRatiosRevisedValue(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor7, this.coreConversionRatiosOpenCurrentYearSponsor7Indirects, this.get('model.projection.coreConversionRatiosCurrentYearSponsor7Indirects'), this.get('model.projection.coreProposalsYearEndSponsor7Indirects'), this.get('model.projection.coreProposalsCurrentYearTotalSponsor7Indirects'), this.get('model.projection.successesYtdSponsor7Indirects'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor7Indirects'), this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Indirects'), this.coreProposalsOpenCurrentYearSponsor7Indirects, this.coreProposalsRevisedCurrentYearSponsor7Indirects);
    }),
    coreConversionRatiosTransitionNextYearNihDirects: Ember.computed('coreConversionRatiosRevisedCurrentYearNihDirects', 'coreConversionRatiosSettingsTransitionNextYearNih', function () {
      return this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearNih, this.coreConversionRatiosOpenCurrentYearNihDirects, this.coreConversionRatiosRevisedCurrentYearNihDirects);
    }),
    coreConversionRatiosTransitionNextYearNihIndirects: Ember.computed('coreConversionRatiosRevisedCurrentYearNihIndirects', 'coreConversionRatiosRevisedCurrentYearNihDirects', 'coreConversionRatiosSettingsTransitionNextYearNih', function () {
      var directs = this.coreConversionRatiosTransitionNextYearNihDirects;
      return this.adjustIndirectConversionRatio(directs, this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearNih, this.coreConversionRatiosOpenCurrentYearNihIndirects, this.coreConversionRatiosRevisedCurrentYearNihIndirects));
    }),
    coreConversionRatiosTransitionNextYearOtherFedDirects: Ember.computed('coreConversionRatiosRevisedCurrentYearOtherFedDirects', 'coreConversionRatiosOpenCurrentYearOtherFedDirects', 'coreConversionRatiosSettingsTransitionNextYearOtherFed', function () {
      return this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearOtherFed, this.coreConversionRatiosOpenCurrentYearOtherFedDirects, this.coreConversionRatiosRevisedCurrentYearOtherFedDirects);
    }),
    coreConversionRatiosTransitionNextYearOtherFedIndirects: Ember.computed('coreConversionRatiosRevisedCurrentYearOtherFedIndirects', 'coreConversionRatiosRevisedCurrentYearOtherFedDirects', 'coreConversionRatiosSettingsTransitionNextYearOtherFed', function () {
      var directs = this.coreConversionRatiosTransitionNextYearOtherFedDirects;
      return this.adjustIndirectConversionRatio(directs, this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearOtherFed, this.coreConversionRatiosOpenCurrentYearOtherFedIndirects, this.coreConversionRatiosRevisedCurrentYearOtherFedIndirects));
    }),
    coreConversionRatiosTransitionNextYearFedFlowThruDirects: Ember.computed('coreConversionRatiosRevisedCurrentYearFedFlowThruDirects', 'coreConversionRatiosSettingsTransitionNextYearFedFlowThru', function () {
      return this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearFedFlowThru, this.coreConversionRatiosOpenCurrentYearFedFlowThruDirects, this.coreConversionRatiosRevisedCurrentYearFedFlowThruDirects);
    }),
    coreConversionRatiosTransitionNextYearFedFlowThruIndirects: Ember.computed('coreConversionRatiosRevisedCurrentYearFedFlowThruIndirects', 'coreConversionRatiosRevisedCurrentYearFedFlowThruDirects', 'coreConversionRatiosSettingsTransitionNextYearFedFlowThru', function () {
      var directs = this.coreConversionRatiosTransitionNextYearFedFlowThruDirects;
      return this.adjustIndirectConversionRatio(directs, this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearFedFlowThru, this.coreConversionRatiosOpenCurrentYearFedFlowThruIndirects, this.coreConversionRatiosRevisedCurrentYearFedFlowThruIndirects));
    }),
    coreConversionRatiosTransitionNextYearNonFedDirects: Ember.computed('coreConversionRatiosRevisedCurrentYearNonFedDirects', 'coreConversionRatiosSettingsTransitionNextYearNonFed', function () {
      return this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearNonFed, this.coreConversionRatiosOpenCurrentYearNonFedDirects, this.coreConversionRatiosRevisedCurrentYearNonFedDirects);
    }),
    coreConversionRatiosTransitionNextYearNonFedIndirects: Ember.computed('coreConversionRatiosRevisedCurrentYearNonFedIndirects', 'coreConversionRatiosSettingsTransitionNextYearNonFed', 'coreConversionRatiosTransitionNextYearNonFedIndirects', function () {
      var directs = this.coreConversionRatiosTransitionNextYearNonFedDirects;
      return this.adjustIndirectConversionRatio(directs, this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearNonFed, this.coreConversionRatiosOpenCurrentYearNonFedIndirects, this.coreConversionRatiosRevisedCurrentYearNonFedIndirects));
    }),
    coreConversionRatiosTransitionNextYearSponsor5Directs: Ember.computed('coreConversionRatiosRevisedCurrentYearSponsor5Directs', 'coreConversionRatiosSettingsTransitionNextYearSponsor5', function () {
      return this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearSponsor5, this.coreConversionRatiosOpenCurrentYearSponsor5Directs, this.coreConversionRatiosRevisedCurrentYearSponsor5Directs);
    }),
    coreConversionRatiosTransitionNextYearSponsor5Indirects: Ember.computed('coreConversionRatiosRevisedCurrentYearSponsor5Indirects', 'coreConversionRatiosSettingsTransitionNextYearSponsor5', 'coreConversionRatiosTransitionNextYearSponsor5Indirects', function () {
      var directs = this.coreConversionRatiosTransitionNextYearSponsor5Directs;
      return this.adjustIndirectConversionRatio(directs, this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearSponsor5, this.coreConversionRatiosOpenCurrentYearSponsor5Indirects, this.coreConversionRatiosRevisedCurrentYearSponsor5Indirects));
    }),
    coreConversionRatiosTransitionNextYearSponsor6Directs: Ember.computed('coreConversionRatiosRevisedCurrentYearSponsor6Directs', 'coreConversionRatiosSettingsTransitionNextYearSponsor6', function () {
      return this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearSponsor6, this.coreConversionRatiosOpenCurrentYearSponsor6Directs, this.coreConversionRatiosRevisedCurrentYearSponsor6Directs);
    }),
    coreConversionRatiosTransitionNextYearSponsor6Indirects: Ember.computed('coreConversionRatiosRevisedCurrentYearSponsor6Indirects', 'coreConversionRatiosSettingsTransitionNextYearSponsor6', 'coreConversionRatiosTransitionNextYearSponsor6Indirects', function () {
      var directs = this.coreConversionRatiosTransitionNextYearSponsor6Directs;
      return this.adjustIndirectConversionRatio(directs, this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearSponsor6, this.coreConversionRatiosOpenCurrentYearSponsor6Indirects, this.coreConversionRatiosRevisedCurrentYearSponsor6Indirects));
    }),
    coreConversionRatiosTransitionNextYearSponsor7Directs: Ember.computed('coreConversionRatiosRevisedCurrentYearSponsor7Directs', 'coreConversionRatiosSettingsTransitionNextYearSponsor7', function () {
      return this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearSponsor7, this.coreConversionRatiosOpenCurrentYearSponsor7Directs, this.coreConversionRatiosRevisedCurrentYearSponsor7Directs);
    }),
    coreConversionRatiosTransitionNextYearSponsor7Indirects: Ember.computed('coreConversionRatiosRevisedCurrentYearSponsor7Indirects', 'coreConversionRatiosSettingsTransitionNextYearSponsor7', 'coreConversionRatiosTransitionNextYearSponsor7Indirects', function () {
      var directs = this.coreConversionRatiosTransitionNextYearSponsor7Directs;
      return this.adjustIndirectConversionRatio(directs, this.nextYearTransitionValue(this.coreConversionRatiosSettingsTransitionNextYearSponsor7, this.coreConversionRatiosOpenCurrentYearSponsor7Indirects, this.coreConversionRatiosRevisedCurrentYearSponsor7Indirects));
    }),
    coreProposalsOpenCurrentYearNihDirects: Ember.computed('model.projection.{coreProposalsOneYearAgoNihDirects,coreProposalsTwoYearAverageNihDirects,coreProposalsThreeYearAverageNihDirects}', 'coreProposalsSettingsOpenCurrentYearNih', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearNih, this.get('model.projection.coreProposalsOneYearAgoNihDirects'), this.get('model.projection.coreProposalsTwoYearAverageNihDirects'), this.get('model.projection.coreProposalsThreeYearAverageNihDirects'));
    }),
    coreProposalsOpenCurrentYearNihIndirects: Ember.computed('model.projection.{coreProposalsOneYearAgoNihIndirects,coreProposalsTwoYearAverageNihIndirects,coreProposalsThreeYearAverageNihIndirects}', 'coreProposalsSettingsOpenCurrentYearNih', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearNih, this.get('model.projection.coreProposalsOneYearAgoNihIndirects'), this.get('model.projection.coreProposalsTwoYearAverageNihIndirects'), this.get('model.projection.coreProposalsThreeYearAverageNihIndirects'));
    }),
    coreProposalsOpenCurrentYearOtherFedDirects: Ember.computed('model.projection.{coreProposalsOneYearAgoOtherFedDirects,coreProposalsTwoYearAverageOtherFedDirects,coreProposalsThreeYearAverageOtherFedDirects}', 'coreProposalsSettingsOpenCurrentYearOtherFed', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearOtherFed, this.get('model.projection.coreProposalsOneYearAgoOtherFedDirects'), this.get('model.projection.coreProposalsTwoYearAverageOtherFedDirects'), this.get('model.projection.coreProposalsThreeYearAverageOtherFedDirects'));
    }),
    coreProposalsOpenCurrentYearOtherFedIndirects: Ember.computed('model.projection.{coreProposalsOneYearAgoOtherFedIndirects,coreProposalsTwoYearAverageOtherFedIndirects,coreProposalsThreeYearAverageOtherFedIndirects}', 'coreProposalsSettingsOpenCurrentYearOtherFed', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearOtherFed, this.get('model.projection.coreProposalsOneYearAgoOtherFedIndirects'), this.get('model.projection.coreProposalsTwoYearAverageOtherFedIndirects'), this.get('model.projection.coreProposalsThreeYearAverageOtherFedIndirects'));
    }),
    coreProposalsOpenCurrentYearFedFlowThruDirects: Ember.computed('model.projection.{coreProposalsOneYearAgoFedFlowThruDirects,coreProposalsTwoYearAverageFedFlowThruDirects,coreProposalsThreeYearAverageFedFlowThruDirects}', 'coreProposalsSettingsOpenCurrentYearFedFlowThru', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearFedFlowThru, this.get('model.projection.coreProposalsOneYearAgoFedFlowThruDirects'), this.get('model.projection.coreProposalsTwoYearAverageFedFlowThruDirects'), this.get('model.projection.coreProposalsThreeYearAverageFedFlowThruDirects'));
    }),
    coreProposalsOpenCurrentYearFedFlowThruIndirects: Ember.computed('model.projection.{coreProposalsOneYearAgoFedFlowThruIndirects,coreProposalsTwoYearAverageFedFlowThruIndirects,coreProposalsThreeYearAverageFedFlowThruIndirects}', 'coreProposalsSettingsOpenCurrentYearFedFlowThru', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearFedFlowThru, this.get('model.projection.coreProposalsOneYearAgoFedFlowThruIndirects'), this.get('model.projection.coreProposalsTwoYearAverageFedFlowThruIndirects'), this.get('model.projection.coreProposalsThreeYearAverageFedFlowThruIndirects'));
    }),
    coreProposalsOpenCurrentYearNonFedDirects: Ember.computed('model.projection.{coreProposalsOneYearAgoNonFedDirects,coreProposalsTwoYearAverageNonFedDirects,coreProposalsThreeYearAverageNonFedDirects}', 'coreProposalsSettingsOpenCurrentYearNonFed', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearNonFed, this.get('model.projection.coreProposalsOneYearAgoNonFedDirects'), this.get('model.projection.coreProposalsTwoYearAverageNonFedDirects'), this.get('model.projection.coreProposalsThreeYearAverageNonFedDirects'));
    }),
    coreProposalsOpenCurrentYearNonFedIndirects: Ember.computed('model.projection.{coreProposalsOneYearAgoNonFedIndirects,coreProposalsTwoYearAverageNonFedIndirects,coreProposalsThreeYearAverageNonFedIndirects}', 'coreProposalsSettingsOpenCurrentYearNonFed', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearNonFed, this.get('model.projection.coreProposalsOneYearAgoNonFedIndirects'), this.get('model.projection.coreProposalsTwoYearAverageNonFedIndirects'), this.get('model.projection.coreProposalsThreeYearAverageNonFedIndirects'));
    }),
    coreProposalsOpenCurrentYearSponsor5Directs: Ember.computed('model.projection.{coreProposalsOneYearAgoSponsor5Directs,coreProposalsTwoYearAverageSponsor5Directs,coreProposalsThreeYearAverageSponsor5Directs}', 'coreProposalsSettingsOpenCurrentYearSponsor5', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearSponsor5, this.get('model.projection.coreProposalsOneYearAgoSponsor5Directs'), this.get('model.projection.coreProposalsTwoYearAverageSponsor5Directs'), this.get('model.projection.coreProposalsThreeYearAverageSponsor5Directs'));
    }),
    coreProposalsOpenCurrentYearSponsor5Indirects: Ember.computed('model.projection.{coreProposalsOneYearAgoSponsor5Indirects,coreProposalsTwoYearAverageSponsor5Indirects,coreProposalsThreeYearAverageSponsor5Indirects}', 'coreProposalsSettingsOpenCurrentYearSponsor5', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearSponsor5, this.get('model.projection.coreProposalsOneYearAgoSponsor5Indirects'), this.get('model.projection.coreProposalsTwoYearAverageSponsor5Indirects'), this.get('model.projection.coreProposalsThreeYearAverageSponsor5Indirects'));
    }),
    coreProposalsOpenCurrentYearSponsor6Directs: Ember.computed('model.projection.{coreProposalsOneYearAgoSponsor6Directs,coreProposalsTwoYearAverageSponsor6Directs,coreProposalsThreeYearAverageSponsor6Directs}', 'coreProposalsSettingsOpenCurrentYearSponsor6', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearSponsor6, this.get('model.projection.coreProposalsOneYearAgoSponsor6Directs'), this.get('model.projection.coreProposalsTwoYearAverageSponsor6Directs'), this.get('model.projection.coreProposalsThreeYearAverageSponsor6Directs'));
    }),
    coreProposalsOpenCurrentYearSponsor6Indirects: Ember.computed('model.projection.{coreProposalsOneYearAgoSponsor6Indirects,coreProposalsTwoYearAverageSponsor6Indirects,coreProposalsThreeYearAverageSponsor6Indirects}', 'coreProposalsSettingsOpenCurrentYearSponsor6', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearSponsor6, this.get('model.projection.coreProposalsOneYearAgoSponsor6Indirects'), this.get('model.projection.coreProposalsTwoYearAverageSponsor6Indirects'), this.get('model.projection.coreProposalsThreeYearAverageSponsor6Indirects'));
    }),
    coreProposalsOpenCurrentYearSponsor7Directs: Ember.computed('model.projection.{coreProposalsOneYearAgoSponsor7Directs,coreProposalsTwoYearAverageSponsor7Directs,coreProposalsThreeYearAverageSponsor7Directs}', 'coreProposalsSettingsOpenCurrentYearSponsor7', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearSponsor7, this.get('model.projection.coreProposalsOneYearAgoSponsor7Directs'), this.get('model.projection.coreProposalsTwoYearAverageSponsor7Directs'), this.get('model.projection.coreProposalsThreeYearAverageSponsor7Directs'));
    }),
    coreProposalsOpenCurrentYearSponsor7Indirects: Ember.computed('model.projection.{coreProposalsOneYearAgoSponsor7Indirects,coreProposalsTwoYearAverageSponsor7Indirects,coreProposalsThreeYearAverageSponsor7Indirects}', 'coreProposalsSettingsOpenCurrentYearSponsor7', function () {
      return this.currentYearOpenValue(this.coreProposalsSettingsOpenCurrentYearSponsor7, this.get('model.projection.coreProposalsOneYearAgoSponsor7Indirects'), this.get('model.projection.coreProposalsTwoYearAverageSponsor7Indirects'), this.get('model.projection.coreProposalsThreeYearAverageSponsor7Indirects'));
    }),
    coreProposalsRevisedCurrentYearNihDirects: Ember.computed('model.projection.{coreProposalsCurrentYearNihDirects,coreProposalsOneYearAgoYtdNihDirects,coreProposalsOneYearAgoNihDirects}', 'coreProposalsOpenCurrentYearNihDirects', 'coreProposalsSettingsRevisedCurrentYearNih', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearNih, this.coreProposalsOpenCurrentYearNihDirects, this.get('model.projection.coreProposalsCurrentYearNihDirects'), this.get('model.projection.coreProposalsOneYearAgoYtdNihDirects'), this.get('model.projection.coreProposalsOneYearAgoNihDirects'));
    }),
    coreProposalsRevisedCurrentYearNihIndirects: Ember.computed('model.projection.{coreProposalsCurrentYearNihIndirects,coreProposalsOneYearAgoYtdNihIndirects,coreProposalsOneYearAgoNihIndirects}', 'coreProposalsOpenCurrentYearNihIndirects', 'coreProposalsSettingsRevisedCurrentYearNih', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearNih, this.coreProposalsOpenCurrentYearNihIndirects, this.get('model.projection.coreProposalsCurrentYearNihIndirects'), this.get('model.projection.coreProposalsOneYearAgoYtdNihIndirects'), this.get('model.projection.coreProposalsOneYearAgoNihIndirects'));
    }),
    coreProposalsRevisedCurrentYearNonFedDirects: Ember.computed('model.projection.{coreProposalsCurrentYearNonFedDirects,coreProposalsOneYearAgoYtdNonFedDirects,coreProposalsOneYearAgoNonFedDirects}', 'coreProposalsOpenCurrentYearNonFedDirects', 'coreProposalsSettingsRevisedCurrentYearNonFed', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearNonFed, this.coreProposalsOpenCurrentYearNonFedDirects, this.get('model.projection.coreProposalsCurrentYearNonFedDirects'), this.get('model.projection.coreProposalsOneYearAgoYtdNonFedDirects'), this.get('model.projection.coreProposalsOneYearAgoNonFedDirects'));
    }),
    coreProposalsRevisedCurrentYearNonFedIndirects: Ember.computed('model.projection.{coreProposalsCurrentYearNonFedIndirects,coreProposalsOneYearAgoYtdNonFedIndirects,coreProposalsOneYearAgoNonFedIndirects}', 'coreProposalsOpenCurrentYearNonFedIndirects', 'coreProposalsSettingsRevisedCurrentYearNonFed', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearNonFed, this.coreProposalsOpenCurrentYearNonFedIndirects, this.get('model.projection.coreProposalsCurrentYearNonFedIndirects'), this.get('model.projection.coreProposalsOneYearAgoYtdNonFedIndirects'), this.get('model.projection.coreProposalsOneYearAgoNonFedIndirects'));
    }),
    coreProposalsRevisedCurrentYearFedFlowThruDirects: Ember.computed('model.projection.{coreProposalsCurrentYearFedFlowThruDirects,coreProposalsOneYearAgoYtdFedFlowThruDirects,coreProposalsOneYearAgoFedFlowThruDirects}', 'coreProposalsOpenCurrentYearFedFlowThruDirects', 'coreProposalsSettingsRevisedCurrentYearFedFlowThru', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearFedFlowThru, this.coreProposalsOpenCurrentYearFedFlowThruDirects, this.get('model.projection.coreProposalsCurrentYearFedFlowThruDirects'), this.get('model.projection.coreProposalsOneYearAgoYtdFedFlowThruDirects'), this.get('model.projection.coreProposalsOneYearAgoFedFlowThruDirects'));
    }),
    coreProposalsRevisedCurrentYearFedFlowThruIndirects: Ember.computed('model.projection.{coreProposalsCurrentYearFedFlowThruIndirects,coreProposalsOneYearAgoYtdFedFlowThruIndirects,coreProposalsOneYearAgoFedFlowThruIndirects}', 'coreProposalsOpenCurrentYearFedFlowThruIndirects', 'coreProposalsSettingsRevisedCurrentYearFedFlowThru', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearFedFlowThru, this.coreProposalsOpenCurrentYearFedFlowThruIndirects, this.get('model.projection.coreProposalsCurrentYearFedFlowThruIndirects'), this.get('model.projection.coreProposalsOneYearAgoYtdFedFlowThruIndirects'), this.get('model.projection.coreProposalsOneYearAgoFedFlowThruIndirects'));
    }),
    coreProposalsRevisedCurrentYearOtherFedDirects: Ember.computed('model.projection.{coreProposalsCurrentYearOtherFedDirects,coreProposalsOneYearAgoYtdOtherFedDirects,coreProposalsOneYearAgoOtherFedDirects}', 'coreProposalsOpenCurrentYearOtherFedDirects', 'coreProposalsSettingsRevisedCurrentYearOtherFed', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearOtherFed, this.coreProposalsOpenCurrentYearOtherFedDirects, this.get('model.projection.coreProposalsCurrentYearOtherFedDirects'), this.get('model.projection.coreProposalsOneYearAgoYtdOtherFedDirects'), this.get('model.projection.coreProposalsOneYearAgoOtherFedDirects'));
    }),
    coreProposalsRevisedCurrentYearOtherFedIndirects: Ember.computed('model.projection.{coreProposalsCurrentYearOtherFedIndirects,coreProposalsOneYearAgoYtdOtherFedIndirects,coreProposalsOneYearAgoOtherFedIndirects}', 'coreProposalsOpenCurrentYearOtherFedIndirects', 'coreProposalsSettingsRevisedCurrentYearOtherFed', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearOtherFed, this.coreProposalsOpenCurrentYearOtherFedIndirects, this.get('model.projection.coreProposalsCurrentYearOtherFedIndirects'), this.get('model.projection.coreProposalsOneYearAgoYtdOtherFedIndirects'), this.get('model.projection.coreProposalsOneYearAgoOtherFedIndirects'));
    }),
    coreProposalsRevisedCurrentYearSponsor5Directs: Ember.computed('model.projection.{coreProposalsCurrentYearSponsor5Directs,coreProposalsOneYearAgoYtdSponsor5Directs,coreProposalsOneYearAgoSponsor5Directs}', 'coreProposalsOpenCurrentYearSponsor5Directs', 'coreProposalsSettingsRevisedCurrentYearSponsor5', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearSponsor5, this.coreProposalsOpenCurrentYearSponsor5Directs, this.get('model.projection.coreProposalsCurrentYearSponsor5Directs'), this.get('model.projection.coreProposalsOneYearAgoYtdSponsor5Directs'), this.get('model.projection.coreProposalsOneYearAgoSponsor5Directs'));
    }),
    coreProposalsRevisedCurrentYearSponsor5Indirects: Ember.computed('model.projection.{coreProposalsCurrentYearSponsor5Indirects,coreProposalsOneYearAgoYtdSponsor5Indirects,coreProposalsOneYearAgoSponsor5Indirects}', 'coreProposalsOpenCurrentYearSponsor5Indirects', 'coreProposalsSettingsRevisedCurrentYearSponsor5', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearSponsor5, this.coreProposalsOpenCurrentYearSponsor5Indirects, this.get('model.projection.coreProposalsCurrentYearSponsor5Indirects'), this.get('model.projection.coreProposalsOneYearAgoYtdSponsor5Indirects'), this.get('model.projection.coreProposalsOneYearAgoSponsor5Indirects'));
    }),
    coreProposalsRevisedCurrentYearSponsor6Directs: Ember.computed('model.projection.{coreProposalsCurrentYearSponsor6Directs,coreProposalsOneYearAgoYtdSponsor6Directs,coreProposalsOneYearAgoSponsor6Directs}', 'coreProposalsOpenCurrentYearSponsor6Directs', 'coreProposalsSettingsRevisedCurrentYearSponsor6', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearSponsor6, this.coreProposalsOpenCurrentYearSponsor6Directs, this.get('model.projection.coreProposalsCurrentYearSponsor6Directs'), this.get('model.projection.coreProposalsOneYearAgoYtdSponsor6Directs'), this.get('model.projection.coreProposalsOneYearAgoSponsor6Directs'));
    }),
    coreProposalsRevisedCurrentYearSponsor6Indirects: Ember.computed('model.projection.{coreProposalsCurrentYearSponsor6Indirects,coreProposalsOneYearAgoYtdSponsor6Indirects,coreProposalsOneYearAgoSponsor6Indirects}', 'coreProposalsOpenCurrentYearSponsor6Indirects', 'coreProposalsSettingsRevisedCurrentYearSponsor6', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearSponsor6, this.coreProposalsOpenCurrentYearSponsor6Indirects, this.get('model.projection.coreProposalsCurrentYearSponsor6Indirects'), this.get('model.projection.coreProposalsOneYearAgoYtdSponsor6Indirects'), this.get('model.projection.coreProposalsOneYearAgoSponsor6Indirects'));
    }),
    coreProposalsRevisedCurrentYearSponsor7Directs: Ember.computed('model.projection.{coreProposalsCurrentYearSponsor7Directs,coreProposalsOneYearAgoYtdSponsor7Directs,coreProposalsOneYearAgoSponsor7Directs}', 'coreProposalsOpenCurrentYearSponsor7Directs', 'coreProposalsSettingsRevisedCurrentYearSponsor7', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearSponsor7, this.coreProposalsOpenCurrentYearSponsor7Directs, this.get('model.projection.coreProposalsCurrentYearSponsor7Directs'), this.get('model.projection.coreProposalsOneYearAgoYtdSponsor7Directs'), this.get('model.projection.coreProposalsOneYearAgoSponsor7Directs'));
    }),
    coreProposalsRevisedCurrentYearSponsor7Indirects: Ember.computed('model.projection.{coreProposalsCurrentYearSponsor7Indirects,coreProposalsOneYearAgoYtdSponsor7Indirects,coreProposalsOneYearAgoSponsor7Indirects}', 'coreProposalsOpenCurrentYearSponsor7Indirects', 'coreProposalsSettingsRevisedCurrentYearSponsor7', function () {
      return this.currentYearRevisedValue(this.coreProposalsSettingsRevisedCurrentYearSponsor7, this.coreProposalsOpenCurrentYearSponsor7Indirects, this.get('model.projection.coreProposalsCurrentYearSponsor7Indirects'), this.get('model.projection.coreProposalsOneYearAgoYtdSponsor7Indirects'), this.get('model.projection.coreProposalsOneYearAgoSponsor7Indirects'));
    }),
    coreProposalsTransitionNextYearNihDirects: Ember.computed('coreProposalsRevisedCurrentYearNihDirects', 'coreProposalsOpenCurrentYearNihDirects', 'coreProposalsSettingsTransitionNextYearNih', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearNih, this.coreProposalsOpenCurrentYearNihDirects, this.coreProposalsRevisedCurrentYearNihDirects);
    }),
    coreProposalsTransitionNextYearNihIndirects: Ember.computed('coreProposalsRevisedCurrentYearNihIndirects', 'coreProposalsOpenCurrentYearNihIndirects', 'coreProposalsSettingsTransitionNextYearNih', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearNih, this.coreProposalsOpenCurrentYearNihIndirects, this.coreProposalsRevisedCurrentYearNihIndirects);
    }),
    coreProposalsTransitionNextYearOtherFedDirects: Ember.computed('coreProposalsRevisedCurrentYearOtherFedDirects', 'coreProposalsOpenCurrentYearOtherFedDirects', 'coreProposalsSettingsTransitionNextYearOtherFed', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearOtherFed, this.coreProposalsOpenCurrentYearOtherFedDirects, this.coreProposalsRevisedCurrentYearOtherFedDirects);
    }),
    coreProposalsTransitionNextYearOtherFedIndirects: Ember.computed('coreProposalsRevisedCurrentYearOtherFedIndirects', 'coreProposalsOpenCurrentYearOtherFedIndirects', 'coreProposalsSettingsTransitionNextYearOtherFed', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearOtherFed, this.coreProposalsOpenCurrentYearOtherFedIndirects, this.coreProposalsRevisedCurrentYearOtherFedIndirects);
    }),
    coreProposalsTransitionNextYearFedFlowThruDirects: Ember.computed('coreProposalsRevisedCurrentYearFedFlowThruDirects', 'coreProposalsOpenCurrentYearFedFlowThruDirects', 'coreProposalsSettingsTransitionNextYearFedFlowThru', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearFedFlowThru, this.coreProposalsOpenCurrentYearFedFlowThruDirects, this.coreProposalsRevisedCurrentYearFedFlowThruDirects);
    }),
    coreProposalsTransitionNextYearFedFlowThruIndirects: Ember.computed('coreProposalsRevisedCurrentYearFedFlowThruIndirects', 'coreProposalsOpenCurrentYearFedFlowThruIndirects', 'coreProposalsSettingsTransitionNextYearFedFlowThru', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearFedFlowThru, this.coreProposalsOpenCurrentYearFedFlowThruIndirects, this.coreProposalsRevisedCurrentYearFedFlowThruIndirects);
    }),
    coreProposalsTransitionNextYearNonFedDirects: Ember.computed('coreProposalsRevisedCurrentYearNonFedDirects', 'coreProposalsOpenCurrentYearNonFedDirects', 'coreProposalsSettingsTransitionNextYearNonFed', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearNonFed, this.coreProposalsOpenCurrentYearNonFedDirects, this.coreProposalsRevisedCurrentYearNonFedDirects);
    }),
    coreProposalsTransitionNextYearNonFedIndirects: Ember.computed('coreProposalsRevisedCurrentYearNonFedIndirects', 'coreProposalsOpenCurrentYearNonFedIndirects', 'coreProposalsSettingsTransitionNextYearNonFed', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearNonFed, this.coreProposalsOpenCurrentYearNonFedIndirects, this.coreProposalsRevisedCurrentYearNonFedIndirects);
    }),
    coreProposalsTransitionNextYearSponsor5Directs: Ember.computed('coreProposalsRevisedCurrentYearSponsor5Directs', 'coreProposalsOpenCurrentYearSponsor5Directs', 'coreProposalsSettingsTransitionNextYearSponsor5', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearSponsor5, this.coreProposalsOpenCurrentYearSponsor5Directs, this.coreProposalsRevisedCurrentYearSponsor5Directs);
    }),
    coreProposalsTransitionNextYearSponsor5Indirects: Ember.computed('coreProposalsRevisedCurrentYearSponsor5Indirects', 'coreProposalsOpenCurrentYearSponsor5Indirects', 'coreProposalsSettingsTransitionNextYearSponsor5', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearSponsor5, this.coreProposalsOpenCurrentYearSponsor5Indirects, this.coreProposalsRevisedCurrentYearSponsor5Indirects);
    }),
    coreProposalsTransitionNextYearSponsor6Directs: Ember.computed('coreProposalsRevisedCurrentYearSponsor6Directs', 'coreProposalsOpenCurrentYearSponsor6Directs', 'coreProposalsSettingsTransitionNextYearSponsor6', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearSponsor6, this.coreProposalsOpenCurrentYearSponsor6Directs, this.coreProposalsRevisedCurrentYearSponsor6Directs);
    }),
    coreProposalsTransitionNextYearSponsor6Indirects: Ember.computed('coreProposalsRevisedCurrentYearSponsor6Indirects', 'coreProposalsOpenCurrentYearSponsor6Indirects', 'coreProposalsSettingsTransitionNextYearSponsor6', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearSponsor6, this.coreProposalsOpenCurrentYearSponsor6Indirects, this.coreProposalsRevisedCurrentYearSponsor6Indirects);
    }),
    coreProposalsTransitionNextYearSponsor7Directs: Ember.computed('coreProposalsRevisedCurrentYearSponsor7Directs', 'coreProposalsOpenCurrentYearSponsor7Directs', 'coreProposalsSettingsTransitionNextYearSponsor7', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearSponsor7, this.coreProposalsOpenCurrentYearSponsor7Directs, this.coreProposalsRevisedCurrentYearSponsor7Directs);
    }),
    coreProposalsTransitionNextYearSponsor7Indirects: Ember.computed('coreProposalsRevisedCurrentYearSponsor7Indirects', 'coreProposalsOpenCurrentYearSponsor7Indirects', 'coreProposalsSettingsTransitionNextYearSponsor7', function () {
      return this.nextYearTransitionValue(this.coreProposalsSettingsTransitionNextYearSponsor7, this.coreProposalsOpenCurrentYearSponsor7Indirects, this.coreProposalsRevisedCurrentYearSponsor7Indirects);
    }),
    projectCoreProposalsCurrentYear: function projectCoreProposalsCurrentYear(open, change) {
      if (open === 0) {
        return change;
      } else {
        return open * (1 + change);
      }
    },
    projectedProposalsCurrentYearNihDirects: Ember.computed('coreProposalsOpenCurrentYearNihDirects', 'coreProposalsCurrentYearChangeNihDirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearNihDirects, this.coreProposalsCurrentYearChangeNihDirects);
    }),
    projectedProposalsCurrentYearNihIndirects: Ember.computed('coreProposalsOpenCurrentYearNihIndirects', 'coreProposalsCurrentYearChangeNihIndirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearNihIndirects, this.coreProposalsCurrentYearChangeNihIndirects);
    }),
    projectedProposalsCurrentYearOtherFedDirects: Ember.computed('coreProposalsOpenCurrentYearOtherFedDirects', 'coreProposalsCurrentYearChangeOtherFedDirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearOtherFedDirects, this.coreProposalsCurrentYearChangeOtherFedDirects);
    }),
    projectedProposalsCurrentYearOtherFedIndirects: Ember.computed('coreProposalsOpenCurrentYearOtherFedIndirects', 'coreProposalsCurrentYearChangeOtherFedIndirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearOtherFedIndirects, this.coreProposalsCurrentYearChangeOtherFedIndirects);
    }),
    projectedProposalsCurrentYearFedFlowThruDirects: Ember.computed('coreProposalsOpenCurrentYearFedFlowThruDirects', 'coreProposalsCurrentYearChangeFedFlowThruDirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearFedFlowThruDirects, this.coreProposalsCurrentYearChangeFedFlowThruDirects);
    }),
    projectedProposalsCurrentYearFedFlowThruIndirects: Ember.computed('coreProposalsOpenCurrentYearFedFlowThruIndirects', 'coreProposalsCurrentYearChangeFedFlowThruIndirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearFedFlowThruIndirects, this.coreProposalsCurrentYearChangeFedFlowThruIndirects);
    }),
    projectedProposalsCurrentYearNonFedDirects: Ember.computed('coreProposalsOpenCurrentYearNonFedDirects', 'coreProposalsCurrentYearChangeNonFedDirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearNonFedDirects, this.coreProposalsCurrentYearChangeNonFedDirects);
    }),
    projectedProposalsCurrentYearNonFedIndirects: Ember.computed('coreProposalsOpenCurrentYearNonFedIndirects', 'coreProposalsCurrentYearChangeNonFedIndirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearNonFedIndirects, this.coreProposalsCurrentYearChangeNonFedIndirects);
    }),
    projectedProposalsCurrentYearSponsor5Directs: Ember.computed('coreProposalsOpenCurrentYearSponsor5Directs', 'coreProposalsCurrentYearChangeSponsor5Directs', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearSponsor5Directs, this.coreProposalsCurrentYearChangeSponsor5Directs);
    }),
    projectedProposalsCurrentYearSponsor5Indirects: Ember.computed('coreProposalsOpenCurrentYearSponsor5Indirects', 'coreProposalsCurrentYearChangeSponsor5Indirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearSponsor5Indirects, this.coreProposalsCurrentYearChangeSponsor5Indirects);
    }),
    projectedProposalsCurrentYearSponsor6Directs: Ember.computed('coreProposalsOpenCurrentYearSponsor6Directs', 'coreProposalsCurrentYearChangeSponsor6Directs', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearSponsor6Directs, this.coreProposalsCurrentYearChangeSponsor6Directs);
    }),
    projectedProposalsCurrentYearSponsor6Indirects: Ember.computed('coreProposalsOpenCurrentYearSponsor6Indirects', 'coreProposalsCurrentYearChangeSponsor6Indirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearSponsor6Indirects, this.coreProposalsCurrentYearChangeSponsor6Indirects);
    }),
    projectedProposalsCurrentYearSponsor7Directs: Ember.computed('coreProposalsOpenCurrentYearSponsor7Directs', 'coreProposalsCurrentYearChangeSponsor7Directs', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearSponsor7Directs, this.coreProposalsCurrentYearChangeSponsor7Directs);
    }),
    projectedProposalsCurrentYearSponsor7Indirects: Ember.computed('coreProposalsOpenCurrentYearSponsor7Indirects', 'coreProposalsCurrentYearChangeSponsor7Indirects', function () {
      return this.projectCoreProposalsCurrentYear(this.coreProposalsOpenCurrentYearSponsor7Indirects, this.coreProposalsCurrentYearChangeSponsor7Indirects);
    }),
    projectedCoreConversionRatiosCurrentYearNihDirects: Ember.computed('coreConversionRatiosOpenCurrentYearNihDirects', 'coreConversionRatiosRevisedCurrentYearNihDirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearNihDirects;
    }),
    projectedCoreConversionRatiosCurrentYearNihIndirects: Ember.computed('coreConversionRatiosOpenCurrentYearNihIndirects', 'coreConversionRatiosRevisedCurrentYearNihIndirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearNihIndirects;
    }),
    projectedCoreConversionRatiosCurrentYearOtherFedDirects: Ember.computed('coreConversionRatiosOpenCurrentYearOtherFedDirects', 'coreConversionRatiosRevisedCurrentYearOtherFedDirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearOtherFedDirects;
    }),
    projectedCoreConversionRatiosCurrentYearOtherFedIndirects: Ember.computed('coreConversionRatiosOpenCurrentYearOtherFedIndirects', 'coreConversionRatiosRevisedCurrentYearOtherFedIndirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearOtherFedIndirects;
    }),
    projectedCoreConversionRatiosCurrentYearFedFlowThruDirects: Ember.computed('coreConversionRatiosOpenCurrentYearFedFlowThruDirects', 'coreConversionRatiosRevisedCurrentYearFedFlowThruDirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearFedFlowThruDirects;
    }),
    projectedCoreConversionRatiosCurrentYearFedFlowThruIndirects: Ember.computed('coreConversionRatiosOpenCurrentYearFedFlowThruIndirects', 'coreConversionRatiosRevisedCurrentYearFedFlowThruIndirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearFedFlowThruIndirects;
    }),
    projectedCoreConversionRatiosCurrentYearNonFedDirects: Ember.computed('coreConversionRatiosOpenCurrentYearNonFedDirects', 'coreConversionRatiosRevisedCurrentYearNonFedDirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearNonFedDirects;
    }),
    projectedCoreConversionRatiosCurrentYearNonFedIndirects: Ember.computed('coreConversionRatiosOpenCurrentYearNonFedIndirects', 'coreConversionRatiosRevisedCurrentYearNonFedIndirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearNonFedIndirects;
    }),
    projectedCoreConversionRatiosCurrentYearSponsor5Directs: Ember.computed('coreConversionRatiosOpenCurrentYearSponsor5Directs', 'coreConversionRatiosRevisedCurrentYearSponsor5Directs', function () {
      return this.coreConversionRatiosRevisedCurrentYearSponsor5Directs;
    }),
    projectedCoreConversionRatiosCurrentYearSponsor5Indirects: Ember.computed('coreConversionRatiosOpenCurrentYearSponsor5Indirects', 'coreConversionRatiosRevisedCurrentYearSponsor5Indirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearSponsor5Indirects;
    }),
    projectedCoreConversionRatiosCurrentYearSponsor6Directs: Ember.computed('coreConversionRatiosOpenCurrentYearSponsor6Directs', 'coreConversionRatiosRevisedCurrentYearSponsor6Directs', function () {
      return this.coreConversionRatiosRevisedCurrentYearSponsor6Directs;
    }),
    projectedCoreConversionRatiosCurrentYearSponsor6Indirects: Ember.computed('coreConversionRatiosOpenCurrentYearSponsor6Indirects', 'coreConversionRatiosRevisedCurrentYearSponsor6Indirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearSponsor6Indirects;
    }),
    projectedCoreConversionRatiosCurrentYearSponsor7Directs: Ember.computed('coreConversionRatiosOpenCurrentYearSponsor7Directs', 'coreConversionRatiosRevisedCurrentYearSponsor7Directs', function () {
      return this.coreConversionRatiosRevisedCurrentYearSponsor7Directs;
    }),
    projectedCoreConversionRatiosCurrentYearSponsor7Indirects: Ember.computed('coreConversionRatiosOpenCurrentYearSponsor7Indirects', 'coreConversionRatiosRevisedCurrentYearSponsor7Indirects', function () {
      return this.coreConversionRatiosRevisedCurrentYearSponsor7Indirects;
    }),
    currentYearOpenValue: function currentYearOpenValue(settings, oneYearAgo, twoYearAvg, threeYearAvg) {
      if (settings === PRIORYEAR) {
        return oneYearAgo;
      } else if (settings === TWOYEARAVG) {
        return twoYearAvg;
      } else if (settings === THREEYRAVG) {
        return threeYearAvg;
      }

      return 0;
    },
    currentYearCoreConversionRatiosOpenValue: function currentYearCoreConversionRatiosOpenValue(settings, oneYearAgo, twoYearAvg, threeYearAvg, isIndirects, directs) {
      if (settings === PRIORYEAR) {
        return isIndirects ? this.adjustIndirectConversionRatio(directs, oneYearAgo) : oneYearAgo;
      } else if (settings === TWOYEARAVG) {
        return isIndirects ? this.adjustIndirectConversionRatio(directs, twoYearAvg) : twoYearAvg;
      } else if (settings === THREEYRAVG) {
        return isIndirects ? this.adjustIndirectConversionRatio(directs, threeYearAvg) : threeYearAvg;
      }

      return 0;
    },
    currentYearRevisedValue: function currentYearRevisedValue(settings, currentYearOpen, currentYearYTD, oneYearAgoYtd, oneYearAgoValue) {
      var oneYearAgoProjected = oneYearAgoValue - oneYearAgoYtd;
      var currentYearYTDChange = (currentYearYTD - oneYearAgoYtd) / oneYearAgoYtd;

      if (settings === STAY) {
        return currentYearOpen;
      } else if (settings === YTD) {
        if (oneYearAgoYtd === 0) {
          currentYearYTDChange = 0;
        }

        return currentYearYTD + (1 + currentYearYTDChange) * oneYearAgoProjected;
      } else if (settings === YTDREVISED) {
        return currentYearYTD + oneYearAgoProjected;
      }

      return 0;
    },
    currentYearCoreConversionRatiosRevisedValue: function currentYearCoreConversionRatiosRevisedValue(settings, currentYearOpen, currentYear, yearEnd, currentYearTotal, successesYTD, proposalsYTD, y1y2, proposalsOpen, proposalsRevised) {
      if (settings === STAY) {
        return currentYearOpen;
      } else if (settings === YTD) {
        var successes = this.projectYtdSuccesses(successesYTD, proposalsYTD, yearEnd); //If there are no successes return 0 for the conversion ratio

        if (successes === 0) {
          return 0;
        }

        var denominator = y1y2 * proposalsOpen + (1 - y1y2) * proposalsRevised; //If there are no proposals, but successes the conversion ratio should be 100%

        if (denominator === 0) {
          return 1;
        }

        return successes / denominator;
      } else if (settings === YTDREVISED) {
        if (currentYearTotal === 0) {
          return 0;
        }

        var _successes = successesYTD + currentYearOpen * yearEnd; //If there are no successes return 0 for the conversion ratio


        if (_successes === 0) {
          return 0;
        }

        var _denominator = y1y2 * proposalsOpen + (1 - y1y2) * proposalsRevised; //If there are no proposals, but successes the conversion ratio should be 100%


        if (_denominator === 0) {
          return 1;
        }

        return _successes / _denominator;
      }
    },
    nextYearTransitionValue: function nextYearTransitionValue(settings, open, revised) {
      if (settings === STAY) {
        return revised;
      } else if (settings === SPLIT) {
        return (open + revised) / 2;
      } else if (settings === REVERT) {
        return open;
      }

      return 0;
    },
    nextYearCoreConversionRatiosTransitionValue: function nextYearCoreConversionRatiosTransitionValue(settings, revised) {
      if (settings === STAY) {
        return 0;
      } else if (settings === SPLIT) {
        return -revised / 2;
      } else if (settings === REVERT) {
        return -revised;
      }

      return 0;
    },
    adjustIndirectConversionRatio: function adjustIndirectConversionRatio(directs, indirects) {
      //Projected indirects cannot exceed directs
      return indirects > directs ? directs : indirects;
    },
    //region Core Successes
    projectedCoreSuccessCurrentYear: function projectedCoreSuccessCurrentYear(setting, y1Y2DerivativeRatio, coreProposalsOneYearAgo, projectedProposalsCurrentYear, projectedCoreConversionRatiosCurrentYear, successesYtd, proposalsYtd, yearEnd, openConversionRatio) {
      if (setting === STAY) {
        return y1Y2DerivativeRatio * projectedCoreConversionRatiosCurrentYear * coreProposalsOneYearAgo + (1 - y1Y2DerivativeRatio) * projectedCoreConversionRatiosCurrentYear * projectedProposalsCurrentYear;
      } else if (setting === YTDREVISED) {
        return successesYtd + openConversionRatio * yearEnd;
      } else if (setting === YTD) {
        return this.projectYtdSuccesses(successesYtd, proposalsYtd, yearEnd);
      }
    },
    projectYtdSuccesses: function projectYtdSuccesses(successesYtd, proposalsYtd, yearEnd) {
      if (proposalsYtd === 0) {
        return successesYtd;
      }

      return successesYtd + successesYtd / proposalsYtd * yearEnd;
    },
    projectedCoreSuccessesCurrentYearNihDirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosNihDirects,coreProposalsOneYearAgoNihDirects}', 'projectedCoreConversionRatiosCurrentYearNihDirects', 'projectedProposalsCurrentYearNihDirects', 'coreConversionRatiosSettingsRevisedCurrentYearNih', 'coreConversionRatiosOpenCurrentYearNihDirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearNih, this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects'), this.get('model.projection.coreProposalsOneYearAgoNihDirects'), this.projectedProposalsCurrentYearNihDirects, this.projectedCoreConversionRatiosCurrentYearNihDirects, this.get('model.projection.successesYtdNihDirects'), this.get('model.projection.coreProposalsCurrentYearYtdNihDirects'), this.get('model.projection.coreProposalsYearEndNihDirects'), this.coreConversionRatiosOpenCurrentYearNihDirects);
    }),
    projectedCoreSuccessesCurrentYearNihIndirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosNihIndirects,coreProposalsOneYearAgoNihIndirects}', 'projectedCoreConversionRatiosCurrentYearNihIndirects', 'projectedProposalsCurrentYearNihIndirects', 'coreConversionRatiosOpenCurrentYearNihIndirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearNih, this.get('model.projection.yoneYtwoDerivativeRatiosNihIndirects'), this.get('model.projection.coreProposalsOneYearAgoNihIndirects'), this.projectedProposalsCurrentYearNihIndirects, this.projectedCoreConversionRatiosCurrentYearNihIndirects, this.get('model.projection.successesYtdNihIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdNihIndirects'), this.get('model.projection.coreProposalsYearEndNihIndirects'), this.coreConversionRatiosOpenCurrentYearNihIndirects);
    }),
    projectedCoreSuccessesCurrentYearFedFlowThruDirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosFedFlowThruDirects,coreProposalsOneYearAgoFedFlowThruDirects}', 'projectedCoreConversionRatiosCurrentYearFedFlowThruDirects', 'projectedProposalsCurrentYearFedFlowThruDirects', 'coreConversionRatiosOpenCurrentYearFedFlowThruDirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru, this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruDirects'), this.get('model.projection.coreProposalsOneYearAgoFedFlowThruDirects'), this.projectedProposalsCurrentYearFedFlowThruDirects, this.projectedCoreConversionRatiosCurrentYearFedFlowThruDirects, this.get('model.projection.successesYtdFedFlowThruDirects'), this.get('model.projection.coreProposalsCurrentYearYtdFedFlowThruDirects'), this.get('model.projection.coreProposalsYearEndFedFlowThruDirects'), this.coreConversionRatiosOpenCurrentYearFedFlowThruDirects);
    }),
    projectedCoreSuccessesCurrentYearFedFlowThruIndirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosFedFlowThruIndirects,coreProposalsOneYearAgoFedFlowThruIndirects}', 'projectedCoreConversionRatiosCurrentYearFedFlowThruIndirects', 'projectedProposalsCurrentYearFedFlowThruIndirects', 'coreConversionRatiosOpenCurrentYearFedFlowThruIndirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru, this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruIndirects'), this.get('model.projection.coreProposalsOneYearAgoFedFlowThruIndirects'), this.projectedProposalsCurrentYearFedFlowThruIndirects, this.projectedCoreConversionRatiosCurrentYearFedFlowThruIndirects, this.get('model.projection.successesYtdFedFlowThruIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdFedFlowThruIndirects'), this.get('model.projection.coreProposalsYearEndFedFlowThruIndirects'), this.coreConversionRatiosOpenCurrentYearFedFlowThruIndirects);
    }),
    projectedCoreSuccessesCurrentYearOtherFedDirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosOtherFedDirects,coreProposalsOneYearAgoOtherFedDirects}', 'projectedCoreConversionRatiosCurrentYearOtherFedDirects', 'projectedProposalsCurrentYearOtherFedDirects', 'coreConversionRatiosOpenCurrentYearOtherFedDirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearOtherFed, this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedDirects'), this.get('model.projection.coreProposalsOneYearAgoOtherFedDirects'), this.projectedProposalsCurrentYearOtherFedDirects, this.projectedCoreConversionRatiosCurrentYearOtherFedDirects, this.get('model.projection.successesYtdOtherFedDirects'), this.get('model.projection.coreProposalsCurrentYearYtdOtherFedDirects'), this.get('model.projection.coreProposalsYearEndOtherFedDirects'), this.coreConversionRatiosOpenCurrentYearOtherFedDirects);
    }),
    projectedCoreSuccessesCurrentYearOtherFedIndirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosOtherFedIndirects,coreProposalsOneYearAgoOtherFedIndirects}', 'projectedCoreConversionRatiosCurrentYearOtherFedIndirects', 'projectedProposalsCurrentYearOtherFedIndirects', 'coreConversionRatiosOpenCurrentYearOtherFedIndirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearOtherFed, this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedIndirects'), this.get('model.projection.coreProposalsOneYearAgoOtherFedIndirects'), this.projectedProposalsCurrentYearOtherFedIndirects, this.projectedCoreConversionRatiosCurrentYearOtherFedIndirects, this.get('model.projection.successesYtdOtherFedIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdOtherFedIndirects'), this.get('model.projection.coreProposalsYearEndOtherFedIndirects'), this.coreConversionRatiosOpenCurrentYearOtherFedIndirects);
    }),
    projectedCoreSuccessesCurrentYearNonFedDirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosNonFedDirects,coreProposalsOneYearAgoNonFedDirects}', 'projectedCoreConversionRatiosCurrentYearNonFedDirects', 'projectedProposalsCurrentYearNonFedDirects', 'coreConversionRatiosOpenCurrentYearNonFedDirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearNonFed, this.get('model.projection.yoneYtwoDerivativeRatiosNonFedDirects'), this.get('model.projection.coreProposalsOneYearAgoNonFedDirects'), this.projectedProposalsCurrentYearNonFedDirects, this.projectedCoreConversionRatiosCurrentYearNonFedDirects, this.get('model.projection.successesYtdNonFedDirects'), this.get('model.projection.coreProposalsCurrentYearYtdNonFedDirects'), this.get('model.projection.coreProposalsYearEndNonFedDirects'), this.coreConversionRatiosOpenCurrentYearNonFedDirects);
    }),
    projectedCoreSuccessesCurrentYearNonFedIndirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosNonFedIndirects,coreProposalsOneYearAgoNonFedIndirects}', 'projectedCoreConversionRatiosCurrentYearNonFedIndirects', 'projectedProposalsCurrentYearNonFedIndirects', 'coreConversionRatiosOpenCurrentYearNonFedIndirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearNonFed, this.get('model.projection.yoneYtwoDerivativeRatiosNonFedIndirects'), this.get('model.projection.coreProposalsOneYearAgoNonFedIndirects'), this.projectedProposalsCurrentYearNonFedIndirects, this.projectedCoreConversionRatiosCurrentYearNonFedIndirects, this.get('model.projection.successesYtdNonFedIndirects'), this.get('model.projection.coreProposalsCurrentYearYtdNonFedIndirects'), this.get('model.projection.coreProposalsYearEndNonFedIndirects'), this.coreConversionRatiosOpenCurrentYearNonFedIndirects);
    }),
    projectedCoreSuccessesCurrentYearSponsor5Directs: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosSponsor5Directs,coreProposalsOneYearAgoSponsor5Directs}', 'projectedCoreConversionRatiosCurrentYearSponsor5Directs', 'projectedProposalsCurrentYearSponsor5Directs', 'coreConversionRatiosOpenCurrentYearSponsor5Directs', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor5, this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Directs'), this.get('model.projection.coreProposalsOneYearAgoSponsor5Directs'), this.projectedProposalsCurrentYearSponsor5Directs, this.projectedCoreConversionRatiosCurrentYearSponsor5Directs, this.get('model.projection.successesYtdSponsor5Directs'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor5Directs'), this.get('model.projection.coreProposalsYearEndSponsor5Directs'), this.coreConversionRatiosOpenCurrentYearSponsor5Directs);
    }),
    projectedCoreSuccessesCurrentYearSponsor5Indirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosSponsor5Indirects,coreProposalsOneYearAgoSponsor5Indirects}', 'projectedCoreConversionRatiosCurrentYearSponsor5Indirects', 'projectedProposalsCurrentYearSponsor5Indirects', 'coreConversionRatiosOpenCurrentYearSponsor5Indirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor5, this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Indirects'), this.get('model.projection.coreProposalsOneYearAgoSponsor5Indirects'), this.projectedProposalsCurrentYearSponsor5Indirects, this.projectedCoreConversionRatiosCurrentYearSponsor5Indirects, this.get('model.projection.successesYtdSponsor5Indirects'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor5Indirects'), this.get('model.projection.coreProposalsYearEndSponsor5Indirects'), this.coreConversionRatiosOpenCurrentYearSponsor5Indirects);
    }),
    projectedCoreSuccessesCurrentYearSponsor6Directs: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosSponsor6Directs,coreProposalsOneYearAgoSponsor6Directs}', 'projectedCoreConversionRatiosCurrentYearSponsor6Directs', 'projectedProposalsCurrentYearSponsor6Directs', 'coreConversionRatiosOpenCurrentYearSponsor6Directs', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor6, this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Directs'), this.get('model.projection.coreProposalsOneYearAgoSponsor6Directs'), this.projectedProposalsCurrentYearSponsor6Directs, this.projectedCoreConversionRatiosCurrentYearSponsor6Directs, this.get('model.projection.successesYtdSponsor6Directs'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor6Directs'), this.get('model.projection.coreProposalsYearEndSponsor6Directs'), this.coreConversionRatiosOpenCurrentYearSponsor6Directs);
    }),
    projectedCoreSuccessesCurrentYearSponsor6Indirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosSponsor6Indirects,coreProposalsOneYearAgoSponsor6Indirects}', 'projectedCoreConversionRatiosCurrentYearSponsor6Indirects', 'projectedProposalsCurrentYearSponsor6Indirects', 'coreConversionRatiosOpenCurrentYearSponsor6Indirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor6, this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Indirects'), this.get('model.projection.coreProposalsOneYearAgoSponsor6Indirects'), this.projectedProposalsCurrentYearSponsor6Indirects, this.projectedCoreConversionRatiosCurrentYearSponsor6Indirects, this.get('model.projection.successesYtdSponsor6Indirects'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor6Indirects'), this.get('model.projection.coreProposalsYearEndSponsor6Indirects'), this.coreConversionRatiosOpenCurrentYearNonFedIndirects);
    }),
    projectedCoreSuccessesCurrentYearSponsor7Directs: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosSponsor7Directs,coreProposalsOneYearAgoSponsor7Directs}', 'projectedCoreConversionRatiosCurrentYearSponsor7Directs', 'projectedProposalsCurrentYearSponsor7Directs', 'coreConversionRatiosOpenCurrentYearSponsor7Directs', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor7, this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Directs'), this.get('model.projection.coreProposalsOneYearAgoSponsor7Directs'), this.projectedProposalsCurrentYearSponsor7Directs, this.projectedCoreConversionRatiosCurrentYearSponsor7Directs, this.get('model.projection.successesYtdSponsor7Directs'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor7Directs'), this.get('model.projection.coreProposalsYearEndSponsor7Directs'), this.coreConversionRatiosOpenCurrentYearSponsor7Directs);
    }),
    projectedCoreSuccessesCurrentYearSponsor7Indirects: Ember.computed('model.projection.{yoneYtwoDerivativeRatiosSponsor7Indirects,coreProposalsOneYearAgoSponsor7Indirects}', 'projectedCoreConversionRatiosCurrentYearSponsor7Indirects', 'projectedProposalsCurrentYearSponsor7Indirects', 'coreConversionRatiosOpenCurrentYearSponsor7Indirects', function () {
      return this.projectedCoreSuccessCurrentYear(this.coreConversionRatiosSettingsRevisedCurrentYearSponsor7, this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Indirects'), this.get('model.projection.coreProposalsOneYearAgoSponsor7Indirects'), this.projectedProposalsCurrentYearSponsor7Indirects, this.projectedCoreConversionRatiosCurrentYearSponsor7Indirects, this.get('model.projection.successesYtdSponsor7Indirects'), this.get('model.projection.coreProposalsCurrentYearYtdSponsor7Indirects'), this.get('model.projection.coreProposalsYearEndSponsor7Indirects'), this.coreConversionRatiosOpenCurrentYearNonFedIndirects);
    }),
    //region Core Conversion Ratios Row Models
    nihConversionRatiosRowModel: Ember.computed('coreConversionRatiosSettingsOpenCurrentYearNih', 'coreConversionRatiosOpenCurrentYearNihDirects', 'coreConversionRatiosOpenCurrentYearNihIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearNih', 'coreConversionRatiosRevisedCurrentYearNihDirects', 'coreConversionRatiosRevisedCurrentYearNihIndirects', 'coreConversionRatiosettingsTransitionNextYearNih', 'coreConversionRatiosTransitionNextYearNihDirects', 'coreConversionRatiosTransitionNextYearNihIndirects', 'coreConversionRatiosSettingsProjectionsNih.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreConversionRatiosSettingsOpenCurrentYearNih,
        openDirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearNihDirects, 1),
        openIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearNihIndirects, 1),
        revised: this.coreConversionRatiosSettingsRevisedCurrentYearNih,
        revisedDirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearNihDirects, 1),
        revisedIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearNihIndirects, 1),
        transition: this.coreConversionRatiosSettingsTransitionNextYearNih,
        transitionDirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearNihDirects, 1),
        transitionIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearNihIndirects, 1),
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNih.fourYearsAheadChangeIndirects'))
      };
    }),
    otherFedConversionRatiosRowModel: Ember.computed('coreConversionRatiosSettingsOpenCurrentYearOtherFed', 'coreConversionRatiosOpenCurrentYearOtherFedDirects', 'coreConversionRatiosOpenCurrentYearOtherFedIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearOtherFed', 'coreConversionRatiosRevisedCurrentYearOtherFedDirects', 'coreConversionRatiosRevisedCurrentYearOtherFedIndirects', 'coreConversionRatiosettingsTransitionNextYearOtherFed', 'coreConversionRatiosTransitionNextYearOtherFedDirects', 'coreConversionRatiosTransitionNextYearOtherFedIndirects', 'coreConversionRatiosSettingsProjectionsOtherFed.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreConversionRatiosSettingsOpenCurrentYearOtherFed,
        openDirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearOtherFedDirects, 1),
        openIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearOtherFedIndirects, 1),
        revised: this.coreConversionRatiosSettingsRevisedCurrentYearOtherFed,
        revisedDirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearOtherFedDirects, 1),
        revisedIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearOtherFedIndirects, 1),
        transition: this.coreConversionRatiosSettingsTransitionNextYearOtherFed,
        transitionDirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearOtherFedDirects, 1),
        transitionIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearOtherFedIndirects, 1),
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsOtherFed.fourYearsAheadChangeIndirects'))
      };
    }),
    fedFlowThruConversionRatiosRowModel: Ember.computed('coreConversionRatiosSettingsOpenCurrentYearFedFlowThru', 'coreConversionRatiosOpenCurrentYearFedFlowThruDirects', 'coreConversionRatiosOpenCurrentYearFedFlowThruIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru', 'coreConversionRatiosRevisedCurrentYearFedFlowThruDirects', 'coreConversionRatiosRevisedCurrentYearFedFlowThruIndirects', 'coreConversionRatioSettingsTransitionNextYearFedFlowThru', 'coreConversionRatiosTransitionNextYearFedFlowThruDirects', 'coreConversionRatiosTransitionNextYearFedFlowThruIndirects', 'coreConversionRatiosSettingsProjectionsFedFlowThru.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreConversionRatiosSettingsOpenCurrentYearFedFlowThru,
        openDirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearFedFlowThruDirects, 1),
        openIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearFedFlowThruIndirects, 1),
        revised: this.coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru,
        revisedDirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearFedFlowThruDirects, 1),
        revisedIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearFedFlowThruIndirects, 1),
        transition: this.coreConversionRatiosSettingsTransitionNextYearFedFlowThru,
        transitionDirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearFedFlowThruDirects, 1),
        transitionIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearFedFlowThruIndirects, 1),
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsFedFlowThru.fourYearsAheadChangeIndirects'))
      };
    }),
    nonFedConversionRatiosRowModel: Ember.computed('coreConversionRatiosSettingsOpenCurrentYearNonFed', 'coreConversionRatiosOpenCurrentYearNonFedDirects', 'coreConversionRatiosOpenCurrentYearNonFedIndirects', 'coreConversionRatiosSettingsRevisedCurrentYearNonFed', 'coreConversionRatiosRevisedCurrentYearNonFedDirects', 'coreConversionRatiosRevisedCurrentYearNonFedIndirects', 'coreConversionRatiosettingsTransitionNextYearNonFed', 'coreConversionRatiosTransitionNextYearNonFedDirects', 'coreConversionRatiosTransitionNextYearNonFedIndirects', 'coreConversionRatiosSettingsProjectionsNonFed.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreConversionRatiosSettingsOpenCurrentYearNonFed,
        openDirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearNonFedDirects, 1),
        openIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearNonFedIndirects, 1),
        revised: this.coreConversionRatiosSettingsRevisedCurrentYearNonFed,
        revisedDirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearNonFedDirects, 1),
        revisedIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearNonFedIndirects, 1),
        transition: this.coreConversionRatiosSettingsTransitionNextYearNonFed,
        transitionDirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearNonFedDirects, 1),
        transitionIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearNonFedIndirects, 1),
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsNonFed.fourYearsAheadChangeIndirects'))
      };
    }),
    sponsor5ConversionRatiosRowModel: Ember.computed('coreConversionRatiosSettingsOpenCurrentYearSponsor5', 'coreConversionRatiosOpenCurrentYearSponsor5Directs', 'coreConversionRatiosOpenCurrentYearSponsor5Indirects', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor5', 'coreConversionRatiosRevisedCurrentYearSponsor5Directs', 'coreConversionRatiosRevisedCurrentYearSponsor5Indirects', 'coreConversionRatiosettingsTransitionNextYearSponsor5', 'coreConversionRatiosTransitionNextYearSponsor5Directs', 'coreConversionRatiosTransitionNextYearSponsor5Indirects', 'coreConversionRatiosSettingsProjectionsSponsor5.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreConversionRatiosSettingsOpenCurrentYearSponsor5,
        openDirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearSponsor5Directs, 1),
        openIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearSponsor5Indirects, 1),
        revised: this.coreConversionRatiosSettingsRevisedCurrentYearSponsor5,
        revisedDirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearSponsor5Directs, 1),
        revisedIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearSponsor5Indirects, 1),
        transition: this.coreConversionRatiosSettingsTransitionNextYearSponsor5,
        transitionDirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearSponsor5Directs, 1),
        transitionIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearSponsor5Indirects, 1),
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor5.fourYearsAheadChangeIndirects'))
      };
    }),
    sponsor6ConversionRatiosRowModel: Ember.computed('coreConversionRatiosSettingsOpenCurrentYearSponsor6', 'coreConversionRatiosOpenCurrentYearSponsor6Directs', 'coreConversionRatiosOpenCurrentYearSponsor6Indirects', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor6', 'coreConversionRatiosRevisedCurrentYearSponsor6Directs', 'coreConversionRatiosRevisedCurrentYearSponsor6Indirects', 'coreConversionRatiosettingsTransitionNextYearSponsor6', 'coreConversionRatiosTransitionNextYearSponsor6Directs', 'coreConversionRatiosTransitionNextYearSponsor6Indirects', 'coreConversionRatiosSettingsProjectionsSponsor6.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreConversionRatiosSettingsOpenCurrentYearSponsor6,
        openDirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearSponsor6Directs, 1),
        openIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearSponsor6Indirects, 1),
        revised: this.coreConversionRatiosSettingsRevisedCurrentYearSponsor6,
        revisedDirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearSponsor6Directs, 1),
        revisedIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearSponsor6Indirects, 1),
        transition: this.coreConversionRatiosSettingsTransitionNextYearSponsor6,
        transitionDirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearSponsor6Directs, 1),
        transitionIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearSponsor6Indirects, 1),
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor6.fourYearsAheadChangeIndirects'))
      };
    }),
    sponsor7ConversionRatiosRowModel: Ember.computed('coreConversionRatiosSettingsOpenCurrentYearSponsor7', 'coreConversionRatiosOpenCurrentYearSponsor7Directs', 'coreConversionRatiosOpenCurrentYearSponsor7Indirects', 'coreConversionRatiosSettingsRevisedCurrentYearSponsor7', 'coreConversionRatiosRevisedCurrentYearSponsor7Directs', 'coreConversionRatiosRevisedCurrentYearSponsor7Indirects', 'coreConversionRatiosettingsTransitionNextYearSponsor7', 'coreConversionRatiosTransitionNextYearSponsor7Directs', 'coreConversionRatiosTransitionNextYearSponsor7Indirects', 'coreConversionRatiosSettingsProjectionsSponsor7.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreConversionRatiosSettingsOpenCurrentYearSponsor7,
        openDirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearSponsor7Directs, 1),
        openIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosOpenCurrentYearSponsor7Indirects, 1),
        revised: this.coreConversionRatiosSettingsRevisedCurrentYearSponsor7,
        revisedDirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearSponsor7Directs, 1),
        revisedIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosRevisedCurrentYearSponsor7Indirects, 1),
        transition: this.coreConversionRatiosSettingsTransitionNextYearSponsor7,
        transitionDirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearSponsor7Directs, 1),
        transitionIndirects: (0, _formatPercentage.default)(this.coreConversionRatiosTransitionNextYearSponsor7Indirects, 1),
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreConversionRatiosSettingsProjectionsSponsor7.fourYearsAheadChangeIndirects'))
      };
    }),
    //endregion
    //region Core Proposals Row Models
    nihProposalsRowModel: Ember.computed('coreProposalsSettingsOpenCurrentYearNih', 'coreProposalsOpenCurrentYearNihDirects', 'coreProposalsOpenCurrentYearNihIndirects', 'coreProposalsSettingsRevisedCurrentYearNih', 'coreProposalsRevisedCurrentYearNihDirects', 'coreProposalsRevisedCurrentYearNihIndirects', 'coreProposalSettingsTransitionNextYearNih', 'coreProposalsTransitionNextYearNihDirects', 'coreProposalsTransitionNextYearNihIndirects', 'coreProposalsSettingsProjectionsNih.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreProposalsSettingsOpenCurrentYearNih,
        openDirects: this.coreProposalsOpenCurrentYearNihDirects,
        openIndirects: this.coreProposalsOpenCurrentYearNihIndirects,
        revised: this.coreProposalsSettingsRevisedCurrentYearNih,
        revisedDirects: this.coreProposalsRevisedCurrentYearNihDirects,
        revisedIndirects: this.coreProposalsRevisedCurrentYearNihIndirects,
        transition: this.coreProposalsSettingsTransitionNextYearNih,
        transitionDirects: this.coreProposalsTransitionNextYearNihDirects,
        transitionIndirects: this.coreProposalsTransitionNextYearNihIndirects,
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNih.fourYearsAheadChangeIndirects'))
      };
    }),
    otherFedProposalsRowModel: Ember.computed('coreProposalsSettingsOpenCurrentYearOtherFed', 'coreProposalsOpenCurrentYearOtherFedDirects', 'coreProposalsOpenCurrentYearOtherFedIndirects', 'coreProposalsSettingsRevisedCurrentYearOtherFed', 'coreProposalsRevisedCurrentYearOtherFedDirects', 'coreProposalsRevisedCurrentYearOtherFedIndirects', 'coreProposalSettingsTransitionNextYearOtherFed', 'coreProposalsTransitionNextYearOtherFedDirects', 'coreProposalsTransitionNextYearOtherFedIndirects', 'coreProposalsSettingsProjectionsOtherFed.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreProposalsSettingsOpenCurrentYearOtherFed,
        openDirects: this.coreProposalsOpenCurrentYearOtherFedDirects,
        openIndirects: this.coreProposalsOpenCurrentYearOtherFedIndirects,
        revised: this.coreProposalsSettingsRevisedCurrentYearOtherFed,
        revisedDirects: this.coreProposalsRevisedCurrentYearOtherFedDirects,
        revisedIndirects: this.coreProposalsRevisedCurrentYearOtherFedIndirects,
        transition: this.coreProposalsSettingsTransitionNextYearOtherFed,
        transitionDirects: this.coreProposalsTransitionNextYearOtherFedDirects,
        transitionIndirects: this.coreProposalsTransitionNextYearOtherFedIndirects,
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsOtherFed.fourYearsAheadChangeIndirects'))
      };
    }),
    fedFlowThruProposalsRowModel: Ember.computed('coreProposalsSettingsOpenCurrentYearFedFlowThru', 'coreProposalsOpenCurrentYearFedFlowThruDirects', 'coreProposalsOpenCurrentYearFedFlowThruIndirects', 'coreProposalsSettingsRevisedCurrentYearFedFlowThru', 'coreProposalsRevisedCurrentYearFedFlowThruDirects', 'coreProposalsRevisedCurrentYearFedFlowThruIndirects', 'coreProposalSettingsTransitionNextYearFedFlowThru', 'coreProposalsTransitionNextYearFedFlowThruDirects', 'coreProposalsTransitionNextYearFedFlowThruIndirects', 'coreProposalsSettingsProjectionsFedFlowThru.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreProposalsSettingsOpenCurrentYearFedFlowThru,
        openDirects: this.coreProposalsOpenCurrentYearFedFlowThruDirects,
        openIndirects: this.coreProposalsOpenCurrentYearFedFlowThruIndirects,
        revised: this.coreProposalsSettingsRevisedCurrentYearFedFlowThru,
        revisedDirects: this.coreProposalsRevisedCurrentYearFedFlowThruDirects,
        revisedIndirects: this.coreProposalsRevisedCurrentYearFedFlowThruIndirects,
        transition: this.coreProposalsSettingsTransitionNextYearFedFlowThru,
        transitionDirects: this.coreProposalsTransitionNextYearFedFlowThruDirects,
        transitionIndirects: this.coreProposalsTransitionNextYearFedFlowThruIndirects,
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsFedFlowThru.fourYearsAheadChangeIndirects'))
      };
    }),
    nonFedProposalsRowModel: Ember.computed('coreProposalsSettingsOpenCurrentYearNonFed', 'coreProposalsOpenCurrentYearNonFedDirects', 'coreProposalsOpenCurrentYearNonFedIndirects', 'coreProposalsSettingsRevisedCurrentYearNonFed', 'coreProposalsRevisedCurrentYearNonFedDirects', 'coreProposalsRevisedCurrentYearNonFedIndirects', 'coreProposalSettingsTransitionNextYearNonFed', 'coreProposalsTransitionNextYearNonFedDirects', 'coreProposalsTransitionNextYearNonFedIndirects', 'coreProposalsSettingsProjectionsNonFed.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreProposalsSettingsOpenCurrentYearNonFed,
        openDirects: this.coreProposalsOpenCurrentYearNonFedDirects,
        openIndirects: this.coreProposalsOpenCurrentYearNonFedIndirects,
        revised: this.coreProposalsSettingsRevisedCurrentYearNonFed,
        revisedDirects: this.coreProposalsRevisedCurrentYearNonFedDirects,
        revisedIndirects: this.coreProposalsRevisedCurrentYearNonFedIndirects,
        transition: this.coreProposalsSettingsTransitionNextYearNonFed,
        transitionDirects: this.coreProposalsTransitionNextYearNonFedDirects,
        transitionIndirects: this.coreProposalsTransitionNextYearNonFedIndirects,
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsNonFed.fourYearsAheadChangeIndirects'))
      };
    }),
    sponsor5ProposalsRowModel: Ember.computed('coreProposalsSettingsOpenCurrentYearSponsor5', 'coreProposalsOpenCurrentYearSponsor5Directs', 'coreProposalsOpenCurrentYearSponsor5Indirects', 'coreProposalsSettingsRevisedCurrentYearSponsor5', 'coreProposalsRevisedCurrentYearSponsor5Directs', 'coreProposalsRevisedCurrentYearSponsor5Indirects', 'coreProposalSettingsTransitionNextYearSponsor5', 'coreProposalsTransitionNextYearSponsor5Directs', 'coreProposalsTransitionNextYearSponsor5Indirects', 'coreProposalsSettingsProjectionsSponsor5.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreProposalsSettingsOpenCurrentYearSponsor5,
        openDirects: this.coreProposalsOpenCurrentYearSponsor5Directs,
        openIndirects: this.coreProposalsOpenCurrentYearSponsor5Indirects,
        revised: this.coreProposalsSettingsRevisedCurrentYearSponsor5,
        revisedDirects: this.coreProposalsRevisedCurrentYearSponsor5Directs,
        revisedIndirects: this.coreProposalsRevisedCurrentYearSponsor5Indirects,
        transition: this.coreProposalsSettingsTransitionNextYearSponsor5,
        transitionDirects: this.coreProposalsTransitionNextYearSponsor5Directs,
        transitionIndirects: this.coreProposalsTransitionNextYearSponsor5Indirects,
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor5.fourYearsAheadChangeIndirects'))
      };
    }),
    sponsor6ProposalsRowModel: Ember.computed('coreProposalsSettingsOpenCurrentYearSponsor6', 'coreProposalsOpenCurrentYearSponsor6Directs', 'coreProposalsOpenCurrentYearSponsor6Indirects', 'coreProposalsSettingsRevisedCurrentYearSponsor6', 'coreProposalsRevisedCurrentYearSponsor6Directs', 'coreProposalsRevisedCurrentYearSponsor6Indirects', 'coreProposalSettingsTransitionNextYearSponsor6', 'coreProposalsTransitionNextYearSponsor6Directs', 'coreProposalsTransitionNextYearSponsor6Indirects', 'coreProposalsSettingsProjectionsSponsor6.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreProposalsSettingsOpenCurrentYearSponsor6,
        openDirects: this.coreProposalsOpenCurrentYearSponsor6Directs,
        openIndirects: this.coreProposalsOpenCurrentYearSponsor6Indirects,
        revised: this.coreProposalsSettingsRevisedCurrentYearSponsor6,
        revisedDirects: this.coreProposalsRevisedCurrentYearSponsor6Directs,
        revisedIndirects: this.coreProposalsRevisedCurrentYearSponsor6Indirects,
        transition: this.coreProposalsSettingsTransitionNextYearSponsor6,
        transitionDirects: this.coreProposalsTransitionNextYearSponsor6Directs,
        transitionIndirects: this.coreProposalsTransitionNextYearSponsor6Indirects,
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor6.fourYearsAheadChangeIndirects'))
      };
    }),
    sponsor7ProposalsRowModel: Ember.computed('coreProposalsSettingsOpenCurrentYearSponsor7', 'coreProposalsOpenCurrentYearSponsor7Directs', 'coreProposalsOpenCurrentYearSponsor7Indirects', 'coreProposalsSettingsRevisedCurrentYearSponsor7', 'coreProposalsRevisedCurrentYearSponsor7Directs', 'coreProposalsRevisedCurrentYearSponsor7Indirects', 'coreProposalSettingsTransitionNextYearSponsor7', 'coreProposalsTransitionNextYearSponsor7Directs', 'coreProposalsTransitionNextYearSponsor7Indirects', 'coreProposalsSettingsProjectionsSponsor7.{oneYearAheadChangeDirects,oneYearAheadChangeIndirects,twoYearsAheadChangeDirects,' + 'twoYearsAheadChangeIndirects,threeYearsAheadChangeDirects,threeYearsAheadChangeIndirects,fourYearsAheadChangeDirects,' + 'fourYearsAheadChangeIndirects}', function () {
      return {
        open: this.coreProposalsSettingsOpenCurrentYearSponsor7,
        openDirects: this.coreProposalsOpenCurrentYearSponsor7Directs,
        openIndirects: this.coreProposalsOpenCurrentYearSponsor7Indirects,
        revised: this.coreProposalsSettingsRevisedCurrentYearSponsor7,
        revisedDirects: this.coreProposalsRevisedCurrentYearSponsor7Directs,
        revisedIndirects: this.coreProposalsRevisedCurrentYearSponsor7Indirects,
        transition: this.coreProposalsSettingsTransitionNextYearSponsor7,
        transitionDirects: this.coreProposalsTransitionNextYearSponsor7Directs,
        transitionIndirects: this.coreProposalsTransitionNextYearSponsor7Indirects,
        oneYearAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.oneYearAheadChangeDirects')),
        oneYearAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.oneYearAheadChangeIndirects')),
        twoYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.twoYearsAheadChangeDirects')),
        twoYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.twoYearsAheadChangeIndirects')),
        threeYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.threeYearsAheadChangeDirects')),
        threeYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.threeYearsAheadChangeIndirects')),
        fourYearsAheadChangeDirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.fourYearsAheadChangeDirects')),
        fourYearsAheadChangeIndirects: (0, _formatPercentage.default)(this.get('coreProposalsSettingsProjectionsSponsor7.fourYearsAheadChangeIndirects'))
      };
    }),
    //endregion
    hasTeamOfficialProjections: Ember.computed('currentUser.team.officialProjectionSettings', function () {
      if (!this.currentUser.team) {
        return false;
      }

      var officialProjectionSettings = this.currentUser.team.officialProjectionSettings;
      return officialProjectionSettings && Object.keys(officialProjectionSettings).length > 0;
    })
  });

  _exports.default = _default;
});