define("b3i-analytics-web-app/templates/components/table-active-mega", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g+hR3Few",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-base\",[],[[\"@table\",\"@canExpand\",\"@isLoading\",\"@expandedRowComponent\",\"@tableActions\",\"@onScrolledToBottom\",\"@onColumnClick\"],[[34,0],true,[34,1],\"row-expanded-active-mega\",[30,[36,4],null,[[\"onGrantChange\"],[[30,[36,3],[[32,0],[35,2]],null]]]],[30,[36,3],[[32,0],\"onScrolledToBottom\"],null],[30,[36,3],[[32,0],\"onColumnClick\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"table\",\"isLoading\",\"onGrantChange\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-active-mega.hbs"
    }
  });

  _exports.default = _default;
});