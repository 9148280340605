define("b3i-analytics-web-app/models/import-from-csv", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    actuals: (0, _model.attr)('boolean'),
    actualsRowsIgnored: (0, _model.attr)('number'),
    coPis: (0, _model.attr)('boolean'),
    coPisRowsIgnored: (0, _model.attr)('number'),
    createdAt: (0, _model.attr)('date'),
    dateOfData: (0, _model.attr)('utc'),
    queryDate: (0, _model.attr)('utc'),
    faculty: (0, _model.attr)('boolean'),
    facultyRowsIgnored: (0, _model.attr)('number'),
    importMetadata: (0, _model.attr)(),
    proposals: (0, _model.attr)('boolean'),
    proposalsRowsIgnored: (0, _model.attr)('boolean'),
    successes: (0, _model.attr)('boolean'),
    successesRowsIgnored: (0, _model.attr)('number'),
    sectorsDepartments: (0, _model.attr)('boolean'),
    sectorsDepartmentsRowsIgnored: (0, _model.attr)('number'),
    user: (0, _model.belongsTo)('user')
  });

  _exports.default = _default;
});