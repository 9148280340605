define("b3i-analytics-web-app/transforms/percent", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (serialized != null) {
        return (serialized * 100).toFixed(1);
      } else {
        return serialized;
      }
    },
    serialize: function serialize(deserialized) {
      return deserialized / 100;
    }
  });

  _exports.default = _default;
});