define("b3i-analytics-web-app/utils/highlight-pts-cells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = highlightPtsCells;

  function highlightPtsCells(rows, extra, includePlanPts) {
    var currentYearDirectsKey = includePlanPts ? 'ptsWithPlanCurrentYearDirects' : 'ptsCurrentYearDirects';
    var currentYearIndirectsKey = includePlanPts ? 'ptsWithPlanCurrentYearIndirects' : 'ptsCurrentYearIndirects';
    var oneYearAheadDirectsKey = includePlanPts ? 'ptsWithPlanOneYearAheadDirects' : 'ptsOneYearAheadDirects';
    var oneYearAheadIndirectsKey = includePlanPts ? 'ptsWithPlanOneYearAheadIndirects' : 'ptsOneYearAheadIndirects';
    var twoYearsAheadDirectsKey = includePlanPts ? 'ptsWithPlanTwoYearsAheadDirects' : 'ptsTwoYearsAheadDirects';
    var twoYearsAheadIndirectsKey = includePlanPts ? 'ptsWithPlanTwoYearsAheadIndirects' : 'ptsTwoYearsAheadIndirects';
    var declineMultiplier = 1.0 - extra / 100;
    var gainMultiplier = 1.0 + extra / 100;
    rows.forEach(function (row) {
      row.set('classNames', ['']);
      var content = row.content; //Decline
      // Current Year

      var directsDeclineCurrentFY = content.get(currentYearDirectsKey) < declineMultiplier * content.get('actualsOneYearAgoDirects');
      var indirectsDeclineCurrentFY = content.get(currentYearIndirectsKey) < declineMultiplier * content.get('actualsOneYearAgoIndirects'); // One Year Ahead

      var directsDeclineOneYearAhead = content.get(oneYearAheadDirectsKey) < declineMultiplier * content.get(currentYearDirectsKey);
      var indirectsDeclineOneYearAhead = content.get(oneYearAheadIndirectsKey) < declineMultiplier * content.get(currentYearIndirectsKey); // Two Years Ahead

      var directsDeclineTwoYearsAhead = content.get(twoYearsAheadDirectsKey) < declineMultiplier * content.get(twoYearsAheadDirectsKey);
      var indirectsDeclineTwoYearsAhead = content.get(twoYearsAheadIndirectsKey) < declineMultiplier * content.get(twoYearsAheadIndirectsKey);
      var classNamesString = '';

      if (directsDeclineCurrentFY) {
        classNamesString += ' highlight-risk-current-year-directs';
      }

      if (indirectsDeclineCurrentFY) {
        classNamesString += ' highlight-risk-current-year-indirects';
      }

      if (directsDeclineOneYearAhead) {
        classNamesString += ' highlight-risk-one-year-directs';
      }

      if (indirectsDeclineOneYearAhead) {
        classNamesString += ' highlight-risk-one-year-indirects';
      }

      if (directsDeclineTwoYearsAhead) {
        classNamesString += ' highlight-risk-two-years-directs';
      }

      if (indirectsDeclineTwoYearsAhead) {
        classNamesString += ' highlight-risk-two-years-indirects';
      } //Gain
      // Current Year


      var directsGainCurrentFY = content.get(currentYearDirectsKey) > gainMultiplier * content.get('actualsOneYearAgoDirects');
      var indirectsGainCurrentFY = content.get(currentYearIndirectsKey) > gainMultiplier * content.get('actualsOneYearAgoIndirects'); // One Year Ahead

      var directsGainOneYearAhead = content.get(oneYearAheadDirectsKey) > gainMultiplier * content.get(currentYearDirectsKey);
      var indirectsGainOneYearAhead = content.get(oneYearAheadIndirectsKey) > gainMultiplier * content.get(currentYearIndirectsKey); // Two Years Ahead

      var directsGainTwoYearsAhead = content.get(twoYearsAheadDirectsKey) > gainMultiplier * content.get(oneYearAheadDirectsKey);
      var indirectsGainTwoYearsAhead = content.get(twoYearsAheadIndirectsKey) > gainMultiplier * content.get(oneYearAheadIndirectsKey);

      if (directsGainCurrentFY) {
        classNamesString += ' highlight-gain-current-year-directs';
      }

      if (indirectsGainCurrentFY) {
        classNamesString += ' highlight-gain-current-year-indirects';
      }

      if (directsGainOneYearAhead) {
        classNamesString += ' highlight-gain-one-year-directs';
      }

      if (indirectsGainOneYearAhead) {
        classNamesString += ' highlight-gain-one-year-indirects';
      }

      if (directsGainTwoYearsAhead) {
        classNamesString += ' highlight-gain-two-years-directs';
      }

      if (indirectsGainTwoYearsAhead) {
        classNamesString += ' highlight-gain-two-years-indirects';
      }

      row.set('classNames', [classNamesString]);
    });
  }
});