define("b3i-analytics-web-app/templates/components/validated-date-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dkLYuwcB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"field \",[30,[36,1],[[35,4],[30,[36,1],[[35,0],\"error\"],null]],null]]]],[12],[2,\"\\n\"],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[15,\"for\",[34,2]],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"pikaday-input\",[],[[\"@value\",\"@onSelection\",\"@format\",\"@placeholder\",\"@useUTC\"],[[34,5],[34,6],\"MM/DD/YYYY\",\"mm/dd/yyyy\",true]],null],[2,\"\\n\"],[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"small\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"error\",\"if\",\"name\",\"label\",\"showError\",\"value\",\"onSelection\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/validated-date-input.hbs"
    }
  });

  _exports.default = _default;
});