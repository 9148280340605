define("b3i-analytics-web-app/initializers/accounting", ["exports", "accounting/settings"], function (_exports, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    // application.inject('route', 'foo', 'service:foo');
    _settings.currency.precision = 0;
    _settings.currency.format = {
      pos: "%s%v",
      neg: "(%s%v)",
      zero: "%s0"
    };
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});