define("b3i-analytics-web-app/templates/app/comparative", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8cZepN2z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pi-header navbar-top-margin navbar-top-margin\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Comparative Stats\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pi-navigation\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"nav-item\"]],[[\"@route\"],[\"app.comparative.departments\"]],[[\"default\"],[{\"statements\":[[2,\"Departments\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"nav-item\"]],[[\"@route\"],[\"app.comparative.investigators\"]],[[\"default\"],[{\"statements\":[[2,\"Investigators\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"container app\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/app/comparative.hbs"
    }
  });

  _exports.default = _default;
});