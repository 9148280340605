define("b3i-analytics-web-app/transforms/date-only", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }

      return (0, _moment.default)(serialized).format('MM/DD/YYYY');
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }

      return (0, _moment.default)(deserialized).format('YYYY-MM-DD');
    }
  });

  _exports.default = _default;
});