define("b3i-analytics-web-app/models/comparative-proposal-history", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    piId: (0, _model.attr)('string'),
    lookbackYears: (0, _model.attr)('number'),
    sponsorType: (0, _model.attr)('string'),
    piProposedCountPerYear: (0, _model.attr)('number'),
    piProposedTotalPerYear: (0, _model.attr)('number'),
    piFundedCountPerYear: (0, _model.attr)('number'),
    piFundedTotalPerYear: (0, _model.attr)('number'),
    departmentSampleCount: (0, _model.attr)('number'),
    departmentProposedCountPerYear: (0, _model.attr)('number'),
    departmentProposedTotalPerYear: (0, _model.attr)('number'),
    departmentFundedCountPerYear: (0, _model.attr)('number'),
    departmentFundedDirectsPerYear: (0, _model.attr)('number'),
    departmentFundedIndirectsPerYear: (0, _model.attr)('number'),
    departmentFundedTotalPerYear: (0, _model.attr)('number'),
    departmentFundedDuration: (0, _model.attr)('number'),
    sectorSampleCount: (0, _model.attr)('number'),
    sectorProposedCountPerYear: (0, _model.attr)('number'),
    sectorProposedTotalPerYear: (0, _model.attr)('number'),
    sectorFundedCountPerYear: (0, _model.attr)('number'),
    sectorFundedTotalPerYear: (0, _model.attr)('number'),
    schoolSampleCount: (0, _model.attr)('number'),
    schoolProposedCountPerYear: (0, _model.attr)('number'),
    schoolProposedTotalPerYear: (0, _model.attr)('number'),
    schoolFundedCountPerYear: (0, _model.attr)('number'),
    schoolFundedTotalPerYear: (0, _model.attr)('number'),
    principalInvestigator: (0, _model.belongsTo)('principal-investigator')
  });

  _exports.default = _default;
});