define("b3i-analytics-web-app/templates/components/cell-label-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oiMaGXD1",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"isHeader\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"label-row-header\"],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"name\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-label-column.hbs"
    }
  });

  _exports.default = _default;
});