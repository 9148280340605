define("b3i-analytics-web-app/templates/components/cell-variance-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "emv7Kao5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[[35,2],\"red\"],null],\" \",[30,[36,1],[[35,0],\"green\"],null]]]],[12],[2,[34,3]],[1,[30,[36,1],[[35,4],\"%\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isPositive\",\"if\",\"isNegative\",\"value\",\"isValid\",\"showValue\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-variance-percentage.hbs"
    }
  });

  _exports.default = _default;
});