define("b3i-analytics-web-app/mixins/pts-summary-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    optionsMegaGrants: Ember.computed(function () {
      return ['No', 'Yes'];
    }),
    tableExtra: Ember.computed('nearTermDecline', 'directsChange', 'indirectsChange', 'selectedIncludePlan', 'meta', 'subtotalRow', function () {
      return {
        nearTermDecline: this.nearTermDecline,
        directsChange: this.directsChange,
        indirectsChange: this.indirectsChange,
        includePlanPts: this.selectedIncludePlan === "Yes",
        modelLength: this.get('meta.pagination.count'),
        riskOfDeclineCount: this.get('meta.risk_of_decline_count'),
        riskOfLapseCount: this.get('meta.risk_of_lapse_count'),
        showingSubtotals: this.subtotalRow !== null && this.subtotalRow !== undefined
      };
    })
  });

  _exports.default = _default;
});