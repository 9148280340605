define("b3i-analytics-web-app/constants/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    ADMIN: 'admin',
    EXECUTIVE: 'executive',
    SECTOR: 'sector',
    DEPARTMENT: 'department',
    SYSTEM_ROLE_HURON_ADMIN: 'huron_admin',
    SYSTEM_ROLE_HURON_EXEC: 'huron_executive',
    SYSTEM_ROLE_USER: 'user'
  };
  _exports.default = _default;
});