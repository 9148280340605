define("b3i-analytics-web-app/templates/components/dropdown-wrapper-multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sLy7cVzK",
    "block": "{\"symbols\":[\"sector\"],\"statements\":[[10,\"span\"],[15,0,[31,[\"dropdown-wrapper-multi-select \",[30,[36,1],[[35,3],\"no-search\"],null]]]],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[34,4]],[2,\":\"],[13],[2,\"\\n  \"],[8,\"power-select-multiple\",[],[[\"@options\",\"@selected\",\"@placeholder\",\"@searchEnabled\",\"@onChange\"],[[34,5],[34,6],[34,7],[34,8],[34,9]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[15,0,[31,[\"description-text \",[30,[36,1],[[35,0],\"warning\"],null]]]],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"descriptionTextWarning\",\"if\",\"descriptionText\",\"searchDisabled\",\"label\",\"options\",\"selected\",\"placeholder\",\"searchEnabled\",\"onchange\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/dropdown-wrapper-multi-select.hbs"
    }
  });

  _exports.default = _default;
});