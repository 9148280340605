define("b3i-analytics-web-app/templates/components/table-comparative-proposals-successes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHD7IVxy",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"table-comparative-proposals-successes\"],[12],[2,\"\\n  \"],[8,\"table-base\",[],[[\"@isLoading\",\"@table\"],[[34,0],[34,1]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"table\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-comparative-proposals-successes.hbs"
    }
  });

  _exports.default = _default;
});