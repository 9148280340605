define("b3i-analytics-web-app/mixins/recruitment-calculations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Mixin$create;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create((_Ember$Mixin$create = {
    yearsAhead: 10,
    termRemaining: 3,
    startUpSpendYearsJunior: 3,
    startupPackageAmountJunior: 0,
    averageTotalCompensationJunior: 0,
    compensationCoverageAfterJunior: 0,
    compensationCoverageStartUpJunior: 0,
    performanceProposalJunior: 0,
    performanceHitRateJunior: 0,
    startUpSpendYearsSenior: 3,
    startupPackageAmountSenior: 0,
    averageTotalCompensationSenior: 0,
    compensationCoverageAfterSenior: 0,
    compensationCoverageStartUpSenior: 0,
    performanceProposalSenior: 0,
    performanceHitRateSenior: 0,
    currentUser: Ember.inject.service('current-user'),
    updateRecruitCounts: function updateRecruitCounts(recruits, isJunior) {
      var name = isJunior ? 'recruitsJunior' : 'recruitsSenior';
      this.set(name, Ember.A(recruits.map(function (value) {
        if (!value) {
          value = 0;
        }

        return Ember.Object.create({
          value: value
        });
      })));
    },
    updateAttritionCounts: function updateAttritionCounts(recruits, isJunior) {
      var name = isJunior ? 'attritionJunior' : 'attritionSenior';
      this.set(name, Ember.A(recruits.map(function (value) {
        return Ember.Object.create({
          value: value
        });
      })));
    },
    updateProjection: function updateProjection(projection) {
      // The recruitment page does not have a model object, but the projection page does so ensure the projection is set right
      if (!this.model) {
        this.set('model', {});
      }

      this.set('model.projection', projection);
    },
    //region Helpers

    /**
     * Helper function for increasing/decreasing the avg count and hit rate based on performance index
     */
    calculateWithPerformanceIndex: function calculateWithPerformanceIndex(value, performanceIndex) {
      if (!performanceIndex) {
        return value;
      }

      return value * (1 + performanceIndex);
    },

    /***
     * Helper function for calculating total number of proposals for one recruit class
     */
    calculateRecruitProposals: function calculateRecruitProposals(proposalCount, proposalSize, yearsAhead, isJunior, withoutAttrition) {
      var net = 0;

      if (withoutAttrition) {
        net = this.recruitsForYear(yearsAhead, isJunior);
      } else {
        net = this.recruitsForYear(yearsAhead, isJunior) - this.attritionForYear(yearsAhead, isJunior);
      } //Number of years we are looking at


      var numYearsOut = this.yearsAhead;
      var proposals = [];

      for (var i = 0; i < numYearsOut; i++) {
        proposals.push(net * proposalCount * proposalSize);
      } //Fill in 0s for the years prior the recruits starting


      for (var _i = 0; _i < yearsAhead - 1; _i++) {
        proposals[_i] = 0;
      }

      return proposals;
    },

    /**
     * Helper function for calculating the proposals array without factoring in the proposals from new recruits in Y1
     */
    calculateRecruitProposalsWithoutTransferredIn: function calculateRecruitProposalsWithoutTransferredIn(index, pArray, proposalCount, proposalSize, isJunior) {
      var recruits = this.recruitsForYear(index + 1, isJunior); //Make a copy so we don't modify the original array

      var proposals = pArray.slice(0);

      if (recruits > 0) {
        //Dont factor Y1 proposals into calculation. This is covered by transferred in succcesses
        var proposalsToRemove = this.calculateRecruitProposals(proposalCount, proposalSize, index + 1, isJunior);
        proposals[index] = proposals[index] - proposalsToRemove[index];
      }

      return proposals;
    },

    /***
     * Helper function for multiplying average transferred in and number of recruits
     */
    calculateRecruitTransferredIn: function calculateRecruitTransferredIn(transferredInAvgTotal, yearsAhead, isJunior) {
      var net = this.recruitsForYear(yearsAhead, isJunior);
      return net * transferredInAvgTotal;
    },
    calculateOneClassRecruitSuccesses: function calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, index) {
      var currentProposals = proposals[index];
      var successes = 0;

      if (index === 0) {
        successes = this.calculateRecruitSuccesses(y1y2, currentProposals, 0, modifiedHitRate);
      } else {
        var lastProposals = proposals[index - 1];
        successes = this.calculateRecruitSuccesses(y1y2, currentProposals, lastProposals, modifiedHitRate);
      }

      return successes;
    },

    /***
     * Helper function for calculating successes from proposals using the y1y2 ratio and hit rate
     */
    calculateRecruitSuccesses: function calculateRecruitSuccesses(y1y2, proposalsThisYear, proposalsLastYear, modifiedHitRate) {
      //Total successes are projected from proposals and the amount expected to be transferred in
      var successes = 0;

      if (!proposalsLastYear) {
        successes = (1 - y1y2) * proposalsThisYear * modifiedHitRate;
      } else {
        successes = y1y2 * proposalsLastYear * modifiedHitRate + (1 - y1y2) * proposalsThisYear * modifiedHitRate;
      }

      return successes;
    },

    /***
     * Helper function that converts the total to indirects using the icr ratio
     */
    calculateIndirectsFromTotal: function calculateIndirectsFromTotal(totalArray, icr) {
      return totalArray.map(function (total) {
        return icr * total / (1 + icr);
      });
    },

    /***
     * Helper function that amortizes the successes for a given year based on the average duration of the grant
     */
    calculateProjectedSpending: function calculateProjectedSpending(wad, successes, yearsAhead, index) {
      if (yearsAhead <= index) {
        return 0;
      }

      var success = successes[index];
      var years = yearsAhead - index;

      switch (wad) {
        case 1:
          switch (years) {
            case 1:
              return success / 2;

            case 2:
              return success / 2;

            default:
              return 0;
          }

        case 2:
          switch (years) {
            case 1:
              return success / 4;

            case 2:
              return success / 2;

            case 3:
              return success / 4;

            default:
              return 0;
          }

        case 3:
          switch (years) {
            case 1:
              return success / 6;

            case 2:
              return success / 3;

            case 3:
              return success / 3;

            case 4:
              return success / 6;

            default:
              return 0;
          }

        case 4:
          switch (years) {
            case 1:
              return success / 8;

            case 2:
              return success / 4;

            case 3:
              return success / 4;

            case 4:
              return success / 4;

            case 5:
              return success / 8;

            default:
              return 0;
          }

        case 5:
          switch (years) {
            case 1:
              return success / 10;

            case 2:
              return success / 5;

            case 3:
              return success / 5;

            case 4:
              return success / 5;

            case 5:
              return success / 5;

            case 6:
              return success / 10;

            default:
              return 0;
          }

        default:
          return 0;
      }
    },
    calculateProjectedSpendingOneClass: function calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, isJunior) {
      var _this = this;

      var proposals = this.calculateRecruitProposals(proposalCount, proposalSize, classIndex + 1, isJunior, true);
      var successes = [];

      for (var i = 0; i < this.yearsAhead; i++) {
        successes.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
      }

      var range = _toConsumableArray(Array(this.yearsAhead).keys());

      var spendingArray = range.map(function (i) {
        return range.map(function (j) {
          return _this.calculateProjectedSpending(wad, successes, j + 1, i);
        });
      });
      return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
    },

    /***
     Helper function for calculating amounts spent over X number of years. Used for startup and relinqiushed calculations
     */
    calculateStartupRelinquished: function calculateStartupRelinquished(amount, num, term, yearsAhead, compCoverage) {
      if (!compCoverage) {
        compCoverage = 0;
      }

      var lookForward = this.yearsAhead;
      var amountYearsArray = Array(lookForward).fill(0);
      var perYearAmount = amount * num / term + compCoverage * num;

      for (var i = 0; i < term; i++) {
        var index = i + (yearsAhead - 1);

        if (index < amountYearsArray.length) {
          amountYearsArray[index] = perYearAmount;
        } else {
          break;
        }
      }

      return amountYearsArray;
    },

    /***
     * Helper function for returning the number of recruits by year
     */
    recruitsForYear: function recruitsForYear(yearsAhead, isJunior) {
      var name = isJunior ? 'recruitsJuniorCounts' : 'recruitsSeniorCounts';
      var recruits = this.get(name)[yearsAhead - 1]; //Replace undefined with 0

      if (!recruits) {
        recruits = 0;
      }

      return recruits;
    },
    attritionForYear: function attritionForYear(yearsAhead, isJunior) {
      var name = isJunior ? 'attritionJuniorCounts' : 'attritionSeniorCounts';
      var attrition = this.get(name)[yearsAhead - 1]; //Replace undefined with 0

      if (!attrition) {
        attrition = 0;
      }

      return attrition;
    },

    /***
     * Helper function for summing the elements of an indefinite length of arrays. Returns one array
     */
    sumArrays: function sumArrays() {
      for (var _len = arguments.length, arrays = new Array(_len), _key = 0; _key < _len; _key++) {
        arrays[_key] = arguments[_key];
      }

      var n = arrays.reduce(function (max, xs) {
        return Math.max(max, xs.length);
      }, 0);
      var result = Array.from({
        length: n
      });
      return result.map(function (_, i) {
        return arrays.map(function (xs) {
          return xs[i] || 0;
        }).reduce(function (sum, x) {
          return sum + x;
        }, 0);
      });
    },

    /***
     *  Helper function that subtracts each element of one array from another
     */
    subtractTwoArrays: function subtractTwoArrays(arrayOne, arrayTwo) {
      return arrayOne.map(function (value, index) {
        return value - arrayTwo[index];
      });
    },

    /***
     * Helper function for converting an array of values into an array of a cumulative sum of values
     */
    cumulativeArray: function cumulativeArray(netArray) {
      var cumulativeArray = [];
      netArray.reduce(function (prev, curr, i) {
        return cumulativeArray[i] = prev + curr;
      }, 0);
      return cumulativeArray;
    },
    //endregion
    //region Junior
    //region Raw Data - modifies raw data with performance index
    proposalNihkJuniorAvgCount: Ember.computed('recruitment.proposalNihkJuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalNihkJuniorAvgCount'), this.performanceProposalJunior);
    }),
    proposalNihrJuniorAvgCount: Ember.computed('recruitment.proposalNihrJuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalNihrJuniorAvgCount'), this.performanceProposalJunior);
    }),
    proposalOtherFedJuniorAvgCount: Ember.computed('recruitment.proposalOtherFedJuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalOtherFedJuniorAvgCount'), this.performanceProposalJunior);
    }),
    proposalFftJuniorAvgCount: Ember.computed('recruitment.proposalFftJuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalFftJuniorAvgCount'), this.performanceProposalJunior);
    }),
    proposalNonFedJuniorAvgCount: Ember.computed('recruitment.proposalNonFedJuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalNonFedJuniorAvgCount'), this.performanceProposalJunior);
    }),
    proposalSponsor5JuniorAvgCount: Ember.computed('recruitment.proposalSponsor5JuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalSponsor5JuniorAvgCount'), this.performanceProposalJunior);
    }),
    proposalSponsor6JuniorAvgCount: Ember.computed('recruitment.proposalSponsor6JuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalSponsor6JuniorAvgCount'), this.performanceProposalJunior);
    }),
    proposalSponsor7JuniorAvgCount: Ember.computed('recruitment.proposalSponsor7JuniorAvgCount', 'performanceProposalJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.proposalSponsor7JuniorAvgCount'), this.performanceProposalJunior);
    }),
    modifiedHitRateNihkJunior: Ember.computed('recruitment.modifiedHitRateNihkJunior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateNihkJunior'), this.performanceHitRateJunior);
    }),
    modifiedHitRateNihrJunior: Ember.computed('recruitment.modifiedHitRateNihrJunior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateNihrJunior'), this.performanceHitRateJunior);
    }),
    modifiedHitRateOtherFedJunior: Ember.computed('recruitment.modifiedHitRateOtherFedkJunior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateOtherFedJunior'), this.performanceHitRateJunior);
    }),
    modifiedHitRateFftJunior: Ember.computed('recruitment.modifiedHitRateFftJunior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateFftJunior'), this.performanceHitRateJunior);
    }),
    modifiedHitRateNonFedJunior: Ember.computed('recruitment.modifiedHitRateNonFedJunior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateNonFedJunior'), this.performanceHitRateJunior);
    }),
    modifiedHitRateSponsor5Junior: Ember.computed('recruitment.modifiedHitRateSponsor5Junior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateSponsor5Junior'), this.performanceHitRateJunior);
    }),
    modifiedHitRateSponsor6Junior: Ember.computed('recruitment.modifiedHitRateSponsor6Junior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateSponsor6Junior'), this.performanceHitRateJunior);
    }),
    modifiedHitRateSponsor7Junior: Ember.computed('recruitment.modifiedHitRateSponsor7Junior', 'performanceHitRateJunior', function () {
      return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateSponsor7Junior'), this.performanceHitRateJunior);
    }),
    //region Proposals

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsNihkJunior: Ember.computed('recruitment.{proposalNihkJuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalNihkJuniorAvgCount', function () {
      var proposalCount = this.proposalNihkJuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalNihkJuniorAvgTotal');
      var proposalsArray = []; //Calculate the proposal totals for each class of recruits

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum up each class of recruits to get the final total


      return this.sumArrays.apply(this, proposalsArray);
    }),

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsNihrJunior: Ember.computed('recruitment.{proposalNihrJuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalNihrJuniorAvgCount', function () {
      var proposalCount = this.proposalNihrJuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalNihrJuniorAvgTotal');
      var proposalsArray = []; //Calculate the proposal totals for each class of recruits

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum up each class of recruits to get the final total


      return this.sumArrays.apply(this, proposalsArray);
    }),

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsOtherFedJunior: Ember.computed('recruitment.{proposalOtherFedJuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalOtherFedJuniorAvgCount', function () {
      var proposalCount = this.proposalOtherFedJuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalOtherFedJuniorAvgTotal');
      var proposalsArray = [];

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum the arrays across each year to get the total proposals


      return this.sumArrays.apply(this, proposalsArray);
    }),

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsFftJunior: Ember.computed('recruitment.{proposalFftJuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalFftJuniorAvgCount', function () {
      var proposalCount = this.proposalFftJuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalFftJuniorAvgTotal');
      var proposalsArray = []; //Calculate the proposal totals for each class of recruits

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum up each class of recruits to get the final total


      return this.sumArrays.apply(this, proposalsArray);
    }),

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsNonFedJunior: Ember.computed('recruitment.{proposalNonFedJuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalNonFedJuniorAvgCount', function () {
      var proposalCount = this.proposalNonFedJuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalNonFedJuniorAvgTotal');
      var proposalsArray = [];

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum the arrays across each year to get the total proposals


      return this.sumArrays.apply(this, proposalsArray);
    }),

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsSponsor5Junior: Ember.computed('recruitment.{proposalSponsor5JuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalSponsor5JuniorAvgCount', function () {
      var proposalCount = this.proposalSponsor5JuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalSponsor5JuniorAvgTotal');
      var proposalsArray = [];

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum the arrays across each year to get the total proposals


      return this.sumArrays.apply(this, proposalsArray);
    }),

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsSponsor6Junior: Ember.computed('recruitment.{proposalSponsor6JuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalSponsor6JuniorAvgCount', function () {
      var proposalCount = this.proposalSponsor6JuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalSponsor6JuniorAvgTotal');
      var proposalsArray = [];

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum the arrays across each year to get the total proposals


      return this.sumArrays.apply(this, proposalsArray);
    }),

    /***
     * Returns an array of proposal totals with the length equal to the number of years ahead
     */
    proposalsSponsor7Junior: Ember.computed('recruitment.{proposalSponsor7JuniorAvgTotal,juniorTerminatedAvgCount}', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'proposalSponsor7JuniorAvgCount', function () {
      var proposalCount = this.proposalSponsor7JuniorAvgCount;
      var proposalSize = this.get('recruitment.proposalSponsor7JuniorAvgTotal');
      var proposalsArray = [];

      for (var i = 1; i < this.yearsAhead + 1; i++) {
        proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, true));
      } //Sum the arrays across each year to get the total proposals


      return this.sumArrays.apply(this, proposalsArray);
    }),
    proposalsTotalJunior: Ember.computed('proposalsNihkJunior.[]', 'proposalsNihrJunior.[]', 'proposalsOtherFedJunior.[]', 'proposalsFftJunior.[]', 'proposalsNonFedJunior.[]', 'proposalsSponsor5Junior.[]', 'proposalsSponsor6Junior.[]', 'proposalsSponsor7Junior.[]', function () {
      return this.sumArrays(this.proposalsNihkJunior, this.proposalsNihrJunior, this.proposalsOtherFedJunior, this.proposalsFftJunior, this.proposalsNonFedJunior, this.proposalsSponsor5Junior, this.proposalsSponsor6Junior, this.proposalsSponsor7Junior);
    }),
    proposalsIndirectsJunior: Ember.computed('proposalsNihkJunior.[]', 'proposalsNihrJunior.[]', 'proposalsOtherFedJunior.[]', 'proposalsFftJunior.[]', 'proposalsNonFedJunior.[]', 'proposalsSponsor5Junior.[]', 'proposalsSponsor6Junior.[]', 'proposalsSponsor7Junior.[]', 'currentUser.team.{icrNihk,icrNihricrOtherFed,icrFedFlowThru,icrNonFed,icrSponsor5,icrSponsor6,icrSponsor7}', function () {
      var icrNihk = this.get('currentUser.team.icrNihk');
      var icrNihr = this.get('currentUser.team.icrNihr');
      var icrOtherFed = this.get('currentUser.team.icrOtherFed');
      var icrFft = this.get('currentUser.team.icrFedFlowThru');
      var icrNonFed = this.get('currentUser.team.icrNonFed');
      var icrSponsor5 = this.get('currentUser.team.icrSponsor5');
      var icrSponsor6 = this.get('currentUser.team.icrSponsor6');
      var icrSponsor7 = this.get('currentUser.team.icrSponsor7');
      return this.sumArrays(this.calculateIndirectsFromTotal(this.proposalsNihkJunior, icrNihk), this.calculateIndirectsFromTotal(this.proposalsNihrJunior, icrNihr), this.calculateIndirectsFromTotal(this.proposalsOtherFedJunior, icrOtherFed), this.calculateIndirectsFromTotal(this.proposalsFftJunior, icrFft), this.calculateIndirectsFromTotal(this.proposalsNonFedJunior, icrNonFed), this.calculateIndirectsFromTotal(this.proposalsSponsor5Junior, icrSponsor5), this.calculateIndirectsFromTotal(this.proposalsSponsor6Junior, icrSponsor6), this.calculateIndirectsFromTotal(this.proposalsSponsor7Junior, icrSponsor7));
    }),
    proposalsDirectsJunior: Ember.computed('proposalsTotalJunior.[]', function () {
      return this.subtractTwoArrays(this.proposalsTotalJunior, this.proposalsIndirectsJunior);
    }),
    //endregion
    //region Successes

    /***
     * Returns an array of success totals with the length equal to the number of years ahead
     */
    successesNihkJunior: Ember.computed('proposalsNihkJunior.[]', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'modifiedHitRateNihkJunior', 'transferredInNihkJunior', function () {
      //Returns an array of projected successes for recruits
      var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
      var modifiedHitRate = this.modifiedHitRateNihkJunior;
      var successesArray = [];

      for (var i = 0; i < this.yearsAhead; i++) {
        var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsNihkJunior, this.proposalNihkJuniorAvgCount, this.get('recruitment.proposalNihkJuniorAvgTotal'), true);
        successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
      } //Also add in transferred in successes


      return this.sumArrays(successesArray, this.transferredInNihkJunior);
    }),

    /***
     * Returns an array of success totals with the length equal to the number of years ahead
     */
    successesNihrJunior: Ember.computed('proposalsNihrJunior.[]', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'modifiedHitRateNihrJunior', 'transferredInNihrJunior', function () {
      var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
      var modifiedHitRate = this.modifiedHitRateNihrJunior;
      var successesArray = [];

      for (var i = 0; i < this.yearsAhead; i++) {
        var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsNihrJunior, this.proposalNihrJuniorAvgCount, this.get('recruitment.proposalNihrJuniorAvgTotal'), true);
        successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
      } //Also add in transferred in successes


      return this.sumArrays(successesArray, this.transferredInNihrJunior);
    }),

    /***
     * Returns an array of success totals with the length equal to the number of years ahead
     */
    successesOtherFedJunior: Ember.computed('proposalsOtherFedJunior.[]', 'model.projection.yoneYtwoDerivativeRatiosOtherFedDirects', 'modifiedHitRateOtherFedJunior', 'transferredInOtherFedJunior', function () {
      var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedDirects');
      var modifiedHitRate = this.modifiedHitRateOtherFedJunior;
      var successesArray = [];

      for (var i = 0; i < this.yearsAhead; i++) {
        var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsOtherFedJunior, this.proposalOtherFedJuniorAvgCount, this.get('recruitment.proposalOtherFedJuniorAvgTotal'), true);
        successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
      } //Also add in transferred in successes


      return this.sumArrays(successesArray, this.transferredInOtherFedJunior);
    }),

    /***
     * Returns an array of success totals with the length equal to the number of years ahead
     */
    successesFftJunior: Ember.computed('proposalsFftJunior.[]', 'model.projection.yoneYtwoDerivativeRatiosFedFlowThruDirects', 'modifiedHitRateFftJunior', 'transferredInFftJunior', function () {
      var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruDirects');
      var modifiedHitRate = this.modifiedHitRateFftJunior;
      var successesArray = [];

      for (var i = 0; i < this.yearsAhead; i++) {
        var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsFftJunior, this.proposalFftJuniorAvgCount, this.get('recruitment.proposalFftJuniorAvgTotal'), true);
        successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
      } //Also add in transferred in successes


      return this.sumArrays(successesArray, this.transferredInFftJunior);
    }),

    /***
     * Returns an array of success totals with the length equal to the number of years ahead
     */
    successesNonFedJunior: Ember.computed('proposalsNonFedJunior.[]', 'model.projection.yoneYtwoDerivativeRatiosNonFedDirects', 'modifiedHitRateNonFedJunior', 'transferredInNonFedJunior', function () {
      var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNonFedDirects');
      var modifiedHitRate = this.modifiedHitRateNonFedJunior;
      var successesArray = [];

      for (var i = 0; i < this.yearsAhead; i++) {
        var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsNonFedJunior, this.proposalNonFedJuniorAvgCount, this.get('recruitment.proposalNonFedJuniorAvgTotal'), true);
        successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
      }

      return this.sumArrays(successesArray, this.transferredInNonFedJunior);
    })
  }, _defineProperty(_Ember$Mixin$create, "successesNonFedJunior", Ember.computed('proposalsNonFedJunior.[]', 'model.projection.yoneYtwoDerivativeRatiosNonFedDirects', 'modifiedHitRateNonFedJunior', 'transferredInNonFedJunior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNonFedDirects');
    var modifiedHitRate = this.modifiedHitRateNonFedJunior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsNonFedJunior, this.proposalNonFedJuniorAvgCount, this.get('recruitment.proposalNonFedJuniorAvgTotal'), true);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    }

    return this.sumArrays(successesArray, this.transferredInNonFedJunior);
  })), _defineProperty(_Ember$Mixin$create, "successesSponsor5Junior", Ember.computed('proposalsSponsor5Junior.[]', 'model.projection.yoneYtwoDerivativeRatiosSponsor5Directs', 'modifiedHitRateSponsor5Junior', 'transferredInSponsor5Junior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor5Junior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsSponsor5Junior, this.proposalSponsor5JuniorAvgCount, this.get('recruitment.proposalSponsor5JuniorAvgTotal'), true);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    }

    return this.sumArrays(successesArray, this.transferredInSponsor5Junior);
  })), _defineProperty(_Ember$Mixin$create, "successesSponsor6Junior", Ember.computed('proposalsSponsor6Junior.[]', 'model.projection.yoneYtwoDerivativeRatiosSponsor6Directs', 'modifiedHitRateSponsor6Junior', 'transferredInSponsor6Junior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor6Junior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsSponsor6Junior, this.proposalSponsor6JuniorAvgCount, this.get('recruitment.proposalSponsor6JuniorAvgTotal'), true);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    }

    return this.sumArrays(successesArray, this.transferredInSponsor6Junior);
  })), _defineProperty(_Ember$Mixin$create, "successesSponsor7Junior", Ember.computed('proposalsSponsor7Junior.[]', 'model.projection.yoneYtwoDerivativeRatiosSponsor7Directs', 'modifiedHitRateSponsor7Junior', 'transferredInSponsor7Junior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor7Junior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsSponsor7Junior, this.proposalSponsor7JuniorAvgCount, this.get('recruitment.proposalSponsor7JuniorAvgTotal'), true);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    }

    return this.sumArrays(successesArray, this.transferredInSponsor7Junior);
  })), _defineProperty(_Ember$Mixin$create, "successesTotalJunior", Ember.computed('successesNihkJunior.[]', 'successesNihrJunior.[]', 'successesOtherFedJunior.[]', 'successesFftJunior.[]', 'successesNonFedJunior', 'successesSponsor5Junior', 'successesSponsor6Junior', 'successesSponsor7Junior', function () {
    return this.sumArrays(this.successesNihkJunior, this.successesNihrJunior, this.successesOtherFedJunior, this.successesFftJunior, this.successesNonFedJunior, this.successesSponsor5Junior, this.successesSponsor6Junior, this.successesSponsor7Junior);
  })), _defineProperty(_Ember$Mixin$create, "successesIndirectsJunior", Ember.computed('successesNihkJunior', 'successesNihrJunior', 'successesOtherFedJunior', 'successesFftJunior', 'successesNonFedJunior', 'successesSponsor5Junior', 'successesSponsor6Junior', 'successesSponsor7Junior', 'currentUser.team.{icrNihk,icrNihr,icrOtherFed,icrFedFlowThru,icrNonFed}', function () {
    var icrNihk = this.get('currentUser.team.icrNihk');
    var icrNihr = this.get('currentUser.team.icrNihr');
    var icrOtherFed = this.get('currentUser.team.icrOtherFed');
    var icrFft = this.get('currentUser.team.icrFedFlowThru');
    var icrNonFed = this.get('currentUser.team.icrNonFed');
    var icrSponsor5 = this.get('currentUser.team.icrSponsor5');
    var icrSponsor6 = this.get('currentUser.team.icrSponsor6');
    var icrSponsor7 = this.get('currentUser.team.icrSponsor7');
    return this.sumArrays(this.calculateIndirectsFromTotal(this.successesNihkJunior, icrNihk), this.calculateIndirectsFromTotal(this.successesNihrJunior, icrNihr), this.calculateIndirectsFromTotal(this.successesOtherFedJunior, icrOtherFed), this.calculateIndirectsFromTotal(this.successesFftJunior, icrFft), this.calculateIndirectsFromTotal(this.successesNonFedJunior, icrNonFed), this.calculateIndirectsFromTotal(this.successesSponsor5Junior, icrSponsor5), this.calculateIndirectsFromTotal(this.successesSponsor6Junior, icrSponsor6), this.calculateIndirectsFromTotal(this.successesSponsor7Junior, icrSponsor7));
  })), _defineProperty(_Ember$Mixin$create, "successesDirectsJunior", Ember.computed('successesTotalJunior', function () {
    return this.subtractTwoArrays(this.successesTotalJunior, this.successesIndirectsJunior);
  })), _defineProperty(_Ember$Mixin$create, "transferredInNihkJunior", Ember.computed('recruitment.successNihkJuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this2 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this2.calculateRecruitTransferredIn(_this2.get('recruitment.successNihkJuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInNihrJunior", Ember.computed('recruitment.successNihrJuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this3 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this3.calculateRecruitTransferredIn(_this3.get('recruitment.successNihrKJuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInOtherFedJunior", Ember.computed('recruitment.successOtherFedJuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this4 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this4.calculateRecruitTransferredIn(_this4.get('recruitment.successOtherFedJuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInFftJunior", Ember.computed('recruitment.successFftJuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this5 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this5.calculateRecruitTransferredIn(_this5.get('recruitment.successFftJuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInNonFedJunior", Ember.computed('recruitment.successNonFedJuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this6 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this6.calculateRecruitTransferredIn(_this6.get('recruitment.successNonFedJuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInSponsor5Junior", Ember.computed('recruitment.successSponsor5JuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this7 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this7.calculateRecruitTransferredIn(_this7.get('recruitment.successSponsor5JuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInSponsor6Junior", Ember.computed('recruitment.successSponsor6JuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this8 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this8.calculateRecruitTransferredIn(_this8.get('recruitment.successSponsor6JuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInSponsor7Junior", Ember.computed('recruitment.successSponsor7JuniorTransferredInAvgTotal', 'recruitsJunior.@each.value', function () {
    var _this9 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this9.calculateRecruitTransferredIn(_this9.get('recruitment.successSponsor7JuniorTransferredInAvgTotal'), i + 1, true);
    });
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingNihkJunior", Ember.computed('successesNihkJunior', function () {
    var _this10 = this;

    var successes = this.successesNihkJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this10.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingNihrJunior", Ember.computed('successesNihrJunior', function () {
    var _this11 = this;

    var successes = this.successesNihrJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this11.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOtherFedJunior", Ember.computed('successesOtherFedJunior', function () {
    var _this12 = this;

    var successes = this.successesOtherFedJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this12.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingFftJunior", Ember.computed('successesFftJunior', function () {
    var _this13 = this;

    var successes = this.successesFftJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationFft');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this13.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingNonFedJunior", Ember.computed('successesNonFedJunior', function () {
    var _this14 = this;

    var successes = this.successesNonFedJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationNonFed');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this14.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingSponsor5Junior", Ember.computed('successesSponsor5Junior', function () {
    var _this15 = this;

    var successes = this.successesSponsor5Junior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this15.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingSponsor6Junior", Ember.computed('successesSponsor6Junior', function () {
    var _this16 = this;

    var successes = this.successesSponsor6Junior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this16.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingSponsor7Junior", Ember.computed('successesSponsor7Junior', function () {
    var _this17 = this;

    var successes = this.successesSponsor7Junior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this17.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsNihkJunior", Ember.computed('projectedSpendingNihkJunior', 'currentUser.team.icrNihk', function () {
    var total = this.projectedSpendingNihkJunior;
    var icr = this.get('currentUser.team.icrNihk');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsNihrJunior", Ember.computed('projectedSpendingNihrJunior', 'currentUser.team.icrNihr', function () {
    var total = this.projectedSpendingNihrJunior;
    var icr = this.get('currentUser.team.icrNihr');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsOtherFedJunior", Ember.computed('projectedSpendingOtherFedJunior', 'currentUser.team.icrOtherFed', function () {
    var total = this.projectedSpendingOtherFedJunior;
    var icr = this.get('currentUser.team.icrOtherFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsFftJunior", Ember.computed('projectedSpendingFftJunior', 'currentUser.team.icrFft', function () {
    var total = this.projectedSpendingFftJunior;
    var icr = this.get('currentUser.team.icrFedFlowThru');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsNonFedJunior", Ember.computed('projectedSpendingNonFedJunior', 'currentUser.team.icrNonFed', function () {
    var total = this.projectedSpendingNonFedJunior;
    var icr = this.get('currentUser.team.icrNonFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsSponsor5Junior", Ember.computed('projectedSpendingSponsor5Junior', 'currentUser.team.icrSponsor5', function () {
    var total = this.projectedSpendingSponsor5Junior;
    var icr = this.get('currentUser.team.icrSponsor5');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsSponsor6Junior", Ember.computed('projectedSpendingSponsor6Junior', 'currentUser.team.icrSponsor6', function () {
    var total = this.projectedSpendingSponsor6Junior;
    var icr = this.get('currentUser.team.icrSponsor6');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsSponsor7Junior", Ember.computed('projectedSpendingSponsor7Junior', 'currentUser.team.icrSponsor7', function () {
    var total = this.projectedSpendingSponsor7Junior;
    var icr = this.get('currentUser.team.icrSponsor7');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingTotalJunior", Ember.computed('projectedSpendingNihkJunior.[]', 'projectedSpendingNihrJunior.[]', 'projectedSpendingOtherFedJunior.[]', 'projectedSpendingFftJunior.[]', 'projectedSpendingNonFedJunior.[]', 'projectedSpendingSponsor5Junior.[]', 'projectedSpendingSponsor6Junior.[]', 'projectedSpendingSponsor7Junior.[]', function () {
    return this.sumArrays(this.projectedSpendingNihkJunior, this.projectedSpendingNihrJunior, this.projectedSpendingOtherFedJunior, this.projectedSpendingFftJunior, this.projectedSpendingNonFedJunior, this.projectedSpendingSponsor5Junior, this.projectedSpendingSponsor6Junior, this.projectedSpendingSponsor7Junior);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsJunior", Ember.computed('projectedSpendingIndirectsNihkJunior.[]', 'projectedSpendingIndirectsNihrJunior.[]', 'projectedSpendingIndirectsOtherFedJunior.[]', 'projectedSpendingIndirectsFftJunior.[]', 'projectedSpendingIndirectsNonFedJunior.[]', 'projectedSpendingIndirectsSponsor5Junior.[]', 'projectedSpendingIndirectsSponsor6Junior.[]', 'projectedSpendingIndirectsSponsor7Junior.[]', function () {
    return this.sumArrays(this.projectedSpendingIndirectsNihkJunior, this.projectedSpendingIndirectsNihrJunior, this.projectedSpendingIndirectsOtherFedJunior, this.projectedSpendingIndirectsFftJunior, this.projectedSpendingIndirectsNonFedJunior, this.projectedSpendingIndirectsSponsor5Junior, this.projectedSpendingIndirectsSponsor6Junior, this.projectedSpendingIndirectsSponsor7Junior);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingDirectsJunior", Ember.computed('projectedSpendingTotalJunior.[]', function () {
    return this.subtractTwoArrays(this.projectedSpendingTotalJunior, this.projectedSpendingIndirectsJunior);
  })), _defineProperty(_Ember$Mixin$create, "earliestClassIndexJunior", Ember.computed('recruitsJunior.@each.value', function () {
    var index = 0;

    for (var i = 1; i < this.yearsAhead + 1; i++) {
      var recruits = this.recruitsForYear(i + 1, true);

      if (recruits > 0) {
        index = i;
        break;
      }
    }

    return index;
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassNihkJunior", Ember.computed('proposalNihkJuniorAvgCount', 'modifiedHitRateNihkJunior', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', function () {
    var proposalCount = this.proposalNihkJuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNihkJuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
    var modifiedHitRate = this.modifiedHitRateNihkJunior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassNihrJunior", Ember.computed('proposalNihrJuniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', 'modifiedHitRateNihrJunior', function () {
    var proposalCount = this.proposalNihrJuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNihrJuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
    var modifiedHitRate = this.modifiedHitRateNihrJunior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassOtherFedJunior", Ember.computed('proposalOtherFedJuniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosOtherFedDirects', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', 'modifiedHitRateOtherFedJunior', function () {
    var proposalCount = this.proposalOtherFedJuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalOtherFedJuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedDirects');
    var modifiedHitRate = this.modifiedHitRateOtherFedJunior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassFftJunior", Ember.computed('proposalFftJuniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosFftDirects', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', 'modifiedHitRateFftJunior', function () {
    var proposalCount = this.proposalFftJuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalFftJuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruDirects');
    var modifiedHitRate = this.modifiedHitRateFftJunior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationFft');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassNonFedJunior", Ember.computed('proposalNonFedJuniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosNonFedDirects', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', 'modifiedHitRateNonFedJunior', function () {
    var proposalCount = this.proposalNonFedJuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNonFedJuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNonFedDirects');
    var modifiedHitRate = this.modifiedHitRateNonFedJunior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassSponsor5Junior", Ember.computed('proposalSponsor5JuniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosSponsor5Directs', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', 'modifiedHitRateSponsor5Junior', function () {
    var proposalCount = this.proposalSponsor5JuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor5JuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor5Junior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassSponsor6Junior", Ember.computed('proposalSponsor6JuniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosSponsor6Directs', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', 'modifiedHitRateSponsor6Junior', function () {
    var proposalCount = this.proposalSponsor6JuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor6JuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor6Junior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassSponsor7Junior", Ember.computed('proposalSponsor7JuniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosSponsor7Directs', 'recruitsJunior.@each.value', 'earliestClassIndexJunior', 'modifiedHitRateSponsor7Junior', function () {
    var proposalCount = this.proposalSponsor7JuniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor7JuniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor7Junior;
    var classIndex = this.earliestClassIndexJunior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, true);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsNihkJunior", Ember.computed('projectedSpendingOneClassNihkJunior', 'currentUser.team.icrNihk', function () {
    var total = this.projectedSpendingOneClassNihkJunior;
    var icr = this.get('currentUser.team.icrNihk');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsNihrJunior", Ember.computed('projectedSpendingOneClassNihrJunior', 'currentUser.team.icrNihr', function () {
    var total = this.projectedSpendingOneClassNihrJunior;
    var icr = this.get('currentUser.team.icrNihr');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsOtherFedJunior", Ember.computed('projectedSpendingOneClassOtherFedJunior', 'currentUser.team.icrOtherFed', function () {
    var total = this.projectedSpendingOneClassOtherFedJunior;
    var icr = this.get('currentUser.team.icrOtherFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsFftJunior", Ember.computed('projectedSpendingOneClassFftJunior', 'currentUser.team.icrFft', function () {
    var total = this.projectedSpendingOneClassFftJunior;
    var icr = this.get('currentUser.team.icrFedFlowThru');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsNonFedJunior", Ember.computed('projectedSpendingOneClassNonFedJunior', 'currentUser.team.icrNonFed', function () {
    var total = this.projectedSpendingOneClassNonFedJunior;
    var icr = this.get('currentUser.team.icrNonFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsSponsor5Junior", Ember.computed('projectedSpendingOneClassSponsor5Junior', 'currentUser.team.icrSponsor5', function () {
    var total = this.projectedSpendingOneClassSponsor5Junior;
    var icr = this.get('currentUser.team.icrSponsor5');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsSponsor6Junior", Ember.computed('projectedSpendingOneClassSponsor6Junior', 'currentUser.team.icrSponsor6', function () {
    var total = this.projectedSpendingOneClassSponsor6Junior;
    var icr = this.get('currentUser.team.icrSponsor6');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsSponsor7Junior", Ember.computed('projectedSpendingOneClassSponsor7Junior', 'currentUser.team.icrSponsor7', function () {
    var total = this.projectedSpendingOneClassSponsor7Junior;
    var icr = this.get('currentUser.team.icrSponsor7');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassTotalJunior", Ember.computed('projectedSpendingOneClassNihkJunior.[]', 'projectedSpendingOneClassNihrJunior.[]', 'projectedSpendingOneClassOtherFedJunior.[]', 'projectedSpendingOneClassFftJunior.[]', 'projectedSpendingOneClassNonFedJunior.[]', 'projectedSpendingOneClassSponsor5Junior.[]', 'projectedSpendingOneClassSponsor6Junior.[]', 'projectedSpendingOneClassSponsor7Junior.[]', function () {
    var a = this.sumArrays(this.projectedSpendingOneClassNihkJunior, this.projectedSpendingOneClassNihrJunior, this.projectedSpendingOneClassOtherFedJunior, this.projectedSpendingOneClassFftJunior, this.projectedSpendingOneClassNonFedJunior, this.projectedSpendingOneClassSponsor5Junior, this.projectedSpendingOneClassSponsor6Junior, this.projectedSpendingOneClassSponsor7Junior);
    return a;
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsJunior", Ember.computed('projectedSpendingOneClassIndirectsNihkJunior.[]', 'projectedSpendingOneClassIndirectsNihrJunior.[]', 'projectedSpendingOneClassIndirectsOtherFedJunior.[]', 'projectedSpendingOneClassIndirectsFftJunior.[]', 'projectedSpendingOneClassIndirectsNonFedJunior.[]', 'projectedSpendingOneClassIndirectsSponsor5Junior.[]', 'projectedSpendingOneClassIndirectsSponsor6Junior.[]', 'projectedSpendingOneClassIndirectsSponsor7Junior.[]', function () {
    return this.sumArrays(this.projectedSpendingOneClassIndirectsNihkJunior, this.projectedSpendingOneClassIndirectsNihrJunior, this.projectedSpendingOneClassIndirectsOtherFedJunior, this.projectedSpendingOneClassIndirectsFftJunior, this.projectedSpendingOneClassIndirectsNonFedJunior, this.projectedSpendingOneClassIndirectsSponsor5Junior, this.projectedSpendingOneClassIndirectsSponsor6Junior, this.projectedSpendingOneClassIndirectsSponsor7Junior);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassDirectsJunior", Ember.computed('projectedSpendingOneClassTotalJunior', function () {
    return this.subtractTwoArrays(this.projectedSpendingOneClassTotalJunior, this.projectedSpendingOneClassIndirectsJunior);
  })), _defineProperty(_Ember$Mixin$create, "relinquishedDirectsJunior", Ember.computed('recruitment.{successJuniorTransferredOutAvgDirects}', 'attritionJunior.@each.value', function () {
    var _this18 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var terminatedDirects = this.get('recruitment.successJuniorTransferredOutAvgDirects');
    var termRemaining = this.termRemaining;
    var terminatedArrays = range.map(function (i) {
      var yearsAhead = i + 1;

      var attrition = _this18.attritionForYear(yearsAhead, true);

      return _this18.calculateStartupRelinquished(terminatedDirects, attrition, termRemaining, i + 1);
    });
    return this.sumArrays.apply(this, _toConsumableArray(terminatedArrays));
  })), _defineProperty(_Ember$Mixin$create, "relinquishedIndirectsJunior", Ember.computed('recruitment.{successJuniorTransferredOutAvgIndrects}', 'attritionJunior.@each.value', function () {
    var _this19 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var terminatedDirects = this.get('recruitment.successJuniorTransferredOutAvgIndirects');
    var termRemaining = this.termRemaining;
    var terminatedArrays = range.map(function (i) {
      var yearsAhead = i + 1;

      var attrition = _this19.attritionForYear(yearsAhead, true);

      return _this19.calculateStartupRelinquished(terminatedDirects, attrition, termRemaining, i + 1);
    });
    return this.sumArrays.apply(this, _toConsumableArray(terminatedArrays));
  })), _defineProperty(_Ember$Mixin$create, "startupPackagesJunior", Ember.computed('startUpSpendYearsJunior', 'startupPackageAmountJunior', 'compensationCoverageStartUpJunior', 'recruitsJunior.@each.value', 'averageTotalCompensationJunior', function () {
    var _this20 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var startupPackageAmount = this.startupPackageAmountJunior;
    var termRemaining = this.startUpSpendYearsJunior;
    var compCoverageStartUp = this.compensationCoverageStartUpJunior;
    var compensation = this.averageTotalCompensationJunior;
    var compCoverage = compCoverageStartUp * compensation;
    var startupArrays = range.map(function (i) {
      var yearsAhead = i + 1;

      var recruits = _this20.recruitsForYear(yearsAhead, true); //Add in the compensation coverage


      return _this20.calculateStartupRelinquished(startupPackageAmount, recruits, termRemaining, yearsAhead, compCoverage);
    });
    return this.sumArrays.apply(this, _toConsumableArray(startupArrays));
  })), _defineProperty(_Ember$Mixin$create, "compensationReductionJunior", Ember.computed('compensationCoverageAfterJunior', 'averageTotalCompensationJunior', 'attritionJunior.@each.value', function () {
    var _this21 = this;

    var yearsAhead = this.yearsAhead;

    var range = _toConsumableArray(Array(yearsAhead).keys());

    var compReductionArrays = range.map(function (i) {
      var year = i + 1;

      var attrition = _this21.attritionForYear(year, true);

      var reduction = _this21.compensationCoverageAfterJunior * _this21.averageTotalCompensationJunior * attrition;
      var reductionOneClassArray = Array(yearsAhead).fill(0);

      for (var _i2 = year - 1; _i2 < yearsAhead; _i2++) {
        reductionOneClassArray[_i2] = reduction;
      }

      return reductionOneClassArray;
    });
    return this.sumArrays.apply(this, _toConsumableArray(compReductionArrays));
  })), _defineProperty(_Ember$Mixin$create, "postStartCompensationJunior", Ember.computed('startUpSpendYearsJunior', 'compensationCoverageAfterJunior', 'averageTotalCompensationJunior', 'recruitsJunior.@each.value', function () {
    var _this22 = this;

    var yearsAhead = this.yearsAhead;

    var range = _toConsumableArray(Array(yearsAhead).keys());

    var term = this.startUpSpendYearsJunior;
    var postStartArrays = range.map(function (i) {
      var year = i + 1;

      var recruits = _this22.recruitsForYear(year, true);

      var compCoverage = _this22.compensationCoverageAfterJunior;
      var averageComp = _this22.averageTotalCompensationJunior;
      var postComp = compCoverage * averageComp * recruits;
      var postCompOneClassArray = Array(yearsAhead).fill(0);

      for (var _i3 = 0; _i3 < yearsAhead; _i3++) {
        var index = _i3 + (year - 1);

        if (index >= yearsAhead) {
          break;
        }

        if (index >= year - 1 + term) {
          postCompOneClassArray[index] = postComp;
        }
      }

      return postCompOneClassArray;
    });
    return this.sumArrays.apply(this, _toConsumableArray(postStartArrays));
  })), _defineProperty(_Ember$Mixin$create, "netIndirectCostRecoveryJunior", Ember.computed('projectedSpendingIndirectsJunior.[]', 'relinquishedIndirectsJunior.[]', function () {
    //Deduct the compensation reduction from the net indirect cost recovery
    var indirectsArray = this.projectedSpendingIndirectsJunior;
    var reductionArray = this.relinquishedIndirectsJunior;
    return this.subtractTwoArrays(indirectsArray, reductionArray);
  })), _defineProperty(_Ember$Mixin$create, "netDirectCostRecoveryJunior", Ember.computed('projectedSpendingDirectsJunior.[]', 'relinquishedDirectsJunior.[]', function () {
    //Deduct the compensation reduction from the net indirect cost recovery
    var indirectsArray = this.projectedSpendingDirectsJunior;
    var reductionArray = this.relinquishedDirectsJunior;
    return this.subtractTwoArrays(indirectsArray, reductionArray);
  })), _defineProperty(_Ember$Mixin$create, "cumulativeIndirectCostRecoveryJunior", Ember.computed('netIndirectCostRecoveryJunior.[]', function () {
    var netIndirectsArray = this.netIndirectCostRecoveryJunior;
    return this.cumulativeArray(netIndirectsArray);
  })), _defineProperty(_Ember$Mixin$create, "netInvestmentJunior", Ember.computed('startupPackagesJunior', 'compensationReductionJunior', 'postStartCompensationJunior', 'projectedSpendingDirectsJunior.[]', function () {
    var startup = this.startupPackagesJunior;
    var compensationReduction = this.compensationReductionJunior;
    var postStart = this.postStartCompensationJunior;
    return this.sumArrays(this.subtractTwoArrays(startup, compensationReduction), postStart);
  })), _defineProperty(_Ember$Mixin$create, "cumulativeInvestmentJunior", Ember.computed('netInvestmentJunior.[]', 'recruitsJunior.@each.value', function () {
    var netInvestmentsArray = this.netInvestmentJunior;
    return this.cumulativeArray(netInvestmentsArray);
  })), _defineProperty(_Ember$Mixin$create, "cumulativeCashFlowJunior", Ember.computed('cumulativeInvestmentJunior.[]', 'cumulativeIndirectCostRecoveryJunior.[]', function () {
    return this.subtractTwoArrays(this.cumulativeIndirectCostRecoveryJunior, this.cumulativeInvestmentJunior);
  })), _defineProperty(_Ember$Mixin$create, "proposalNihkSeniorAvgCount", Ember.computed('recruitment.proposalNihkSeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalNihkSeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalNihrSeniorAvgCount", Ember.computed('recruitment.proposalNihrSeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalNihrSeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalOtherFedSeniorAvgCount", Ember.computed('recruitment.proposalOtherFedSeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalOtherFedSeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalFftSeniorAvgCount", Ember.computed('recruitment.proposalFftSeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalFftSeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalNonFedSeniorAvgCount", Ember.computed('recruitment.proposalNonFedSeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalNonFedSeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalSponsor5SeniorAvgCount", Ember.computed('recruitment.proposalSponsor5SeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalSponsor5SeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalSponsor6SeniorAvgCount", Ember.computed('recruitment.proposalSponsor6SeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalSponsor6SeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalSponsor7SeniorAvgCount", Ember.computed('recruitment.proposalSponsor7SeniorAvgCount', 'performanceProposalSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.proposalSponsor7SeniorAvgCount'), this.performanceProposalSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateNihkSenior", Ember.computed('recruitment.modifiedHitRateNihkSenior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateNihkSenior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateNihrSenior", Ember.computed('recruitment.modifiedHitRateNihrSenior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateNihrSenior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateOtherFedSenior", Ember.computed('recruitment.modifiedHitRateOtherFedkSenior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateOtherFedSenior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateFftSenior", Ember.computed('recruitment.modifiedHitRateFftSenior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateFftSenior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateNonFedSenior", Ember.computed('recruitment.modifiedHitRateNonFedSenior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateNonFedSenior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateSponsor5Senior", Ember.computed('recruitment.modifiedHitRateSponsor5Senior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateSponsor5Senior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateSponsor6Senior", Ember.computed('recruitment.modifiedHitRateSponsor6Senior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateSponsor6Senior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "modifiedHitRateSponsor7Senior", Ember.computed('recruitment.modifiedHitRateSponsor7Senior', 'performanceHitRateSenior', function () {
    return this.calculateWithPerformanceIndex(this.get('recruitment.modifiedHitRateSponsor7Senior'), this.performanceHitRateSenior);
  })), _defineProperty(_Ember$Mixin$create, "proposalsNihkSenior", Ember.computed('recruitment.{proposalNihkSeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalNihkSeniorAvgCount', function () {
    var proposalCount = this.proposalNihkSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNihkSeniorAvgTotal');
    var proposalsArray = []; //Calculate the proposal totals for each class of recruits

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum up each class of recruits to get the final total


    var a = this.sumArrays.apply(this, proposalsArray);
    return a;
  })), _defineProperty(_Ember$Mixin$create, "proposalsNihrSenior", Ember.computed('recruitment.{proposalNihrSeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalNihrSeniorAvgCount', function () {
    var proposalCount = this.proposalNihrSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNihrSeniorAvgTotal');
    var proposalsArray = []; //Calculate the proposal totals for each class of recruits

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum up each class of recruits to get the final total


    return this.sumArrays.apply(this, proposalsArray);
  })), _defineProperty(_Ember$Mixin$create, "proposalsOtherFedSenior", Ember.computed('recruitment.{proposalOtherFedSeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalOtherFedSeniorAvgCount', function () {
    var proposalCount = this.proposalOtherFedSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalOtherFedSeniorAvgTotal');
    var proposalsArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum the arrays across each year to get the total proposals


    return this.sumArrays.apply(this, proposalsArray);
  })), _defineProperty(_Ember$Mixin$create, "proposalsFftSenior", Ember.computed('recruitment.{proposalFftSeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalFftSeniorAvgCount', function () {
    var proposalCount = this.proposalFftSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalFftSeniorAvgTotal');
    var proposalsArray = []; //Calculate the proposal totals for each class of recruits

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum up each class of recruits to get the final total


    return this.sumArrays.apply(this, proposalsArray);
  })), _defineProperty(_Ember$Mixin$create, "proposalsNonFedSenior", Ember.computed('recruitment.{proposalNonFedSeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalNonFedSeniorAvgCount', function () {
    var proposalCount = this.proposalNonFedSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNonFedSeniorAvgTotal');
    var proposalsArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum the arrays across each year to get the total proposals


    return this.sumArrays.apply(this, proposalsArray);
  })), _defineProperty(_Ember$Mixin$create, "proposalsSponsor5Senior", Ember.computed('recruitment.{proposalSponsor5SeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalSponsor5SeniorAvgCount', function () {
    var proposalCount = this.proposalSponsor5SeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor5SeniorAvgTotal');
    var proposalsArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum the arrays across each year to get the total proposals


    return this.sumArrays.apply(this, proposalsArray);
  })), _defineProperty(_Ember$Mixin$create, "proposalsSponsor6Senior", Ember.computed('recruitment.{proposalSponsor6SeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalSponsor6SeniorAvgCount', function () {
    var proposalCount = this.proposalSponsor6SeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor6SeniorAvgTotal');
    var proposalsArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum the arrays across each year to get the total proposals


    return this.sumArrays.apply(this, proposalsArray);
  })), _defineProperty(_Ember$Mixin$create, "proposalsSponsor7Senior", Ember.computed('recruitment.{proposalSponsor7SeniorAvgTotal,seniorTerminatedAvgCount}', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'proposalSponsor7SeniorAvgCount', function () {
    var proposalCount = this.proposalSponsor7SeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor7SeniorAvgTotal');
    var proposalsArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      proposalsArray.push(this.calculateRecruitProposals(proposalCount, proposalSize, i, false));
    } //Sum the arrays across each year to get the total proposals


    return this.sumArrays.apply(this, proposalsArray);
  })), _defineProperty(_Ember$Mixin$create, "proposalsTotalSenior", Ember.computed('proposalsNihkSenior.[]', 'proposalsNihrSenior.[]', 'proposalsOtherFedSenior.[]', 'proposalsFftSenior.[]', 'proposalsNonFedSenior.[]', 'proposalsSponsor5Senior.[]', 'proposalsSponsor6Senior.[]', 'proposalsSponsor7Senior.[]', function () {
    return this.sumArrays(this.proposalsNihkSenior, this.proposalsNihrSenior, this.proposalsOtherFedSenior, this.proposalsFftSenior, this.proposalsNonFedSenior, this.proposalsSponsor5Senior, this.proposalsSponsor6Senior, this.proposalsSponsor7Senior);
  })), _defineProperty(_Ember$Mixin$create, "proposalsIndirectsSenior", Ember.computed('proposalsNihkSenior.[]', 'proposalsNihrSenior.[]', 'proposalsOtherFedSenior.[]', 'proposalsFftSenior.[]', 'proposalsNonFedSenior.[]', 'proposalsSponsor5Senior.[]', 'proposalsSponsor6Senior.[]', 'proposalsSponsor7Senior.[]', 'currentUser.team.{icrNihk,icrNihr,icrOtherFed,icrFedFlowThru,icrNonFed,icrSponsor5,icrSponsor6,icrSponsor7}', function () {
    var icrNihk = this.get('currentUser.team.icrNihk');
    var icrNihr = this.get('currentUser.team.icrNihr');
    var icrOtherFed = this.get('currentUser.team.icrOtherFed');
    var icrFft = this.get('currentUser.team.icrFedFlowThru');
    var icrNonFed = this.get('currentUser.team.icrNonFed');
    var icrSponsor5 = this.get('currentUser.team.icrSponsor5');
    var icrSponsor6 = this.get('currentUser.team.icrSponsor6');
    var icrSponsor7 = this.get('currentUser.team.icrSponsor7');
    return this.sumArrays(this.calculateIndirectsFromTotal(this.proposalsNihkSenior, icrNihk), this.calculateIndirectsFromTotal(this.proposalsNihrSenior, icrNihr), this.calculateIndirectsFromTotal(this.proposalsOtherFedSenior, icrOtherFed), this.calculateIndirectsFromTotal(this.proposalsFftSenior, icrFft), this.calculateIndirectsFromTotal(this.proposalsNonFedSenior, icrNonFed), this.calculateIndirectsFromTotal(this.proposalsSponsor5Senior, icrSponsor5), this.calculateIndirectsFromTotal(this.proposalsSponsor6Senior, icrSponsor6), this.calculateIndirectsFromTotal(this.proposalsSponsor7Senior, icrSponsor7));
  })), _defineProperty(_Ember$Mixin$create, "proposalsDirectsSenior", Ember.computed('proposalsTotalSenior', function () {
    return this.subtractTwoArrays(this.proposalsTotalSenior, this.proposalsIndirectsSenior);
  })), _defineProperty(_Ember$Mixin$create, "successesNihkSenior", Ember.computed('proposalsNihkSenior.[]', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'modifiedHitRateNihkSenior', 'transferredInNihkSenior', function () {
    //Returns an array of projected successes for recruits
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
    var modifiedHitRate = this.modifiedHitRateNihkSenior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsNihkSenior, this.proposalNihkSeniorAvgCount, this.get('recruitment.proposalNihkSeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInNihkSenior);
  })), _defineProperty(_Ember$Mixin$create, "successesNihrSenior", Ember.computed('proposalsNihrSenior.[]', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'modifiedHitRateNihrSenior', 'transferredInNihrSenior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
    var modifiedHitRate = this.modifiedHitRateNihrSenior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsNihrSenior, this.proposalNihrSeniorAvgCount, this.get('recruitment.proposalNihrSeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInNihrSenior);
  })), _defineProperty(_Ember$Mixin$create, "successesOtherFedSenior", Ember.computed('proposalsOtherFedSenior.[]', 'model.projection.yoneYtwoDerivativeRatiosOtherFedDirects', 'modifiedHitRateOtherFedSenior', 'transferredInOtherFedSenior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedDirects');
    var modifiedHitRate = this.modifiedHitRateOtherFedSenior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsOtherFedSenior, this.proposalOtherFedSeniorAvgCount, this.get('recruitment.proposalOtherFedSeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInOtherFedSenior);
  })), _defineProperty(_Ember$Mixin$create, "successesFftSenior", Ember.computed('proposalsFftSenior.[]', 'model.projection.yoneYtwoDerivativeRatiosfftDirects', 'modifiedHitRateFftSenior', 'transferredInFftSenior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruDirects');
    var modifiedHitRate = this.modifiedHitRateFftSenior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsFftSenior, this.proposalFftSeniorAvgCount, this.get('recruitment.proposalFftSeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInFftSenior);
  })), _defineProperty(_Ember$Mixin$create, "successesNonFedSenior", Ember.computed('proposalsNonFedSenior.[]', 'model.projection.yoneYtwoDerivativeRatiosNonFedDirects', 'modifiedHitRateNonFedSenior', 'transferredInNonFedSenior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNonFedDirects');
    var modifiedHitRate = this.modifiedHitRateNonFedSenior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsNonFedSenior, this.proposalNonFedSeniorAvgCount, this.get('recruitment.proposalNonFedSeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInNonFedSenior);
  })), _defineProperty(_Ember$Mixin$create, "successesSponsor5Senior", Ember.computed('proposalsSponsor5Senior.[]', 'model.projection.yoneYtwoDerivativeRatiosSponsor5Directs', 'modifiedHitRateSponsor5Senior', 'transferredInSponsor5Senior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor5Senior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsSponsor5Senior, this.proposalSponsor5SeniorAvgCount, this.get('recruitment.proposalSponsor5SeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInSponsor5Senior);
  })), _defineProperty(_Ember$Mixin$create, "successesSponsor6Senior", Ember.computed('proposalsSponsor6Senior.[]', 'model.projection.yoneYtwoDerivativeRatiosSponsor6Directs', 'modifiedHitRateSponsor6Senior', 'transferredInSponsor6Senior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor6Senior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsSponsor6Senior, this.proposalSponsor6SeniorAvgCount, this.get('recruitment.proposalSponsor6SeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInSponsor6Senior);
  })), _defineProperty(_Ember$Mixin$create, "successesSponsor7Senior", Ember.computed('proposalsSponsor7Senior.[]', 'model.projection.yoneYtwoDerivativeRatiosSponsor7Directs', 'modifiedHitRateSponsor7Senior', 'transferredInSponsor7Senior', function () {
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor7Senior;
    var successesArray = [];

    for (var i = 0; i < this.yearsAhead; i++) {
      var proposals = this.calculateRecruitProposalsWithoutTransferredIn(i, this.proposalsSponsor7Senior, this.proposalSponsor7SeniorAvgCount, this.get('recruitment.proposalSponsor7SeniorAvgTotal'), false);
      successesArray.push(this.calculateOneClassRecruitSuccesses(y1y2, proposals, modifiedHitRate, i));
    } //Also add in transferred in successes


    return this.sumArrays(successesArray, this.transferredInSponsor7Senior);
  })), _defineProperty(_Ember$Mixin$create, "successesTotalSenior", Ember.computed('successesNihkSenior', 'successesNihrSenior', 'successesOtherFedSenior', 'successesFftSenior', 'successesNonFedSenior', 'successesSponsor5Senior', 'successesSponsor6Senior', 'successesSponsor7Senior', function () {
    return this.sumArrays(this.successesNihkSenior, this.successesNihrSenior, this.successesOtherFedSenior, this.successesFftSenior, this.successesNonFedSenior, this.successesSponsor5Senior, this.successesSponsor6Senior, this.successesSponsor7Senior);
  })), _defineProperty(_Ember$Mixin$create, "successesIndirectsSenior", Ember.computed('successesNihkSenior', 'successesNihrSenior', 'successesOtherFedSenior', 'successesFftSenior', 'successesNonFedSenior', 'successesSponsor5Senior', 'successesSponsor6Senior', 'successesSponsor7Senior', 'currentUser.team.{icrNihk,icrNihr,icrOtherFed,icrFedFlowThru,icrNonFed,icrSponsor5,icrSponsor6,icrSponsor7}', function () {
    var icrNihk = this.get('currentUser.team.icrNihk');
    var icrNihr = this.get('currentUser.team.icrNihr');
    var icrOtherFed = this.get('currentUser.team.icrOtherFed');
    var icrFft = this.get('currentUser.team.icrFedFlowThru');
    var icrNonFed = this.get('currentUser.team.icrNonFed');
    var icrSponsor5 = this.get('currentUser.team.icrSponsor5');
    var icrSponsor6 = this.get('currentUser.team.icrSponsor6');
    var icrSponsor7 = this.get('currentUser.team.icrSponsor7');
    return this.sumArrays(this.calculateIndirectsFromTotal(this.successesNihkSenior, icrNihk), this.calculateIndirectsFromTotal(this.successesNihrSenior, icrNihr), this.calculateIndirectsFromTotal(this.successesOtherFedSenior, icrOtherFed), this.calculateIndirectsFromTotal(this.successesFftSenior, icrFft), this.calculateIndirectsFromTotal(this.successesNonFedSenior, icrNonFed), this.calculateIndirectsFromTotal(this.successesSponsor5Senior, icrSponsor5), this.calculateIndirectsFromTotal(this.successesSponsor6Senior, icrSponsor6), this.calculateIndirectsFromTotal(this.successesSponsor7Senior, icrSponsor7));
  })), _defineProperty(_Ember$Mixin$create, "successesDirectsSenior", Ember.computed('successesTotalSenior', function () {
    return this.subtractTwoArrays(this.successesTotalSenior, this.successesIndirectsSenior);
  })), _defineProperty(_Ember$Mixin$create, "transferredInNihkSenior", Ember.computed('recruitment.successNihkSeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this23 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this23.calculateRecruitTransferredIn(_this23.get('recruitment.successNihkSeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInNihrSenior", Ember.computed('recruitment.successNihrSeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this24 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this24.calculateRecruitTransferredIn(_this24.get('recruitment.successNihrKSeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInOtherFedSenior", Ember.computed('recruitment.successOtherFedSeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this25 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this25.calculateRecruitTransferredIn(_this25.get('recruitment.successOtherFedSeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInFftSenior", Ember.computed('recruitment.successFftSeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this26 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this26.calculateRecruitTransferredIn(_this26.get('recruitment.successFftSeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInNonFedSenior", Ember.computed('recruitment.successNonFedSeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this27 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this27.calculateRecruitTransferredIn(_this27.get('recruitment.successNonFedSeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInSponsor5Senior", Ember.computed('recruitment.successSponsor5SeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this28 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this28.calculateRecruitTransferredIn(_this28.get('recruitment.successSponsor5SeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInSponsor6Senior", Ember.computed('recruitment.successSponsor6SeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this29 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this29.calculateRecruitTransferredIn(_this29.get('recruitment.successSponsor6SeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "transferredInSponsor7Senior", Ember.computed('recruitment.successSponsor7SeniorTransferredInAvgTotal', 'recruitsSenior.@each.value', function () {
    var _this30 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    return range.map(function (i) {
      return _this30.calculateRecruitTransferredIn(_this30.get('recruitment.successSponsor7SeniorTransferredInAvgTotal'), i + 1, false);
    });
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingNihkSenior", Ember.computed('successesNihkSenior', function () {
    var _this31 = this;

    var successes = this.successesNihkSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this31.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingNihrSenior", Ember.computed('successesNihrSenior', function () {
    var _this32 = this;

    var successes = this.successesNihrSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this32.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOtherFedSenior", Ember.computed('successesOtherFedSenior', function () {
    var _this33 = this;

    var successes = this.successesOtherFedSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this33.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingFftSenior", Ember.computed('successesFftSenior', function () {
    var _this34 = this;

    var successes = this.successesFftSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationFft');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this34.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingNonFedSenior", Ember.computed('successesNonFedSenior', function () {
    var _this35 = this;

    var successes = this.successesNonFedSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationNonFed');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this35.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingSponsor5Senior", Ember.computed('successesSponsor5Senior', function () {
    var _this36 = this;

    var successes = this.successesSponsor5Senior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this36.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingSponsor6Senior", Ember.computed('successesSponsor6Senior', function () {
    var _this37 = this;

    var successes = this.successesSponsor6Senior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this37.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingSponsor7Senior", Ember.computed('successesSponsor7Senior', function () {
    var _this38 = this;

    var successes = this.successesSponsor7Senior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var spendingArray = range.map(function (i) {
      return range.map(function (j) {
        return _this38.calculateProjectedSpending(wad, successes, j + 1, i);
      });
    });
    return this.sumArrays.apply(this, _toConsumableArray(spendingArray));
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsNihkSenior", Ember.computed('projectedSpendingNihkSenior', 'currentUser.team.icrNihk', function () {
    var total = this.projectedSpendingNihkSenior;
    var icr = this.get('currentUser.team.icrNihk');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsNihrSenior", Ember.computed('projectedSpendingNihrSenior', 'currentUser.team.icrNihr', function () {
    var total = this.projectedSpendingNihrSenior;
    var icr = this.get('currentUser.team.icrNihr');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsOtherFedSenior", Ember.computed('projectedSpendingOtherFedSenior', 'currentUser.team.icrOtherFed', function () {
    var total = this.projectedSpendingOtherFedSenior;
    var icr = this.get('currentUser.team.icrOtherFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsFftSenior", Ember.computed('projectedSpendingFftSenior', 'currentUser.team.icrFft', function () {
    var total = this.projectedSpendingFftSenior;
    var icr = this.get('currentUser.team.icrFedFlowThru');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsNonFedSenior", Ember.computed('projectedSpendingNonFedSenior', 'currentUser.team.icrNonFed', function () {
    var total = this.projectedSpendingNonFedSenior;
    var icr = this.get('currentUser.team.icrNonFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsSponsor5Senior", Ember.computed('projectedSpendingSponsor5Senior', 'currentUser.team.icrSponsor5', function () {
    var total = this.projectedSpendingSponsor5Senior;
    var icr = this.get('currentUser.team.icrSponsor5');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsSponsor6Senior", Ember.computed('projectedSpendingSponsor6Senior', 'currentUser.team.icrSponsor6', function () {
    var total = this.projectedSpendingSponsor6Senior;
    var icr = this.get('currentUser.team.icrSponsor6');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsSponsor7Senior", Ember.computed('projectedSpendingSponsor7Senior', 'currentUser.team.icrSponsor7', function () {
    var total = this.projectedSpendingSponsor7Senior;
    var icr = this.get('currentUser.team.icrSponsor7');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingTotalSenior", Ember.computed('projectedSpendingNihkSenior.[]', 'projectedSpendingNihrSenior.[]', 'projectedSpendingOtherFedSenior.[]', 'projectedSpendingFftSenior.[]', 'projectedSpendingNonFedSenior.[]', 'projectedSpendingSponsor5Senior.[]', 'projectedSpendingSponsor6Senior.[]', 'projectedSpendingSponsor7Senior.[]', function () {
    return this.sumArrays(this.projectedSpendingNihkSenior, this.projectedSpendingNihrSenior, this.projectedSpendingOtherFedSenior, this.projectedSpendingFftSenior, this.projectedSpendingNonFedSenior, this.projectedSpendingSponsor5Senior, this.projectedSpendingSponsor6Senior, this.projectedSpendingSponsor7Senior);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingIndirectsSenior", Ember.computed('projectedSpendingIndirectsNihkSenior.[]', 'projectedSpendingIndirectsNihrSenior.[]', 'projectedSpendingIndirectsOtherFedSenior.[]', 'projectedSpendingIndirectsFftSenior.[]', 'projectedSpendingIndirectsNonFedSenior.[]', 'projectedSpendingIndirectsSponsor5Senior.[]', 'projectedSpendingIndirectsSponsor6Senior.[]', 'projectedSpendingIndirectsSponsor7Senior.[]', function () {
    return this.sumArrays(this.projectedSpendingIndirectsNihkSenior, this.projectedSpendingIndirectsNihrSenior, this.projectedSpendingIndirectsOtherFedSenior, this.projectedSpendingIndirectsFftSenior, this.projectedSpendingIndirectsNonFedSenior, this.projectedSpendingIndirectsSponsor5Senior, this.projectedSpendingIndirectsSponsor6Senior, this.projectedSpendingIndirectsSponsor7Senior);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingDirectsSenior", Ember.computed('projectedSpendingTotalSenior', function () {
    return this.subtractTwoArrays(this.projectedSpendingTotalSenior, this.projectedSpendingIndirectsSenior);
  })), _defineProperty(_Ember$Mixin$create, "earliestClassIndexSenior", Ember.computed('recruitsSenior.@each.value', function () {
    var index = 0;

    for (var i = 0; i < this.yearsAhead + 1; i++) {
      var recruits = this.recruitsForYear(i + 1, false);

      if (recruits > 0) {
        index = i;
        break;
      }
    }

    return index;
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassNihkSenior", Ember.computed('proposalNihkSeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateNihkSenior', function () {
    var proposalCount = this.proposalNihkSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNihkSeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
    var modifiedHitRate = this.modifiedHitRateNihkSenior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassNihrSenior", Ember.computed('proposalNihrSeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosNihDirects', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateNihrSenior', function () {
    var proposalCount = this.proposalNihrSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNihrSeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNihDirects');
    var modifiedHitRate = this.modifiedHitRateNihrSenior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationNih');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassOtherFedSenior", Ember.computed('proposalOtherFedSeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosOtherFedDirects', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateOtherFedSenior', function () {
    var proposalCount = this.proposalOtherFedSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalOtherFedSeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosOtherFedDirects');
    var modifiedHitRate = this.modifiedHitRateOtherFedSenior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassFftSenior", Ember.computed('proposalFftSeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosFftDirects', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateFftSenior', function () {
    var proposalCount = this.proposalFftSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalFftSeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosFedFlowThruDirects');
    var modifiedHitRate = this.modifiedHitRateFftSenior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationFft');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassNonFedSenior", Ember.computed('proposalNonFedSeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosNonFedDirects', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateNonFedSenior', function () {
    var proposalCount = this.proposalNonFedSeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalNonFedSeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosNonFedDirects');
    var modifiedHitRate = this.modifiedHitRateNonFedSenior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassSponsor5Senior", Ember.computed('proposalSponsor5SeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosSponsor5Directs', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateSponsor5Senior', function () {
    var proposalCount = this.proposalSponsor5SeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor5SeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor5Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor5Senior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassSponsor6Senior", Ember.computed('proposalSponsor6SeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosSponsor6Directs', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateSponsor6Senior', function () {
    var proposalCount = this.proposalSponsor6SeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor6SeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor6Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor6Senior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassSponsor7Senior", Ember.computed('proposalSponsor7SeniorAvgCount', 'model.projection.yoneYtwoDerivativeRatiosSponsor7Directs', 'recruitsSenior.@each.value', 'earliestClassIndexSenior', 'modifiedHitRateSponsor7Senior', function () {
    var proposalCount = this.proposalSponsor7SeniorAvgCount;
    var proposalSize = this.get('recruitment.proposalSponsor7SeniorAvgTotal');
    var y1y2 = this.get('model.projection.yoneYtwoDerivativeRatiosSponsor7Directs');
    var modifiedHitRate = this.modifiedHitRateSponsor7Senior;
    var classIndex = this.earliestClassIndexSenior;
    var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
    return this.calculateProjectedSpendingOneClass(proposalCount, proposalSize, y1y2, wad, modifiedHitRate, classIndex, false);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsNihkSenior", Ember.computed('projectedSpendingOneClassNihkSenior', 'currentUser.team.icrNihk', function () {
    var total = this.projectedSpendingOneClassNihkSenior;
    var icr = this.get('currentUser.team.icrNihk');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsNihrSenior", Ember.computed('projectedSpendingOneClassNihrSenior', 'currentUser.team.icrNihr', function () {
    var total = this.projectedSpendingOneClassNihrSenior;
    var icr = this.get('currentUser.team.icrNihr');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsOtherFedSenior", Ember.computed('projectedSpendingOneClassOtherFedSenior', 'currentUser.team.icrOtherFed', function () {
    var total = this.projectedSpendingOneClassOtherFedSenior;
    var icr = this.get('currentUser.team.icrOtherFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsFftSenior", Ember.computed('projectedSpendingOneClassFftSenior', 'currentUser.team.icrFft', function () {
    var total = this.projectedSpendingOneClassFftSenior;
    var icr = this.get('currentUser.team.icrFedFlowThru');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsNonFedSenior", Ember.computed('projectedSpendingOneClassNonFedSenior', 'currentUser.team.icrNonFed', function () {
    var total = this.projectedSpendingOneClassNonFedSenior;
    var icr = this.get('currentUser.team.icrNonFed');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsSponsor5Senior", Ember.computed('projectedSpendingOneClassSponsor5Senior', 'currentUser.team.icrSponsor5', function () {
    var total = this.projectedSpendingOneClassSponsor5Senior;
    var icr = this.get('currentUser.team.icrSponsor5');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsSponsor6Senior", Ember.computed('projectedSpendingOneClassSponsor6Senior', 'currentUser.team.icrSponsor6', function () {
    var total = this.projectedSpendingOneClassSponsor6Senior;
    var icr = this.get('currentUser.team.icrSponsor6');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsSponsor7Senior", Ember.computed('projectedSpendingOneClassSponsor7Senior', 'currentUser.team.icrSponsor7', function () {
    var total = this.projectedSpendingOneClassSponsor7Senior;
    var icr = this.get('currentUser.team.icrSponsor7');
    return this.calculateIndirectsFromTotal(total, icr);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassTotalSenior", Ember.computed('projectedSpendingOneClassNihkSenior.[]', 'projectedSpendingOneClassNihrSenior.[]', 'projectedSpendingOneClassOtherFedSenior.[]', 'projectedSpendingOneClassFftSenior.[]', 'projectedSpendingOneClassNonFedSenior.[]', 'projectedSpendingOneClassSponsor5Senior.[]', 'projectedSpendingOneClassSponsor6Senior.[]', 'projectedSpendingOneClassSponsor7Senior.[]', function () {
    return this.sumArrays(this.projectedSpendingOneClassNihkSenior, this.projectedSpendingOneClassNihrSenior, this.projectedSpendingOneClassOtherFedSenior, this.projectedSpendingOneClassFftSenior, this.projectedSpendingOneClassNonFedSenior, this.projectedSpendingOneClassSponsor5Senior, this.projectedSpendingOneClassSponsor6Senior, this.projectedSpendingOneClassSponsor7Senior);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassIndirectsSenior", Ember.computed('projectedSpendingOneClassIndirectsNihkSenior.[]', 'projectedSpendingOneClassIndirectsNihrSenior.[]', 'projectedSpendingOneClassIndirectsOtherFedSenior.[]', 'projectedSpendingOneClassIndirectsFftSenior.[]', 'projectedSpendingOneClassIndirectsNonFedSenior.[]', 'projectedSpendingOneClassIndirectsSponsor5Senior.[]', 'projectedSpendingOneClassIndirectsSponsor6Senior.[]', 'projectedSpendingOneClassIndirectsSponsor7Senior.[]', function () {
    return this.sumArrays(this.projectedSpendingOneClassIndirectsNihkSenior, this.projectedSpendingOneClassIndirectsNihrSenior, this.projectedSpendingOneClassIndirectsOtherFedSenior, this.projectedSpendingOneClassIndirectsFftSenior, this.projectedSpendingOneClassIndirectsNonFedSenior, this.projectedSpendingOneClassIndirectsSponsor5Senior, this.projectedSpendingOneClassIndirectsSponsor6Senior, this.projectedSpendingOneClassIndirectsSponsor7Senior);
  })), _defineProperty(_Ember$Mixin$create, "projectedSpendingOneClassDirectsSenior", Ember.computed('projectedSpendingOneClassTotalSenior', function () {
    return this.subtractTwoArrays(this.projectedSpendingOneClassTotalSenior, this.projectedSpendingOneClassIndirectsSenior);
  })), _defineProperty(_Ember$Mixin$create, "relinquishedDirectsSenior", Ember.computed('recruitment.{successSeniorTransferredOutAvgDirects}', 'attritionSenior.@each.value', function () {
    var _this39 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var terminatedDirects = this.get('recruitment.successSeniorTransferredOutAvgDirects');
    var termRemaining = this.termRemaining;
    var terminatedArrays = range.map(function (i) {
      var yearsAhead = i + 1;

      var attrition = _this39.attritionForYear(yearsAhead, false);

      return _this39.calculateStartupRelinquished(terminatedDirects, attrition, termRemaining, i + 1);
    });
    return this.sumArrays.apply(this, _toConsumableArray(terminatedArrays));
  })), _defineProperty(_Ember$Mixin$create, "relinquishedIndirectsSenior", Ember.computed('recruitment.{successSeniorTransferredOutAvgIndrects}', 'attritionSenior.@each.value', function () {
    var _this40 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var terminatedDirects = this.get('recruitment.successSeniorTransferredOutAvgIndirects');
    var termRemaining = this.termRemaining;
    var terminatedArrays = range.map(function (i) {
      var yearsAhead = i + 1;

      var attrition = _this40.attritionForYear(yearsAhead, false);

      return _this40.calculateStartupRelinquished(terminatedDirects, attrition, termRemaining, i + 1);
    });
    return this.sumArrays.apply(this, _toConsumableArray(terminatedArrays));
  })), _defineProperty(_Ember$Mixin$create, "startupPackagesSenior", Ember.computed('startUpSpendYearsSenior', 'startupPackageAmountSenior', 'compensationCoverageStartUpSenior', 'recruitsSenior.@each.value', 'averageTotalCompensationSenior', function () {
    var _this41 = this;

    var range = _toConsumableArray(Array(this.yearsAhead).keys());

    var startupPackageAmount = this.startupPackageAmountSenior;
    var termRemaining = this.startUpSpendYearsSenior;
    var compCoverageStartUp = this.compensationCoverageStartUpSenior;
    var compensation = this.averageTotalCompensationSenior;
    var compCoverage = compCoverageStartUp * compensation;
    var startupArrays = range.map(function (i) {
      var yearsAhead = i + 1;

      var recruits = _this41.recruitsForYear(yearsAhead, false);

      return _this41.calculateStartupRelinquished(startupPackageAmount, recruits, termRemaining, yearsAhead, compCoverage);
    });
    return this.sumArrays.apply(this, _toConsumableArray(startupArrays));
  })), _defineProperty(_Ember$Mixin$create, "compensationReductionSenior", Ember.computed('compensationCoverageAfterSenior', 'averageTotalCompensationSenior', 'attritionSenior.@each.value', function () {
    var _this42 = this;

    var yearsAhead = this.yearsAhead;

    var range = _toConsumableArray(Array(yearsAhead).keys());

    var compReductionArrays = range.map(function (i) {
      var year = i + 1;

      var attrition = _this42.attritionForYear(year, false);

      var reduction = _this42.compensationCoverageAfterSenior * _this42.averageTotalCompensationSenior * attrition;
      var reductionOneClassArray = Array(yearsAhead).fill(0);

      for (var _i4 = year - 1; _i4 < yearsAhead; _i4++) {
        reductionOneClassArray[_i4] = reduction;
      }

      return reductionOneClassArray;
    });
    return this.sumArrays.apply(this, _toConsumableArray(compReductionArrays));
  })), _defineProperty(_Ember$Mixin$create, "postStartCompensationSenior", Ember.computed('startUpSpendYearsSenior', 'compensationCoverageAfterSenior', 'averageTotalCompensationSenior', 'recruitsSenior.@each.value', function () {
    var _this43 = this;

    var yearsAhead = this.yearsAhead;

    var range = _toConsumableArray(Array(yearsAhead).keys());

    var term = this.startUpSpendYearsSenior;
    var postStartArrays = range.map(function (i) {
      var year = i + 1;

      var recruits = _this43.recruitsForYear(year, false);

      var compCoverage = _this43.compensationCoverageAfterSenior;
      var averageComp = _this43.averageTotalCompensationSenior;
      var postComp = compCoverage * averageComp * recruits;
      var postCompOneClassArray = Array(yearsAhead).fill(0);

      for (var _i5 = 0; _i5 < yearsAhead; _i5++) {
        var index = _i5 + (year - 1);

        if (index >= year - 1 + term) {
          postCompOneClassArray[index] = postComp;
        }
      }

      return postCompOneClassArray;
    });
    return this.sumArrays.apply(this, _toConsumableArray(postStartArrays));
  })), _defineProperty(_Ember$Mixin$create, "netIndirectCostRecoverySenior", Ember.computed('projectedSpendingIndirectsSenior.[]', 'relinquishedIndirectsSenior.[]', function () {
    //Deduct the compensation reduction from the net indirect cost recovery
    var indirectsArray = this.projectedSpendingIndirectsSenior;
    var reductionArray = this.relinquishedIndirectsSenior;
    return this.subtractTwoArrays(indirectsArray, reductionArray);
  })), _defineProperty(_Ember$Mixin$create, "netDirectCostRecoverySenior", Ember.computed('projectedSpendingDirectsSenior.[]', 'relinquishedDirectsSenior.[]', function () {
    //Deduct the compensation reduction from the net indirect cost recovery
    var indirectsArray = this.projectedSpendingDirectsSenior;
    var reductionArray = this.relinquishedDirectsSenior;
    return this.subtractTwoArrays(indirectsArray, reductionArray);
  })), _defineProperty(_Ember$Mixin$create, "cumulativeIndirectCostRecoverySenior", Ember.computed('netIndirectCostRecoverySenior.[]', function () {
    var netIndirectsArray = this.netIndirectCostRecoverySenior;
    return this.cumulativeArray(netIndirectsArray);
  })), _defineProperty(_Ember$Mixin$create, "netInvestmentSenior", Ember.computed('startupPackagesSenior', 'compensationReductionSenior', 'postStartCompensationSenior', 'recruitsSenior.@each.value', function () {
    var startup = this.startupPackagesSenior;
    var compensationReduction = this.compensationReductionSenior;
    var postStart = this.postStartCompensationSenior;
    return Ember.A(this.sumArrays(this.subtractTwoArrays(startup, compensationReduction), postStart));
  })), _defineProperty(_Ember$Mixin$create, "cumulativeInvestmentSenior", Ember.computed('netInvestmentSenior.[]', function () {
    var netInvestmentsArray = this.netInvestmentSenior;
    return this.cumulativeArray(netInvestmentsArray);
  })), _defineProperty(_Ember$Mixin$create, "cumulativeCashFlowSenior", Ember.computed('cumulativeInvestmentSenior.[]', 'cumulativeIndirectCostRecoverySenior.[]', function () {
    return this.subtractTwoArrays(this.cumulativeIndirectCostRecoverySenior, this.cumulativeInvestmentSenior);
  })), _Ember$Mixin$create));

  _exports.default = _default;
});