define("b3i-analytics-web-app/templates/app/mega-grants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pzqaYhUL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pi-header navbar-top-margin\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Mega Grants\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-controls\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"secondary\"],[4,[38,0],[[32,0],\"openPrintDialog\"],null],[12],[2,\"Print\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pi-navigation\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"nav-item\"]],[[\"@route\"],[\"app.mega-grants.active\"]],[[\"default\"],[{\"statements\":[[2,\"Active Mega Grants\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"nav-item\"]],[[\"@route\"],[\"app.mega-grants.summary\"]],[[\"default\"],[{\"statements\":[[2,\"Summary\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"nav-item\"]],[[\"@route\"],[\"app.mega-grants.pending\"]],[[\"default\"],[{\"statements\":[[2,\"Pending Mega Grants\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"container app\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/app/mega-grants.hbs"
    }
  });

  _exports.default = _default;
});