define("b3i-analytics-web-app/components/table-comparative-departments", ["exports", "b3i-analytics-web-app/mixins/table-common", "b3i-analytics-web-app/utils/format-percentage", "b3i-analytics-web-app/utils/format-m-currency"], function (_exports, _tableCommon, _formatPercentage, _formatMCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    columns: Ember.computed('columnNames', 'extra', function () {
      var columnNames = this.columnNames;
      var headerColumnNames = Object.keys(columnNames);
      return [{
        width: '200px',
        sortable: false,
        subColumns: [{
          label: 'Department',
          sortable: true,
          valuePath: 'cellDeptData',
          width: '200px',
          cellComponent: 'cell-link-department'
        }]
      }, {
        label: headerColumnNames[0],
        sortable: false,
        subColumns: [{
          label: columnNames[headerColumnNames[0]][0].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[0]][0].valuePath,
          cellClassNames: "column-group-left ".concat(Ember.String.dasherize(columnNames[headerColumnNames[0]][0].valuePath))
        }, {
          label: columnNames[headerColumnNames[0]][1].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[0]][1].valuePath,
          cellClassNames: "column-group-left ".concat(Ember.String.dasherize(columnNames[headerColumnNames[0]][1].valuePath))
        }, {
          label: columnNames[headerColumnNames[0]][2].name,
          sortable: true,
          ascending: false,
          align: 'center',
          cellComponent: 'cell-percentage-non-bold',
          valuePath: columnNames[headerColumnNames[0]][2].valuePath,
          cellClassNames: "column-group-left ".concat(Ember.String.dasherize(columnNames[headerColumnNames[0]][2].valuePath)),
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return (0, _formatPercentage.default)(value, 0);
            } else {
              return null;
            }
          }
        }, {
          label: columnNames[headerColumnNames[0]][3].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[0]][3].valuePath,
          cellClassNames: "".concat(Ember.String.dasherize(columnNames[headerColumnNames[0]][3].valuePath))
        }]
      }, {
        label: headerColumnNames[1],
        sortable: false,
        subColumns: [{
          label: columnNames[headerColumnNames[1]][0].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[1]][0].valuePath,
          cellClassNames: "column-group-left ".concat(Ember.String.dasherize(columnNames[headerColumnNames[1]][0].valuePath)),
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return value.toFixed(1);
            } else {
              return null;
            }
          }
        }, {
          label: columnNames[headerColumnNames[1]][1].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[1]][1].valuePath,
          cellClassNames: "column-group-right ".concat(Ember.String.dasherize(columnNames[headerColumnNames[1]][1].valuePath))
        }, {
          label: columnNames[headerColumnNames[1]][2].name,
          sortable: true,
          ascending: false,
          align: 'center',
          cellComponent: 'cell-percentage-non-bold',
          valuePath: columnNames[headerColumnNames[1]][2].valuePath,
          cellClassNames: "".concat(Ember.String.dasherize(columnNames[headerColumnNames[1]][2].valuePath)),
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return (0, _formatPercentage.default)(value, 0);
            } else {
              return null;
            }
          }
        }, {
          label: columnNames[headerColumnNames[1]][3].name,
          sortable: true,
          align: 'center',
          valuePath: columnNames[headerColumnNames[1]][3].valuePath,
          cellClassNames: "".concat(Ember.String.dasherize(columnNames[headerColumnNames[1]][3].valuePath))
        }]
      }, {
        label: headerColumnNames[2],
        sortable: false,
        subColumns: [{
          label: columnNames[headerColumnNames[2]][0].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[2]][0].valuePath,
          cellClassNames: "column-group-left ".concat(Ember.String.dasherize(columnNames[headerColumnNames[2]][0].valuePath)),
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return value.toFixed(1);
            } else {
              return null;
            }
          }
        }, {
          label: columnNames[headerColumnNames[2]][1].name,
          sortable: true,
          align: 'center',
          valuePath: columnNames[headerColumnNames[2]][1].valuePath,
          cellClassNames: Ember.String.dasherize(columnNames[headerColumnNames[2]][0].valuePath)
        }]
      }, {
        label: "".concat(headerColumnNames[3], " Sponsor Sub-Totals are not Available"),
        sortable: false,
        cellClassNames: 'column-group-left',
        subColumns: [{
          label: columnNames[headerColumnNames[3]][0].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[3]][0].valuePath,
          cellClassNames: "column-group-left ".concat(Ember.String.dasherize(columnNames[headerColumnNames[3]][0].valuePath)),
          cellComponent: 'cell-money',
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return (0, _formatMCurrency.default)(value);
            } else {
              return null;
            }
          }
        }, {
          label: columnNames[headerColumnNames[3]][1].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[3]][1].valuePath,
          cellComponent: 'cell-money',
          cellClassNames: "".concat(Ember.String.dasherize(columnNames[headerColumnNames[3]][1].valuePath)),
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return (0, _formatMCurrency.default)(value);
            } else {
              return null;
            }
          }
        }, {
          label: columnNames[headerColumnNames[3]][2].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[3]][2].valuePath,
          cellClassNames: "column-group-left ".concat(Ember.String.dasherize(columnNames[headerColumnNames[3]][2].valuePath)),
          cellComponent: 'cell-money',
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return (0, _formatMCurrency.default)(value);
            } else {
              return null;
            }
          }
        }, {
          label: columnNames[headerColumnNames[3]][3].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[3]][3].valuePath,
          cellClassNames: "column-group-right  ".concat(Ember.String.dasherize(columnNames[headerColumnNames[3]][3].valuePath))
        }, {
          label: columnNames[headerColumnNames[3]][4].name,
          sortable: true,
          ascending: false,
          align: 'center',
          valuePath: columnNames[headerColumnNames[3]][4].valuePath,
          cellClassNames: "column-group-left  ".concat(Ember.String.dasherize(columnNames[headerColumnNames[3]][4].valuePath)),
          cellComponent: 'cell-percentage-non-bold',
          format: function format(value) {
            if (value !== undefined && value) {
              if (value === '-') {
                return '-';
              }

              return (0, _formatPercentage.default)(value);
            } else {
              return null;
            }
          }
        }]
      }];
    }),
    extraObserver: Ember.observer('extra', 'model', function () {
      var extra = this.extra;

      if (extra) {
        var table = this.table;
        var rows = table.get('rows');
        var columns = table.get('columns');

        if (rows.length > 0) {
          this.highlightColumns(rows, columns, extra);
        }
      }
    }).on('init'),
    highlightColumns: function highlightColumns(rows, columns, extra) {
      var _this = this;

      var declineMultiplier = extra.highlightLessThan / 100;
      var gainMultiplier = extra.highlightGreaterThan / 100;
      var totalOrAverageRow = null;

      var _loop = function _loop(i) {
        var classNamesString = 'dark-grey-text';
        var row = rows[i];

        if (i === 0) {
          classNamesString = 'dark-grey-bg dark-grey-text row-border-bottom';
          totalOrAverageRow = row.content;
        }

        var content = row.content;
        extra.columnsToHighlight.forEach(function (columnName) {
          // First row should be highlighted neutral
          if (i === 0) {
            classNamesString += " highlight-neutral-".concat(Ember.String.dasherize(columnName));
          } else {
            classNamesString = _this.highlightColumnClassNames(gainMultiplier, declineMultiplier, totalOrAverageRow, extra.deptCount, content, columnName, classNamesString);
          } //Highlight the header of the column


          columns.forEach(function (column) {
            column.subColumns.forEach(function (subColumn) {
              //Highlight the exact column or the associated rank column for proposal, success, actuals, and hit rates
              if (subColumn.valuePath === columnName || subColumn.valuePath.includes(columnName) && subColumn.valuePath.includes('proposal') || subColumn.valuePath.includes(columnName) && subColumn.valuePath.includes('success') || subColumn.valuePath.includes(columnName) && subColumn.valuePath.includes('hit_rate') || subColumn.valuePath.includes(columnName) && subColumn.valuePath.includes('actuals')) {
                subColumn.set('classNames', ['highlight']);
              }
            });
          });
        });
        row.set('classNames', [classNamesString]);
      };

      for (var i = 0; i < rows.length; i++) {
        _loop(i);
      }
    },
    highlightColumnClassNames: function highlightColumnClassNames(gainMultiplier, declineMultiplier, totalOrAverageRow, deptCount, content, columnName, classNamesString) {
      var avgValue = totalOrAverageRow[columnName]; //Certain columns are totals though we need averages to highlight

      if (columnName === 'pi_count_tier') {
        avgValue = totalOrAverageRow[columnName] / deptCount;
      } else if (columnName === 'rank_pi_tier_dept') {
        avgValue = deptCount / 2;
      }

      var shouldHighlightDecline = content[columnName] < declineMultiplier * avgValue;
      var shouldHighlightGain = content[columnName] > gainMultiplier * avgValue; // Highlighting is reversed (lower number should be a "gain")

      if (columnName.includes('rank')) {
        shouldHighlightDecline = content[columnName] > declineMultiplier * avgValue;
        shouldHighlightGain = content[columnName] < gainMultiplier * avgValue; //If both are true then this should be neutral

        if (shouldHighlightDecline && shouldHighlightGain) {
          shouldHighlightDecline = false;
          shouldHighlightGain = false;
        }
      }

      if (shouldHighlightDecline) {
        classNamesString += " highlight-decline-".concat(Ember.String.dasherize(columnName));
      } else if (shouldHighlightGain) {
        classNamesString += " highlight-gain-".concat(Ember.String.dasherize(columnName));
      } else {
        classNamesString += " highlight-neutral-".concat(Ember.String.dasherize(columnName));
      }

      return classNamesString;
    }
  });

  _exports.default = _default;
});