define("b3i-analytics-web-app/templates/components/percent-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "onF/TxtJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"percent-input-wrapper\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[34,0]],[2,\":\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[15,0,[31,[\"input-wrapper \",[30,[36,1],[[30,[36,4],[[35,2],0],null],\"value-negative\"],null],\" \",[30,[36,1],[[30,[36,3],[[35,2],0],null],\"value-positive\"],null]]]],[12],[2,\"\\n    \"],[8,\"input\",[[16,0,[34,8]],[16,\"placeholder\",0]],[[\"@key-up\",\"@disabled\",\"@focus-in\",\"@max\",\"@maxlength\",\"@name\",\"@type\",\"@value\"],[[30,[36,5],[[32,0],\"onValueChanged\"],null],[34,6],[34,7],\"100\",\"2\",[34,9],\"number\",[34,2]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"value\",\"gt\",\"lt\",\"action\",\"disabled\",\"focusIn\",\"class\",\"name\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/percent-text-input.hbs"
    }
  });

  _exports.default = _default;
});