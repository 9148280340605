define("b3i-analytics-web-app/utils/sort-string-with-plan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sortStringWithPlan;

  function sortStringWithPlan(sortString, selectedIncludePlan) {
    var position = sortString.indexOf('-') > -1 ? 4 : 3;

    if (sortString.indexOf('pts') > -1) {
      if (selectedIncludePlan === "Yes") {
        if (sortString.indexOf('plan') === -1) {
          return sortString.substr(0, position) + '_with_plan' + sortString.substr(position);
        }
      } else {
        if (sortString.indexOf('plan') > -1) {
          return sortString.substr(0, position) + sortString.substr(position + 10);
        }
      }
    }

    return null;
  }
});