define("b3i-analytics-web-app/utils/pts-renewal-ammortization", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ptsRenewalAmmortization;

  function ptsRenewalAmmortization(grant, year, isDirects, fiscalMonth, nceAndCarryover, shrinkage) {
    var startDate = (0, _moment.default)(grant.get('startDate'));
    var endDate = (0, _moment.default)(grant.get('endDate'));
    var endFiscalMonth = grant.get('endFiscalMonth');
    var endFiscalYear = grant.get('endFiscalYear'); //Factor in the nce

    var extendedMonths = nceAndCarryover;
    var extendedDuration = endDate.diff(startDate, 'days') / 30.5 + extendedMonths;
    var amount = isDirects ? grant.get('directs') : grant.get('indirects'); //Account for school shrinkage

    var successAmount = amount * (1 - shrinkage / 100);
    var result = 0;

    if (year === endFiscalYear) {
      result = successAmount * (12 - endFiscalMonth) / extendedDuration;
    } else if (year > endFiscalYear) {
      result = successAmount * 12 / extendedDuration;
    }

    return result;
  }
});