define("b3i-analytics-web-app/templates/components/cell-link-is-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vgA52Ml0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\"],[[34,0,[\"link\"]],[34,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"name\"]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[35,0,[\"isNew\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"is-new-flag\"],[12],[2,\"New\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-link-is-new.hbs"
    }
  });

  _exports.default = _default;
});