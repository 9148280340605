define("b3i-analytics-web-app/templates/forgot-password-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dbG0o6Cq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container authentication\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-6\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-8\"],[12],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"logo--stacked\"],[12],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"card-content\"],[12],[2,\"\\n              \"],[10,\"p\"],[12],[2,\"We just sent you an email with a link to reset your password. Follow the link to reset your password and login.\"],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[8,\"link-to\",[],[[\"@route\",\"@id\"],[\"forgot-password\",\"forgot-password\"]],[[\"default\"],[{\"statements\":[[2,\"Send another email\"]],\"parameters\":[]}]]],[2,\"\\n\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[2,\"© 2007 - \"],[1,[30,[36,1],[[35,0],\"YYYY\"],null]],[2,\" U.S. Patent 8,271,372\"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"now\",\"moment-format\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/forgot-password-success.hbs"
    }
  });

  _exports.default = _default;
});