define("b3i-analytics-web-app/templates/components/cell-dropdown-funded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LCLPZwi+",
    "block": "{\"symbols\":[],\"statements\":[[8,\"select-dropdown\",[],[[\"@options\",\"@selectedOption\",\"@optionChanged\"],[[34,0],[34,1],[30,[36,2],[[32,0],\"optionChanged\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"options\",\"value\",\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-dropdown-funded.hbs"
    }
  });

  _exports.default = _default;
});