define("b3i-analytics-web-app/serializers/user", ["exports", "b3i-analytics-web-app/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      password: {
        serialize: false
      },
      instanceAccessCount: {
        serialize: false
      },
      lastActivity: {
        serialize: false
      },
      systemRole: {
        serialize: false
      },
      isSuperuser: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});