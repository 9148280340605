define("b3i-analytics-web-app/constants/hit-rate-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    FIVE_YEAR: '5 Yr. Hit Rate',
    THREE_YEAR: '3 Yr. Hit Rate'
  };
  _exports.default = _default;
});