define("b3i-analytics-web-app/templates/components/modal-delete-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CXer2zjy",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-wrapper\",[],[[\"@title\",\"@close\"],[[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Are you sure you want to delete \"],[1,[35,3,[\"nameComma\"]]],[2,\"? This action isn't reversible.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn-primary\"],[4,[38,4],[[32,0],\"deleteAction\"],null],[12],[2,\"Yes DELETE\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n        \"],[8,\"error-message\",[],[[\"@errors\"],[[34,0]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errors\",\"title\",\"close\",\"user\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/modal-delete-user.hbs"
    }
  });

  _exports.default = _default;
});