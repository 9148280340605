define("b3i-analytics-web-app/templates/components/cell-variance-money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KZ0c788P",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[[35,2],\"red\"],null],\" \",[30,[36,1],[[35,0],\"green\"],null]]]],[12],[1,[30,[36,4],[[35,3]],[[\"precision\"],[1]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isPositive\",\"if\",\"isNegative\",\"value\",\"format-money\",\"showValue\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-variance-money.hbs"
    }
  });

  _exports.default = _default;
});