define("b3i-analytics-web-app/components/async-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isInline'],
    actions: {
      submitAction: function submitAction() {
        this.set('isPending', true);

        var _this = this;

        this.submitAction().then(function () {
          if (_this && !_this.isDestroyed) {
            _this.set('isPending', false);
          }
        }).catch(function () {
          if (_this && !_this.isDestroyed) {
            _this.set('isPending', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});