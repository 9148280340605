define("b3i-analytics-web-app/mixins/table-common", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    modelObserver: Ember.observer('model.[]', function () {
      var model = this.model;
      this.table.setRows(model);
    }),
    sortValuePathObserver: Ember.observer('sortValuePath', function () {
      var _this = this;

      if (this.sortValuePath) {
        var allColumns = this.table.get('allColumns');
        allColumns.forEach(function (column) {
          if (column.valuePath === _this.sortValuePath) {
            column.set('sorted', true);
            column.set('ascending', false);
          } else {
            column.set('sorted', false);
          }
        });
      }
    }).on('init'),
    columnsObserver: Ember.observer('columns', function () {
      var columns = this.columns;
      var table = this.table;
      var sortedColumns = table.get('sortedColumns');
      table.setColumns(columns);

      if (sortedColumns) {
        sortedColumns.forEach(function (column) {
          var columnToSort = table.get('allColumns').findBy('valuePath', column.get('valuePath'));

          if (columnToSort) {
            columnToSort.set('sorted', true);
            columnToSort.set('ascending', column.get('ascending'));
          }
        });
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var model = this.model;

      if (!model) {
        model = [];
      }

      var table = _emberLightTable.default.create({
        columns: this.columns,
        rows: model,
        enableSync: false
      });

      this.set('table', table); // Maybe we need this

      /*
      // Scroll fixed-position table header when the rest of the table scrolls
      Ember.$('#table').on('scroll', function() {
        let scrollOffset = document.getElementById('table').scrollLeft * -1 + 'px';
        Ember.$('.lt-head table').css('left', scrollOffset);
      });*/
    },
    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.handlePagination) {
          this.handlePagination();
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (this.handleSortChange) {
          if (column.sorted) {
            var direction = column.ascending ? '' : '-';
            var columnName = column.valuePath;
            this.handleSortChange(direction, columnName);
          }
        }
      }
    }
  });

  _exports.default = _default;
});