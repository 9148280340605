define("b3i-analytics-web-app/components/modal-edit-user", ["exports", "b3i-analytics-web-app/constants/roles"], function (_exports, _roles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    departments: null,
    departmentsList: Ember.computed('departments', 'disableDepartmentSelect', function () {
      if (this.disableDepartmentSelect) {
        return [{
          value: '0',
          display: 'All Departments'
        }];
      } else {
        return this.departments;
      }
    }),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newUser', Ember.Object.create());
      var access = this.access;
      this.set('roleOptions', ['Department', 'Sector', 'Executive', 'Admin']);
      this.selectedRoleObserver;

      if (access) {
        var userRole = access.get('role').capitalize();
        var userDepartment = access.get('departmentId');
        var userSectors = access.get('sectors');
        this.set('newUser.name', access.get('user.name'));
        this.set('newUser.email', access.get('user.email'));
        this.set('newUser.position', access.get('position'));

        if (userDepartment) {
          this.set('newUser.department', userDepartment);
        } else {
          this.set('newUser.department', '0');
        }

        if (userSectors) {
          this.set('newUser.sectors', userSectors.map(function (sector) {
            return sector.get('name');
          }));
        } else {
          this.set('newUser.sectors', null);
        }

        if (userRole) {
          this.set('newUser.role', userRole);

          if (userRole !== 'Department') {
            this.set('disableDepartmentSelect', true);
          }

          if (userRole !== 'Sector') {
            this.set('disableSectorSelect', true);
          }
        }
      }
    },
    actions: {
      saveAction: function saveAction() {
        var newUser = this.newUser;
        var user = this.access.get('user');
        var membership = {
          position: newUser.get('position'),
          role: newUser.get('role'),
          departmentId: newUser.get('department'),
          sectors: newUser.get('sectors'),
          id: this.access.get('id')
        };
        user.set('name', newUser.get('name'));
        user.set('email', newUser.get('email'));
        this.saveAction(user, membership);
      },
      departmentOptionChanged: function departmentOptionChanged(value) {
        if (value === 'All Departments') {
          this.set('newUser.department', '0');
        } else {
          this.set('newUser.department', value);
        }
      },
      roleOptionChanged: function roleOptionChanged(value) {
        this.set('newUser.role', value);

        if (value === 'Executive' || value === "Admin") {
          this.set('newUser.department', '0');
          this.set('newUser.sectors', null);
          this.set('disableDepartmentSelect', true);
          this.set('disableSectorSelect', true);
          this.set('newUser.sectors', null);
        } else if (value === 'Department') {
          this.set('disableDepartmentSelect', false);
          this.set('disableSectorSelect', true);
          var departments = this.departments;

          if (departments.objectAt(0).display === 'All Departments') {
            departments.removeAt(0);
          }

          this.set('newUser.sectors', null); //Select first department

          if (this.departmentsList[0]) {
            this.set('newUser.department', this.departmentsList[0].value);
          }
        } else if (value === 'Sector') {
          this.set('disableSectorSelect', false);
          this.set('disableDepartmentSelect', true);
        }
      }
    }
  });

  _exports.default = _default;
});