define("b3i-analytics-web-app/templates/components/cell-title-is-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V2hcIy6U",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"],[6,[37,2],[[35,1,[\"isNew\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"is-new-flag\"],[12],[2,\"New\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"formattedTitle\",\"value\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-title-is-new.hbs"
    }
  });

  _exports.default = _default;
});