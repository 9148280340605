define("b3i-analytics-web-app/utils/original-sponsor-for-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = originalSponsorForType;

  function originalSponsorForType(originalValue, sponsors) {
    if (!sponsors) {
      return null;
    }

    var sponsor = sponsors.filterBy('originalValue', originalValue);

    if (sponsor.length === 0) {
      return null;
    } else {
      return sponsor[0];
    }
  }
});