define("b3i-analytics-web-app/utils/format-m-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatMCurrency;

  function formatMCurrency(number) {
    if (number || number === 0) {
      var value = number / 1000;
      return value.toFixed(1);
    }

    return null;
  }
});