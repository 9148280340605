define("b3i-analytics-web-app/templates/export-investigators/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rDtuJabY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"app container investigator-plans-loading\"],[12],[2,\"\\n  \"],[8,\"loading-animation\",[],[[],[]],null],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Loading...\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"This may take a few minutes. Please do not refresh the page\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/export-investigators/loading.hbs"
    }
  });

  _exports.default = _default;
});