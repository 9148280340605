define("b3i-analytics-web-app/constants/validations", ["exports", "b3i-analytics-web-app/constants/errors", "ember-cp-validations"], function (_exports, _errors, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var password = {
    password: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: _errors.default.presenceError,
      description: 'Password'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      message: 'Your password must be at least 8 characters.'
    })],
    confirmPassword: (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match.'
    })
  };
  var _default = {
    Password: password
  };
  _exports.default = _default;
});