define("b3i-analytics-web-app/mixins/non-department-only-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service('current-user'),
    beforeModel: function beforeModel(transition) {
      if (this.get('currentUser.role') === 'department') {
        transition.abort();
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});