define("b3i-analytics-web-app/templates/components/table-grants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xiaF4Y8a",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"table-base\",[],[[\"@table\",\"@tableActions\",\"@emptyTableMessage\"],[[34,0],[30,[36,4],null,[[\"onGrantChange\"],[[30,[36,3],[[32,0],[35,2]],null]]]],[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"table-base\",[],[[\"@table\",\"@emptyTableMessage\"],[[34,0],[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"table\",\"emptyTableMessage\",\"onGrantChange\",\"action\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-grants.hbs"
    }
  });

  _exports.default = _default;
});