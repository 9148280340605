define("b3i-analytics-web-app/mixins/admin-only-route", ["exports", "b3i-analytics-web-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service('current-user'),
    beforeModel: function beforeModel(transition) {
      if (this.get('currentUser.role') !== 'admin') {
        transition.abort();
        this.transitionTo(_environment.default.routeAfterAuthentication);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});