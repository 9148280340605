define("b3i-analytics-web-app/templates/components/table-institutions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C32lQUJy",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-base\",[],[[\"@table\",\"@isLoading\",\"@tableActions\"],[[34,0],[34,1],[30,[36,6],null,[[\"onDeleteInstitution\",\"onEditInstitution\",\"onViewInstitution\"],[[30,[36,3],[[32,0],[35,5]],null],[30,[36,3],[[32,0],[35,4]],null],[30,[36,3],[[32,0],[35,2]],null]]]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"table\",\"isLoading\",\"onViewInstitution\",\"action\",\"onEditInstitution\",\"onDeleteInstitution\",\"hash\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-institutions.hbs"
    }
  });

  _exports.default = _default;
});