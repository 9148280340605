define("b3i-analytics-web-app/components/modal-add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    departmentsList: Ember.computed('departments', 'disableDepartmentSelect', function () {
      if (this.disableDepartmentSelect) {
        return [{
          value: '0',
          display: 'All Departments'
        }];
      } else {
        return this.departments;
      }
    }),
    sectorList: Ember.computed('sectors', 'disableSectorSelect', function () {
      return this.sectors;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('user', []);
      this.set('roleOptions', ['Department', 'Sector', 'Executive', 'Admin']);
      this.set('user.role', 'Executive');
      this.set('user.sectors', []);
      this.set('disableDepartmentSelect', true);
      this.set('disableSectorSelect', true);
    },
    descriptionTextRole: Ember.computed('user.role', function () {
      var role = this.get('user.role');

      if (role === 'Executive') {
        return 'This role designation will give this user access to all Department and PI pages but not to application administration pages.';
      } else if (role === 'Admin') {
        return 'This role designation will give this user access to all Department, PI pages plus the Huron Analytics application administration pages including “Users”, “Settings”, and “Data Import”.';
      } else if (role === 'Department') {
        return 'This role designation will only give this user access to one Department, including the Department’s PI Pages, designated below.';
      } else if (role === 'Sector') {
        return 'This role designation will only give this user access to to the Sector or Sectors listed below.';
      } else {
        return null;
      }
    }),
    actions: {
      saveAction: function saveAction() {
        var user = this.user;
        user.saml_key = this.samlAttr;
        this.saveAction(user);
      },
      departmentOptionChanged: function departmentOptionChanged(value) {
        if (value === 'All Departments') {
          this.set('user.department', '0');
        } else {
          this.set('user.department', value);
        }
      },
      roleOptionChanged: function roleOptionChanged(value) {
        this.set('user.role', value);

        if (value === 'Executive' || value === "Admin") {
          this.set('user.department', '0');
          this.set('disableDepartmentSelect', true);
          this.set('disableSectorSelect', true);
          this.set('user.sectors', null);
        } else if (value === 'Department') {
          this.set('disableDepartmentSelect', false);
          this.set('disableSectorSelect', true);
          this.set('user.sectors', null);
          var departments = this.departments;

          if (departments.objectAt(0).display === 'All Departments') {
            departments.removeAt(0);
          } //Select first department


          this.set('user.department', this.departmentsList[0].value);
        } else if (value === 'Sector') {
          this.set('disableSectorSelect', false);
          this.set('disableDepartmentSelect', true);
        }
      }
    }
  });

  _exports.default = _default;
});