define("b3i-analytics-web-app/templates/components/cell-check-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VNX14FNu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[14,6,\"https://public.era.nih.gov/commons/public/login.do?TYPE=33554433&REALMOID=06-1edb031f-46c7-44b3-b803-60b537de74d2&GUID=&SMAUTHREASON=0&METHOD=GET&SMAGENTNAME=-SM-938PYmoLVb4VrDeXo04LZUDVDvc%2b3899ByInEAjuSUvWNIGfB2zRpWiCivYGCogG&TARGET=-SM-HTTPS%3a%2f%2fpublic%2eera%2enih%2egov%2fcommons%2fcommonsInit%2edo\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"Check Status\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-check-status.hbs"
    }
  });

  _exports.default = _default;
});