define("b3i-analytics-web-app/templates/components/select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OGi1uIUS",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[10,\"select\"],[15,0,[34,4]],[15,\"disabled\",[34,5]],[15,\"onchange\",[30,[36,6],[[32,0],\"optionChanged\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"option\"],[15,2,[32,1,[\"value\"]]],[15,\"selected\",[30,[36,1],[[32,1,[\"value\"]],[35,0]],null]],[12],[1,[32,1,[\"display\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,1],[[32,1],[35,0]],null]],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectedOption\",\"eq\",\"multiValues\",\"if\",\"selectClass\",\"disabled\",\"action\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/select-dropdown.hbs"
    }
  });

  _exports.default = _default;
});