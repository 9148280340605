define("b3i-analytics-web-app/templates/app/insights/core-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V0H79/Ss",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-wrapper\",[],[[\"@label\",\"@hoverText\"],[\"Core Detail (mm)\",[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"table-controls\"],[12],[2,\"\\n    \"],[8,\"select-grouped-dropdown-wrapper\",[],[[\"@label\",\"@disabled\",\"@selectedOption\",\"@triggerClass\",\"@optionsClass\",\"@optionChanged\",\"@options\"],[\"Department\",[34,1],[34,2],\"department-variable-width\",\"department-variable-width\",[30,[36,3],[[32,0],\"selectSector\"],null],[34,4]]],null],[2,\"\\n\\n  \"],[13],[2,\"\\n  \"],[8,\"table-core-detail\",[],[[\"@isLoading\",\"@model\"],[[34,5],[34,6]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hoverText\",\"disableSectorSelect\",\"selectedFilterOption\",\"action\",\"coreDetailFilterOptions\",\"isLoading\",\"tableData\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/app/insights/core-detail.hbs"
    }
  });

  _exports.default = _default;
});