define("b3i-analytics-web-app/templates/components/table-projections-core-mega-prior", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1M/+lYs2",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-base\",[],[[\"@table\"],[[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"table\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-projections-core-mega-prior.hbs"
    }
  });

  _exports.default = _default;
});