define("b3i-analytics-web-app/templates/components/loading-animation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PqHXKVOn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"loading-animation \",[34,0]]]],[12],[2,\"\\n  \"],[10,\"span\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"className\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/loading-animation.hbs"
    }
  });

  _exports.default = _default;
});