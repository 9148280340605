define("b3i-analytics-web-app/transforms/utc", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //http://blog.skylight.io/bringing-sanity-to-javascript-utc-dates-with-moment-js-and-ember-data/
  var _default = _transform.default.extend({
    serialize: function serialize(value) {
      return value ? (0, _moment.default)(value).format("YYYY-MM-DD") : null;
    },
    deserialize: function deserialize(value) {
      if (!value) {
        return null;
      }

      return _moment.default.utc(value);
    }
  });

  _exports.default = _default;
});