define("b3i-analytics-web-app/constants/institution-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATES = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'Wyoming', 'AS', 'GU', 'MP', 'PR', 'VI', 'INT'];
  var TYPE_ACADEMIC = 'Academic Medical Center';
  var TYPE_CHILDRENS = 'Children\'s Hospital';
  var TYPE_HIGHER_ED = 'Higher Education / University';
  var TYPE_NON_ACADEMIC = 'Non-Academic Hospital';
  var TYPE_OPTIONS = [TYPE_ACADEMIC, TYPE_CHILDRENS, TYPE_HIGHER_ED, TYPE_NON_ACADEMIC];
  var PUBLIC_OPTIONS = ['Public', 'Private'];
  var REGION_OPTIONS = ['South', 'West', 'Northeast', 'Midwest', 'International'];
  var _default = {
    TYPE_ACADEMIC: TYPE_ACADEMIC,
    TYPE_CHILDRENS: TYPE_CHILDRENS,
    TYPE_HIGHER_ED: TYPE_HIGHER_ED,
    TYPE_NON_ACADEMIC: TYPE_NON_ACADEMIC,
    TYPE_OPTIONS: TYPE_OPTIONS,
    PUBLIC_OPTIONS: PUBLIC_OPTIONS,
    REGION_OPTIONS: REGION_OPTIONS,
    STATES: STATES
  };
  _exports.default = _default;
});