define("b3i-analytics-web-app/utils/display-value-from-new-value-sponsors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = displayValueFromNewValueSponsors;

  function displayValueFromNewValueSponsors(newValue, sponsors) {
    if (!sponsors) {
      return newValue;
    }

    var sponsor = sponsors.filterBy('newValue', newValue);

    if (sponsor.length === 0) {
      return newValue;
    } else {
      var displayValue = sponsor[0].displayValue;

      if (!displayValue) {
        return sponsor[0].newValue;
      }

      return displayValue;
    }
  }
});