define("b3i-analytics-web-app/models/grant", ["exports", "@ember-data/model", "b3i-analytics-web-app/utils/display-value-from-new-value-sponsors"], function (_exports, _model, _displayValueFromNewValueSponsors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    currentUser: Ember.inject.service('current-user'),
    startDate: (0, _model.attr)('utc'),
    endDate: (0, _model.attr)('utc'),
    grantId: (0, _model.attr)('string'),
    directs: (0, _model.attr)('number'),
    indirects: (0, _model.attr)('number'),
    total: (0, _model.attr)('number'),
    //This is the sponsor type in the data
    sponsorType: (0, _model.attr)('string'),
    //The sponsor type to be displayed to the end user
    sponsorTypeDisplay: Ember.computed('sponsorType', 'currentUser.team.sponsors.[]', function () {
      if (!this.currentUser.team) {
        return this.sponsorType;
      }

      return (0, _displayValueFromNewValueSponsors.default)(this.sponsorType, this.currentUser.team.sponsors);
    }),
    title: (0, _model.attr)('string'),
    startFiscalMonth: (0, _model.attr)('number'),
    startFiscalYear: (0, _model.attr)('number'),
    endFiscalMonth: (0, _model.attr)('number'),
    endFiscalYear: (0, _model.attr)('number'),
    //New since the pi last saved their plan
    isNewForPlan: (0, _model.attr)('boolean'),
    department: (0, _model.belongsTo)('department'),
    principalInvestigator: (0, _model.belongsTo)('principal-investigator'),
    //Local
    effort: (0, _model.attr)('number', {
      serialize: false,
      defaultValue: 0
    }),
    cellTitleData: Ember.computed('isNewForPlan', 'title', function () {
      return {
        "title": this.title,
        "isNew": this.isNewForPlan
      };
    })
  });

  _exports.default = _default;
});