define("b3i-analytics-web-app/templates/components/modal-team-switcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AjsLJ4bX",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-wrapper\",[],[[\"@title\",\"@close\"],[[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"stacked-field-wrapper\"],[12],[2,\"\\n    \"],[8,\"dropdown-wrapper\",[],[[\"@selectedOption\",\"@optionChanged\",\"@multiValues\",\"@options\",\"@label\"],[[34,3],[30,[36,4],[[32,0],\"teamOptionChanged\"],null],true,[34,5],\"School\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn-primary\"],[4,[38,4],[[32,0],\"saveAction\"],null],[12],[2,\"Save\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,6],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n        \"],[8,\"error-message\",[],[[\"@errors\"],[[34,0]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errors\",\"title\",\"close\",\"selectedTeamOption\",\"action\",\"teamOptions\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/modal-team-switcher.hbs"
    }
  });

  _exports.default = _default;
});