define("b3i-analytics-web-app/controllers/app/comparative/departments", ["exports", "b3i-analytics-web-app/mixins/permissions-check", "jquery", "b3i-analytics-web-app/utils/percentiles-from-quartile", "b3i-analytics-web-app/constants/tiers"], function (_exports, _permissionsCheck, _jquery, _percentilesFromQuartile, _tiers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DEFAULT_HIRE_YEAR = '------';
  var DEFAULT_HIGHLIGHT_COLUMN = {
    value: 'Select column',
    valuePath: ''
  };
  var QUARTILE_OPTION_NO_ACTIVITY = 'No {year} Activity';
  var NO_SCENARIO = {
    id: 0,
    name: 'No scenario'
  };

  var _default = Ember.Controller.extend(_permissionsCheck.default, {
    ajax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    year: Ember.inject.service(),
    page: 1,
    selectedClassify: 'All',
    selectedQuartile: _tiers.default.QUARTILE_OPTION_TOP_25,
    selectedSponsorType: 'All',
    selectedHireYearFilter: 'All',
    selectedHireYear: DEFAULT_HIRE_YEAR,
    sortString: 'department_name',
    highlightLessThan: 80,
    highlightGreaterThan: 120,
    selectedHighlightColumn1: DEFAULT_HIGHLIGHT_COLUMN,
    selectedHighlightColumn2: DEFAULT_HIGHLIGHT_COLUMN,
    selectedHighlightColumn3: DEFAULT_HIGHLIGHT_COLUMN,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    defaultAllSectorOption: {
      isGroupName: true,
      value: 'All Sectors'
    },
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.selectedSector) {
        this.set('selectedSector', this.defaultAllSectorOption);
      }

      this.set('sponsorTypeOptions', this.currentUser.sponsorlist);
      this.set('classifyOptions', ['All', 'Junior', 'Senior', 'Other']);
      QUARTILE_OPTION_NO_ACTIVITY = QUARTILE_OPTION_NO_ACTIVITY.replace('{year}', this.year.lastFiscalYear);
      this.set('quartileOptions', [_tiers.default.QUARTILE_OPTION_TOP_10, _tiers.default.QUARTILE_OPTION_TOP_25, _tiers.default.QUARTILE_OPTION_MID_TIER, _tiers.default.QUARTILE_OPTION_LOW_TIER, QUARTILE_OPTION_NO_ACTIVITY]); // Set hire year options

      if (this.get('currentUser.team.hasHireDate')) {
        this.set('hireYearOptions', ['All', 'Before', 'In', 'After']); // Generate the last 40 years as options for the hire year dropdown

        var range = _toConsumableArray(Array(41).keys());

        var currentYear = this.year.fiscalYear;
        this.set('hireYearListOptions', [DEFAULT_HIRE_YEAR].concat(range.map(function (i) {
          return (currentYear - i).toString();
        })));
        this.set('disableHireYearOptions', false);
      } else {
        this.set('hireYearOptions', ['All']);
        this.set('hireYearOptionsDisabled', true);
      }

      var scenarioOptions = [NO_SCENARIO, {
        id: -1,
        name: null
      }, {
        id: 1,
        name: 'Proposal Count and Hit Rates',
        indexes: [3, 4],
        sortString: 'proposal_count_per_year'
      }, {
        id: 2,
        name: 'Proposal Count and 2019 Total $$',
        indexes: [3, 8],
        sortString: 'proposal_count_per_year'
      }, {
        id: 3,
        name: 'Proposal Count and Success Count',
        indexes: [3, 5],
        sortString: 'proposal_count_per_year'
      }, {
        id: 4,
        name: 'Proposal Count and Tier % Rank',
        indexes: [3, 2],
        sortString: 'proposal_count_per_year'
      }, {
        id: 5,
        name: 'Hit Rate and 2019 Total $$',
        indexes: [4, 8],
        sortString: 'proposal_hit_rate_avg'
      }, {
        id: 6,
        name: 'Hit Rate and Tier % Rank',
        indexes: [4, 2],
        sortString: 'proposal_hit_rate_avg'
      }, {
        id: 7,
        name: 'Hit Rate and Success Count',
        indexes: [4, 5],
        sortString: 'proposal_hit_rate_avg'
      }, {
        id: 8,
        name: 'Success Count and Tier % Rank',
        indexes: [5, 2],
        sortString: 'success_count_per_year'
      }, {
        id: 9,
        name: 'Success Count and 2019 Total $$',
        indexes: [5, 8],
        sortString: 'success_count_per_year'
      }, {
        id: 10,
        name: 'Tier % Rank and 2019 Total $$',
        indexes: [2, 8],
        sortString: 'rank_pi_tier_dept'
      }];

      if (!this.selectedScenarioId) {
        //Highlight + sort total last year actuals
        this.send('highlightColumnChanged', 1, this.columnNamesForHighlighting[3]);
        this.send('highlightColumnChanged', 2, this.columnNamesForHighlighting[4]);
        this.send('highlightColumnChanged', 3, this.columnNamesForHighlighting[8]);
        this.set('sortString', '-actuals_last_year_avg_total');
        this.set('sortValuePath', 'actuals_last_year_avg_total');
      } else {
        this.set('selectedScenarioId', -1000);
      }

      this.set('scenarioOptions', scenarioOptions);
    },
    columnNames: Ember.computed('selectedQuartile', function () {
      var tierColumnName;
      var lastYear = this.year.lastFiscalYear;

      switch (this.selectedQuartile) {
        case _tiers.default.QUARTILE_OPTION_TOP_10:
          tierColumnName = 'Top 10%';
          break;

        case _tiers.default.QUARTILE_OPTION_TOP_25:
          tierColumnName = 'Top 25%';
          break;

        case _tiers.default.QUARTILE_OPTION_MID_TIER:
          tierColumnName = 'Mid 26% - 74%';
          break;

        case _tiers.default.QUARTILE_OPTION_LOW_TIER:
          tierColumnName = 'Low 0% - 25%';
          break;

        case QUARTILE_OPTION_NO_ACTIVITY:
          tierColumnName = "No ".concat(this.year.lastFiscalYear, " Activity");
          break;

        case 'All':
          tierColumnName = 'All';
          break;

        default:
          tierColumnName = 'Top 10%';
      }

      var actualsColumnName = "Actuals Median ".concat(lastYear, " (m)");
      var dict = {
        'PI Counts': [{
          name: 'Total',
          valuePath: 'pi_count_dept_total',
          highlightable: false
        }, {
          name: "In ".concat(tierColumnName),
          valuePath: 'pi_count_tier',
          highlightable: false
        }, {
          name: "% ".concat(tierColumnName),
          valuePath: 'pi_tier_dept_total_percentage',
          highlightable: true
        }, {
          name: 'Rank',
          valuePath: 'rank_pi_tier_dept',
          highlightable: true
        }],
        'Proposal Averages': [{
          name: 'Count/Yr',
          valuePath: 'proposal_count_per_year',
          highlightable: true
        }, {
          name: 'Rank',
          valuePath: 'rank_proposal_count_per_year',
          highlightable: false
        }, {
          name: 'Hit Rate',
          valuePath: 'proposal_hit_rate_avg',
          highlightable: true
        }, {
          name: 'Rank',
          valuePath: 'rank_proposal_hit_rate_avg',
          highlightable: false
        }],
        'Success Averages': [{
          name: 'Count/Yr',
          valuePath: 'success_count_per_year',
          highlightable: true
        }, {
          name: 'Rank',
          valuePath: 'rank_success_count_per_year',
          highlightable: false
        }]
      };
      dict[actualsColumnName] = [{
        name: 'Directs',
        valuePath: 'actuals_last_year_avg_directs',
        highlightable: true
      }, {
        name: 'Indirects',
        valuePath: 'actuals_last_year_avg_indirects',
        highlightable: true
      }, {
        name: 'Total',
        valuePath: 'actuals_last_year_avg_total',
        highlightable: true
      }, {
        name: 'Rank',
        valuePath: 'rank_actuals_last_year_avg_total',
        highlightable: false
      }, {
        name: 'ICR',
        valuePath: 'actuals_last_year_icr',
        highlightable: true
      }];
      return dict;
    }),
    columnNamesForHighlighting: Ember.computed('columnNames', function () {
      var _this = this;

      var columnNamesForHighlight = [];
      Object.keys(this.columnNames).forEach(function (header) {
        _this.columnNames[header].forEach(function (column) {
          if (column.highlightable) {
            columnNamesForHighlight.push({
              value: "".concat(header, " - ").concat(column.name),
              valuePath: column.valuePath
            });
          }
        });
      });
      columnNamesForHighlight.unshift(DEFAULT_HIGHLIGHT_COLUMN);
      return columnNamesForHighlight;
    }),
    isSelectHighlightColumnsDisabled: Ember.computed('selectedScenarioId', function () {
      return this.selectedScenario && this.selectedScenario.id !== 0;
    }),
    scenarioObserver: Ember.observer('selectedScenarioId', function () {
      var _this2 = this;

      if (this.selectedScenarioId) {
        this.set('selectedHighlightColumn1', DEFAULT_HIGHLIGHT_COLUMN);
        this.set('selectedHighlightColumn2', DEFAULT_HIGHLIGHT_COLUMN);
        this.set('selectedHighlightColumn3', DEFAULT_HIGHLIGHT_COLUMN);

        if (this.selectedScenarioId > 0) {
          var selectedScenario = this.scenarioOptions.find(function (option) {
            return option.id === _this2.selectedScenarioId;
          });

          for (var i = 0; i < selectedScenario.indexes.length; i++) {
            var index = selectedScenario.indexes[i];
            this.set("selectedHighlightColumn".concat(i + 1), this.columnNamesForHighlighting[index]);
          }

          if (selectedScenario.sortString && !Ember.testing) {
            this.set('sortString', "-".concat(selectedScenario.sortString));
            this.set('sortValuePath', selectedScenario.sortString);
            this.fetchData();
          }
        }

        this.saveSettings();
      }
    }).on('init'),
    selectedColumnsHighlighted: Ember.computed('selectedHighlightColumn1.value', 'selectedHighlightColumn2.value', 'selectedHighlightColumn3.value', function () {
      var columnsToHighlight = [];

      if (this.selectedHighlightColumn1 && this.selectedHighlightColumn1.value !== DEFAULT_HIGHLIGHT_COLUMN.value) {
        columnsToHighlight.push(this.selectedHighlightColumn1.valuePath);
      }

      if (this.selectedHighlightColumn2 && this.selectedHighlightColumn2.value !== DEFAULT_HIGHLIGHT_COLUMN.value) {
        columnsToHighlight.push(this.selectedHighlightColumn2.valuePath);
      }

      if (this.selectedHighlightColumn3 && this.selectedHighlightColumn3.value !== DEFAULT_HIGHLIGHT_COLUMN.value) {
        columnsToHighlight.push(this.selectedHighlightColumn3.valuePath);
      }

      return columnsToHighlight;
    }),
    tableExtra: Ember.computed('highlightLessThan', 'highlightGreaterThan', 'selectedColumnsHighlighted.[]', 'departments', function () {
      return {
        highlightLessThan: this.highlightLessThan,
        highlightGreaterThan: this.highlightGreaterThan,
        columnsToHighlight: this.selectedColumnsHighlighted,
        deptCount: this.departments ? this.departments.length : 0
      };
    }),
    hireYearFieldDisabled: Ember.computed('selectedHireYearFilter', 'hireYearOptionsDisabled', function () {
      return this.hireYearOptionsDisabled || this.selectedHireYearFilter === 'All';
    }),
    fetchData: function fetchData() {
      var _this3 = this;

      this.set('isLoading', true);
      var queryParams = {};

      if (this.selectedSector.value !== 'All Sectors') {
        queryParams.sector = this.selectedSector.value;
      }

      if (this.sortString) {
        queryParams.ordering = this.sortString;
      }

      if (this.selectedSponsorType !== 'All') queryParams.sponsor_type = queryParams.sponsor_type = this.currentUser.getSponsorFilterValueForDisplayValue(this.selectedSponsorType);

      if (this.selectedClassify !== 'All') {
        queryParams.classify = this.selectedClassify;
      }

      if (this.selectedQuartile !== 'All') {
        if (this.selectedQuartile === QUARTILE_OPTION_NO_ACTIVITY) {
          queryParams.tier_no_actuals_last_year = true;
        } else {
          queryParams = Object.assign({}, queryParams, (0, _percentilesFromQuartile.default)(this.selectedQuartile));
        }
      }

      if (this.selectedHireYearFilter !== 'All' && this.selectedHireYear !== DEFAULT_HIRE_YEAR) {
        if (this.selectedHireYearFilter === 'Before') {
          queryParams.hire_year_before = this.selectedHireYear;
        } else if (this.selectedHireYearFilter === 'After') {
          queryParams.hire_year_after = this.selectedHireYear;
        } else if (this.selectedHireYearFilter === 'In') {
          queryParams.hire_year = this.selectedHireYear;
        }
      }

      this.ajax.request("departments_comparative/?".concat(_jquery.default.param(queryParams)), {
        headers: {
          'Accept': 'application/vnd.api+json'
        }
      }).then(function (resp) {
        _this3.set('departments', resp.data); //Add cell link data for table display


        _this3.departments.forEach(function (department) {
          department.cellDeptData = {
            id: department.department_id,
            name: department.department_name
          };
        });

        _this3.set('isLoading', false);
      });
    },
    saveSettings: function saveSettings() {
      var settings = {
        selectedClassify: this.selectedClassify,
        selectedQuartile: this.selectedQuartile,
        selectedSponsorType: this.selectedSponsorType,
        selectedHireYearFilter: this.selectedHireYearFilter,
        selectedHireYear: this.selectedHireYear,
        selectedSector: this.selectedSector,
        sortString: this.sortString,
        sortValuePath: this.sortValuePath,
        selectedScenarioId: this.selectedScenarioId,
        highlightLessThan: this.highlightLessThan,
        highlightGreaterThan: this.highlightGreaterThan,
        selectedHighlightColumn1: this.selectedHighlightColumn1,
        selectedHighlightColumn2: this.selectedHighlightColumn2,
        selectedHighlightColumn3: this.selectedHighlightColumn3
      };
      window.sessionStorage.setItem('comparativeDepartmentSettings', JSON.stringify(settings));
    },
    saveAndUpdateSetting: function saveAndUpdateSetting(option, value) {
      this.set(option, value);
      this.fetchData();
      Ember.run.debounce(this, this.saveSettings, 250);
    },
    actions: {
      selectedHireYearChanged: function selectedHireYearChanged(year) {
        this.saveAndUpdateSetting('selectedHireYear', year);
      },
      sectorChanged: function sectorChanged(sector) {
        this.saveAndUpdateSetting('selectedSector', sector);
      },
      sponsorTypeChanged: function sponsorTypeChanged(type) {
        this.saveAndUpdateSetting('selectedSponsorType', type);
      },
      hireYearFilterChanged: function hireYearFilterChanged(hireYearFilter) {
        this.saveAndUpdateSetting('selectedHireYearFilter', hireYearFilter);
      },
      classifyChanged: function classifyChanged(classify) {
        this.saveAndUpdateSetting('selectedClassify', classify);
      },
      quartileChanged: function quartileChanged(option) {
        this.saveAndUpdateSetting('selectedQuartile', option);
      },
      highlightColumnChanged: function highlightColumnChanged(num, option) {
        this.set("selectedHighlightColumn".concat(num), option);
      },
      toggleHelp: function toggleHelp() {
        this.toggleProperty('isShowingHelp');
      },
      departmentClicked: function departmentClicked(dept) {
        var _this4 = this;

        this.transitionToRoute('app.comparative.investigators').then(function (route) {
          var opts = {
            deptId: dept.id.toString()
          };

          if (_this4.selectedClassify !== 'All') {
            opts.classify = _this4.selectedClassify;
          }

          if (_this4.selectedQuartile !== 'All') {
            opts.quartile = _this4.selectedQuartile;
          }

          route.set('filtersFromAnotherRoute', opts);
        });
      },
      resetScenario: function resetScenario() {
        this.set('selectedScenarioId', NO_SCENARIO.id);
        this.set('selectedHighlightColumn1', DEFAULT_HIGHLIGHT_COLUMN);
        this.set('selectedHighlightColumn2', DEFAULT_HIGHLIGHT_COLUMN);
        this.set('selectedHighlightColumn3', DEFAULT_HIGHLIGHT_COLUMN);
      },
      handleSortChange: function handleSortChange(sortDirection, sortColumn) {
        var sortString = "".concat(sortDirection).concat(sortColumn);
        this.set('sortString', sortString);
        this.set('sortValuePath', sortColumn);
        this.fetchData();
      }
    }
  });

  _exports.default = _default;
});