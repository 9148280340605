define("b3i-analytics-web-app/templates/components/commentary-drawer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AB/LUHK7",
    "block": "{\"symbols\":[\"contentItem\"],\"statements\":[[10,\"div\"],[14,0,\"commentary-drawer\"],[12],[2,\"\\n  \"],[11,\"div\"],[24,0,\"commentary-trigger\"],[4,[38,0],[[32,0],\"toggleShowList\"],null],[12],[2,\"Commentary Articles and Tips\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"commentary-list\"],[12],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"commentary-content-list\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[10,\"a\"],[15,6,[32,1,[\"fields\",\"Files\",\"firstObject\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[1,[32,1,[\"fields\",\"Name\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"content\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/commentary-drawer.hbs"
    }
  });

  _exports.default = _default;
});