define("b3i-analytics-web-app/components/table-active-mega", ["exports", "b3i-analytics-web-app/mixins/table-common"], function (_exports, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    columns: Ember.computed(function () {
      return [{
        label: 'Investigator',
        cellComponent: 'cell-link-is-new',
        valuePath: 'cellLinkData',
        width: '200px'
      }, {
        label: 'Department',
        valuePath: 'departmentName',
        width: '150px'
      }, {
        label: 'ID',
        sortable: false,
        valuePath: 'success.grantId',
        width: '100px'
      }, {
        label: 'Title',
        sortable: false,
        valuePath: 'success.title',
        width: '150px'
      }, {
        align: 'right',
        label: 'Start',
        valuePath: 'success.startFiscalYear',
        width: '60px'
      }, {
        align: 'right',
        label: 'End',
        valuePath: 'success.endFiscalYear',
        width: '60px'
      }, {
        align: 'right',
        label: 'Duration',
        valuePath: 'duration',
        width: '80px'
      }, {
        align: 'right',
        label: 'Directs',
        cellComponent: 'cell-mega-grant-variance',
        valuePath: 'directsTableValue',
        width: '95px'
      }, {
        align: 'right',
        label: 'Indirects',
        cellComponent: 'cell-mega-grant-variance',
        valuePath: 'indirectsTableValue',
        width: '95px'
      }, {
        align: 'right',
        label: 'Total',
        cellComponent: 'cell-mega-grant-variance',
        valuePath: 'totalTableValue',
        width: '95px'
      }, {
        label: 'Renew?',
        sortable: false,
        cellComponent: 'cell-dropdown-mega-grants',
        valuePath: 'renews',
        width: '70px',
        extra: {
          disabled: this.disableDropdowns
        }
      }, {
        label: 'Override?',
        sortable: false,
        cellComponent: 'cell-dropdown-mega-grants',
        width: '70px',
        valuePath: 'isOverride',
        extra: {
          disabled: this.disableDropdowns
        }
      }];
    }),
    tableRows: Ember.computed('table', function () {
      var rows = this.get('table.rows');
      return rows;
    }),
    // Add classes to projections table for styling
    rowObserver: Ember.observer('table', 'tableRows.@each', 'tableRows.@each.renews', 'tableRows.@each.isOverride', 'tableRows.@each.excludeFromHedgeChallenge', 'tableRows', function () {
      var tableRows = this.tableRows;
      tableRows.forEach(function (row) {
        // Highlight rows if any defaults change
        if (!row.get('renews') || row.get('isOverride') || row.get('excludeFromHedgeChallenge')) {
          Ember.set(row, 'classNames', 'row-mega-changed');
        } else {
          Ember.set(row, 'classNames', '');
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});