define("b3i-analytics-web-app/templates/components/cell-dropdown-effort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7uYdMO9q",
    "block": "{\"symbols\":[],\"statements\":[[8,\"select-dropdown\",[],[[\"@selectClass\",\"@options\",\"@selectedOption\",\"@optionChanged\"],[[30,[36,1],[[35,0],\"hidden\"],null],[34,2],[34,3],[30,[36,4],[[32,0],\"optionChanged\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"isHidden\",\"if\",\"options\",\"value\",\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-dropdown-effort.hbs"
    }
  });

  _exports.default = _default;
});