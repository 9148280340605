define("b3i-analytics-web-app/models/comparative-statistic", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    piDirects: (0, _model.attr)('number'),
    piIndirects: (0, _model.attr)('number'),
    piModifiedOneYearAgoDirects: (0, _model.attr)('number'),
    piAverageCoverage: (0, _model.attr)('number'),
    departmentDirects: (0, _model.attr)('number'),
    departmentIndirects: (0, _model.attr)('number'),
    departmentModifiedOneYearAgoDirects: (0, _model.attr)('number'),
    departmentSampleCount: (0, _model.attr)('number'),
    departmentSquareFootage: (0, _model.attr)('number'),
    departmentCompensation: (0, _model.attr)('number'),
    departmentCompensationPercentile: (0, _model.attr)('number'),
    departmentAverageCoverage: (0, _model.attr)('number'),
    departmentReserveBalances: (0, _model.attr)('number'),
    sectorDirects: (0, _model.attr)('number'),
    sectorIndirects: (0, _model.attr)('number'),
    sectorModifiedOneYearAgoDirects: (0, _model.attr)('number'),
    sectorSquareFootage: (0, _model.attr)('number'),
    sectorSampleCount: (0, _model.attr)('number'),
    sectorCompensation: (0, _model.attr)('number'),
    sectorCompensationPercentile: (0, _model.attr)('number'),
    sectorAverageCoverage: (0, _model.attr)('number'),
    sectorReserveBalances: (0, _model.attr)('number'),
    schoolDirects: (0, _model.attr)('number'),
    schoolIndirects: (0, _model.attr)('number'),
    schoolModifiedOneYearAgoDirects: (0, _model.attr)('number'),
    schoolSampleCount: (0, _model.attr)('number'),
    schoolSquareFootage: (0, _model.attr)('number'),
    schoolCompensation: (0, _model.attr)('number'),
    schoolCompensationPercentile: (0, _model.attr)('number'),
    schoolAverageCoverage: (0, _model.attr)('number'),
    schoolReserveBalances: (0, _model.attr)('number')
  });

  _exports.default = _default;
});