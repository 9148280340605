define("b3i-analytics-web-app/templates/components/cell-edit-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hXpYOQz3",
    "block": "{\"symbols\":[],\"statements\":[[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"toggleDeleteModal\"],null],[12],[2,\"Delete\"],[13],[2,\"\\n\"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"toggleEditModal\"],null],[12],[2,\"Edit\"],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-edit-user.hbs"
    }
  });

  _exports.default = _default;
});