define("b3i-analytics-web-app/components/table-institutions", ["exports", "b3i-analytics-web-app/mixins/table-common", "moment"], function (_exports, _tableCommon, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_tableCommon.default, (_dec = Ember.computed(), (_obj = {
    get columns() {
      return [{
        label: 'Name',
        sortable: false,
        valuePath: 'name',
        width: '200px'
      }, {
        label: 'Type',
        sortable: false,
        valuePath: 'type',
        width: '200px'
      }, {
        label: 'Public/Private',
        sortable: false,
        valuePath: 'isPublicString',
        width: '120px'
      }, {
        label: 'Medical School?',
        sortable: false,
        valuePath: 'isMedicalSchoolString',
        width: '120px'
      }, {
        label: 'Fiscal Year End',
        sortable: false,
        valuePath: 'fiscalYearStartMonth',
        format: function format(value) {
          var endMonth = value - 2;

          if (endMonth < 0) {
            endMonth === 11;
          }

          return (0, _moment.default)().startOf('year').add(endMonth, 'month').endOf('month').format('MM/DD');
        },
        width: '100px'
      }, {
        label: 'State',
        sortable: false,
        cellClassNames: 'column-group-left',
        valuePath: 'state',
        width: '40px'
      }, {
        label: 'Region',
        sortable: false,
        valuePath: 'region',
        width: '140px'
      }, {
        label: '',
        cellClassNames: 'edit-user column-group-left',
        sortable: false,
        align: 'center',
        valuePath: 'id',
        cellComponent: 'cell-edit-institution',
        width: '200px'
      }];
    }

  }, (_applyDecoratedDescriptor(_obj, "columns", [_dec], Object.getOwnPropertyDescriptor(_obj, "columns"), _obj)), _obj)));

  _exports.default = _default;
});