define("b3i-analytics-web-app/mixins/projection-filter-route", ["exports", "b3i-analytics-web-app/config/environment", "b3i-analytics-web-app/constants/roles"], function (_exports, _environment, _roles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    projectionsModelWithFilter: function projectionsModelWithFilter(settingsString) {
      var _this = this;

      var queryParams = {};

      if (this.currentUser.get('role') === _roles.default.DEPARTMENT) {
        queryParams['department_id'] = this.currentUser.get('departmentId');
      }

      if (settingsString) {
        var settings = JSON.parse(settingsString);
        var option = settings['selectedFilterOption'];

        if (option != null) {
          if (option.value === 'All Sectors') {
            return this.store.query('projection', {}).then(function (projections) {
              if (projections.content.length > 0) {
                return Ember.RSVP.hash({
                  projection: projections.objectAt(0)
                });
              } else {
                return Ember.RSVP.hash({
                  projection: Ember.Object.create({})
                });
              }
            });
          } else if (option.isGroupName) {
            return this.store.query('projection', {
              'sector': option.value
            }).then(function (projections) {
              if (projections.content.length > 0) {
                return Ember.RSVP.hash({
                  projection: projections.objectAt(0)
                });
              } else {
                return Ember.RSVP.hash({
                  projection: Ember.Object.create({})
                });
              }
            });
          } else {
            return this.store.query('projection', {
              'department_id': option.id
            }).then(function (projections) {
              if (projections.content.length > 0) {
                return Ember.RSVP.hash({
                  projection: projections.objectAt(0)
                });
              } else {
                return Ember.RSVP.hash({
                  projection: Ember.Object.create({})
                });
              }
            });
          }
        }
      }

      return this.store.query('projection', queryParams).then(function (projections) {
        if (projections.content.length > 0) {
          return Ember.RSVP.hash({
            projection: projections.objectAt(0)
          });
        } else {
          return Ember.RSVP.hash({
            projection: _this.store.createRecord('projection', {})
          });
        }
      });
    },
    populateProjectionFilterOnController: function populateProjectionFilterOnController(controller) {
      var _this2 = this;

      if (this.currentUser.get('role') === _roles.default.DEPARTMENT) {
        var option = {
          value: this.currentUser.get('departmentName'),
          id: this.currentUser.get('departmentId')
        };
        controller.set('selectedFilterOption', option);
        controller.set('projectionsFilterOptions', [option]);
      } else {
        var url = "".concat(_environment.default.host, "/").concat(_environment.default.namespace, "/department_names/");
        this.ajax.request(url, {
          contentType: 'application/json'
        }).then(function (response) {
          if (response) {
            (function () {
              var sectorDict = {};

              _this2.store.pushPayload('department', response);

              var departments = _this2.store.peekAll('department');

              departments.forEach(function (department) {
                var sector = department.get('sector.name');

                if (sector in sectorDict) {
                  sectorDict[sector].push(department);
                } else {
                  sectorDict[sector] = [department];
                }
              });
              var orderedSectorDict = {};
              Object.keys(sectorDict).sort().forEach(function (key) {
                orderedSectorDict[key] = sectorDict[key];

                if (orderedSectorDict[key].length < 1) {
                  orderedSectorDict[key] = [];
                }
              }); //Only admins and execs should have access to sectors

              var optionsList = [];

              if (_this2.get('currentUser.role') === _roles.default.ADMIN || _this2.get('currentUser.role') === _roles.default.EXECUTIVE) {
                optionsList.push({
                  isGroupName: true,
                  value: 'All Sectors'
                });
              }

              for (var sector in orderedSectorDict) {
                if (sectorDict.hasOwnProperty(sector)) {
                  optionsList.push({
                    isGroupName: true,
                    value: sector
                  });
                  var _departments = orderedSectorDict[sector];

                  _departments.forEach(function (department) {
                    optionsList.push({
                      value: department.get('name'),
                      id: department.get('id')
                    });
                  });
                }
              }

              controller.set('projectionsFilterOptions', optionsList);

              if (!controller.selectedFilterOption) {
                controller.set('selectedFilterOption', optionsList[0]);
              } //Set the baseline if unset


              if (!controller.baselineCurrentFutureDirectsRow && controller.setBaseline) {
                controller.setBaseline();
              }
            })();
          }
        });
      }
    }
  });

  _exports.default = _default;
});