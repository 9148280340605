define("b3i-analytics-web-app/templates/saml-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kUqGaVaP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container authentication\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-6\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-8\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"logo--stacked\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"card-content\"],[12],[2,\"\\n              \"],[10,\"h1\"],[12],[2,\"Email Not Found\"],[13],[2,\"\\n              \"],[10,\"p\"],[12],[2,\"Sorry, the email you used to login has not been registered for your institution's Huron Analytics account. Please contact your administrator to make sure that your email is added.\"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[8,\"link-to\",[],[[\"@route\"],[\"login\"]],[[\"default\"],[{\"statements\":[[10,\"button\"],[14,0,\"btn-primary\"],[12],[2,\"Return to login\"],[13]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"p\"],[12],[2,\"© 2007 - \"],[1,[30,[36,1],[[35,0],\"YYYY\"],null]],[2,\" U.S. Patent 8,271,372\"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"now\",\"moment-format\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/saml-error.hbs"
    }
  });

  _exports.default = _default;
});