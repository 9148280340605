define("b3i-analytics-web-app/templates/components/slider-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nU3PWtWt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"slider\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"input\"],[14,1,\"rangeInput\"],[15,0,[31,[\"value-\",[34,2]]]],[15,\"max\",[34,3]],[15,\"min\",[34,4]],[15,\"step\",[34,5]],[15,2,[34,6]],[15,\"oninput\",[30,[36,7],[[32,0],\"changedSlider\"],null]],[15,\"onchange\",[30,[36,7],[[32,0],\"changedSlider\"],null]],[14,4,\"range\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"rangePercent\",\"max\",\"min\",\"step\",\"value\",\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/slider-input.hbs"
    }
  });

  _exports.default = _default;
});