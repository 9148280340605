define("b3i-analytics-web-app/templates/mfa-setup/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n0xdE3uq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container authentication\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-6\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-8\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"logo--stacked\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"card-content\"],[12],[2,\"\\n              \"],[10,\"h1\"],[12],[2,\"Success!\"],[13],[2,\"\\n              \"],[10,\"p\"],[12],[2,\"Multi-factor authentication is now enabled for your account. Next time you login to Huron Analytics, you'll be asked to enter a verification code that is sent to your phone number.\"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn-primary\"],[4,[38,0],[[32,0],\"goToApp\"],null],[12],[2,\"Continue to Huron Analytics\"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/mfa-setup/success.hbs"
    }
  });

  _exports.default = _default;
});