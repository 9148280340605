define("b3i-analytics-web-app/components/scroll-to", ["exports", "ember-scroll-to-mk2/components/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _scrollTo.default;
    }
  });
});