define("b3i-analytics-web-app/mixins/model-cell-link-data", ["exports", "b3i-analytics-web-app/utils/cell-link-data"], function (_exports, _cellLinkData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    cellLinkData: Ember.computed('id', 'name', 'constructor.modelName', function () {
      var modelName = this.get('constructor.modelName');
      var id = this.id;
      var name = this.name;
      return (0, _cellLinkData.default)(id, name, modelName);
    })
  });

  _exports.default = _default;
});