define("b3i-analytics-web-app/templates/components/table-users-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1/W1mTYA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-base\",[],[[\"@table\",\"@isLoading\",\"@tableActions\",\"@onScrolledToBottom\",\"@onColumnClick\"],[[34,0],[34,1],[30,[36,6],null,[[\"onDeleteUser\",\"onViewUser\",\"onResendInvitation\"],[[30,[36,3],[[32,0],[35,5]],null],[30,[36,3],[[32,0],[35,4]],null],[30,[36,3],[[32,0],[35,2]],null]]]],[30,[36,3],[[32,0],\"onScrolledToBottom\"],null],[30,[36,3],[[32,0],\"onColumnClick\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"table\",\"isLoading\",\"onResendInvitation\",\"action\",\"onViewUser\",\"onDeleteUser\",\"hash\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-users-admin.hbs"
    }
  });

  _exports.default = _default;
});