define("b3i-analytics-web-app/components/table-core-detail", ["exports", "b3i-analytics-web-app/mixins/table-common", "b3i-analytics-web-app/utils/format-percentage", "b3i-analytics-web-app/utils/format-mm-currency"], function (_exports, _tableCommon, _formatPercentage, _formatMmCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    year: Ember.inject.service(),
    columns: Ember.computed('selectedView', function () {
      return [{
        sortable: false,
        width: '40px',
        classNames: 'column-group-right',
        subColumns: [{
          sortable: false,
          label: 'Grant Type',
          valuePath: 'label',
          width: '130px',
          cellComponent: 'cell-label-column',
          cellClassNames: 'grey-bg column-group-right row-label',
          classNames: 'column-group-right'
        }]
      }, {
        label: 'Proposals (mm)',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: "".concat(this.get('year.fiscalYearFourYearsAgo')),
          valuePath: 'rowData.proposals',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-left',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[0]) {
              if (isNaN(value[0])) {
                return value[0];
              }

              return (0, _formatMmCurrency.default)(value[0]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.fiscalYearThreeYearsAgo')),
          valuePath: 'rowData.proposals',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[1]) {
              if (isNaN(value[1])) {
                return "<span class=\"scenario-label\">".concat(value[1], "</span>");
              }

              return (0, _formatMmCurrency.default)(value[1]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.fiscalYearTwoYearsAgo')),
          valuePath: 'rowData.proposals',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[2]) {
              if (isNaN(value[2])) {
                return value[2];
              }

              return (0, _formatMmCurrency.default)(value[2]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.lastFiscalYear')),
          valuePath: 'rowData.proposals',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[3]) {
              if (isNaN(value[3])) {
                return value[3];
              }

              return (0, _formatMmCurrency.default)(value[3]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.fiscalYear'), " (P)"),
          valuePath: 'rowData.proposals',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[4]) {
              if (isNaN(value[4])) {
                return "<span class=\"scenario-label\">".concat(value[4], "</span>");
              }

              return (0, _formatMmCurrency.default)(value[4]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          width: '70px',
          label: 'Var',
          valuePath: 'rowData.proposals',
          cellComponent: 'cell-variance-money',
          cellClassNames: 'column-group-right',
          classNames: 'column-group-right',
          sortable: false,
          format: function format(value) {
            if (value !== undefined) {
              if (isNaN(value[5])) {
                return value[5];
              }

              return (0, _formatMmCurrency.default)(value[5]);
            } else {
              return null;
            }
          }
        }]
      }, {
        sortable: false,
        width: '40px',
        classNames: 'column-group-right',
        subColumns: [{
          sortable: false,
          label: 'Grant Type',
          valuePath: 'label',
          width: '130px',
          cellComponent: 'cell-label-column',
          cellClassNames: 'grey-bg column-group-right row-label',
          classNames: 'column-group-right',
          format: function format(value) {
            if (value.hideTotals) {
              return null;
            }

            return value;
          }
        }]
      }, {
        label: 'Conversion',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: "".concat(this.get('year.fiscalYearThreeYearsAgo')),
          valuePath: 'rowData.conversion_ratio',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[0]) {
              if (value[0] === '-') {
                return '-';
              }

              return "".concat((0, _formatPercentage.default)(value[0], 1), "%");
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.fiscalYearTwoYearsAgo')),
          valuePath: 'rowData.conversion_ratio',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[1]) {
              if (value[1] === '-') {
                return '-';
              }

              if (isNaN(value[1])) {
                return value[1];
              } else {
                return "".concat((0, _formatPercentage.default)(value[1], 1), "%");
              }
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.lastFiscalYear')),
          valuePath: 'rowData.conversion_ratio',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[2]) {
              if (value[2] === '-') {
                return '-';
              }

              return "".concat((0, _formatPercentage.default)(value[2], 1), "%");
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.fiscalYear'), " (P)"),
          valuePath: 'rowData.conversion_ratio',
          cellComponent: 'cell-non-escape',
          sortable: false,
          format: function format(value) {
            if (value !== undefined && value[3]) {
              if (value[3] === '-') {
                return '-';
              }

              if (isNaN(value[3])) {
                return Ember.String.htmlSafe("<span class=\"scenario-label\">".concat(value[3], "</span>"));
              } else {
                return "".concat((0, _formatPercentage.default)(value[3], 1), "%");
              }
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          width: '70px',
          label: 'Var',
          valuePath: 'rowData.conversion_ratio',
          cellComponent: 'cell-variance-percentage',
          cellClassNames: 'column-group-right',
          classNames: 'column-group-right',
          sortable: false,
          format: function format(value) {
            if (value !== undefined) {
              if (value[4] === '-') {
                return '-';
              }

              if (isNaN(value[4])) {
                return value[4];
              } else {
                return "".concat((0, _formatPercentage.default)(value[4], 1), "%");
              }
            } else {
              return null;
            }
          }
        }]
      }, {
        sortable: false,
        width: '40px',
        classNames: 'column-group-right',
        cellClassNames: 'column-group-right',
        subColumns: [{
          sortable: false,
          label: 'Grant Type',
          valuePath: 'label',
          width: '130px',
          cellComponent: 'cell-label-column',
          cellClassNames: 'grey-bg column-group-right row-label',
          classNames: 'column-group-right'
        }]
      }, {
        label: 'Successes (mm)',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: "".concat(this.get('year.fiscalYearThreeYearsAgo')),
          valuePath: 'rowData.successes',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined) {
              return (0, _formatMmCurrency.default)(value[0]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.fiscalYearTwoYearsAgo')),
          valuePath: 'rowData.successes',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined) {
              return (0, _formatMmCurrency.default)(value[1]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.lastFiscalYear')),
          valuePath: 'rowData.successes',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined) {
              return (0, _formatMmCurrency.default)(value[2]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          label: "".concat(this.get('year.fiscalYear'), " (P)"),
          valuePath: 'rowData.successes',
          cellComponent: 'cell-money',
          sortable: false,
          format: function format(value) {
            if (value !== undefined) {
              return (0, _formatMmCurrency.default)(value[3]);
            } else {
              return null;
            }
          }
        }, {
          align: 'right',
          width: '70px',
          label: 'Var',
          valuePath: 'rowData.successes',
          cellComponent: 'cell-variance-money',
          cellClassNames: 'column-group-right',
          sortable: false,
          format: function format(value) {
            if (value !== undefined) {
              return (0, _formatMmCurrency.default)(value[4]);
            } else {
              return null;
            }
          }
        }]
      }];
    }),
    // Add classes to projections table for styling
    rowObserver: Ember.observer('table', function () {
      var table = this.table;
      var rows = table.get('rows');
      rows.forEach(function (row) {
        var label = row.get('label');

        if (label === 'Non Fed') {
          Ember.set(row, 'classNames', 'row-border-top');
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});