define("b3i-analytics-web-app/templates/components/async-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3MYjdZhP",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,0,[31,[\"async-button \",[34,3]]]],[16,\"disabled\",[34,4]],[4,[38,5],[[32,0],\"submitAction\"],null],[12],[2,\"\\n\"],[6,[37,2],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"button-animation\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[3,\" TODO: set error and success states \"],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1,[\"isFulfilled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Saved!\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1,[\"isRejected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Submission failed\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"label\",\"activePromise\",\"if\",\"classNames\",\"isPending\",\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/async-button.hbs"
    }
  });

  _exports.default = _default;
});