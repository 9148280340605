define("b3i-analytics-web-app/templates/components/modal-delete-user-admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YS73MdIr",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-wrapper\",[],[[\"@title\",\"@close\"],[[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n        \"],[10,\"p\"],[12],[2,\"Are you sure you want to delete \"],[10,\"b\"],[12],[1,[35,3,[\"firstName\"]]],[2,\" \"],[1,[35,3,[\"lastName\"]]],[13],[2,\"? This user will be erased from the system and will lose access to all institutions.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[8,\"async-button\",[],[[\"@class\",\"@label\",\"@submitAction\"],[\"inline\",\"Yes, delete\",[30,[36,5],[[32,0],[35,4]],null]]],null],[2,\"\\n      \"],[11,\"a\"],[24,0,\"margin-left\"],[24,6,\"#\"],[4,[38,5],[[32,0],[35,2]],null],[12],[2,\"No, do not delete\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,6],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n        \"],[8,\"error-message\",[],[[\"@errors\"],[[34,0]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"errors\",\"title\",\"close\",\"user\",\"deleteAction\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/modal-delete-user-admin.hbs"
    }
  });

  _exports.default = _default;
});