define("b3i-analytics-web-app/mixins/permissions-check", ["exports", "b3i-analytics-web-app/constants/roles"], function (_exports, _roles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Computed properties for roles
   */
  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service('current-user'),
    isAdmin: Ember.computed('currentUser.role', function () {
      return this.get('currentUser.role') === _roles.default.ADMIN;
    }),
    isAdminOrExec: Ember.computed('currentUser.role', function () {
      return this.get('currentUser.role') === _roles.default.ADMIN || this.get('currentUser.role') === _roles.default.EXECUTIVE;
    }),
    isNotDepartment: Ember.computed('currentUser.role', function () {
      return this.get('currentUser.role') !== _roles.default.DEPARTMENT;
    }),
    canExportData: Ember.computed('currentUser.{role,team.canExecutivesDownloadData}', function () {
      if (this.currentUser.team.canExecutivesDownloadData) {
        return this.isAdminOrExec;
      } else {
        return this.isAdmin;
      }
    })
  });

  _exports.default = _default;
});