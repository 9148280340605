define("b3i-analytics-web-app/templates/components/change-plus-minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oc2is9M3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"change-plus-minus\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"plus-minus\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"plus\"],[12],[2,\"+\"],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"/\"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"minus\"],[12],[2,\"-\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/change-plus-minus.hbs"
    }
  });

  _exports.default = _default;
});