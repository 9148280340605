define("b3i-analytics-web-app/templates/mfa-setup/verify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KCY6svR4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container authentication\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-6\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row center-xs\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-xs-10 col-sm-8\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"logo--stacked\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"card-content\"],[12],[2,\"\\n              \"],[10,\"h1\"],[12],[2,\"Multi-Factor Authentication\"],[13],[2,\"\\n              \"],[10,\"p\"],[12],[2,\"We just sent a verification code to your phone number. Enter that code to finish setting up multi-factor authentication.\"],[13],[2,\"\\n              \"],[8,\"validated-text-input\",[],[[\"@name\",\"@label\",\"@type\",\"@placeholder\",\"@value\",\"@error\",\"@showError\"],[\"verificationCode\",\"Verification Code\",\"text\",\"Verification Code\",[34,0],[34,1,[\"attrs\",\"mfaCode\",\"message\"]],[34,2]]],null],[2,\"\\n            \"],[13],[2,\"\\n            \"],[8,\"async-button\",[],[[\"@label\",\"@submitAction\"],[\"Submit\",[30,[36,3],[[32,0],\"verify\"],null]]],null],[2,\"\\n            \"],[8,\"error-message\",[],[[\"@errors\"],[[34,4]]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"mfaCode\",\"validations\",\"showError\",\"action\",\"errors\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/mfa-setup/verify.hbs"
    }
  });

  _exports.default = _default;
});