define("b3i-analytics-web-app/templates/components/dropdown-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hMdDPE3s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"dropdown-wrapper \",[34,5]]]],[12],[2,\"\\n\"],[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[34,4]],[2,\": \"],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[8,\"help-icon\",[],[[\"@hoverText\"],[[34,3]]],null]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"select-dropdown\",[],[[\"@disabled\",\"@options\",\"@multiValues\",\"@selectedOption\",\"@optionChanged\"],[[34,6],[34,7],[34,8],[34,9],[34,10]]],null],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[15,0,[31,[\"description-text \",[30,[36,1],[[35,0],\"warning\"],null]]]],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"descriptionTextWarning\",\"if\",\"descriptionText\",\"hoverText\",\"label\",\"classNames\",\"disabled\",\"options\",\"multiValues\",\"selectedOption\",\"optionChanged\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/dropdown-wrapper.hbs"
    }
  });

  _exports.default = _default;
});