define("b3i-analytics-web-app/templates/components/cell-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H529H1Y2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[30,[36,1],[[35,0],\"bold\",\"light\"],null]],[12],[2,[34,2]],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"%\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"isBold\",\"if\",\"value\",\"isValid\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-percentage.hbs"
    }
  });

  _exports.default = _default;
});