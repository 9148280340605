define("b3i-analytics-web-app/components/table-grants", ["exports", "moment", "b3i-analytics-web-app/mixins/table-common"], function (_exports, _moment, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    renewHelpText: Ember.computed('isActiveGrants', 'isCoPi', function () {
      if (this.isActiveGrants) {
        return "If you have good reason to believe that this active grant will renew in your name as contact PI, select \"Yes\".  If you are certain this active grant will not renew, select \"No\". If there is a possibility that this grant might renew but it is too soon to say so with certainty, select \"Don’t Know\".";
      } else if (this.isCoPi) {
        return "If you have good reason to believe that this active grant will renew, select \"Yes\".  If you are certain this active grant will not renew, select \"No\". If there is a possibility that this grant might renew but it is too soon to say so with certainty, select \"Don’t Know\".";
      } else {
        return "If funding is certain based on priority score, status in Commons, or any other factor, note \"Yes\". If the proposal was not discussed or, for any other reason, is certain not to be funded note \"No\". If it is too soon to tell if a proposal is likely or unlikely to be funded, note \"Don't Know\".";
      }
    }),
    columns: Ember.computed('isExporting', function () {
      return [{
        label: 'Title',
        cellComponent: 'cell-title-is-new',
        valuePath: 'cellTitleData',
        sortable: false,
        //width: this.isExporting? '75px': '200px',
        width: '200px'
      }, {
        label: 'ID',
        valuePath: 'grantId',
        sortable: false,
        //width: this.isExporting ? '50px': '90px'
        width: '90px'
      }, {
        label: 'Start',
        valuePath: 'startDate',
        sortable: false,
        //width: this.isExporting? '50px': '75px',
        width: '75px',
        format: function format(value) {
          return (0, _moment.default)(value).format('MM/DD/YY');
        }
      }, {
        label: 'End',
        valuePath: 'endDate',
        sortable: false,
        //width: this.isExporting ? '50px': '75px',
        width: '75px',
        format: function format(value) {
          return (0, _moment.default)(value).format('MM/DD/YY');
        }
      }, {
        label: 'Sponsor',
        valuePath: 'sponsorTypeDisplay',
        sortable: false,
        //width: this.isExporting ? '65px': '100px'
        width: '100px'
      }, {
        label: 'Renewal?',
        valuePath: 'renewal',
        sortable: false,
        //width: this.isExporting? '40px': '95px',
        width: '95px',
        component: this.isExporting ? null : 'column-help-icon',
        align: this.isExporting ? 'center' : null,
        extra: 'If a competing renewal proposal appears and the ending grant it would renew also appears, it is important to take care not to duplicate by selecting “Yes” for both “Funded?” and “Renew?”.  If the funding for the competing proposal and the success it is renewing are about the same, it makes little difference which is selected except that the renewal proposal will be discounted a bit and the success will not.  If the competing renewal requests a significant increase in funding and the increase in funding is uncertain, it might be more appropriate to respond “No” for “Funded?” and “Yes” for “Renew?”.',
        hidden: this.isActiveGrants || this.isCoPi,
        format: function format(value) {
          return value ? 'Yes' : 'No';
        }
      }, {
        label: 'Directs',
        valuePath: 'directs',
        cellComponent: 'cell-money',
        sortable: false,
        //width: this.isExporting ? '50px': '100px',
        width: '100px',
        align: 'right'
      }, {
        label: 'Indirects',
        valuePath: 'indirects',
        cellComponent: 'cell-money',
        sortable: false,
        //width: this.isExporting ? '50px': '100px',
        width: '100px',
        align: 'right'
      }, {
        label: 'Total',
        valuePath: 'total',
        cellComponent: 'cell-money',
        sortable: false,
        //width: this.isExporting ? '50px': '100px',
        width: '100px',
        align: 'right'
      }, {
        label: "".concat(this.isActiveGrants || this.isCoPi ? 'Renew?' : 'Funded?'),
        valuePath: "".concat(this.isActiveGrants || this.isCoPi ? 'renewProjection' : 'funded'),
        //width: this.isExporting ? '50px': '90px',
        width: '90px',
        component: this.isExporting ? null : 'column-help-icon',
        cellComponent: this.isExporting ? null : 'cell-dropdown-funded',
        align: this.isExporting ? 'center' : null,
        sortable: false,
        extra: this.renewHelpText
      }, {
        label: 'Effort?',
        valuePath: 'effort',
        component: this.isExporting ? null : 'column-help-icon',
        cellComponent: this.isExporting ? null : 'cell-dropdown-effort',
        sortable: false,
        extra: "Note the approximate level of effort, and, therefore, percentage of compensation that will be charged to\n        this grant if you have noted \"Yes\" for ".concat(this.isActiveGrants ? 'renewal' : 'funding', ". A reasonable estimate is perfectly acceptable for this purpose."),
        //width: this.isExporting ? '50px': '90px',
        width: '90px',
        align: this.isExporting ? 'center' : null,
        format: function format(value) {
          return "".concat(value, "%");
        }
      }];
    }),
    tableRows: Ember.computed('table', function () {
      var rows = this.get('table.rows');
      return rows;
    }),
    // Add classes to grants table for styling
    rowObserver: Ember.observer('tableRows', 'tableRows.@each.renews', 'tableRows.@each.funded', 'tableRows.@each.renewProjection', function () {
      var tableRows = this.tableRows;
      tableRows.forEach(function (row) {
        if (row.get('renews') || row.get('funded') === 'Yes' || row.get('renewProjection') === 'Yes') {
          Ember.set(row, 'classNames', 'row-pi-grant-changed');
        } else {
          Ember.set(row, 'classNames', '');
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});