define("b3i-analytics-web-app/utils/cell-link-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cellLinkData;

  function cellLinkData(id, name, modelName) {
    var link = '#';

    if (modelName === 'department') {
      link = 'app.departments.department';
    } else if (modelName === 'principal-investigator') {
      link = 'app.departments.principal-investigator';
    }

    return {
      id: id,
      name: name,
      link: link
    };
  }
});