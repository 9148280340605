define("b3i-analytics-web-app/serializers/membership", ["exports", "b3i-analytics-web-app/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      invitation: {
        serialize: false
      },
      departmentName: {
        serialize: false
      },
      user: {
        serialize: false
      }
    }
  });

  _exports.default = _default;
});