define("b3i-analytics-web-app/templates/components/cell-link-department", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JUioIA8d",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,0,[\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"linkClicked\"],null],[12],[1,[35,0,[\"name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-link-department.hbs"
    }
  });

  _exports.default = _default;
});