define("b3i-analytics-web-app/components/table-proposal-history", ["exports", "b3i-analytics-web-app/mixins/table-common", "b3i-analytics-web-app/utils/title-case"], function (_exports, _tableCommon, _titleCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    columns: Ember.computed('selectedView', function () {
      return [{
        label: 'FY',
        sortable: false,
        valuePath: 'displayYear',
        width: '50px',
        cellClassNames: 'grey-bg column-group-right row-label',
        classNames: 'column-group-right'
      }, {
        label: 'Sponsor',
        sortable: false,
        valuePath: 'sponsorTypeDisplay',
        width: '120px'
      }, {
        label: 'Title',
        sortable: false,
        valuePath: 'title',
        width: '200px',
        format: function format(value) {
          return (0, _titleCase.default)(value);
        },
        cellClassNames: 'column-group-right',
        classNames: 'column-group-right'
      }, {
        label: 'ID',
        sortable: false,
        valuePath: 'grantId',
        width: '100px'
      }, {
        label: 'Status',
        sortable: false,
        valuePath: 'status',
        width: '110px',
        component: 'column-help-icon',
        extra: "The administrator of this application has set 12/1/2017 as the cutoff date for pending proposals.  " + "Proposals with start dates prior to this date are classified as “Not Funded” even if the proposal database still " + "lists them as Pending. If proposals that are reclassified in this manner are, in fact, subsequently funded, that " + "change in status will be noted when the proposal data is posted.",
        cellClassNames: 'column-group-right',
        classNames: 'column-group-right',
        format: function format(value) {
          if (value === 'not_funded') {
            return 'Not Funded';
          }

          return value.charAt(0).toUpperCase() + value.slice(1);
        }
      }, {
        label: 'Directs',
        sortable: false,
        valuePath: 'directs',
        cellComponent: 'cell-money',
        align: 'right',
        width: '100px'
      }, {
        label: 'Indirects',
        sortable: false,
        valuePath: 'indirects',
        cellComponent: 'cell-money',
        align: 'right',
        width: '100px'
      }, {
        label: 'Total',
        sortable: false,
        valuePath: 'total',
        cellComponent: 'cell-money',
        align: 'right',
        cellClassNames: 'column-group-right',
        classNames: 'column-group-right',
        width: '100px'
      }, {
        label: 'Funded Directs',
        sortable: false,
        valuePath: 'fundedDirects',
        cellComponent: 'cell-money',
        align: 'right',
        width: '100px'
      }, {
        label: 'Funded Indirects',
        sortable: false,
        valuePath: 'fundedIndirects',
        cellComponent: 'cell-money',
        align: 'right',
        width: '100px'
      }, {
        label: 'Funded Total',
        sortable: false,
        valuePath: 'fundedTotal',
        cellComponent: 'cell-money',
        align: 'right',
        width: '100px'
      }];
    }),
    // Add classes to projections table for styling
    rowObserver: Ember.observer('table', function () {
      var table = this.table;
      var rows = table.get('rows');
      rows.forEach(function (row, index) {
        var fy = row.get('displayYear');

        if (fy && index > 0) {
          Ember.set(row, 'classNames', 'row-border-top');
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});