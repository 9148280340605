define("b3i-analytics-web-app/components/select-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('select').click(function (e) {
        e.stopPropagation();
      });
    },
    actions: {
      optionChanged: function optionChanged(val) {
        if (this.optionChanged) {
          this.optionChanged(val);
        }
      }
    }
  });

  _exports.default = _default;
});