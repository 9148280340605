define("b3i-analytics-web-app/templates/commentary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QT3Q15Dq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"commentary-page\"],[12],[2,\"\\n  \"],[8,\"sticky-element\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"sticky-nav sticky-nav-always-show\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row start-xs\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-xs-3 col-sm-6\"],[12],[2,\"\\n            \"],[8,\"scroll-to\",[],[[\"@target\"],[\"#hero\"]],[[\"default\"],[{\"statements\":[[10,\"div\"],[14,0,\"logo--stacked\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"col-xs-9 col-sm-6\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"nav-text\"],[12],[2,\"\\n              \"],[10,\"a\"],[14,6,\"/\"],[14,0,\"link-green\"],[12],[2,\"\\n                About Huron Analytics\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container app help-page-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row around-xs\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12 col-sm-6\"],[12],[2,\"\\n        \"],[8,\"commentary-list\",[],[[\"@contentList\"],[[34,0]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"contentList\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/commentary.hbs"
    }
  });

  _exports.default = _default;
});