define("b3i-analytics-web-app/templates/components/select-grouped-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tJfwsvDi",
    "block": "{\"symbols\":[\"opt\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"select-group-dropdown-wrapper \",[34,8]]]],[4,[38,9],[[30,[36,3],[[32,0],\"hideOptions\"],null]],null],[12],[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"select-group-trigger \",[34,12],\" \",[30,[36,0],[[35,11],\"disabled\"],null],\" \",[30,[36,0],[[35,10],\"truncate\"],null]]]],[4,[38,3],[[32,0],\"toggleShowOptions\"],null],[12],[1,[35,1,[\"value\"]]],[13],[2,\"\\n\\n\"],[6,[37,0],[[35,13]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"select-group-options \",[34,4]]]],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"span\"],[24,\"preventDefault\",\"true\"],[16,0,[31,[[30,[36,0],[[30,[36,2],[[32,1,[\"value\"]],[35,1]],null],\"selected\"],null],\" \",[30,[36,0],[[32,1,[\"isGroupName\"]],\"group-header\"],null]]]],[4,[38,3],[[32,0],\"optionChanged\",[32,1]],null],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"selectedOption\",\"eq\",\"action\",\"optionsClass\",\"options\",\"-track-array\",\"each\",\"wrapperClass\",\"on-click-outside\",\"truncate\",\"disabled\",\"triggerClass\",\"showOptions\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/select-grouped-dropdown.hbs"
    }
  });

  _exports.default = _default;
});