define("b3i-analytics-web-app/components/modal-edit-membership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    departments: null,
    departmentsList: Ember.computed('departments', 'disableDepartmentSelect', function () {
      if (this.disableDepartmentSelect) {
        return [{
          value: '0',
          display: 'All Departments'
        }];
      } else {
        return this.departments;
      }
    }),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('newMembership', Ember.Object.create());
      var membership = this.membership;
      this.set('roleOptions', ['Department', 'Sector', 'Executive', 'Admin']);

      if (membership) {
        var userRole = membership.get('role').capitalize();
        var userDepartment = membership.get('departmentId');
        var userSectors = membership.get('sectors');
        this.set('newMembership.email', membership.get('invitation.signupCode.email'));
        this.set('newMembership.position', membership.get('position'));

        if (userDepartment) {
          this.set('newMembership.department', userDepartment);
        } else {
          this.set('newMembership.department', '0');
        }

        if (userSectors) {
          this.set('newMembership.sectors', userSectors.map(function (sector) {
            return sector.get('name');
          }));
        } else {
          this.set('newMembership.sectors', null);
        }

        if (userRole) {
          this.set('newMembership.role', userRole);

          if (userRole !== 'Department') {
            this.set('disableDepartmentSelect', true);
          }

          if (userRole !== 'Sector') {
            this.set('disableSectorSelect', true);
          }
        }
      }
    },
    actions: {
      saveAction: function saveAction() {
        var newMembership = this.newMembership;
        this.saveAction(newMembership);
      },
      departmentOptionChanged: function departmentOptionChanged(value) {
        if (value === 'All Departments') {
          this.set('newMembership.department', '0');
        } else {
          this.set('newMembership.department', value);
        }
      },
      roleOptionChanged: function roleOptionChanged(value) {
        this.set('newMembership.role', value);
        var departments = this.departments;

        if (value === 'Executive' || value === 'Admin') {
          this.set('newMembership.department', '0');
          this.set('disableDepartmentSelect', true);
          this.set('disableSectorSelect', true);
          this.set('newMembership.sectors', null);
        } else if (value === 'Department') {
          this.set('disableDepartmentSelect', false);
          this.set('disableSectorSelect', true);

          if (departments.objectAt(0).display === 'All Departments') {
            departments.removeAt(0);
          }

          this.set('newMembership.sectors', null);
          this.set('newMembership.department', this.departmentsList[0].value);
        } else if (value === 'Sector') {
          this.set('disableSectorSelect', false);
          this.set('disableDepartmentSelect', true);
        }
      }
    }
  });

  _exports.default = _default;
});