define("b3i-analytics-web-app/templates/components/help-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQ+ZKYoD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"help-icon \",[30,[36,1],[[35,0],\"hidden\"],null]]]],[15,1,[34,2]],[12],[2,\"\\n  ?\\n  \"],[8,\"ember-tooltip\",[],[[\"@side\",\"@text\",\"@popperContainer\",\"@targetId\",\"@event\",\"@tooltipClass\"],[[34,3],[34,4],\"body\",[34,2],\"hover\",\"tooltip\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"hidden\",\"if\",\"uniqueIdTarget\",\"side\",\"hoverText\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/help-icon.hbs"
    }
  });

  _exports.default = _default;
});