define("b3i-analytics-web-app/utils/title-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleCase;

  //https://medium.freecodecamp.org/three-ways-to-title-case-a-sentence-in-javascript-676a9175eb27
  function titleCase(str) {
    return str.toLowerCase().split(' ').map(function (word) {
      if (!word || word.length === 0) {
        return '';
      }

      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  }
});