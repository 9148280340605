define("b3i-analytics-web-app/templates/components/cell-edit-membership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LnhD6uRu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cell-edit-membership\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"resendInvitation\"],null],[12],[2,\"Resend Invitation\"],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"deleteAction\"],null],[12],[2,\"Delete\"],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,2],[[32,0],\"toggleEditModal\"],null],[12],[2,\"Edit\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"success-message\",[],[[\"@successMessage\"],[[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"error-message\",[],[[\"@errors\"],[[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"errors\",\"successMessage\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-edit-membership.hbs"
    }
  });

  _exports.default = _default;
});