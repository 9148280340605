define("b3i-analytics-web-app/templates/components/table-potential-to-spend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ecGWt9F4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-base\",[],[[\"@table\",\"@extra\",\"@isLoading\",\"@onScrolledToBottom\",\"@onColumnClick\"],[[34,0],[34,1],[34,2],[30,[36,3],[[32,0],\"onScrolledToBottom\"],null],[30,[36,3],[[32,0],\"onColumnClick\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"table\",\"extra\",\"isLoading\",\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-potential-to-spend.hbs"
    }
  });

  _exports.default = _default;
});