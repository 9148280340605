define("b3i-analytics-web-app/services/year", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentMomentDate: (0, _moment.default)(),
    currentUser: Ember.inject.service('current-user'),
    startFiscalMonth: 7,
    fiscalYear: 2018,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentMonth', (0, _moment.default)().get('month'));
    },
    lastFiscalYear: Ember.computed('fiscalYear', function () {
      return this.fiscalYear - 1;
    }),
    fiscalYearTwoYearsAgo: Ember.computed('fiscalYear', function () {
      return this.fiscalYear - 2;
    }),
    fiscalYearThreeYearsAgo: Ember.computed('fiscalYear', function () {
      return this.fiscalYear - 3;
    }),
    fiscalYearFourYearsAgo: Ember.computed('fiscalYear', function () {
      return this.fiscalYear - 4;
    }),
    fiscalYearFiveYearsAgo: Ember.computed('fiscalYear', function () {
      return this.fiscalYear - 5;
    }),
    fiscalYearOneYearAhead: Ember.computed('fiscalYear', function () {
      return this.fiscalYear + 1;
    }),
    fiscalYearTwoYearsAhead: Ember.computed('fiscalYear', function () {
      return this.fiscalYear + 2;
    }),
    fiscalYearThreeYearsAhead: Ember.computed('fiscalYear', function () {
      return this.fiscalYear + 3;
    }),
    fiscalYearFourYearsAhead: Ember.computed('fiscalYear', function () {
      return this.fiscalYear + 4;
    }),
    fiscalYearFiveYearsAhead: Ember.computed('fiscalYear', function () {
      return this.fiscalYear + 5;
    }),
    fiscalYearSixYearsAhead: Ember.computed('fiscalYear', function () {
      return this.fiscalYear + 6;
    }),
    fiscalStartMonth: Ember.computed('currentUser', function () {
      return this.get('currentUser.team.fiscalYearStartMonth');
    }),
    calculateFiscalMonth: function calculateFiscalMonth(startMonth, currentMonth) {
      if (currentMonth >= startMonth) {
        return currentMonth - (startMonth - 1);
      } else {
        return 12 - (startMonth - currentMonth - 1);
      }
    },
    //Couldnt get the observer to fire after a data upload so manually call this function
    updateMonthAndYear: function updateMonthAndYear() {
      var team = this.get('currentUser.team');

      if (team) {
        var startFiscalMonth = team.get('fiscalYearStartMonth');
        var currentFiscalYear = team.get('currentFiscalYear');

        if (startFiscalMonth !== null && currentFiscalYear != null) {
          this.set('fiscalYear', currentFiscalYear);
          this.set('startFiscalMonth', startFiscalMonth);
        }

        var asOfMonth = (0, _moment.default)(this.get('currentUser.queryDate'), 'MM-DD-YYY').get('month');
        var startMonth = this.get('currentUser.team.fiscalYearStartMonth');
        var lastCompletedFiscalMonth = this.calculateFiscalMonth(startMonth, asOfMonth);

        if (lastCompletedFiscalMonth) {
          this.set('lastCompletedFiscalMonth', this.calculateFiscalMonth(startMonth, asOfMonth));
        }
      }
    }
  });

  _exports.default = _default;
});