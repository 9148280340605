define("b3i-analytics-web-app/utils/highlight-pts-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = highlightPtsRows;

  function highlightPtsRows(rows, extra, includePlanPts) {
    var currentYearDirectsKey = includePlanPts ? 'ptsWithPlanCurrentYearDirects' : 'ptsCurrentYearDirects';
    var currentYearIndirectsKey = includePlanPts ? 'ptsWithPlanCurrentYearIndirects' : 'ptsCurrentYearIndirects';
    var lapse = 0;
    var decline = 0;
    rows.forEach(function (row) {
      var content = row.content;
      var directsDecline = content.get('actualsOneYearAgoDirects') > 0 && content.get(currentYearDirectsKey) < (1.0 - extra.nearTermDecline / 100) * content.get('actualsOneYearAgoDirects') && content.get(currentYearDirectsKey) - content.get('actualsOneYearAgoDirects') < extra.directsChange;
      var indirectsDecline = content.get('actualsOneYearAgoIndirects') > 0 && content.get(currentYearIndirectsKey) < (1 - extra.nearTermDecline / 100) * content.get('actualsOneYearAgoIndirects') && content.get(currentYearIndirectsKey) - content.get('actualsOneYearAgoIndirects') < extra.indirectsChange;

      if (content.get('actualsOneYearAgoDirects') > 0 && content.get(currentYearDirectsKey) === 0) {
        row.set('classNames', ['highlight-risk']);
        lapse++;
      } else if (directsDecline || indirectsDecline) {
        row.set('classNames', ['highlight-decline']);
        decline++;
      } else {
        row.set('classNames', ['']);
      }
    });
    return {
      lapse: lapse,
      decline: decline
    };
  }
});