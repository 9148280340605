define("b3i-analytics-web-app/controllers/app/projections/forecast-modifier", ["exports", "accounting/format-number", "b3i-analytics-web-app/mixins/full-projections", "moment-range", "moment", "b3i-analytics-web-app/utils/future-mega-projection", "accounting/format-money"], function (_exports, _formatNumber, _fullProjections, _momentRange, _moment, _futureMegaProjection, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GRANT_TYPE_ALL = 'Base (Core & Mega)';
  var GRANT_TYPE_MEGA = 'Mega';
  var SPONSOR_TYPE_ALL = 'All Sponsor Types';

  var moment = _momentRange.default.extendMoment(_moment.default);

  var _default = Ember.Controller.extend(_fullProjections.default, {
    projectionsController: Ember.inject.controller('app.projections.index'),
    selectedGrantType: GRANT_TYPE_ALL,
    selectedSponsor: SPONSOR_TYPE_ALL,
    phase1Start: 'Mar 2020',
    phase1End: 'Jun 2020',
    phase1ReductionsDirects: 8,
    phase1ReductionsIndirects: 6,
    phase1RecoveryDirects: 90,
    phase1RecoveryIndirects: 90,
    isPhase2Enabled: true,
    phase2Start: 'Jul 2020',
    phase2End: 'Sep 2020',
    phase2ReductionsDirects: 12,
    phase2ReductionsIndirects: 10,
    phase2RecoveryDirects: 90,
    phase2RecoveryIndirects: 90,
    isPhase3Enabled: true,
    phase3Start: 'Oct 2020',
    phase3End: 'Dec 2020',
    phase3ReductionsDirects: 7,
    phase3ReductionsIndirects: 5,
    phase3RecoveryDirects: 90,
    phase3RecoveryIndirects: 90,
    compensationReplacementNih: 65,
    compensationReplacementOtherFed: 65,
    compensationReplacementFedFlowThru: 65,
    compensationReplacementNonFed: 65,
    compensationReplacementSponsor5: 65,
    compensationReplacementSponsor6: 65,
    compensationReplacementSponsor7: 65,
    selectedCompensationReplacementSponsor: 'All',
    //Copy over settings from main projections page
    coreConversionRatiosSettingsOpenCurrentYearNih: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsOpenCurrentYearNih'),
    coreConversionRatiosSettingsRevisedCurrentYearNih: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsRevisedCurrentYearNih'),
    coreConversionRatiosSettingsTransitionNextYearNih: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsTransitionNextYearNih'),
    coreConversionRatiosSettingsProjectionsNih: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsProjectionsNih'),
    coreConversionRatiosSettingsOpenCurrentYearOtherFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsOpenCurrentYearOtherFed'),
    coreConversionRatiosSettingsRevisedCurrentYearOtherFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsRevisedCurrentYearOtherFed'),
    coreConversionRatiosSettingsTransitionNextYearOtherFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsTransitionNextYearOtherFed'),
    coreConversionRatiosSettingsProjectionsOtherFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsProjectionsOtherFed'),
    coreConversionRatiosSettingsOpenCurrentYearFedFlowThru: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsOpenCurrentYearFedFlowThru'),
    coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsRevisedCurrentYearFedFlowThru'),
    coreConversionRatiosSettingsTransitionNextYearFedFlowThru: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsTransitionNextYearFedFlowThru'),
    coreConversionRatiosSettingsProjectionsFedFlowThru: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsProjectionsFedFlowThru'),
    coreConversionRatiosSettingsOpenCurrentYearNonFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsOpenCurrentYearNonFed'),
    coreConversionRatiosSettingsRevisedCurrentYearNonFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsRevisedCurrentYearNonFed'),
    coreConversionRatiosSettingsTransitionNextYearNonFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsTransitionNextYearNonFed'),
    coreConversionRatiosSettingsProjectionsNonFed: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsProjectionsNonFed'),
    coreConversionRatiosSettingsOpenCurrentYearSponsor5: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsOpenCurrentYearSponsor5'),
    coreConversionRatiosSettingsRevisedCurrentYearSponsor5: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsRevisedCurrentYearSponsor5'),
    coreConversionRatiosSettingsTransitionNextYearSponsor5: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsTransitionNextYearSponsor5'),
    coreConversionRatiosSettingsProjectionsSponsor5: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsProjectionsSponsor5'),
    coreConversionRatiosSettingsOpenCurrentYearSponsor6: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsOpenCurrentYearSponsor6'),
    coreConversionRatiosSettingsRevisedCurrentYearSponsor6: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsRevisedCurrentYearSponsor6'),
    coreConversionRatiosSettingsTransitionNextYearSponsor6: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsTransitionNextYearSponsor6'),
    coreConversionRatiosSettingsProjectionsSponsor6: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsProjectionsSponsor6'),
    coreConversionRatiosSettingsOpenCurrentYearSponsor7: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsOpenCurrentYearSponsor7'),
    coreConversionRatiosSettingsRevisedCurrentYearSponsor7: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsRevisedCurrentYearSponsor7'),
    coreConversionRatiosSettingsTransitionNextYearSponsor7: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsTransitionNextYearSponsor7'),
    coreConversionRatiosSettingsProjectionsSponsor7: Ember.computed.alias('projectionsController.coreConversionRatiosSettingsProjectionsSponsor7'),
    coreProposalsSettingsOpenCurrentYearNih: Ember.computed.alias('projectionsController.coreProposalsSettingsOpenCurrentYearNih'),
    coreProposalsSettingsRevisedCurrentYearNih: Ember.computed.alias('projectionsController.coreProposalsSettingsRevisedCurrentYearNih'),
    coreProposalsSettingsTransitionNextYearNih: Ember.computed.alias('projectionsController.coreProposalsSettingsTransitionNextYearNih'),
    coreProposalsSettingsProjectionsNih: Ember.computed.alias('projectionsController.coreProposalsSettingsProjectionsNih'),
    coreProposalsSettingsOpenCurrentYearOtherFed: Ember.computed.alias('projectionsController.coreProposalsSettingsOpenCurrentYearOtherFed'),
    coreProposalsSettingsRevisedCurrentYearOtherFed: Ember.computed.alias('projectionsController.coreProposalsSettingsRevisedCurrentYearOtherFed'),
    coreProposalsSettingsTransitionNextYearOtherFed: Ember.computed.alias('projectionsController.coreProposalsSettingsTransitionNextYearOtherFed'),
    coreProposalsSettingsProjectionsOtherFed: Ember.computed.alias('projectionsController.coreProposalsSettingsProjectionsOtherFed'),
    coreProposalsSettingsOpenCurrentYearFedFlowThru: Ember.computed.alias('projectionsController.coreProposalsSettingsOpenCurrentYearFedFlowThru'),
    coreProposalsSettingsRevisedCurrentYearFedFlowThru: Ember.computed.alias('projectionsController.coreProposalsSettingsRevisedCurrentYearFedFlowThru'),
    coreProposalsSettingsTransitionNextYearFedFlowThru: Ember.computed.alias('projectionsController.coreProposalsSettingsTransitionNextYearFedFlowThru'),
    coreProposalsSettingsProjectionsFedFlowThru: Ember.computed.alias('projectionsController.coreProposalsSettingsProjectionsFedFlowThru'),
    coreProposalsSettingsOpenCurrentYearNonFed: Ember.computed.alias('projectionsController.coreProposalsSettingsOpenCurrentYearNonFed'),
    coreProposalsSettingsRevisedCurrentYearNonFed: Ember.computed.alias('projectionsController.coreProposalsSettingsRevisedCurrentYearNonFed'),
    coreProposalsSettingsTransitionNextYearNonFed: Ember.computed.alias('projectionsController.coreProposalsSettingsTransitionNextYearNonFed'),
    coreProposalsSettingsProjectionsNonFed: Ember.computed.alias('projectionsController.coreProposalsSettingsProjectionsNonFed'),
    coreProposalsSettingsOpenCurrentYearSponsor5: Ember.computed.alias('projectionsController.coreProposalsSettingsOpenCurrentYearSponsor5'),
    coreProposalsSettingsRevisedCurrentYearSponsor5: Ember.computed.alias('projectionsController.coreProposalsSettingsRevisedCurrentYearSponsor5'),
    coreProposalsSettingsTransitionNextYearSponsor5: Ember.computed.alias('projectionsController.coreProposalsSettingsTransitionNextYearSponsor5'),
    coreProposalsSettingsProjectionsSponsor5: Ember.computed.alias('projectionsController.coreProposalsSettingsProjectionsSponsor5'),
    coreProposalsSettingsOpenCurrentYearSponsor6: Ember.computed.alias('projectionsController.coreProposalsSettingsOpenCurrentYearSponsor6'),
    coreProposalsSettingsRevisedCurrentYearSponsor6: Ember.computed.alias('projectionsController.coreProposalsSettingsRevisedCurrentYearSponsor6'),
    coreProposalsSettingsTransitionNextYearSponsor6: Ember.computed.alias('projectionsController.coreProposalsSettingsTransitionNextYearSponsor6'),
    coreProposalsSettingsProjectionsSponsor6: Ember.computed.alias('projectionsController.coreProposalsSettingsProjectionsSponsor6'),
    coreProposalsSettingsOpenCurrentYearSponsor7: Ember.computed.alias('projectionsController.coreProposalsSettingsOpenCurrentYearSponsor7'),
    coreProposalsSettingsRevisedCurrentYearSponsor7: Ember.computed.alias('projectionsController.coreProposalsSettingsRevisedCurrentYearSponsor7'),
    coreProposalsSettingsTransitionNextYearSponsor7: Ember.computed.alias('projectionsController.coreProposalsSettingsTransitionNextYearSponsor7'),
    coreProposalsSettingsProjectionsSponsor7: Ember.computed.alias('projectionsController.coreProposalsSettingsProjectionsSponsor7'),
    currentUser: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var startMonth = moment([2020, 2]).startOf('month');
      var monthsOptions = Array(36).fill(0).map(function (_, i) {
        return moment(startMonth).add('month', i).format('MMM YYYY');
      });
      this.set('phase1StartOptions', monthsOptions);
      this.set('phase2StartOptions', monthsOptions);
      this.set('phase3StartOptions', monthsOptions);
      this.set('sponsorOptions', this.currentUser.sponsorlist);
      var baseChartOptions = {
        scales: {
          yAxes: [{
            ticks: {
              display: true,
              beginAtZero: true,
              fontColor: '#323539',
              fontFamily: 'Lato',
              fontStyle: 'bold'
            },
            gridLines: {
              display: true,
              drawTicks: true,
              zeroLineWidth: 2
            }
          }],
          xAxes: [{
            ticks: {
              fontColor: '#323539',
              fontFamily: 'Lato',
              fontStyle: 'bold'
            },
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          position: 'top',
          reverse: false,
          labels: {
            boxWidth: 12,
            fontColor: '#323539',
            fontFamily: 'Lato',
            fontSize: 12,
            fontStyle: 'bold'
          }
        },
        tooltips: {
          backgroundColor: '#FAFBFB',
          bodyFontColor: '#416477',
          bodyFontFamily: 'Lato',
          bodySpacing: 6,
          enabled: false,
          intersect: false,
          footerMarginBottom: 20,
          mode: 'index',
          multiKeyBackground: '#FAFBFB',
          position: 'nearest',
          titleFontColor: '#416477',
          titleMarginBottom: 12,
          xPadding: 12,
          yPadding: 12
        },
        title: {
          display: true
        },
        plugins: {
          datalabels: {
            align: 'bottom',
            anchor: 'end',
            color: 'rgba(0,0,0,0.9)',
            font: {
              family: 'Lato',
              size: 11,
              weight: 900
            },
            padding: 2
          }
        }
      };
      Chart.defaults.global.maintainAspectRatio = false; //eslint-disable-line
      // Chart Options for standard bar chart

      var dollarChartOptions = JSON.parse(JSON.stringify(baseChartOptions));
      this.set('dollarChartOptions', dollarChartOptions);
      var projectedReducedBaselineOptions = JSON.parse(JSON.stringify(dollarChartOptions));
      projectedReducedBaselineOptions.title.text = 'Projected Spending and Indirect Cost Recovery (mm)';
      projectedReducedBaselineOptions.scales.xAxes[0].stacked = true;
      projectedReducedBaselineOptions.scales.yAxes[0].stacked = true;
      projectedReducedBaselineOptions.plugins.datalabels.display = false;

      projectedReducedBaselineOptions.scales.yAxes[0].ticks.callback = function (value) {
        return (0, _formatMoney.default)(value);
      };

      this.set('projectedReducedBaselineOptions', projectedReducedBaselineOptions);
      var compensationReplacementOptions = JSON.parse(JSON.stringify(dollarChartOptions));
      compensationReplacementOptions.title.text = 'Potential Research Backstop (mm)';
      compensationReplacementOptions.scales.yAxes[0].ticks.display = false;
      compensationReplacementOptions.scales.yAxes[0].gridLines.display = false;

      compensationReplacementOptions.plugins.datalabels.formatter = function (value) {
        if (!value) {
          return null;
        }

        return (0, _formatNumber.default)(value, 1);
      };

      this.set('compensationReplacementOptions', compensationReplacementOptions);
    },
    saveSettings: function saveSettings() {
      var settings = {
        phase1Start: this.phase1Start,
        phase1End: this.phase1End,
        phase1ReductionsDirects: this.phase1ReductionsDirects,
        phase1ReductionsIndirects: this.phase1ReductionsIndirects,
        phase1RecoveryDirects: this.phase1RecoveryDirects,
        phase1RecoveryIndirects: this.phase1RecoveryIndirects,
        isPhase2Enabled: this.isPhase2Enabled,
        phase2Start: this.phase2Start,
        phase2End: this.phase2End,
        phase2ReductionsDirects: this.phase2ReductionsDirects,
        phase2ReductionsIndirects: this.phase2ReductionsIndirects,
        phase2RecoveryDirects: this.phase2RecoveryDirects,
        phase2RecoveryIndirects: this.phase2RecoveryIndirects,
        isPhase3Enabled: this.isPhase3Enabled,
        phase3Start: this.phase3Start,
        phase3End: this.phase3End,
        phase3ReductionsDirects: this.phase3ReductionsDirects,
        phase3ReductionsIndirects: this.phase3ReductionsIndirects,
        phase3RecoveryDirects: this.phase3RecoveryDirects,
        phase3RecoveryIndirects: this.phase3RecoveryIndirects,
        compensationReplacementNih: this.compensationReplacementNih,
        compensationReplacementOtherFed: this.compensationReplacementOtherFed,
        compensationReplacementFedFlowThru: this.compensationReplacementFedFlowThru,
        compensationReplacementNonFed: this.compensationReplacementNonFed,
        compensationReplacementSponsor5: this.compensationReplacementSponsor5,
        compensationReplacementSponsor6: this.compensationReplacementSponsor6,
        compensationReplacementSponsor7: this.compensationReplacementSponsor7,
        selectedCompensationReplacementSponsor: this.selectedCompensationReplacementSponsor,
        selectedFilterOption: this.selectedFilterOption
      };
      window.localStorage.setItem('forecastModifierSettings', JSON.stringify(settings));
    },
    phase1OptionsObserver: Ember.observer('phase1StartOptions', 'phase1Start', function () {
      this.set('phase1EndOptions', this.phaseEndOptions(this.phase1StartOptions, this.phase1Start));

      if (this.phase1EndOptions.indexOf(this.phase1End) === -1 && this.phase1EndOptions.length > 0) {
        this.set('phase1End', this.phase1EndOptions[0]);
      }
    }).on('init'),
    phase2OptionsObserver: Ember.observer('phase2StartOptions', 'phase2Start', function () {
      this.set('phase2EndOptions', this.phaseEndOptions(this.phase2StartOptions, this.phase2Start));

      if (this.phase2EndOptions.indexOf(this.phase2End) === -1 && this.phase2EndOptions.length > 0) {
        this.set('phase2End', this.phase2EndOptions[0]);
      }
    }).on('init'),
    phase3OptionsObserver: Ember.observer('phase3StartOptions', 'phase3Start', function () {
      this.set('phase3EndOptions', this.phaseEndOptions(this.phase3StartOptions, this.phase3Start));

      if (this.phase3EndOptions.indexOf(this.phase3End) === -1 && this.phase3EndOptions.length > 0) {
        this.set('phase3End', this.phase3EndOptions[0]);
      }
    }).on('init'),
    phase2StartOptionsObserver: Ember.observer('phase1EndOptions', 'phase1End', function () {
      this.set('phase2StartOptions', this.phaseEndOptions(this.phase1EndOptions, this.phase1End));

      if (this.phase2StartOptions.indexOf(this.phase2Start) === -1 && this.phase2StartOptions.length > 0) {
        this.set('phase2Start', this.phase2StartOptions[0]);
      }
    }).on('init'),
    phase3StartOptionsObserver: Ember.observer('phase2EndOptions', 'phase2End', function () {
      this.set('phase3StartOptions', this.phaseEndOptions(this.phase2EndOptions, this.phase2End));

      if (this.phase3StartOptions.indexOf(this.phase3Start) === -1 && this.phase3EndOptions && this.phase3EndOptions.length > 0) {
        this.set('phase3Start', this.phase3StartOptions[0]);
      }
    }).on('init'),
    phaseEndOptions: function phaseEndOptions(options, start) {
      if (!options) {
        return [];
      } else if (!start) {
        return options;
      }

      var index = options.findIndex(function (month) {
        return month === start;
      });

      if (index) {
        var indexToSlice = index + 1 >= options.length ? options.length - 1 : index + 1;
        return options.slice(indexToSlice);
      } else {
        return options;
      }
    },
    //Add the revised reduced calculations to account for reductions
    //region Revised Current Year
    revisedReducedCurrentYearNihDirects: Ember.computed('currentUser.team', 'model.projection.{successesFiveYearsAgoNihDirects,successesFourYearsAgoNihDirects,successesThreeYearsAgoNihDirects,successesTwoYearsAgoNihDirects,successesOneYearAgoNihDirects}', 'projectedCoreSuccessesCurrentYearNihDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoNihDirects'), this.get('model.projection.successesFourYearsAgoNihDirects'), this.get('model.projection.successesThreeYearsAgoNihDirects'), this.get('model.projection.successesTwoYearsAgoNihDirects'), this.get('model.projection.successesOneYearAgoNihDirects'), this.projectedCoreSuccessesCurrentYearNihDirects, this.year.fiscalYear, true);
    }),
    revisedReducedCurrentYearNihIndirects: Ember.computed('model.projection.{successesFiveYearsAgoNihIndirects,successesFourYearsAgoNihIndirects,successesThreeYearsAgoNihIndirects,successesTwoYearsAgoNihIndirects,successesOneYearAgoNihIndirects}', 'projectedCoreSuccessesCurrentYearNihIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoNihIndirects'), this.get('model.projection.successesFourYearsAgoNihIndirects'), this.get('model.projection.successesThreeYearsAgoNihIndirects'), this.get('model.projection.successesTwoYearsAgoNihIndirects'), this.get('model.projection.successesOneYearAgoNihIndirects'), this.projectedCoreSuccessesCurrentYearNihIndirects, this.year.fiscalYear, false);
    }),
    revisedReducedCurrentYearFedFlowThruDirects: Ember.computed('model.projection.{successesFiveYearsAgoFedFlowThruDirects,successesFourYearsAgoFedFlowThruDirects}', 'modifiedFedFlowThruThreeYearsAgoDirects', 'modifiedFedFlowThruTwoYearsAgoDirects', 'modifiedFedFlowThruOneYearAgoDirects', 'projectedCoreSuccessesCurrentYearFedFlowThruDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoFedFlowThruDirects'), this.get('model.projection.successesFourYearsAgoFedFlowThruDirects'), this.modifiedFedFlowThruThreeYearsAgoDirects, this.modifiedFedFlowThruTwoYearsAgoDirects, this.modifiedFedFlowThruOneYearAgoDirects, this.projectedCoreSuccessesCurrentYearFedFlowThruDirects, this.year.fiscalYear, true);
    }),
    revisedReducedCurrentYearFedFlowThruIndirects: Ember.computed('model.projection.{successesFiveYearsAgoFedFlowThruIndirects,successesFourYearsAgoFedFlowThruIndirects}', 'modifiedFedFlowThruThreeYearsAgoIndirects', 'modifiedFedFlowThruTwoYearsAgoIndirects', 'modifiedFedFlowThruOneYearAgoIndirects', 'projectedCoreSuccessesCurrentYearFedFlowThruIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoFedFlowThruIndirects'), this.get('model.projection.successesFourYearsAgoFedFlowThruIndirects'), this.modifiedFedFlowThruThreeYearsAgoIndirects, this.modifiedFedFlowThruTwoYearsAgoIndirects, this.modifiedFedFlowThruOneYearAgoIndirects, this.projectedCoreSuccessesCurrentYearFedFlowThruIndirects, this.year.fiscalYear, false);
    }),
    revisedReducedCurrentYearOtherFedDirects: Ember.computed('model.projection.{successesFiveYearsAgoOtherFedDirects,successesFourYearsAgoOtherFedDirects,successesThreeYearsAgoOtherFedDirects,successesTwoYearsAgoOtherFedDirects,successesOneYearAgoOtherFedDirects}', 'projectedCoreSuccessesCurrentYearOtherFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoOtherFedDirects'), this.get('model.projection.successesFourYearsAgoOtherFedDirects'), this.get('model.projection.successesThreeYearsAgoOtherFedDirects'), this.get('model.projection.successesTwoYearsAgoOtherFedDirects'), this.get('model.projection.successesOneYearAgoOtherFedDirects'), this.projectedCoreSuccessesCurrentYearOtherFedDirects, this.year.fiscalYear, true);
    }),
    revisedReducedCurrentYearOtherFedIndirects: Ember.computed('model.projection.{successesFiveYearsAgoOtherFedIndirects,successesFourYearsAgoOtherFedIndirects,successesThreeYearsAgoOtherFedIndirects,successesTwoYearsAgoOtherFedIndirects,successesOneYearAgoOtherFedIndirects}', 'projectedCoreSuccessesCurrentYearOtherFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoOtherFedIndirects'), this.get('model.projection.successesFourYearsAgoOtherFedIndirects'), this.get('model.projection.successesThreeYearsAgoOtherFedIndirects'), this.get('model.projection.successesTwoYearsAgoOtherFedIndirects'), this.get('model.projection.successesOneYearAgoOtherFedIndirects'), this.projectedCoreSuccessesCurrentYearOtherFedIndirects, this.year.fiscalYear, false);
    }),
    revisedReducedCurrentYearNonFedDirects: Ember.computed('model.projection.{successesFiveYearsAgoNonFedDirects,successesFourYearsAgoNonFedDirects,successesThreeYearsAgoNonFedDirects,successesTwoYearsAgoNonFedDirects,successesOneYearAgoNonFedDirects}', 'projectedCoreSuccessesCurrentYearNonFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoNonFedDirects'), this.get('model.projection.successesFourYearsAgoNonFedDirects'), this.get('model.projection.successesThreeYearsAgoNonFedDirects'), this.get('model.projection.successesTwoYearsAgoNonFedDirects'), this.get('model.projection.successesOneYearAgoNonFedDirects'), this.projectedCoreSuccessesCurrentYearNonFedDirects, this.year.fiscalYear, true);
    }),
    revisedReducedCurrentYearNonFedIndirects: Ember.computed('model.projection.{successesFiveYearsAgoNonFedIndirects,successesFourYearsAgoNonFedIndirects,successesThreeYearsAgoNonFedIndirects,successesTwoYearsAgoNonFedIndirects,successesOneYearAgoNonFedIndirects}', 'projectedCoreSuccessesCurrentYearNonFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', 'recoveryCoreCurrentYearDirects', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoNonFedIndirects'), this.get('model.projection.successesFourYearsAgoNonFedIndirects'), this.get('model.projection.successesThreeYearsAgoNonFedIndirects'), this.get('model.projection.successesTwoYearsAgoNonFedIndirects'), this.get('model.projection.successesOneYearAgoNonFedIndirects'), this.projectedCoreSuccessesCurrentYearNonFedIndirects, this.year.fiscalYear, false);
    }),
    revisedReducedCurrentYearSponsor5Directs: Ember.computed('model.projection.{successesFiveYearsAgoSponsor5Directs,successesFourYearsAgoSponsor5Directs,successesThreeYearsAgoSponsor5Directs,successesTwoYearsAgoSponsor5Directs,successesOneYearAgoSponsor5Directs}', 'projectedCoreSuccessesCurrentYearSponsor5Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoSponsor5Directs'), this.get('model.projection.successesFourYearsAgoSponsor5Directs'), this.get('model.projection.successesThreeYearsAgoSponsor5Directs'), this.get('model.projection.successesTwoYearsAgoSponsor5Directs'), this.get('model.projection.successesOneYearAgoSponsor5Directs'), this.projectedCoreSuccessesCurrentYearSponsor5Directs, this.year.fiscalYear, true);
    }),
    revisedReducedCurrentYearSponsor5Indirects: Ember.computed('model.projection.{successesFiveYearsAgoSponsor5Indirects,successesFourYearsAgoSponsor5Indirects,successesThreeYearsAgoSponsor5Indirects,successesTwoYearsAgoSponsor5Indirects,successesOneYearAgoSponsor5Indirects}', 'projectedCoreSuccessesCurrentYearSponsor5Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', 'recoveryCoreCurrentYearDirects', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoSponsor5Indirects'), this.get('model.projection.successesFourYearsAgoSponsor5Indirects'), this.get('model.projection.successesThreeYearsAgoSponsor5Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor5Indirects'), this.get('model.projection.successesOneYearAgoSponsor5Indirects'), this.projectedCoreSuccessesCurrentYearSponsor5Indirects, this.year.fiscalYear, false);
    }),
    revisedReducedCurrentYearSponsor6Directs: Ember.computed('model.projection.{successesFiveYearsAgoSponsor6Directs,successesFourYearsAgoSponsor6Directs,successesThreeYearsAgoSponsor6Directs,successesTwoYearsAgoSponsor6Directs,successesOneYearAgoSponsor6Directs}', 'projectedCoreSuccessesCurrentYearSponsor6Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoSponsor6Directs'), this.get('model.projection.successesFourYearsAgoSponsor6Directs'), this.get('model.projection.successesThreeYearsAgoSponsor6Directs'), this.get('model.projection.successesTwoYearsAgoSponsor6Directs'), this.get('model.projection.successesOneYearAgoSponsor6Directs'), this.projectedCoreSuccessesCurrentYearSponsor6Directs, this.year.fiscalYear, true);
    }),
    revisedReducedCurrentYearSponsor6Indirects: Ember.computed('model.projection.{successesFiveYearsAgoSponsor6Indirects,successesFourYearsAgoSponsor6Indirects,successesThreeYearsAgoSponsor6Indirects,successesTwoYearsAgoSponsor6Indirects,successesOneYearAgoSponsor6Indirects}', 'projectedCoreSuccessesCurrentYearSponsor6Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', 'recoveryCoreCurrentYearDirects', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoSponsor6Indirects'), this.get('model.projection.successesFourYearsAgoSponsor6Indirects'), this.get('model.projection.successesThreeYearsAgoSponsor6Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor6Indirects'), this.get('model.projection.successesOneYearAgoSponsor6Indirects'), this.projectedCoreSuccessesCurrentYearSponsor6Indirects, this.year.fiscalYear, false);
    }),
    revisedReducedCurrentYearSponsor7Directs: Ember.computed('model.projection.{successesFiveYearsAgoSponsor7Directs,successesFourYearsAgoSponsor7Directs,successesThreeYearsAgoSponsor7Directs,successesTwoYearsAgoSponsor7Directs,successesOneYearAgoSponsor7Directs}', 'projectedCoreSuccessesCurrentYearSponsor7Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoSponsor7Directs'), this.get('model.projection.successesFourYearsAgoSponsor7Directs'), this.get('model.projection.successesThreeYearsAgoSponsor7Directs'), this.get('model.projection.successesTwoYearsAgoSponsor7Directs'), this.get('model.projection.successesOneYearAgoSponsor7Directs'), this.projectedCoreSuccessesCurrentYearSponsor7Directs, this.year.fiscalYear, true);
    }),
    revisedReducedCurrentYearSponsor7Indirects: Ember.computed('model.projection.{successesFiveYearsAgoSponsor7Indirects,successesFourYearsAgoSponsor7Indirects,successesThreeYearsAgoSponsor7Indirects,successesTwoYearsAgoSponsor7Indirects,successesOneYearAgoSponsor7Indirects}', 'projectedCoreSuccessesCurrentYearSponsor7Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', 'recoveryCoreCurrentYearDirects', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFiveYearsAgoSponsor7Indirects'), this.get('model.projection.successesFourYearsAgoSponsor7Indirects'), this.get('model.projection.successesThreeYearsAgoSponsor7Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor7Indirects'), this.get('model.projection.successesOneYearAgoSponsor7Indirects'), this.projectedCoreSuccessesCurrentYearSponsor7Indirects, this.year.fiscalYear, false);
    }),
    revisedReducedCurrentYearDirects: Ember.computed('revisedReducedCurrentYearNihDirects', 'revisedReducedCurrentYearOtherFedDirects', 'revisedReducedCurrentYearFedFlowThruDirects', 'revisedReducedCurrentYearNonFedDirects', 'revisedReducedCurrentYearSponsor5Directs', 'revisedReducedCurrentYearSponsor6Directs', 'revisedReducedCurrentYearSponsor7Directs', 'model.projection.megaGrantTotalsCurrentYearDirects', 'selectedGrantType', 'currentUser.team.megaGrantFutureCurrentYearDirects', 'selectedSponsorType', 'selectedSponsor', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsCurrentYearNihDirects, model.megaGrantTotalsCurrentYearFedFlowThruDirects, model.megaGrantTotalsCurrentYearOtherFedDirects, model.megaGrantTotalsCurrentYearNonFedDirects, model.megaGrantTotalsCurrentYearSponsor5Directs, model.megaGrantTotalsCurrentYearSponsor6Directs, model.megaGrantTotalsCurrentYearSponsor7Directs); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(0, true, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYear, true);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedCurrentYearNihDirects, this.revisedReducedCurrentYearFedFlowThruDirects, this.revisedReducedCurrentYearOtherFedDirects, this.revisedReducedCurrentYearNonFedDirects, this.revisedReducedCurrentYearSponsor5Directs, this.revisedReducedCurrentYearSponsor6Directs, this.revisedReducedCurrentYearSponsor7Directs) + this.recoveryCoreCurrentYearDirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    revisedReducedCurrentYearIndirects: Ember.computed('revisedReducedCurrentYearNihIndirects', 'revisedReducedCurrentYearOtherFedIndirects', 'revisedReducedCurrentYearFedFlowThruIndirects', 'revisedReducedCurrentYearNonFedIndirects', 'revisedReducedCurrentYearSponsor5Indirects', 'revisedReducedCurrentYearSponsor6Indirects', 'revisedReducedCurrentYearSponsor7Indirects', 'model.projection.megaGrantTotalsCurrentYearNihIndirects', 'selectedGrantType', 'currentUser.team.megaGrantFutureCurrentYearIndirects', 'selectedSponsor', 'recoveryCoreCurrentYearIndirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsCurrentYearNihIndirects, model.megaGrantTotalsCurrentYearFedFlowThruIndirects, model.megaGrantTotalsCurrentYearOtherFedIndirects, model.megaGrantTotalsCurrentYearNonFedIndirects, model.megaGrantTotalsCurrentYearSponsor5Indirects, model.megaGrantTotalsCurrentYearSponsor6Indirects, model.megaGrantTotalsCurrentYearSponsor7Indirects); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(0, false, this.get('currentUser.team'));
      }

      +this.recoveryCoreCurrentYearIndirects;
      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYear, false);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedCurrentYearNihIndirects, this.revisedReducedCurrentYearFedFlowThruIndirects, this.revisedReducedCurrentYearOtherFedIndirects, this.revisedReducedCurrentYearNonFedIndirects, this.revisedReducedCurrentYearSponsor5Indirects, this.revisedReducedCurrentYearSponsor6Indirects, this.revisedReducedCurrentYearSponsor7Indirects) + this.recoveryCoreCurrentYearIndirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    //endregion
    //region Revised One Year Ahead
    revisedReducedOneYearAheadNihDirects: Ember.computed('model.projection.{successesFourYearsAgoNihDirects,successesThreeYearsAgoNihDirects,successesTwoYearsAgoNihDirects,successesOneYearAgoNihDirects}', 'projectedCoreSuccessesCurrentYearNihDirects', 'projectedCoreSuccessesOneYearAheadNihDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoNihDirects'), this.get('model.projection.successesThreeYearsAgoNihDirects'), this.get('model.projection.successesTwoYearsAgoNihDirects'), this.get('model.projection.successesOneYearAgoNihDirects'), this.projectedCoreSuccessesCurrentYearNihDirects, this.projectedCoreSuccessesOneYearAheadNihDirects, this.year.fiscalYearOneYearAhead, true);
    }),
    revisedReducedOneYearAheadNihIndirects: Ember.computed('model.projection.{successesFourYearsAgoNihIndirects,successesThreeYearsAgoNihIndirects,successesTwoYearsAgoNihIndirects,successesOneYearAgoNihIndirects}', 'projectedCoreSuccessesCurrentYearNihIndirects', 'projectedCoreSuccessesOneYearAheadNihIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoNihIndirects'), this.get('model.projection.successesThreeYearsAgoNihIndirects'), this.get('model.projection.successesTwoYearsAgoNihIndirects'), this.get('model.projection.successesOneYearAgoNihIndirects'), this.projectedCoreSuccessesCurrentYearNihIndirects, this.projectedCoreSuccessesOneYearAheadNihIndirects, this.year.fiscalYearOneYearAhead, false);
    }),
    revisedReducedOneYearAheadOtherFedDirects: Ember.computed('model.projection.{successesFourYearsAgoOtherFedDirects,successesThreeYearsAgoOtherFedDirects,successesTwoYearsAgoOtherFedDirects,successesOneYearAgoOtherFedDirects}', 'projectedCoreSuccessesCurrentYearOtherFedDirects', 'projectedCoreSuccessesOneYearAheadOtherFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoOtherFedDirects'), this.get('model.projection.successesThreeYearsAgoOtherFedDirects'), this.get('model.projection.successesTwoYearsAgoOtherFedDirects'), this.get('model.projection.successesOneYearAgoOtherFedDirects'), this.projectedCoreSuccessesCurrentYearOtherFedDirects, this.projectedCoreSuccessesOneYearAheadOtherFedDirects, this.year.fiscalYearOneYearAhead, true);
    }),
    revisedReducedOneYearAheadOtherFedIndirects: Ember.computed('model.projection.{successesFourYearsAgoOtherFedIndirects,successesThreeYearsAgoOtherFedIndirects,successesTwoYearsAgoOtherFedIndirects,successesOneYearAgoOtherFedIndirects}', 'projectedCoreSuccessesCurrentYearOtherFedIndirects', 'projectedCoreSuccessesOneYearAheadOtherFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phaseRanges', 'phase3ReductionsIndirects', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoOtherFedIndirects'), this.get('model.projection.successesThreeYearsAgoOtherFedIndirects'), this.get('model.projection.successesTwoYearsAgoOtherFedIndirects'), this.get('model.projection.successesOneYearAgoOtherFedIndirects'), this.projectedCoreSuccessesCurrentYearOtherFedIndirects, this.projectedCoreSuccessesOneYearAheadOtherFedIndirects, this.year.fiscalYearOneYearAhead, false);
    }),
    revisedReducedOneYearAheadFedFlowThruDirects: Ember.computed('model.projection.successesFourYearsAgoFedFlowThruDirects', 'modifiedFedFlowThruThreeYearsAgoDirects', 'modifiedFedFlowThruTwoYearsAgoDirects', 'modifiedFedFlowThruOneYearAgoDirects', 'projectedCoreSuccessesCurrentYearFedFlowThruDirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoFedFlowThruDirects'), this.modifiedFedFlowThruThreeYearsAgoDirects, this.modifiedFedFlowThruTwoYearsAgoDirects, this.modifiedFedFlowThruOneYearAgoDirects, this.projectedCoreSuccessesCurrentYearFedFlowThruDirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruDirects, this.year.fiscalYearOneYearAhead, true);
    }),
    revisedReducedOneYearAheadFedFlowThruIndirects: Ember.computed('model.projection.successesFourYearsAgoFedFlowThruIndirects', 'modifiedFedFlowThruThreeYearsAgoIndirects', 'modifiedFedFlowThruTwoYearsAgoIndirects', 'modifiedFedFlowThruOneYearAgoIndirects', 'projectedCoreSuccessesCurrentYearFedFlowThruIndirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoFedFlowThruIndirects'), this.modifiedFedFlowThruThreeYearsAgoIndirects, this.modifiedFedFlowThruTwoYearsAgoIndirects, this.modifiedFedFlowThruOneYearAgoIndirects, this.projectedCoreSuccessesCurrentYearFedFlowThruIndirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruIndirects, this.year.fiscalYearOneYearAhead, false);
    }),
    revisedReducedOneYearAheadNonFedDirects: Ember.computed('model.projection.{successesFourYearsAgoNonFedDirects,successesThreeYearsAgoNonFedDirects,successesTwoYearsAgoNonFedDirects,successesOneYearAgoNonFedDirects}', 'projectedCoreSuccessesCurrentYearNonFedDirects', 'projectedCoreSuccessesOneYearAheadNonFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoNonFedDirects'), this.get('model.projection.successesThreeYearsAgoNonFedDirects'), this.get('model.projection.successesTwoYearsAgoNonFedDirects'), this.get('model.projection.successesOneYearAgoNonFedDirects'), this.projectedCoreSuccessesCurrentYearNonFedDirects, this.projectedCoreSuccessesOneYearAheadNonFedDirects, this.year.fiscalYearOneYearAhead, true);
    }),
    revisedReducedOneYearAheadNonFedIndirects: Ember.computed('model.projection.{successesFourYearsAgoNonFedIndirects,successesThreeYearsAgoNonFedIndirects,successesTwoYearsAgoNonFedIndirects,successesOneYearAgoNonFedIndirects}', 'projectedCoreSuccessesCurrentYearNonFedIndirects', 'projectedCoreSuccessesOneYearAheadNonFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoNonFedIndirects'), this.get('model.projection.successesThreeYearsAgoNonFedIndirects'), this.get('model.projection.successesTwoYearsAgoNonFedIndirects'), this.get('model.projection.successesOneYearAgoNonFedIndirects'), this.projectedCoreSuccessesCurrentYearNonFedIndirects, this.projectedCoreSuccessesOneYearAheadNonFedIndirects, this.year.fiscalYearOneYearAhead, false);
    }),
    revisedReducedOneYearAheadSponsor5Directs: Ember.computed('model.projection.{successesFourYearsAgoSponsor5Directs,successesThreeYearsAgoSponsor5Directs,successesTwoYearsAgoSponsor5Directs,successesOneYearAgoSponsor5Directs}', 'projectedCoreSuccessesCurrentYearSponsor5Directs', 'projectedCoreSuccessesOneYearAheadSponsor5Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoSponsor5Directs'), this.get('model.projection.successesThreeYearsAgoSponsor5Directs'), this.get('model.projection.successesTwoYearsAgoSponsor5Directs'), this.get('model.projection.successesOneYearAgoSponsor5Directs'), this.projectedCoreSuccessesCurrentYearSponsor5Directs, this.projectedCoreSuccessesOneYearAheadSponsor5Directs, this.year.fiscalYearOneYearAhead, true);
    }),
    revisedReducedOneYearAheadSponsor5Indirects: Ember.computed('model.projection.{successesFourYearsAgoSponsor5Indirects,successesThreeYearsAgoSponsor5Indirects,successesTwoYearsAgoSponsor5Indirects,successesOneYearAgoSponsor5Indirects}', 'projectedCoreSuccessesCurrentYearSponsor5Indirects', 'projectedCoreSuccessesOneYearAheadSponsor5Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoSponsor5Indirects'), this.get('model.projection.successesThreeYearsAgoSponsor5Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor5Indirects'), this.get('model.projection.successesOneYearAgoSponsor5Indirects'), this.projectedCoreSuccessesCurrentYearSponsor5Indirects, this.projectedCoreSuccessesOneYearAheadSponsor5Indirects, this.year.fiscalYearOneYearAhead, false);
    }),
    revisedReducedOneYearAheadSponsor6Directs: Ember.computed('model.projection.{successesFourYearsAgoSponsor6Directs,successesThreeYearsAgoSponsor6Directs,successesTwoYearsAgoSponsor6Directs,successesOneYearAgoSponsor6Directs}', 'projectedCoreSuccessesCurrentYearSponsor6Directs', 'projectedCoreSuccessesOneYearAheadSponsor6Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoSponsor6Directs'), this.get('model.projection.successesThreeYearsAgoSponsor6Directs'), this.get('model.projection.successesTwoYearsAgoSponsor6Directs'), this.get('model.projection.successesOneYearAgoSponsor6Directs'), this.projectedCoreSuccessesCurrentYearSponsor6Directs, this.projectedCoreSuccessesOneYearAheadSponsor6Directs, this.year.fiscalYearOneYearAhead, true);
    }),
    revisedReducedOneYearAheadSponsor6Indirects: Ember.computed('model.projection.{successesFourYearsAgoSponsor6Indirects,successesThreeYearsAgoSponsor6Indirects,successesTwoYearsAgoSponsor6Indirects,successesOneYearAgoSponsor6Indirects}', 'projectedCoreSuccessesCurrentYearSponsor6Indirects', 'projectedCoreSuccessesOneYearAheadSponsor6Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoSponsor6Indirects'), this.get('model.projection.successesThreeYearsAgoSponsor6Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor6Indirects'), this.get('model.projection.successesOneYearAgoSponsor6Indirects'), this.projectedCoreSuccessesCurrentYearSponsor6Indirects, this.projectedCoreSuccessesOneYearAheadSponsor6Indirects, this.year.fiscalYearOneYearAhead, false);
    }),
    revisedReducedOneYearAheadSponsor7Directs: Ember.computed('model.projection.{successesFourYearsAgoSponsor7Directs,successesThreeYearsAgoSponsor7Directs,successesTwoYearsAgoSponsor7Directs,successesOneYearAgoSponsor7Directs}', 'projectedCoreSuccessesCurrentYearSponsor7Directs', 'projectedCoreSuccessesOneYearAheadSponsor7Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoSponsor7Directs'), this.get('model.projection.successesThreeYearsAgoSponsor7Directs'), this.get('model.projection.successesTwoYearsAgoSponsor7Directs'), this.get('model.projection.successesOneYearAgoSponsor7Directs'), this.projectedCoreSuccessesCurrentYearSponsor7Directs, this.projectedCoreSuccessesOneYearAheadSponsor7Directs, this.year.fiscalYearOneYearAhead, true);
    }),
    revisedReducedOneYearAheadSponsor7Indirects: Ember.computed('model.projection.{successesFourYearsAgoSponsor7Indirects,successesThreeYearsAgoSponsor7Indirects,successesTwoYearsAgoSponsor7Indirects,successesOneYearAgoSponsor7Indirects}', 'projectedCoreSuccessesCurrentYearSponsor7Indirects', 'projectedCoreSuccessesOneYearAheadSponsor7Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesFourYearsAgoSponsor7Indirects'), this.get('model.projection.successesThreeYearsAgoSponsor7Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor7Indirects'), this.get('model.projection.successesOneYearAgoSponsor7Indirects'), this.projectedCoreSuccessesCurrentYearSponsor7Indirects, this.projectedCoreSuccessesOneYearAheadSponsor7Indirects, this.year.fiscalYearOneYearAhead, false);
    }),
    revisedReducedOneYearAheadDirects: Ember.computed('revisedReducedOneYearAheadNihDirects', 'revisedReducedOneYearAheadOtherFedDirects', 'revisedReducedOneYearAheadFedFlowThruDirects', 'revisedReducedOneYearAheadNonFedDirects', 'revisedReducedOneYearAheadSponsor5Directs', 'revisedReducedOneYearAheadSponsor6Directs', 'revisedReducedOneYearAheadSponsor7Directs', 'model.projection.megaGrantTotalsOneYearAheadDirects', 'selectedGrantType', 'currentUser.team.megaGrantFutureOneYearAheadDirects', 'selectedSponsor', 'recoveryCoreOneYearAheadDirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsOneYearAheadNihDirects, model.megaGrantTotalsOneYearAheadFedFlowThruDirects, model.megaGrantTotalsOneYearAheadOtherFedDirects, model.megaGrantTotalsOneYearAheadNonFedDirects, model.megaGrantTotalsOneYearAheadSponsor5Directs, model.megaGrantTotalsOneYearAheadSponsor6Directs, model.megaGrantTotalsOneYearAheadSponsor7Directs); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(1, true, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearOneYearAhead, true);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedOneYearAheadNihDirects, this.revisedReducedOneYearAheadFedFlowThruDirects, this.revisedReducedOneYearAheadOtherFedDirects, this.revisedReducedOneYearAheadNonFedDirects, this.revisedReducedOneYearAheadSponsor5Directs, this.revisedReducedOneYearAheadSponsor6Directs, this.revisedReducedOneYearAheadSponsor7Directs) + this.recoveryCoreOneYearAheadDirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    revisedReducedOneYearAheadIndirects: Ember.computed('revisedReducedOneYearAheadNihIndirects', 'revisedReducedOneYearAheadOtherFedIndirects', 'revisedReducedOneYearAheadFedFlowThruIndirects', 'revisedReducedOneYearAheadNonFedIndirects', 'revisedReducedOneYearAheadSponsor5Indirects', 'revisedReducedOneYearAheadSponsor6Indirects', 'revisedReducedOneYearAheadSponsor7Indirects', 'model.projection.megaGrantTotalsOneYearAheadIndirects', 'selectedGrantType', 'currentUser.team.megaGrantFutureOneYearAheadDirects', 'selectedSponsor', 'recoveryCoreOneYearAheadIndirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsOneYearAheadNihIndirects, model.megaGrantTotalsOneYearAheadFedFlowThruIndirects, model.megaGrantTotalsOneYearAheadOtherFedIndirects, model.megaGrantTotalsOneYearAheadNonFedIndirects, model.megaGrantTotalsOneYearAheadSponsor5Indirects, model.megaGrantTotalsOneYearAheadSponsor6Indirects, model.megaGrantTotalsOneYearAheadSponsor7Indirects); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(1, false, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearOneYearAhead, false);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedOneYearAheadNihIndirects, this.revisedReducedOneYearAheadFedFlowThruIndirects, this.revisedReducedOneYearAheadOtherFedIndirects, this.revisedReducedOneYearAheadNonFedIndirects, this.revisedReducedOneYearAheadSponsor5Indirects, this.revisedReducedOneYearAheadSponsor6Indirects, this.revisedReducedOneYearAheadSponsor7Indirects) + this.recoveryCoreOneYearAheadIndirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    //endregion
    //region Revised Two Years Ahead
    revisedReducedTwoYearsAheadNihDirects: Ember.computed('model.projection.{successesThreeYearsAgoNihDirects,successesTwoYearsAgoNihDirects,successesOneYearAgoNihDirects}', 'projectedCoreSuccessesCurrentYearNihDirects', 'projectedCoreSuccessesOneYearAheadNihDirects', 'projectedCoreSuccessesTwoYearsAheadNihDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoNihDirects'), this.get('model.projection.successesTwoYearsAgoNihDirects'), this.get('model.projection.successesOneYearAgoNihDirects'), this.projectedCoreSuccessesCurrentYearNihDirects, this.projectedCoreSuccessesOneYearAheadNihDirects, this.projectedCoreSuccessesTwoYearsAheadNihDirects, this.year.fiscalYearTwoYearsAhead, true);
    }),
    revisedReducedTwoYearsAheadNihIndirects: Ember.computed('model.projection.{successesThreeYearsAgoNihIndirects,successesTwoYearsAgoNihIndirects,successesOneYearAgoNihIndirects}', 'projectedCoreSuccessesCurrentYearNihIndirects', 'projectedCoreSuccessesOneYearAheadNihIndirects', 'projectedCoreSuccessesTwoYearsAheadNihIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoNihIndirects'), this.get('model.projection.successesTwoYearsAgoNihIndirects'), this.get('model.projection.successesOneYearAgoNihIndirects'), this.projectedCoreSuccessesCurrentYearNihIndirects, this.projectedCoreSuccessesOneYearAheadNihIndirects, this.projectedCoreSuccessesTwoYearsAheadNihIndirects, this.year.fiscalYearTwoYearsAhead, false);
    }),
    revisedReducedTwoYearsAheadFedFlowThruDirects: Ember.computed('modifiedFedFlowThruThreeYearsAgoDirects', 'modifiedFedFlowThruTwoYearsAgoDirects', 'modifiedFedFlowThruOneYearAgoDirects', 'projectedCoreSuccessesCurrentYearFedFlowThruDirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruDirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.modifiedFedFlowThruThreeYearsAgoDirects, this.modifiedFedFlowThruTwoYearsAgoDirects, this.modifiedFedFlowThruOneYearAgoDirects, this.projectedCoreSuccessesCurrentYearFedFlowThruDirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruDirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects, this.year.fiscalYearTwoYearsAhead, true);
    }),
    revisedReducedTwoYearsAheadFedFlowThruIndirects: Ember.computed('modifiedFedFlowThruThreeYearsAgoIndirects', 'modifiedFedFlowThruTwoYearsAgoIndirects', 'modifiedFedFlowThruOneYearAgoIndirects', 'projectedCoreSuccessesCurrentYearFedFlowThruIndirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruIndirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.get('model.projection.modifiedFedFlowThruThreeYearsAgoIndirects'), this.modifiedFedFlowThruTwoYearsAgoIndirects, this.modifiedFedFlowThruOneYearAgoIndirects, this.projectedCoreSuccessesCurrentYearFedFlowThruIndirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruIndirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects, this.year.fiscalYearTwoYearsAhead, false);
    }),
    revisedReducedTwoYearsAheadOtherFedDirects: Ember.computed('model.projection.{successesThreeYearsAgoOtherFedDirects,successesTwoYearsAgoOtherFedDirects,successesOneYearAgoOtherFedDirects}', 'projectedCoreSuccessesCurrentYearOtherFedDirects', 'projectedCoreSuccessesOneYearAheadOtherFedDirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoOtherFedDirects'), this.get('model.projection.successesTwoYearsAgoOtherFedDirects'), this.get('model.projection.successesOneYearAgoOtherFedDirects'), this.projectedCoreSuccessesCurrentYearOtherFedDirects, this.projectedCoreSuccessesOneYearAheadOtherFedDirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedDirects, this.year.fiscalYearTwoYearsAhead, true);
    }),
    revisedReducedTwoYearsAheadOtherFedIndirects: Ember.computed('model.projection.{successesThreeYearsAgoOtherFedIndirects,successesTwoYearsAgoOtherFedIndirects,successesOneYearAgoOtherFedIndirects}', 'projectedCoreSuccessesCurrentYearOtherFedIndirects', 'projectedCoreSuccessesOneYearAheadOtherFedIndirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoOtherFedIndirects'), this.get('model.projection.successesTwoYearsAgoOtherFedIndirects'), this.get('model.projection.successesOneYearAgoOtherFedIndirects'), this.projectedCoreSuccessesCurrentYearOtherFedIndirects, this.projectedCoreSuccessesOneYearAheadOtherFedIndirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedIndirects, this.year.fiscalYearTwoYearsAhead, false);
    }),
    revisedReducedTwoYearsAheadNonFedDirects: Ember.computed('model.projection.{successesThreeYearsAgoNonFedDirects,successesTwoYearsAgoNonFedDirects,successesOneYearAgoNonFedDirects}', 'projectedCoreSuccessesCurrentYearNonFedDirects', 'projectedCoreSuccessesOneYearAheadNonFedDirects', 'projectedCoreSuccessesTwoYearsAheadNonFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoNonFedDirects'), this.get('model.projection.successesTwoYearsAgoNonFedDirects'), this.get('model.projection.successesOneYearAgoNonFedDirects'), this.projectedCoreSuccessesCurrentYearNonFedDirects, this.projectedCoreSuccessesOneYearAheadNonFedDirects, this.projectedCoreSuccessesTwoYearsAheadNonFedDirects, this.year.fiscalYearTwoYearsAhead, true);
    }),
    revisedReducedTwoYearsAheadNonFedIndirects: Ember.computed('model.projection.{successesThreeYearsAgoNonFedIndirects,successesTwoYearsAgoNonFedIndirects,successesOneYearAgoNonFedIndirects}', 'projectedCoreSuccessesCurrentYearNonFedIndirects', 'projectedCoreSuccessesOneYearAheadNonFedIndirects', 'projectedCoreSuccessesTwoYearsAheadNonFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoNonFedIndirects'), this.get('model.projection.successesTwoYearsAgoNonFedIndirects'), this.get('model.projection.successesOneYearAgoNonFedIndirects'), this.projectedCoreSuccessesCurrentYearNonFedIndirects, this.projectedCoreSuccessesOneYearAheadNonFedIndirects, this.projectedCoreSuccessesTwoYearsAheadNonFedIndirects, this.year.fiscalYearTwoYearsAhead, false);
    }),
    revisedReducedTwoYearsAheadSponsor5Directs: Ember.computed('model.projection.{successesThreeYearsAgoSponsor5Directs,successesTwoYearsAgoSponsor5Directs,successesOneYearAgoSponsor5Directs}', 'projectedCoreSuccessesCurrentYearSponsor5Directs', 'projectedCoreSuccessesOneYearAheadSponsor5Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor5Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoSponsor5Directs'), this.get('model.projection.successesTwoYearsAgoSponsor5Directs'), this.get('model.projection.successesOneYearAgoSponsor5Directs'), this.projectedCoreSuccessesCurrentYearSponsor5Directs, this.projectedCoreSuccessesOneYearAheadSponsor5Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor5Directs, this.year.fiscalYearTwoYearsAhead, true);
    }),
    revisedReducedTwoYearsAheadSponsor5Indirects: Ember.computed('model.projection.{successesThreeYearsAgoSponsor5Indirects,successesTwoYearsAgoSponsor5Indirects,successesOneYearAgoSponsor5Indirects}', 'projectedCoreSuccessesCurrentYearSponsor5Indirects', 'projectedCoreSuccessesOneYearAheadSponsor5Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor5Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoSponsor5Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor5Indirects'), this.get('model.projection.successesOneYearAgoSponsor5Indirects'), this.projectedCoreSuccessesCurrentYearSponsor5Indirects, this.projectedCoreSuccessesOneYearAheadSponsor5Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor5Indirects, this.year.fiscalYearTwoYearsAhead, false);
    }),
    revisedReducedTwoYearsAheadSponsor6Directs: Ember.computed('model.projection.{successesThreeYearsAgoSponsor6Directs,successesTwoYearsAgoSponsor6Directs,successesOneYearAgoSponsor6Directs}', 'projectedCoreSuccessesCurrentYearSponsor6Directs', 'projectedCoreSuccessesOneYearAheadSponsor6Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor6Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoSponsor6Directs'), this.get('model.projection.successesTwoYearsAgoSponsor6Directs'), this.get('model.projection.successesOneYearAgoSponsor6Directs'), this.projectedCoreSuccessesCurrentYearSponsor6Directs, this.projectedCoreSuccessesOneYearAheadSponsor6Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor6Directs, this.year.fiscalYearTwoYearsAhead, true);
    }),
    revisedReducedTwoYearsAheadSponsor6Indirects: Ember.computed('model.projection.{successesThreeYearsAgoSponsor6Indirects,successesTwoYearsAgoSponsor6Indirects,successesOneYearAgoSponsor6Indirects}', 'projectedCoreSuccessesCurrentYearSponsor6Indirects', 'projectedCoreSuccessesOneYearAheadSponsor6Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor6Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoSponsor6Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor6Indirects'), this.get('model.projection.successesOneYearAgoSponsor6Indirects'), this.projectedCoreSuccessesCurrentYearSponsor6Indirects, this.projectedCoreSuccessesOneYearAheadSponsor6Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor6Indirects, this.year.fiscalYearTwoYearsAhead, false);
    }),
    revisedReducedTwoYearsAheadSponsor7Directs: Ember.computed('model.projection.{successesThreeYearsAgoSponsor7Directs,successesTwoYearsAgoSponsor7Directs,successesOneYearAgoSponsor7Directs}', 'projectedCoreSuccessesCurrentYearSponsor7Directs', 'projectedCoreSuccessesOneYearAheadSponsor7Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor7Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoSponsor7Directs'), this.get('model.projection.successesTwoYearsAgoSponsor7Directs'), this.get('model.projection.successesOneYearAgoSponsor7Directs'), this.projectedCoreSuccessesCurrentYearSponsor7Directs, this.projectedCoreSuccessesOneYearAheadSponsor7Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor7Directs, this.year.fiscalYearTwoYearsAhead, true);
    }),
    revisedReducedTwoYearsAheadSponsor7Indirects: Ember.computed('model.projection.{successesThreeYearsAgoSponsor7Indirects,successesTwoYearsAgoSponsor7Indirects,successesOneYearAgoSponsor7Indirects}', 'projectedCoreSuccessesCurrentYearSponsor7Indirects', 'projectedCoreSuccessesOneYearAheadSponsor7Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor7Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesThreeYearsAgoSponsor7Indirects'), this.get('model.projection.successesTwoYearsAgoSponsor7Indirects'), this.get('model.projection.successesOneYearAgoSponsor7Indirects'), this.projectedCoreSuccessesCurrentYearSponsor7Indirects, this.projectedCoreSuccessesOneYearAheadSponsor7Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor7Indirects, this.year.fiscalYearTwoYearsAhead, false);
    }),
    revisedReducedTwoYearsAheadDirects: Ember.computed('revisedReducedTwoYearsAheadNihDirects', 'revisedReducedTwoYearsAheadOtherFedDirects', 'revisedReducedTwoYearsAheadFedFlowThruDirects', 'revisedReducedTwoYearsAheadNonFedDirects', 'revisedReducedTwoYearsAheadSponsor5Directs', 'revisedReducedTwoYearsAheadSponsor6Directs', 'revisedReducedTwoYearsAheadSponsor7Directs', 'model.projection.megaGrantTotalsTwoYearsAheadDirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearDirects,megaGrantFutureOneYearAheadDirects}', 'selectedSponsor', 'recoveryCoreTwoYearsAheadDirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsTwoYearsAheadNihDirects, model.megaGrantTotalsTwoYearsAheadFedFlowThruDirects, model.megaGrantTotalsTwoYearsAheadOtherFedDirects, model.megaGrantTotalsTwoYearsAheadNonFedDirects, model.megaGrantTotalsTwoYearsAheadSponsor5Directs, model.megaGrantTotalsTwoYearsAheadSponsor6Directs, model.megaGrantTotalsTwoYearsAheadSponsor7Directs); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(2, true, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearTwoYearsAhead, true);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedTwoYearsAheadNihDirects, this.revisedReducedTwoYearsAheadFedFlowThruDirects, this.revisedReducedTwoYearsAheadOtherFedDirects, this.revisedReducedTwoYearsAheadNonFedDirects, this.revisedReducedTwoYearsAheadSponsor5Directs, this.revisedReducedTwoYearsAheadSponsor6Directs, this.revisedReducedTwoYearsAheadSponsor7Directs) + this.recoveryCoreTwoYearsAheadDirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    revisedReducedTwoYearsAheadIndirects: Ember.computed('revisedReducedTwoYearsAheadNihIndirects', 'revisedReducedTwoYearsAheadOtherFedIndirects', 'revisedReducedTwoYearsAheadFedFlowThruIndirects', 'revisedReducedTwoYearsAheadNonFedIndirects', 'revisedReducedTwoYearsAheadSponsor5Indirects', 'revisedReducedTwoYearsAheadSponsor6Indirects', 'revisedReducedTwoYearsAheadSponsor7Indirects', 'model.projection.megaGrantTotalsTwoYearsAheadIndirects', '' + 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearIndirects,megaGrantFutureOneYearAheadIndirects}', 'selectedSponsor', 'recoveryCoreTwoYearsAheadIndirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsTwoYearsAheadNihIndirects, model.megaGrantTotalsTwoYearsAheadFedFlowThruIndirects, model.megaGrantTotalsTwoYearsAheadOtherFedIndirects, model.megaGrantTotalsTwoYearsAheadNonFedIndirects, model.megaGrantTotalsTwoYearsAheadSponsor5Indirects, model.megaGrantTotalsTwoYearsAheadSponsor6Indirects, model.megaGrantTotalsTwoYearsAheadSponsor7Indirects); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(2, false, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearTwoYearsAhead, false);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedTwoYearsAheadNihIndirects, this.revisedReducedTwoYearsAheadFedFlowThruIndirects, this.revisedReducedTwoYearsAheadOtherFedIndirects, this.revisedReducedTwoYearsAheadNonFedIndirects, this.revisedReducedTwoYearsAheadSponsor5Indirects, this.revisedReducedTwoYearsAheadSponsor6Indirects, this.revisedReducedTwoYearsAheadSponsor7Indirects) + this.recoveryCoreTwoYearsAheadIndirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    //endregion
    //region Revised Three Years Ahead
    revisedReducedThreeYearsAheadNihDirects: Ember.computed('model.projection.{successesTwoYearsAgoNihDirects,successesOneYearAgoNihDirects}', 'projectedCoreSuccessesCurrentYearNihDirects', 'projectedCoreSuccessesOneYearAheadNihDirects', 'projectedCoreSuccessesTwoYearsAheadNihDirects', 'projectedCoreSuccessesThreeYearsAheadNihDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoNihDirects'), this.get('model.projection.successesOneYearAgoNihDirects'), this.projectedCoreSuccessesCurrentYearNihDirects, this.projectedCoreSuccessesOneYearAheadNihDirects, this.projectedCoreSuccessesTwoYearsAheadNihDirects, this.projectedCoreSuccessesThreeYearsAheadNihDirects, this.year.fiscalYearThreeYearsAhead, true);
    }),
    revisedReducedThreeYearsAheadNihIndirects: Ember.computed('model.projection.{successesTwoYearsAgoNihIndirects,successesOneYearAgoNihIndirects}', 'projectedCoreSuccessesCurrentYearNihIndirects', 'projectedCoreSuccessesOneYearAheadNihIndirects', 'projectedCoreSuccessesTwoYearsAheadNihIndirects', 'projectedCoreSuccessesThreeYearsAheadNihIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoNihIndirects'), this.get('model.projection.successesOneYearAgoNihIndirects'), this.projectedCoreSuccessesCurrentYearNihIndirects, this.projectedCoreSuccessesOneYearAheadNihIndirects, this.projectedCoreSuccessesTwoYearsAheadNihIndirects, this.projectedCoreSuccessesThreeYearsAheadNihIndirects, this.year.fiscalYearThreeYearsAhead, false);
    }),
    revisedReducedThreeYearsAheadFedFlowThruDirects: Ember.computed('modifiedFedFlowThruTwoYearsAgoDirects', 'modifiedFedFlowThruOneYearAgoDirects', 'projectedCoreSuccessesCurrentYearFedFlowThruDirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruDirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.modifiedFedFlowThruTwoYearsAgoDirects, this.modifiedFedFlowThruOneYearAgoDirects, this.projectedCoreSuccessesCurrentYearFedFlowThruDirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruDirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects, this.year.fiscalYearThreeYearsAhead, true);
    }),
    revisedReducedThreeYearsAheadFedFlowThruIndirects: Ember.computed('modifiedFedFlowThruTwoYearsAgoIndirects', 'modifiedFedFlowThruOneYearAgoIndirects', 'projectedCoreSuccessesCurrentYearFedFlowThruIndirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruIndirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.modifiedFedFlowThruTwoYearsAgoIndirects, this.modifiedFedFlowThruOneYearAgoIndirects, this.projectedCoreSuccessesCurrentYearFedFlowThruIndirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruIndirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects, this.year.fiscalYearThreeYearsAhead, false);
    }),
    revisedReducedThreeYearsAheadOtherFedDirects: Ember.computed('model.projection.{successesTwoYearsAgoOtherFedDirects,successesOneYearAgoOtherFedDirects}', 'projectedCoreSuccessesCurrentYearOtherFedDirects', 'projectedCoreSuccessesOneYearAheadOtherFedDirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedDirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoOtherFedDirects'), this.get('model.projection.successesOneYearAgoOtherFedDirects'), this.projectedCoreSuccessesCurrentYearOtherFedDirects, this.projectedCoreSuccessesOneYearAheadOtherFedDirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedDirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedDirects, this.year.fiscalYearThreeYearsAhead, true);
    }),
    revisedReducedThreeYearsAheadOtherFedIndirects: Ember.computed('model.projection.{successesTwoYearsAgoOtherFedIndirects,successesOneYearAgoOtherFedIndirects}', 'projectedCoreSuccessesCurrentYearOtherFedIndirects', 'projectedCoreSuccessesOneYearAheadOtherFedIndirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedIndirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoOtherFedIndirects'), this.get('model.projection.successesOneYearAgoOtherFedIndirects'), this.projectedCoreSuccessesCurrentYearOtherFedIndirects, this.projectedCoreSuccessesOneYearAheadOtherFedIndirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedIndirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedIndirects, this.year.fiscalYearThreeYearsAhead, false);
    }),
    revisedReducedThreeYearsAheadNonFedDirects: Ember.computed('model.projection.{successesTwoYearsAgoNonFedDirects,successesOneYearAgoNonFedDirects}', 'projectedCoreSuccessesCurrentYearNonFedDirects', 'projectedCoreSuccessesOneYearAheadNonFedDirects', 'projectedCoreSuccessesTwoYearsAheadNonFedDirects', 'projectedCoreSuccessesThreeYearsAheadNonFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoNonFedDirects'), this.get('model.projection.successesOneYearAgoNonFedDirects'), this.projectedCoreSuccessesCurrentYearNonFedDirects, this.projectedCoreSuccessesOneYearAheadNonFedDirects, this.projectedCoreSuccessesTwoYearsAheadNonFedDirects, this.projectedCoreSuccessesThreeYearsAheadNonFedDirects, this.year.fiscalYearThreeYearsAhead, true);
    }),
    revisedReducedThreeYearsAheadNonFedIndirects: Ember.computed('model.projection.{successesTwoYearsAgoNonFedDirects,successesOneYearAgoNonFedDirects}', 'projectedCoreSuccessesCurrentYearNonFedIndirects', 'projectedCoreSuccessesOneYearAheadNonFedIndirects', 'projectedCoreSuccessesTwoYearsAheadNonFedIndirects', 'projectedCoreSuccessesThreeYearsAheadNonFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoNonFedIndirects'), this.get('model.projection.successesOneYearAgoNonFedIndirects'), this.projectedCoreSuccessesCurrentYearNonFedIndirects, this.projectedCoreSuccessesOneYearAheadNonFedIndirects, this.projectedCoreSuccessesTwoYearsAheadNonFedIndirects, this.projectedCoreSuccessesThreeYearsAheadNonFedIndirects, this.year.fiscalYearThreeYearsAhead, false);
    }),
    revisedReducedThreeYearsAheadSponsor5Directs: Ember.computed('model.projection.{successesTwoYearsAgoSponsor5Directs,successesOneYearAgoSponsor5Directs}', 'projectedCoreSuccessesCurrentYearSponsor5Directs', 'projectedCoreSuccessesOneYearAheadSponsor5Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor5Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor5Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoSponsor5Directs'), this.get('model.projection.successesOneYearAgoSponsor5Directs'), this.projectedCoreSuccessesCurrentYearSponsor5Directs, this.projectedCoreSuccessesOneYearAheadSponsor5Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor5Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor5Directs, this.year.fiscalYearThreeYearsAhead, true);
    }),
    revisedReducedThreeYearsAheadSponsor5Indirects: Ember.computed('model.projection.{successesTwoYearsAgoSponsor5Directs,successesOneYearAgoSponsor5Directs}', 'projectedCoreSuccessesCurrentYearSponsor5Indirects', 'projectedCoreSuccessesOneYearAheadSponsor5Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor5Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor5Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoSponsor5Indirects'), this.get('model.projection.successesOneYearAgoSponsor5Indirects'), this.projectedCoreSuccessesCurrentYearSponsor5Indirects, this.projectedCoreSuccessesOneYearAheadSponsor5Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor5Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor5Indirects, this.year.fiscalYearThreeYearsAhead, false);
    }),
    revisedReducedThreeYearsAheadSponsor6Directs: Ember.computed('model.projection.{successesTwoYearsAgoSponsor6Directs,successesOneYearAgoSponsor6Directs}', 'projectedCoreSuccessesCurrentYearSponsor6Directs', 'projectedCoreSuccessesOneYearAheadSponsor6Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor6Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor6Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoSponsor6Directs'), this.get('model.projection.successesOneYearAgoSponsor6Directs'), this.projectedCoreSuccessesCurrentYearSponsor6Directs, this.projectedCoreSuccessesOneYearAheadSponsor6Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor6Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor6Directs, this.year.fiscalYearThreeYearsAhead, true);
    }),
    revisedReducedThreeYearsAheadSponsor6Indirects: Ember.computed('model.projection.{successesTwoYearsAgoSponsor6Directs,successesOneYearAgoSponsor6Directs}', 'projectedCoreSuccessesCurrentYearSponsor6Indirects', 'projectedCoreSuccessesOneYearAheadSponsor6Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor6Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor6Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoSponsor6Indirects'), this.get('model.projection.successesOneYearAgoSponsor6Indirects'), this.projectedCoreSuccessesCurrentYearSponsor6Indirects, this.projectedCoreSuccessesOneYearAheadSponsor6Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor6Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor6Indirects, this.year.fiscalYearThreeYearsAhead, false);
    }),
    revisedReducedThreeYearsAheadSponsor7Directs: Ember.computed('model.projection.{successesTwoYearsAgoSponsor7Directs,successesOneYearAgoSponsor7Directs}', 'projectedCoreSuccessesCurrentYearSponsor7Directs', 'projectedCoreSuccessesOneYearAheadSponsor7Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor7Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor7Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoSponsor7Directs'), this.get('model.projection.successesOneYearAgoSponsor7Directs'), this.projectedCoreSuccessesCurrentYearSponsor7Directs, this.projectedCoreSuccessesOneYearAheadSponsor7Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor7Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor7Directs, this.year.fiscalYearThreeYearsAhead, true);
    }),
    revisedReducedThreeYearsAheadSponsor7Indirects: Ember.computed('model.projection.{successesTwoYearsAgoSponsor7Directs,successesOneYearAgoSponsor7Directs}', 'projectedCoreSuccessesCurrentYearSponsor7Indirects', 'projectedCoreSuccessesOneYearAheadSponsor7Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor7Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor7Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesTwoYearsAgoSponsor7Indirects'), this.get('model.projection.successesOneYearAgoSponsor7Indirects'), this.projectedCoreSuccessesCurrentYearSponsor7Indirects, this.projectedCoreSuccessesOneYearAheadSponsor7Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor7Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor7Indirects, this.year.fiscalYearThreeYearsAhead, false);
    }),
    revisedReducedThreeYearsAheadDirects: Ember.computed('revisedReducedThreeYearsAheadNihDirects', 'revisedReducedThreeYearsAheadOtherFedDirects', 'revisedReducedThreeYearsAheadFedFlowThruDirects', 'revisedReducedThreeYearsAheadNonFedDirects', 'revisedReducedThreeYearsAheadSponsor5Directs', 'revisedReducedThreeYearsAheadSponsor6Directs', 'revisedReducedThreeYearsAheadSponsor7Directs', 'model.projection.megaGrantTotalsThreeYearsAheadDirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearDirects,megaGrantFutureOneYearAheadDirects}', 'selectedSponsor', 'recoveryCoreThreeYearsAheadDirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsThreeYearsAheadNihDirects, model.megaGrantTotalsThreeYearsAheadFedFlowThruDirects, model.megaGrantTotalsThreeYearsAheadOtherFedDirects, model.megaGrantTotalsThreeYearsAheadNonFedDirects, model.megaGrantTotalsThreeYearsAheadSponsor5Directs, model.megaGrantTotalsThreeYearsAheadSponsor6Directs, model.megaGrantTotalsThreeYearsAheadSponsor7Directs); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(3, true, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearThreeYearsAhead, true);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedThreeYearsAheadNihDirects, this.revisedReducedThreeYearsAheadFedFlowThruDirects, this.revisedReducedThreeYearsAheadOtherFedDirects, this.revisedReducedThreeYearsAheadNonFedDirects, this.revisedReducedThreeYearsAheadSponsor5Directs, this.revisedReducedThreeYearsAheadSponsor6Directs, this.revisedReducedThreeYearsAheadSponsor7Directs) + this.recoveryCoreThreeYearsAheadDirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    revisedReducedThreeYearsAheadIndirects: Ember.computed('revisedThreeYearsAheadNihIndirects', 'revisedThreeYearsAheadOtherFedIndirects', 'revisedThreeYearsAheadFedFlowThruIndirects', 'revisedThreeYearsAheadNonFedIndirects', 'revisedThreeYearsAheadSponsor5Indirects', 'revisedThreeYearsAheadSponsor6Indirects', 'revisedThreeYearsAheadSponsor7Indirects', 'model.projection.megaGrantTotalsThreeYearsAheadIndirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearIndirects,megaGrantFutureOneYearAheadIndirects}', 'selectedSponsor', 'recoveryCoreThreeYearsAheadIndirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsThreeYearsAheadNihIndirects, model.megaGrantTotalsThreeYearsAheadFedFlowThruIndirects, model.megaGrantTotalsThreeYearsAheadOtherFedIndirects, model.megaGrantTotalsThreeYearsAheadNonFedIndirects, model.megaGrantTotalsThreeYearsAheadSponsor5Indirects, model.megaGrantTotalsThreeYearsAheadSponsor6Indirects, model.megaGrantTotalsThreeYearsAheadSponsor7Indirects); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(3, false, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearThreeYearsAhead, false);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedThreeYearsAheadNihIndirects, this.revisedReducedThreeYearsAheadFedFlowThruIndirects, this.revisedReducedThreeYearsAheadOtherFedIndirects, this.revisedReducedThreeYearsAheadNonFedIndirects, this.revisedReducedThreeYearsAheadSponsor5Indirects, this.revisedReducedThreeYearsAheadSponsor6Indirects, this.revisedReducedThreeYearsAheadSponsor7Indirects) + this.recoveryCoreThreeYearsAheadIndirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    //endregion
    //region Revised Four Years Ahead
    revisedReducedFourYearsAheadNihDirects: Ember.computed('model.projection.successesOneYearAgoNihDirects', 'projectedCoreSuccessesCurrentYearNihDirects', 'projectedCoreSuccessesOneYearAheadNihDirects', 'projectedCoreSuccessesTwoYearsAheadNihDirects', 'projectedCoreSuccessesThreeYearsAheadNihDirects', 'projectedCoreSuccessesFourYearsAheadNihDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoNihDirects'), this.projectedCoreSuccessesCurrentYearNihDirects, this.projectedCoreSuccessesOneYearAheadNihDirects, this.projectedCoreSuccessesTwoYearsAheadNihDirects, this.projectedCoreSuccessesThreeYearsAheadNihDirects, this.projectedCoreSuccessesFourYearsAheadNihDirects, this.year.fiscalYearFourYearsAhead, true);
    }),
    revisedReducedFourYearsAheadNihIndirects: Ember.computed('model.projection.successesOneYearAgoNihIndirects', 'projectedCoreSuccessesCurrentYearNihIndirects', 'projectedCoreSuccessesOneYearAheadNihIndirects', 'projectedCoreSuccessesTwoYearsAheadNihIndirects', 'projectedCoreSuccessesThreeYearsAheadNihIndirects', 'projectedCoreSuccessesFourYearsAheadNihIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoNihIndirects'), this.projectedCoreSuccessesCurrentYearNihIndirects, this.projectedCoreSuccessesOneYearAheadNihIndirects, this.projectedCoreSuccessesTwoYearsAheadNihIndirects, this.projectedCoreSuccessesThreeYearsAheadNihIndirects, this.projectedCoreSuccessesFourYearsAheadNihIndirects, this.year.fiscalYearFourYearsAhead, false);
    }),
    revisedReducedFourYearsAheadFedFlowThruDirects: Ember.computed('modifiedFedFlowThruOneYearAgoDirects', 'projectedCoreSuccessesCurrentYearFedFlowThruDirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruDirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesFourYearsAheadFedFlowThruDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.modifiedFedFlowThruOneYearAgoDirects, this.projectedCoreSuccessesCurrentYearFedFlowThruDirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruDirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesFourYearsAheadFedFlowThruDirects, this.year.fiscalYearFourYearsAhead, true);
    }),
    revisedReducedFourYearsAheadFedFlowThruIndirects: Ember.computed('modifiedFedFlowThruOneYearAgoIndirects', 'projectedCoreSuccessesCurrentYearFedFlowThruIndirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruIndirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesFourYearsAheadFedFlowThruIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.modifiedFedFlowThruOneYearAgoIndirects, this.projectedCoreSuccessesCurrentYearFedFlowThruIndirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruIndirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesFourYearsAheadFedFlowThruIndirects, this.year.fiscalYearFourYearsAhead, false);
    }),
    revisedReducedFourYearsAheadOtherFedDirects: Ember.computed('model.projection.successesOneYearAgoOtherFedDirects', 'projectedCoreSuccessesCurrentYearOtherFedDirects', 'projectedCoreSuccessesOneYearAheadOtherFedDirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedDirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedDirects', 'projectedCoreSuccessesFourYearsAheadOtherFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoOtherFedDirects'), this.projectedCoreSuccessesCurrentYearOtherFedDirects, this.projectedCoreSuccessesOneYearAheadOtherFedDirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedDirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedDirects, this.projectedCoreSuccessesFourYearsAheadOtherFedDirects, this.year.fiscalYearFourYearsAhead, true);
    }),
    revisedReducedFourYearsAheadOtherFedIndirects: Ember.computed('model.projection.successesOneYearAgoOtherFedIndirects', 'projectedCoreSuccessesCurrentYearOtherFedIndirects', 'projectedCoreSuccessesOneYearAheadOtherFedIndirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedIndirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedIndirects', 'projectedCoreSuccessesFourYearsAheadOtherFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoOtherFedIndirects'), this.projectedCoreSuccessesCurrentYearOtherFedIndirects, this.projectedCoreSuccessesOneYearAheadOtherFedIndirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedIndirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedIndirects, this.projectedCoreSuccessesFourYearsAheadOtherFedIndirects, this.year.fiscalYearFourYearsAhead, false);
    }),
    revisedReducedFourYearsAheadNonFedDirects: Ember.computed('model.projection.successesOneYearAgoNonFedDirects', 'projectedCoreSuccessesCurrentYearNonFedDirects', 'projectedCoreSuccessesOneYearAheadNonFedDirects', 'projectedCoreSuccessesTwoYearsAheadNonFedDirects', 'projectedCoreSuccessesThreeYearsAheadNonFedDirects', 'projectedCoreSuccessesFourYearsAheadNonFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoNonFedDirects'), this.projectedCoreSuccessesCurrentYearNonFedDirects, this.projectedCoreSuccessesOneYearAheadNonFedDirects, this.projectedCoreSuccessesTwoYearsAheadNonFedDirects, this.projectedCoreSuccessesThreeYearsAheadNonFedDirects, this.projectedCoreSuccessesFourYearsAheadNonFedDirects, this.year.fiscalYearFourYearsAhead, true);
    }),
    revisedReducedFourYearsAheadNonFedIndirects: Ember.computed('model.projection.successesOneYearAgoNonFedIndirects', 'projectedCoreSuccessesCurrentYearNonFedIndirects', 'projectedCoreSuccessesOneYearAheadNonFedIndirects', 'projectedCoreSuccessesTwoYearsAheadNonFedIndirects', 'projectedCoreSuccessesThreeYearsAheadNonFedIndirects', 'projectedCoreSuccessesFourYearsAheadNonFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoNonFedIndirects'), this.projectedCoreSuccessesCurrentYearNonFedIndirects, this.projectedCoreSuccessesOneYearAheadNonFedIndirects, this.projectedCoreSuccessesTwoYearsAheadNonFedIndirects, this.projectedCoreSuccessesThreeYearsAheadNonFedIndirects, this.projectedCoreSuccessesFourYearsAheadNonFedIndirects, this.year.fiscalYearFourYearsAhead, false);
    }),
    revisedReducedFourYearsAheadSponsor5Directs: Ember.computed('model.projection.successesOneYearAgoSponsor5Directs', 'projectedCoreSuccessesCurrentYearSponsor5Directs', 'projectedCoreSuccessesOneYearAheadSponsor5Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor5Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor5Directs', 'projectedCoreSuccessesFourYearsAheadSponsor5Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoSponsor5Directs'), this.projectedCoreSuccessesCurrentYearSponsor5Directs, this.projectedCoreSuccessesOneYearAheadSponsor5Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor5Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor5Directs, this.projectedCoreSuccessesFourYearsAheadSponsor5Directs, this.year.fiscalYearFourYearsAhead, true);
    }),
    revisedReducedFourYearsAheadSponsor5Indirects: Ember.computed('model.projection.successesOneYearAgoSponsor5Indirects', 'projectedCoreSuccessesCurrentYearSponsor5Indirects', 'projectedCoreSuccessesOneYearAheadSponsor5Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor5Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor5Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor5Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoSponsor5Indirects'), this.projectedCoreSuccessesCurrentYearSponsor5Indirects, this.projectedCoreSuccessesOneYearAheadSponsor5Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor5Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor5Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor5Indirects, this.year.fiscalYearFourYearsAhead, false);
    }),
    revisedReducedFourYearsAheadSponsor6Directs: Ember.computed('model.projection.successesOneYearAgoSponsor6Directs', 'projectedCoreSuccessesCurrentYearSponsor6Directs', 'projectedCoreSuccessesOneYearAheadSponsor6Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor6Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor6Directs', 'projectedCoreSuccessesFourYearsAheadSponsor6Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoSponsor6Directs'), this.projectedCoreSuccessesCurrentYearSponsor6Directs, this.projectedCoreSuccessesOneYearAheadSponsor6Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor6Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor6Directs, this.projectedCoreSuccessesFourYearsAheadSponsor6Directs, this.year.fiscalYearFourYearsAhead, true);
    }),
    revisedReducedFourYearsAheadSponsor6Indirects: Ember.computed('model.projection.successesOneYearAgoSponsor6Indirects', 'projectedCoreSuccessesCurrentYearSponsor6Indirects', 'projectedCoreSuccessesOneYearAheadSponsor6Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor6Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor6Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor6Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoSponsor6Indirects'), this.projectedCoreSuccessesCurrentYearSponsor6Indirects, this.projectedCoreSuccessesOneYearAheadSponsor6Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor6Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor6Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor6Indirects, this.year.fiscalYearFourYearsAhead, false);
    }),
    revisedReducedFourYearsAheadSponsor7Directs: Ember.computed('model.projection.successesOneYearAgoSponsor7Directs', 'projectedCoreSuccessesCurrentYearSponsor7Directs', 'projectedCoreSuccessesOneYearAheadSponsor7Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor7Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor7Directs', 'projectedCoreSuccessesFourYearsAheadSponsor7Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoSponsor7Directs'), this.projectedCoreSuccessesCurrentYearSponsor7Directs, this.projectedCoreSuccessesOneYearAheadSponsor7Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor7Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor7Directs, this.projectedCoreSuccessesFourYearsAheadSponsor7Directs, this.year.fiscalYearFourYearsAhead, true);
    }),
    revisedReducedFourYearsAheadSponsor7Indirects: Ember.computed('model.projection.successesOneYearAgoSponsor7Indirects', 'projectedCoreSuccessesCurrentYearSponsor7Indirects', 'projectedCoreSuccessesOneYearAheadSponsor7Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor7Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor7Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor7Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.get('model.projection.successesOneYearAgoSponsor7Indirects'), this.projectedCoreSuccessesCurrentYearSponsor7Indirects, this.projectedCoreSuccessesOneYearAheadSponsor7Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor7Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor7Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor7Indirects, this.year.fiscalYearFourYearsAhead, false);
    }),
    revisedReducedFourYearsAheadDirects: Ember.computed('revisedReducedFourYearsAheadNihDirects', 'revisedReducedFourYearsAheadOtherFedDirects', 'revisedReducedFourYearsAheadFedFlowThruDirects', 'revisedReducedFourYearsAheadNonFedDirects', 'revisedReducedFourYearsAheadSponsor5Directs', 'revisedReducedFourYearsAheadSponsor6Directs', 'revisedReducedFourYearsAheadSponsor7Directs', 'model.projection.megaGrantTotalsFourYearsAheadDirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearDirects,megaGrantFutureOneYearAheadDirects}', 'selectedSponsor', 'recoveryCoreFourYearsAheadDirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.model.projection.megaGrantTotalsFourYearsAheadNihDirects, this.model.projection.megaGrantTotalsFourYearsAheadFedFlowThruDirects, this.model.projection.megaGrantTotalsFourYearsAheadOtherFedDirects, this.model.projection.megaGrantTotalsFourYearsAheadNonFedDirects, this.model.projection.megaGrantTotalsFourYearsAheadSponsor5Directs, this.model.projection.megaGrantTotalsFourYearsAheadSponsor6Directs, this.model.projection.megaGrantTotalsFourYearsAheadSponsor7Directs); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(4, true, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearFourYearsAhead, true);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedFourYearsAheadNihDirects, this.revisedReducedFourYearsAheadFedFlowThruDirects, this.revisedReducedFourYearsAheadOtherFedDirects, this.revisedReducedFourYearsAheadNonFedDirects, this.revisedReducedFourYearsAheadSponsor5Directs, this.revisedReducedFourYearsAheadSponsor6Directs, this.revisedReducedFourYearsAheadSponsor7Directs) + this.recoveryCoreFourYearsAheadDirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    revisedReducedFourYearsAheadIndirects: Ember.computed('revisedReducedFourYearsAheadNihIndirects', 'revisedReducedFourYearsAheadOtherFedIndirects', 'revisedReducedFourYearsAheadFedFlowThruIndirects', 'revisedReducedFourYearsAheadNonFedIndirects', 'revisedReducedFourYearsAheadSponsor5Indirects', 'revisedReducedFourYearsAheadSponsor6Indirects', 'revisedReducedFourYearsAheadSponsor7Indirects', 'model.projection.megaGrantTotalsFourYearsAheadIndirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearIndirects,megaGrantFutureOneYearAheadIndirects}', 'selectedSponsor', 'recoveryCoreFourYearsAheadIndirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsFourYearsAheadNihIndirects, model.megaGrantTotalsFourYearsAheadFedFlowThruIndirects, model.megaGrantTotalsFourYearsAheadOtherFedIndirects, model.megaGrantTotalsFourYearsAheadNonFedIndirects, model.megaGrantTotalsFourYearsAheadSponsor5Indirects, model.megaGrantTotalsFourYearsAheadSponsor6Indirects, model.megaGrantTotalsFourYearsAheadSponsor7Indirects); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(4, false, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearFourYearsAhead, false);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedFourYearsAheadNihIndirects, this.revisedReducedFourYearsAheadFedFlowThruIndirects, this.revisedReducedFourYearsAheadOtherFedIndirects, this.revisedReducedFourYearsAheadNonFedIndirects, this.revisedReducedFourYearsAheadSponsor5Indirects, this.revisedReducedFourYearsAheadSponsor6Indirects, this.revisedReducedFourYearsAheadSponsor7Indirects) + this.recoveryCoreFourYearsAheadIndirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    //region Revised Five Years Ahead
    revisedReducedFiveYearsAheadNihDirects: Ember.computed('projectedCoreSuccessesCurrentYearNihDirects', 'projectedCoreSuccessesOneYearAheadNihDirects', 'projectedCoreSuccessesTwoYearsAheadNihDirects', 'projectedCoreSuccessesThreeYearsAheadNihDirects', 'projectedCoreSuccessesFourYearsAheadNihDirects', 'projectedCoreSuccessesFiveYearsAheadNihDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearNihDirects, this.projectedCoreSuccessesOneYearAheadNihDirects, this.projectedCoreSuccessesTwoYearsAheadNihDirects, this.projectedCoreSuccessesThreeYearsAheadNihDirects, this.projectedCoreSuccessesFourYearsAheadNihDirects, this.projectedCoreSuccessesFiveYearsAheadNihDirects, this.year.fiscalYearFiveYearsAhead, true);
    }),
    revisedReducedFiveYearsAheadNihIndirects: Ember.computed('projectedCoreSuccessesCurrentYearNihIndirects', 'projectedCoreSuccessesOneYearAheadNihIndirects', 'projectedCoreSuccessesTwoYearsAheadNihIndirects', 'projectedCoreSuccessesThreeYearsAheadNihIndirects', 'projectedCoreSuccessesFourYearsAheadNihIndirects', 'projectedCoreSuccessesFiveYearsAheadNihIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearNihIndirects, this.projectedCoreSuccessesOneYearAheadNihIndirects, this.projectedCoreSuccessesTwoYearsAheadNihIndirects, this.projectedCoreSuccessesThreeYearsAheadNihIndirects, this.projectedCoreSuccessesFourYearsAheadNihIndirects, this.projectedCoreSuccessesFiveYearsAheadNihIndirects, this.year.fiscalYearFiveYearsAhead, false);
    }),
    revisedReducedFiveYearsAheadFedFlowThruDirects: Ember.computed('projectedCoreSuccessesCurrentYearFedFlowThruDirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruDirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesFourYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesFiveYearsAheadFedFlowThruDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearFedFlowThruDirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruDirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesFourYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesFiveYearsAheadFedFlowThruDirects, this.year.fiscalYearFiveYearsAhead, true);
    }),
    revisedReducedFiveYearsAheadFedFlowThruIndirects: Ember.computed('projectedCoreSuccessesCurrentYearFedFlowThruIndirects', 'projectedCoreSuccessesOneYearAheadFedFlowThruIndirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesFourYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesFiveYearsAheadFedFlowThruIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearFedFlowThruIndirects, this.projectedCoreSuccessesOneYearAheadFedFlowThruIndirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesFourYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesFiveYearsAheadFedFlowThruIndirects, this.year.fiscalYearFiveYearsAhead, false);
    }),
    revisedReducedFiveYearsAheadOtherFedDirects: Ember.computed('projectedCoreSuccessesCurrentYearOtherFedDirects', 'projectedCoreSuccessesOneYearAheadOtherFedDirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedDirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedDirects', 'projectedCoreSuccessesFourYearsAheadOtherFedDirects', 'projectedCoreSuccessesFiveYearsAheadOtherFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearOtherFedDirects, this.projectedCoreSuccessesOneYearAheadOtherFedDirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedDirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedDirects, this.projectedCoreSuccessesFourYearsAheadOtherFedDirects, this.projectedCoreSuccessesFiveYearsAheadOtherFedDirects, this.year.fiscalYearFiveYearsAhead, true);
    }),
    revisedReducedFiveYearsAheadOtherFedIndirects: Ember.computed('projectedCoreSuccessesCurrentYearOtherFedIndirects', 'projectedCoreSuccessesOneYearAheadOtherFedIndirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedIndirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedIndirects', 'projectedCoreSuccessesFourYearsAheadOtherFedIndirects', 'projectedCoreSuccessesFiveYearsAheadOtherFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearOtherFedIndirects, this.projectedCoreSuccessesOneYearAheadOtherFedIndirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedIndirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedIndirects, this.projectedCoreSuccessesFourYearsAheadOtherFedIndirects, this.projectedCoreSuccessesFiveYearsAheadOtherFedIndirects, this.year.fiscalYearFiveYearsAhead, false);
    }),
    revisedReducedFiveYearsAheadNonFedDirects: Ember.computed('projectedCoreSuccessesCurrentYearNonFedDirects', 'projectedCoreSuccessesOneYearAheadNonFedDirects', 'projectedCoreSuccessesTwoYearsAheadNonFedDirects', 'projectedCoreSuccessesThreeYearsAheadNonFedDirects', 'projectedCoreSuccessesFourYearsAheadNonFedDirects', 'projectedCoreSuccessesFiveYearsAheadNonFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearNonFedDirects, this.projectedCoreSuccessesOneYearAheadNonFedDirects, this.projectedCoreSuccessesTwoYearsAheadNonFedDirects, this.projectedCoreSuccessesThreeYearsAheadNonFedDirects, this.projectedCoreSuccessesFourYearsAheadNonFedDirects, this.projectedCoreSuccessesFiveYearsAheadNonFedDirects, this.year.fiscalYearFiveYearsAhead, true);
    }),
    revisedReducedFiveYearsAheadNonFedIndirects: Ember.computed('projectedCoreSuccessesCurrentYearNonFedIndirects', 'projectedCoreSuccessesOneYearAheadNonFedIndirects', 'projectedCoreSuccessesTwoYearsAheadNonFedIndirects', 'projectedCoreSuccessesThreeYearsAheadNonFedIndirects', 'projectedCoreSuccessesFourYearsAheadNonFedIndirects', 'projectedCoreSuccessesFiveYearsAheadNonFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearNonFedIndirects, this.projectedCoreSuccessesOneYearAheadNonFedIndirects, this.projectedCoreSuccessesTwoYearsAheadNonFedIndirects, this.projectedCoreSuccessesThreeYearsAheadNonFedIndirects, this.projectedCoreSuccessesFourYearsAheadNonFedIndirects, this.projectedCoreSuccessesFiveYearsAheadNonFedIndirects, this.year.fiscalYearFiveYearsAhead, false);
    }),
    revisedReducedFiveYearsAheadSponsor5Directs: Ember.computed('projectedCoreSuccessesCurrentYearSponsor5Directs', 'projectedCoreSuccessesOneYearAheadSponsor5Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor5Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor5Directs', 'projectedCoreSuccessesFourYearsAheadSponsor5Directs', 'projectedCoreSuccessesFiveYearsAheadSponsor5Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearSponsor5Directs, this.projectedCoreSuccessesOneYearAheadSponsor5Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor5Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor5Directs, this.projectedCoreSuccessesFourYearsAheadSponsor5Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor5Directs, this.year.fiscalYearFiveYearsAhead, true);
    }),
    revisedReducedFiveYearsAheadSponsor5Indirects: Ember.computed('projectedCoreSuccessesCurrentYearSponsor5Indirects', 'projectedCoreSuccessesOneYearAheadSponsor5Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor5Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor5Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor5Indirects', 'projectedCoreSuccessesFiveYearsAheadSponsor5Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearSponsor5Indirects, this.projectedCoreSuccessesOneYearAheadSponsor5Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor5Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor5Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor5Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor5Indirects, this.year.fiscalYearFiveYearsAhead, false);
    }),
    revisedReducedFiveYearsAheadSponsor6Directs: Ember.computed('projectedCoreSuccessesCurrentYearSponsor6Directs', 'projectedCoreSuccessesOneYearAheadSponsor6Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor6Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor6Directs', 'projectedCoreSuccessesFourYearsAheadSponsor6Directs', 'projectedCoreSuccessesFiveYearsAheadSponsor6Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearSponsor6Directs, this.projectedCoreSuccessesOneYearAheadSponsor6Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor6Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor6Directs, this.projectedCoreSuccessesFourYearsAheadSponsor6Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor6Directs, this.year.fiscalYearFiveYearsAhead, true);
    }),
    revisedReducedFiveYearsAheadSponsor6Indirects: Ember.computed('projectedCoreSuccessesCurrentYearSponsor6Indirects', 'projectedCoreSuccessesOneYearAheadSponsor6Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor6Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor6Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor6Indirects', 'projectedCoreSuccessesFiveYearsAheadSponsor6Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearSponsor6Indirects, this.projectedCoreSuccessesOneYearAheadSponsor6Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor6Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor6Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor6Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor6Indirects, this.year.fiscalYearFiveYearsAhead, false);
    }),
    revisedReducedFiveYearsAheadSponsor7Directs: Ember.computed('projectedCoreSuccessesCurrentYearSponsor7Directs', 'projectedCoreSuccessesOneYearAheadSponsor7Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor7Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor7Directs', 'projectedCoreSuccessesFourYearsAheadSponsor7Directs', 'projectedCoreSuccessesFiveYearsAheadSponsor7Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearSponsor7Directs, this.projectedCoreSuccessesOneYearAheadSponsor7Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor7Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor7Directs, this.projectedCoreSuccessesFourYearsAheadSponsor7Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor7Directs, this.year.fiscalYearFiveYearsAhead, true);
    }),
    revisedReducedFiveYearsAheadSponsor7Indirects: Ember.computed('projectedCoreSuccessesCurrentYearSponsor7Indirects', 'projectedCoreSuccessesOneYearAheadSponsor7Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor7Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor7Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor7Indirects', 'projectedCoreSuccessesFiveYearsAheadSponsor7Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesCurrentYearSponsor7Indirects, this.projectedCoreSuccessesOneYearAheadSponsor7Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor7Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor7Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor7Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor7Indirects, this.year.fiscalYearFiveYearsAhead, false);
    }),
    revisedReducedFiveYearsAheadDirects: Ember.computed('revisedReducedFiveYearsAheadNihDirects', 'revisedReducedFiveYearsAheadOtherFedDirects', 'revisedReducedFiveYearsAheadFedFlowThruDirects', 'revisedReducedFiveYearsAheadNonFedDirects', 'revisedReducedFiveYearsAheadSponsor5Directs', 'revisedReducedFiveYearsAheadSponsor6Directs', 'revisedReducedFiveYearsAheadSponsor7Directs', 'model.projection.megaGrantTotalsFiveYearsAheadDirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearDirects,megaGrantFutureOneYearAheadDirects}', 'selectedSponsor', 'recoveryCoreFiveYearsAheadDirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.model.projection.megaGrantTotalsFiveYearsAheadNihDirects, this.model.projection.megaGrantTotalsFiveYearsAheadFedFlowThruDirects, this.model.projection.megaGrantTotalsFiveYearsAheadOtherFedDirects, this.model.projection.megaGrantTotalsFiveYearsAheadNonFedDirects, this.model.projection.megaGrantTotalsFiveYearsAheadSponsor5Directs, this.model.projection.megaGrantTotalsFiveYearsAheadSponsor6Directs, this.model.projection.megaGrantTotalsFiveYearsAheadSponsor7Directs); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(5, true, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearFiveYearsAhead, true);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedFiveYearsAheadNihDirects, this.revisedReducedFiveYearsAheadFedFlowThruDirects, this.revisedReducedFiveYearsAheadOtherFedDirects, this.revisedReducedFiveYearsAheadNonFedDirects, this.revisedReducedFiveYearsAheadSponsor5Directs, this.revisedReducedFiveYearsAheadSponsor6Directs, this.revisedReducedFiveYearsAheadSponsor7Directs) + this.recoveryCoreFiveYearsAheadDirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    revisedReducedFiveYearsAheadIndirects: Ember.computed('revisedReducedFiveYearsAheadNihIndirects', 'revisedReducedFiveYearsAheadOtherFedIndirects', 'revisedReducedFiveYearsAheadFedFlowThruIndirects', 'revisedReducedFiveYearsAheadNonFedIndirects', 'revisedReducedFiveYearsAheadSponsor5Indirects', 'revisedReducedFiveYearsAheadSponsor6Indirects', 'revisedReducedFiveYearsAheadSponsor7Indirects', 'model.projection.megaGrantTotalsFiveYearsAheadDirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearIndirects,megaGrantFutureOneYearAheadIndirects}', 'selectedSponsor', 'recoveryCoreFiveYearsAheadIndirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsFiveYearsAheadNihIndirects, model.megaGrantTotalsFiveYearsAheadFedFlowThruIndirects, model.megaGrantTotalsFiveYearsAheadOtherFedIndirects, model.megaGrantTotalsFiveYearsAheadNonFedIndirects, model.megaGrantTotalsFiveYearsAheadSponsor5Indirects, model.megaGrantTotalsFiveYearsAheadSponsor6Indirects, model.megaGrantTotalsFiveYearsAheadSponsor7Indirects); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(5, false, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearFiveYearsAhead, false);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedFiveYearsAheadNihIndirects, this.revisedReducedFiveYearsAheadFedFlowThruIndirects, this.revisedReducedFiveYearsAheadOtherFedIndirects, this.revisedReducedFiveYearsAheadNonFedIndirects, this.revisedReducedFiveYearsAheadSponsor5Indirects, this.revisedReducedFiveYearsAheadSponsor6Indirects, this.revisedReducedFiveYearsAheadSponsor7Indirects) + this.recoveryCoreFiveYearsAheadIndirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    //endregion
    //region Revised Six Years Ahead
    revisedReducedSixYearsAheadNihDirects: Ember.computed('projectedCoreSuccessesOneYearAheadNihDirects', 'projectedCoreSuccessesTwoYearsAheadNihDirects', 'projectedCoreSuccessesThreeYearsAheadNihDirects', 'projectedCoreSuccessesFourYearsAheadNihDirects', 'projectedCoreSuccessesFiveYearsAheadNihDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadNihDirects, this.projectedCoreSuccessesTwoYearsAheadNihDirects, this.projectedCoreSuccessesThreeYearsAheadNihDirects, this.projectedCoreSuccessesFourYearsAheadNihDirects, this.projectedCoreSuccessesFiveYearsAheadNihDirects, this.projectedCoreSuccessesFiveYearsAheadNihDirects);
    }),
    revisedReducedSixYearsAheadNihIndirects: Ember.computed('projectedCoreSuccessesOneYearAheadNihIndirects', 'projectedCoreSuccessesTwoYearsAheadNihIndirects', 'projectedCoreSuccessesThreeYearsAheadNihIndirects', 'projectedCoreSuccessesFourYearsAheadNihIndirects', 'projectedCoreSuccessesFiveYearsAheadNihIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadNihIndirects, this.projectedCoreSuccessesTwoYearsAheadNihIndirects, this.projectedCoreSuccessesThreeYearsAheadNihIndirects, this.projectedCoreSuccessesFourYearsAheadNihIndirects, this.projectedCoreSuccessesFiveYearsAheadNihIndirects, this.projectedCoreSuccessesFiveYearsAheadNihIndirects);
    }),
    revisedReducedSixYearsAheadFedFlowThruDirects: Ember.computed('projectedCoreSuccessesOneYearAheadFedFlowThruDirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesFourYearsAheadFedFlowThruDirects', 'projectedCoreSuccessesFiveYearsAheadFedFlowThruDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadFedFlowThruDirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesFourYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesFiveYearsAheadFedFlowThruDirects, this.projectedCoreSuccessesFiveYearsAheadFedFlowThruDirects);
    }),
    revisedReducedSixYearsAheadFedFlowThruIndirects: Ember.computed('projectedCoreSuccessesOneYearAheadFedFlowThruIndirects', 'projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesFourYearsAheadFedFlowThruIndirects', 'projectedCoreSuccessesFiveYearsAheadFedFlowThruIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadFedFlowThruIndirects, this.projectedCoreSuccessesTwoYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesThreeYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesFourYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesFiveYearsAheadFedFlowThruIndirects, this.projectedCoreSuccessesFiveYearsAheadFedFlowThruIndirects);
    }),
    revisedReducedSixYearsAheadOtherFedDirects: Ember.computed('projectedCoreSuccessesOneYearAheadOtherFedDirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedDirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedDirects', 'projectedCoreSuccessesFourYearsAheadOtherFedDirects', 'projectedCoreSuccessesFiveYearsAheadOtherFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadOtherFedDirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedDirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedDirects, this.projectedCoreSuccessesFourYearsAheadOtherFedDirects, this.projectedCoreSuccessesFiveYearsAheadOtherFedDirects, this.projectedCoreSuccessesFiveYearsAheadOtherFedDirects);
    }),
    revisedReducedSixYearsAheadOtherFedIndirects: Ember.computed('projectedCoreSuccessesOneYearAheadOtherFedIndirects', 'projectedCoreSuccessesTwoYearsAheadOtherFedIndirects', 'projectedCoreSuccessesThreeYearsAheadOtherFedIndirects', 'projectedCoreSuccessesFourYearsAheadOtherFedIndirects', 'projectedCoreSuccessesFiveYearsAheadOtherFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadOtherFedIndirects, this.projectedCoreSuccessesTwoYearsAheadOtherFedIndirects, this.projectedCoreSuccessesThreeYearsAheadOtherFedIndirects, this.projectedCoreSuccessesFourYearsAheadOtherFedIndirects, this.projectedCoreSuccessesFiveYearsAheadOtherFedIndirects, this.projectedCoreSuccessesFiveYearsAheadOtherFedIndirects);
    }),
    revisedReducedSixYearsAheadNonFedDirects: Ember.computed('projectedCoreSuccessesOneYearAheadNonFedDirects', 'projectedCoreSuccessesTwoYearsAheadNonFedDirects', 'projectedCoreSuccessesThreeYearsAheadNonFedDirects', 'projectedCoreSuccessesFourYearsAheadNonFedDirects', 'projectedCoreSuccessesFiveYearsAheadNonFedDirects', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadNonFedDirects, this.projectedCoreSuccessesTwoYearsAheadNonFedDirects, this.projectedCoreSuccessesThreeYearsAheadNonFedDirects, this.projectedCoreSuccessesFourYearsAheadNonFedDirects, this.projectedCoreSuccessesFiveYearsAheadNonFedDirects, this.projectedCoreSuccessesFiveYearsAheadNonFedDirects);
    }),
    revisedReducedSixYearsAheadNonFedIndirects: Ember.computed('projectedCoreSuccessesOneYearAheadNonFedIndirects', 'projectedCoreSuccessesTwoYearsAheadNonFedIndirects', 'projectedCoreSuccessesThreeYearsAheadNonFedIndirects', 'projectedCoreSuccessesFourYearsAheadNonFedIndirects', 'projectedCoreSuccessesFiveYearsAheadNonFedIndirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadNonFedIndirects, this.projectedCoreSuccessesTwoYearsAheadNonFedIndirects, this.projectedCoreSuccessesThreeYearsAheadNonFedIndirects, this.projectedCoreSuccessesFourYearsAheadNonFedIndirects, this.projectedCoreSuccessesFiveYearsAheadNonFedIndirects, this.projectedCoreSuccessesFiveYearsAheadNonFedIndirects);
    }),
    revisedReducedSixYearsAheadSponsor5Directs: Ember.computed('projectedCoreSuccessesOneYearAheadSponsor5Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor5Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor5Directs', 'projectedCoreSuccessesFourYearsAheadSponsor5Directs', 'projectedCoreSuccessesFiveYearsAheadSponsor5Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadSponsor5Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor5Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor5Directs, this.projectedCoreSuccessesFourYearsAheadSponsor5Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor5Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor5Directs);
    }),
    revisedReducedSixYearsAheadSponsor5Indirects: Ember.computed('projectedCoreSuccessesOneYearAheadSponsor5Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor5Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor5Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor5Indirects', 'projectedCoreSuccessesFiveYearsAheadSponsor5Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadSponsor5Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor5Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor5Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor5Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor5Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor5Indirects);
    }),
    revisedReducedSixYearsAheadSponsor6Directs: Ember.computed('projectedCoreSuccessesOneYearAheadSponsor6Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor6Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor6Directs', 'projectedCoreSuccessesFourYearsAheadSponsor6Directs', 'projectedCoreSuccessesFiveYearsAheadSponsor6Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadSponsor6Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor6Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor6Directs, this.projectedCoreSuccessesFourYearsAheadSponsor6Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor6Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor6Directs);
    }),
    revisedReducedSixYearsAheadSponsor6Indirects: Ember.computed('projectedCoreSuccessesOneYearAheadSponsor6Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor6Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor6Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor6Indirects', 'projectedCoreSuccessesFiveYearsAheadSponsor6Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadSponsor6Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor6Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor6Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor6Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor6Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor6Indirects);
    }),
    revisedReducedSixYearsAheadSponsor7Directs: Ember.computed('projectedCoreSuccessesOneYearAheadSponsor7Directs', 'projectedCoreSuccessesTwoYearsAheadSponsor7Directs', 'projectedCoreSuccessesThreeYearsAheadSponsor7Directs', 'projectedCoreSuccessesFourYearsAheadSponsor7Directs', 'projectedCoreSuccessesFiveYearsAheadSponsor7Directs', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadSponsor7Directs, this.projectedCoreSuccessesTwoYearsAheadSponsor7Directs, this.projectedCoreSuccessesThreeYearsAheadSponsor7Directs, this.projectedCoreSuccessesFourYearsAheadSponsor7Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor7Directs, this.projectedCoreSuccessesFiveYearsAheadSponsor7Directs);
    }),
    revisedReducedSixYearsAheadSponsor7Indirects: Ember.computed('projectedCoreSuccessesOneYearAheadSponsor7Indirects', 'projectedCoreSuccessesTwoYearsAheadSponsor7Indirects', 'projectedCoreSuccessesThreeYearsAheadSponsor7Indirects', 'projectedCoreSuccessesFourYearsAheadSponsor7Indirects', 'projectedCoreSuccessesFiveYearsAheadSponsor7Indirects', 'phase1ReductionsIndirects', 'phase2ReductionsIndirects', 'phase3ReductionsIndirects', 'phaseRanges', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.reductionProjectedSpending(wad, this.projectedCoreSuccessesOneYearAheadSponsor7Indirects, this.projectedCoreSuccessesTwoYearsAheadSponsor7Indirects, this.projectedCoreSuccessesThreeYearsAheadSponsor7Indirects, this.projectedCoreSuccessesFourYearsAheadSponsor7Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor7Indirects, this.projectedCoreSuccessesFiveYearsAheadSponsor7Indirects);
    }),
    revisedReducedSixYearsAheadDirects: Ember.computed('revisedReducedSixYearsAheadNihDirects', 'revisedReducedSixYearsAheadOtherFedDirects', 'revisedReducedSixYearsAheadFedFlowThruDirects', 'revisedReducedSixYearsAheadNonFedDirects', 'revisedReducedSixYearsAheadSponsor5Directs', 'revisedReducedSixYearsAheadSponsor6Directs', 'revisedReducedSixYearsAheadSponsor7Directs', 'model.projection.megaGrantTotalsFiveYearsAheadDirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearDirects,megaGrantFutureOneYearAheadDirects}', 'selectedSponsor', 'recoveryCoreSixYearsAheadDirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsFiveYearsAheadNihDirects, model.megaGrantTotalsFiveYearsAheadFedFlowThruDirects, model.megaGrantTotalsFiveYearsAheadOtherFedDirects, model.megaGrantTotalsFiveYearsAheadNonFedDirects, model.megaGrantTotalsFiveYearsAheadSponsor5Directs, model.megaGrantTotalsFiveYearsAheadSponsor6Directs, model.megaGrantTotalsFiveYearsAheadSponsor7Directs); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(6, true, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearSixYearsAhead, true);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedSixYearsAheadNihDirects, this.revisedReducedSixYearsAheadFedFlowThruDirects, this.revisedReducedSixYearsAheadOtherFedDirects, this.revisedReducedSixYearsAheadNonFedDirects, this.revisedReducedSixYearsAheadSponsor5Directs, this.revisedReducedSixYearsAheadSponsor6Directs, this.revisedReducedSixYearsAheadSponsor7Directs) + this.recoveryCoreSixYearsAheadDirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    revisedReducedSixYearsAheadIndirects: Ember.computed('revisedReducedSixYearsAheadNihIndirects', 'revisedReducedSixYearsAheadOtherFedIndirects', 'revisedReducedSixYearsAheadFedFlowThruIndirects', 'revisedReducedSixYearsAheadNonFedIndirects', 'revisedReducedSixYearsAheadSponsor5Indirects', 'revisedReducedSixYearsAheadSponsor6Indirects', 'revisedReducedSixYearsAheadSponsor7Indirects', 'model.projection.megaGrantTotalsFiveYearsAheadIndirects', 'selectedGrantType', 'currentUser.team.{megaGrantFutureCurrentYearIndirects,megaGrantFutureOneYearAheadIndirects}', 'model.projection', 'selectedSponsor', 'recoveryCoreSixYearsAheadIndirects', function () {
      var model = this.model.projection;
      var mega = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, model.megaGrantTotalsFiveYearsAheadNihIndirects, model.megaGrantTotalsFiveYearsAheadFedFlowThruIndirects, model.megaGrantTotalsFiveYearsAheadOtherFedIndirects, model.megaGrantTotalsFiveYearsAheadNonFedIndirects, model.megaGrantTotalsFiveYearsAheadSponsor5Indirects, model.megaGrantTotalsFiveYearsAheadSponsor6Indirects, model.megaGrantTotalsFiveYearsAheadSponsor7Indirects); //Only apply future mega projection to entire school projection

      if (model.get('sector') === null && model.get('department.id') === undefined && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        mega += (0, _futureMegaProjection.default)(6, false, this.get('currentUser.team'));
      }

      mega = this.reductionMegaProjectedSpending(mega, this.year.fiscalYearSixYearsAhead, false);
      var core = this.calculateTotalsFromSponsorSelection(this.selectedSponsor, this.revisedReducedSixYearsAheadNihIndirects, this.revisedReducedSixYearsAheadFedFlowThruIndirects, this.revisedReducedSixYearsAheadOtherFedIndirects, this.revisedReducedSixYearsAheadNonFedIndirects, this.revisedReducedSixYearsAheadSponsor5Indirects, this.revisedReducedSixYearsAheadSponsor6Indirects, this.revisedReducedSixYearsAheadSponsor7Indirects) + this.recoveryCoreSixYearsAheadIndirects;
      var setting = this.selectedGrantType;
      return this.revisedTotal(core, mega, setting);
    }),
    //endregion
    //region Recovery
    recoveryCoreCurrentYearDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsDirects', 'phase1RecoveryDirects', 'phase2ReductionsDirects', 'phase2RecoveryDirects', 'phaseRanges', 'phase3ReductionsDirects', 'phase3RecoveryDirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(0, nihWad, this.currentUser.sponsorTypeNih, true).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(0, fftWad, this.currentUser.sponsorTypeFFT, true).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(0, otherFedWad, this.currentUser.sponsorTypeOtherFed, true).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(0, nonFedwad, this.currentUser.sponsorTypeNonFed, true).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(0, sponsor5wad, this.currentUser.sponsorTypeSponsor5, true).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(0, sponsor6wad, this.currentUser.sponsorTypeSponsor6, true).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(0, sponsor7wad, this.currentUser.sponsorTypeSponsor7, true).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreCurrentYearIndirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsIndirects', 'phase1RecoveryIndirects', 'phase2ReductionsIndirects', 'phase2RecoveryIndirects', 'phaseRanges', 'phase3ReductionsIndirects', 'phase3RecoveryIndirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(0, nihWad, this.currentUser.sponsorTypeNih, false).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(0, fftWad, this.currentUser.sponsorTypeFFT, false).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(0, otherFedWad, this.currentUser.sponsorTypeOtherFed, false).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(0, nonFedwad, this.currentUser.sponsorTypeNonFed, false).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(0, sponsor5wad, this.currentUser.sponsorTypeSponsor5, false).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(0, sponsor6wad, this.currentUser.sponsorTypeSponsor6, false).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(0, sponsor7wad, this.currentUser.sponsorTypeSponsor7, false).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreOneYearAheadDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsDirects', 'phase1RecoveryDirects', 'phase2ReductionsDirects', 'phase2RecoveryDirects', 'phaseRanges', 'phase3ReductionsDirects', 'phase3RecoveryDirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(1, nihWad, this.currentUser.sponsorTypeNih, true).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(1, fftWad, this.currentUser.sponsorTypeFFT, true).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(1, otherFedWad, this.currentUser.sponsorTypeOtherFed, true).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(1, nonFedwad, this.currentUser.sponsorTypeNonFed, true).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(1, sponsor5wad, this.currentUser.sponsorTypeSponsor5, true).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(1, sponsor6wad, this.currentUser.sponsorTypeSponsor6, true).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(1, sponsor7wad, this.currentUser.sponsorTypeSponsor7, true).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreOneYearAheadIndirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsIndirects', 'phase1RecoveryIndirects', 'phase2ReductionsIndirects', 'phase2RecoveryIndirects', 'phaseRanges', 'phase3ReductionsIndirects', 'phase3RecoveryIndirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(1, nihWad, this.currentUser.sponsorTypeNih, false).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(1, fftWad, this.currentUser.sponsorTypeFFT, false).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(1, otherFedWad, this.currentUser.sponsorTypeOtherFed, false).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(1, nonFedwad, this.currentUser.sponsorTypeNonFed, false).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(1, sponsor5wad, this.currentUser.sponsorTypeSponsor5, false).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(1, sponsor6wad, this.currentUser.sponsorTypeSponsor6, false).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(1, sponsor7wad, this.currentUser.sponsorTypeSponsor7, false).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreTwoYearsAheadDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsDirects', 'phase1RecoveryDirects', 'phase2ReductionsDirects', 'phase2RecoveryDirects', 'phaseRanges', 'phase3ReductionsDirects', 'phase3RecoveryDirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(2, nihWad, this.currentUser.sponsorTypeNih, true).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(2, fftWad, this.currentUser.sponsorTypeFFT, true).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(2, otherFedWad, this.currentUser.sponsorTypeOtherFed, true).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(2, nonFedwad, this.currentUser.sponsorTypeNonFed, true).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(2, sponsor5wad, this.currentUser.sponsorTypeSponsor5, true).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(2, sponsor6wad, this.currentUser.sponsorTypeSponsor6, true).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(2, sponsor7wad, this.currentUser.sponsorTypeSponsor7, true).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreTwoYearsAheadIndirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsIndirects', 'phase1RecoveryIndirects', 'phase2ReductionsIndirects', 'phase2RecoveryIndirects', 'phaseRanges', 'phase3ReductionsIndirects', 'phase3RecoveryIndirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(2, nihWad, this.currentUser.sponsorTypeNih, false).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(2, fftWad, this.currentUser.sponsorTypeFFT, false).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(2, otherFedWad, this.currentUser.sponsorTypeOtherFed, false).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(2, nonFedwad, this.currentUser.sponsorTypeNonFed, false).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(2, sponsor5wad, this.currentUser.sponsorTypeSponsor5, false).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(2, sponsor6wad, this.currentUser.sponsorTypeSponsor6, false).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(2, sponsor7wad, this.currentUser.sponsorTypeSponsor7, false).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
      s;
    }),
    recoveryCoreThreeYearsAheadDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsDirects', 'phase1RecoveryDirects', 'phase2ReductionsDirects', 'phase2RecoveryDirects', 'phaseRanges', 'phase3ReductionsDirects', 'phase3RecoveryDirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(3, nihWad, this.currentUser.sponsorTypeNih, true).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(3, fftWad, this.currentUser.sponsorTypeFFT, true).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(3, otherFedWad, this.currentUser.sponsorTypeOtherFed, true).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(3, nonFedwad, this.currentUser.sponsorTypeNonFed, true).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(3, sponsor5wad, this.currentUser.sponsorTypeSponsor5, true).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(3, sponsor6wad, this.currentUser.sponsorTypeSponsor6, true).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(3, sponsor7wad, this.currentUser.sponsorTypeSponsor7, true).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreThreeYearsAheadIndirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsIndirects', 'phase1RecoveryIndirects', 'phase2ReductionsIndirects', 'phase2RecoveryIndirects', 'phaseRanges', 'phase3ReductionsIndirects', 'phase3RecoveryIndirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(3, nihWad, this.currentUser.sponsorTypeNih, false).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(3, fftWad, this.currentUser.sponsorTypeFFT, false).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(3, otherFedWad, this.currentUser.sponsorTypeOtherFed, false).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(3, nonFedwad, this.currentUser.sponsorTypeNonFed, false).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(3, sponsor5wad, this.currentUser.sponsorTypeSponsor5, false).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(3, sponsor6wad, this.currentUser.sponsorTypeSponsor6, false).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(3, sponsor7wad, this.currentUser.sponsorTypeSponsor7, false).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreFourYearsAheadDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsDirects', 'phase1RecoveryDirects', 'phase2ReductionsDirects', 'phase2RecoveryDirects', 'phaseRanges', 'phase3ReductionsDirects', 'phase3RecoveryDirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(4, nihWad, this.currentUser.sponsorTypeNih, true).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(4, fftWad, this.currentUser.sponsorTypeFFT, true).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(4, otherFedWad, this.currentUser.sponsorTypeOtherFed, true).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(4, nonFedwad, this.currentUser.sponsorTypeNonFed, true).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(4, sponsor5wad, this.currentUser.sponsorTypeSponsor5, true).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(4, sponsor6wad, this.currentUser.sponsorTypeSponsor6, true).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(4, sponsor7wad, this.currentUser.sponsorTypeSponsor7, true).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
      s;
    }),
    recoveryCoreFourYearsAheadIndirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsIndirects', 'phase1RecoveryIndirects', 'phase2ReductionsIndirects', 'phase2RecoveryIndirects', 'phaseRanges', 'phase3ReductionsIndirects', 'phase3RecoveryIndirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(4, nihWad, this.currentUser.sponsorTypeNih, false).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(4, fftWad, this.currentUser.sponsorTypeFFT, false).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(4, otherFedWad, this.currentUser.sponsorTypeOtherFed, false).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(4, nonFedwad, this.currentUser.sponsorTypeNonFed, false).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(4, sponsor5wad, this.currentUser.sponsorTypeSponsor5, false).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(4, sponsor6wad, this.currentUser.sponsorTypeSponsor6, false).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(4, sponsor7wad, this.currentUser.sponsorTypeSponsor7, false).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreFiveYearsAheadDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsDirects', 'phase1RecoveryDirects', 'phase2ReductionsDirects', 'phase2RecoveryDirects', 'phaseRanges', 'phase3ReductionsDirects', 'phase3RecoveryDirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(5, nihWad, this.currentUser.sponsorTypeNih, true).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(5, fftWad, this.currentUser.sponsorTypeFFT, true).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(5, otherFedWad, this.currentUser.sponsorTypeOtherFed, true).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(5, nonFedwad, this.currentUser.sponsorTypeNonFed, true).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(5, sponsor5wad, this.currentUser.sponsorTypeSponsor5, true).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(5, sponsor6wad, this.currentUser.sponsorTypeSponsor6, true).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(5, sponsor7wad, this.currentUser.sponsorTypeSponsor7, true).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreFiveYearsAheadIndirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsIndirects', 'phase1RecoveryIndirects', 'phase2ReductionsIndirects', 'phase2RecoveryIndirects', 'phaseRanges', 'phase3ReductionsIndirects', 'phase3RecoveryIndirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(5, nihWad, this.currentUser.sponsorTypeNih, false).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(5, fftWad, this.currentUser.sponsorTypeFFT, false).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(5, otherFedWad, this.currentUser.sponsorTypeOtherFed, false).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(5, nonFedwad, this.currentUser.sponsorTypeNonFed, false).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(5, sponsor5wad, this.currentUser.sponsorTypeSponsor5, false).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(5, sponsor6wad, this.currentUser.sponsorTypeSponsor6, false).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(5, sponsor7wad, this.currentUser.sponsorTypeSponsor7, false).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreSixYearsAheadDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsDirects', 'phase1RecoveryDirects', 'phase2ReductionsDirects', 'phase2RecoveryDirects', 'phaseRanges', 'phase3ReductionsDirects', 'phase3RecoveryDirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(6, nihWad, this.currentUser.sponsorTypeNih, true).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(6, fftWad, this.currentUser.sponsorTypeFFT, true).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(6, otherFedWad, this.currentUser.sponsorTypeOtherFed, true).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(6, nonFedwad, this.currentUser.sponsorTypeNonFed, true).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(6, sponsor5wad, this.currentUser.sponsorTypeSponsor5, true).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(6, sponsor6wad, this.currentUser.sponsorTypeSponsor6, true).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(6, sponsor7wad, this.currentUser.sponsorTypeSponsor7, true).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    recoveryCoreSixYearsAheadIndirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phase1ReductionsIndirects', 'phase1RecoveryIndirects', 'phase2ReductionsIndirects', 'phase2RecoveryIndirects', 'phaseRanges', 'phase3ReductionsIndirects', 'phase3RecoveryIndirects', 'model.projection', function () {
      var nihWad = this.get('currentUser.team.weightedAverageDurationNih');
      var fftWad = this.get('currentUser.team.weightedAverageDurationFft');
      var otherFedWad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      var nonFedwad = this.get('currentUser.team.weightedAverageDurationNonFed');
      var sponsor5wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      var sponsor6wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      var sponsor7wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      var nihRecovery = this.reductionRecoveryForGrantPool(6, nihWad, this.currentUser.sponsorTypeNih, false).recoveryAmount;
      var fftRecovery = this.reductionRecoveryForGrantPool(6, fftWad, this.currentUser.sponsorTypeFFT, false).recoveryAmount;
      var otherFedRecovery = this.reductionRecoveryForGrantPool(6, otherFedWad, this.currentUser.sponsorTypeOtherFed, false).recoveryAmount;
      var nonFedRecovery = this.reductionRecoveryForGrantPool(6, nonFedwad, this.currentUser.sponsorTypeNonFed, false).recoveryAmount;
      var sponsor5Recovery = this.reductionRecoveryForGrantPool(6, sponsor5wad, this.currentUser.sponsorTypeSponsor5, false).recoveryAmount;
      var sponsor6Recovery = this.reductionRecoveryForGrantPool(6, sponsor6wad, this.currentUser.sponsorTypeSponsor6, false).recoveryAmount;
      var sponsor7Recovery = this.reductionRecoveryForGrantPool(6, sponsor7wad, this.currentUser.sponsorTypeSponsor7, false).recoveryAmount;
      return nihRecovery + fftRecovery + otherFedRecovery + nonFedRecovery + sponsor5Recovery + sponsor6Recovery + sponsor7Recovery;
    }),
    reductionRecoveryForGrantPool: function reductionRecoveryForGrantPool(currentYearOffset, wad, sponsorType, isDirects) {
      var _this = this;

      // Figure out which year to look at based on the current year.
      // ie if we are in the current year (currentYearOffset = 0) and the wad is 4, we should be looking at 2016 successes
      // The recovery should be placed the year after the grant ends
      var offset = currentYearOffset - wad;
      var yearPart;

      switch (offset - 1) {
        case -5:
          yearPart = 'FiveYearsAgo';
          break;

        case -4:
          yearPart = 'FourYearsAgo';
          break;

        case -3:
          yearPart = 'ThreeYearsAgo';
          break;

        case -2:
          yearPart = 'TwoYearsAgo';
          break;

        case -1:
          yearPart = 'OneYearAgo';
          break;

        case 0:
          yearPart = 'CurrentYear';
          break;

        case 1:
          yearPart = 'OneYearAhead';
          break;

        case 2:
          yearPart = 'TwoYearsAhead';
          break;

        case 3:
          yearPart = 'ThreeYearsAhead';
          break;

        case 4:
          yearPart = 'FourYearsAhead';
          break;

        case 5:
          yearPart = 'FiveYearsAhead';
          break;

        default:
          return {
            recoveryAmount: 0,
            reductionAmount: 0
          };
      }

      var sponsorPart;

      switch (sponsorType) {
        case this.currentUser.sponsorTypeNih:
          sponsorPart = 'Nih';
          break;

        case this.currentUser.sponsorTypeOtherFed:
          sponsorPart = 'OtherFed';
          break;

        case this.currentUser.sponsorTypeFFT:
          sponsorPart = 'FedFlowThru';
          break;

        case this.currentUser.sponsorTypeNonFed:
          sponsorPart = 'NonFed';
          break;

        case this.currentUser.sponsorTypeSponsor5:
          sponsorPart = 'Sponsor5';
          break;

        case this.currentUser.sponsorTypeSponsor6:
          sponsorPart = 'Sponsor6';
          break;

        case this.currentUser.sponsorTypeSponsor7:
          sponsorPart = 'Sponsor7';
          break;
      }

      var directsPart = isDirects ? 'Directs' : 'Indirects';
      var prefix = offset > 0 ? 'projectedCoreSuccesses' : 'model.projection.successes'; // Retrieve the correct successes pool

      var successes = this.get("".concat(prefix).concat(yearPart).concat(sponsorPart).concat(directsPart)); // Determine grant range: (grants are ammortized for half years start and end)

      var fiscalStartMonth = this.year.fiscalStartMonth;
      var grantStartYear = offset + this.year.fiscalYear;
      var grantEndYear = grantStartYear + wad;
      var grantStart = moment([fiscalStartMonth - 6 < 1 ? grantStartYear - 1 : grantStartYear, (fiscalStartMonth - 6 < 1 ? 12 - fiscalStartMonth : fiscalStartMonth - 6) - 1]).startOf('month');
      var grantEnd = moment([fiscalStartMonth + 5 > 12 ? grantEndYear : grantEndYear - 1, (fiscalStartMonth + 5 > 12 ? fiscalStartMonth + 5 - 12 : fiscalStartMonth + 5) - 1]).endOf('month');
      var grantRange = moment.range(grantStart, grantEnd); // Determine if the set phase intersects when the grant is in place

      var phaseRanges = this.phaseRanges;
      var phaseReductions = [1, 2, 3].map(function (i) {
        return (isDirects ? _this.get("phase".concat(i, "ReductionsDirects")) : _this.get("phase".concat(i, "ReductionsIndirects"))) / 100;
      });
      var phaseRecoveries = [1, 2, 3].map(function (i) {
        return (isDirects ? _this.get("phase".concat(i, "RecoveryDirects")) : _this.get("phase".concat(i, "RecoveryIndirects"))) / 100;
      });
      var recoveryAmount = 0;
      var reductionAmount = 0;

      for (var i = 0; i < 3; i++) {
        var overlap = grantRange.intersect(phaseRanges[i]);
        var monthsOfReduction = 0;

        if (overlap) {
          monthsOfReduction = overlap.diff('months');
        }

        var lengthOfGrant = Array.from(grantRange.by('month')).length; // Return the total amount the grant pool was reduced by

        var reduction = successes * phaseReductions[i] * (monthsOfReduction / lengthOfGrant);
        reductionAmount += reduction;
        recoveryAmount += reduction * phaseRecoveries[i];
      }

      return {
        recoveryAmount: recoveryAmount,
        reductionAmount: reductionAmount
      };
    },
    phaseRanges: Ember.computed('isPhase2Enabled', 'isPhase3Enabled', 'phase1Start', 'phase1End', 'phase2Start', 'phase2End', 'phase3Start', 'phase3End', function () {
      var _this2 = this;

      var ranges = [1, 2, 3].map(function (i) {
        return moment.range(moment(_this2.get("phase".concat(i, "Start")), 'MMM YYYY').startOf('month'), moment(_this2.get("phase".concat(i, "End")), 'MMM YYYY').endOf('month'));
      });

      if (!this.isPhase2Enabled) {
        ranges[1] = moment.range(moment([2010, 1]), moment([2010, 1]));
      }

      if (!this.isPhase3Enabled) {
        ranges[2] = moment.range(moment([2010, 1]), moment([2010, 1]));
      }

      return ranges;
    }),
    //endregion
    reductionProjectedSpending: function reductionProjectedSpending(wad, fiveYearsAgo, fourYearsAgo, threeYearsAgo, twoYearsAgo, oneYearAgo, current, currentYear, isDirects) {
      var _this3 = this;

      if (wad > 5) {
        wad = 5;
      }

      var phaseRanges = this.phaseRanges;
      var phaseReductions = [1, 2, 3].map(function (i) {
        return (isDirects ? _this3.get("phase".concat(i, "ReductionsDirects")) : _this3.get("phase".concat(i, "ReductionsIndirects"))) / 100;
      });

      switch (wad) {
        case 1:
          return oneYearAgo / 2 * this.calculateReductionForGrantPoolYear(currentYear, true, false, wad, phaseRanges, phaseReductions) + current / 2 * this.calculateReductionForGrantPoolYear(currentYear, false, true, wad, phaseRanges, phaseReductions);

        case 2:
          return twoYearsAgo / 4 * this.calculateReductionForGrantPoolYear(currentYear, true, false, wad, phaseRanges, phaseReductions) + oneYearAgo / 2 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + current / 4 * this.calculateReductionForGrantPoolYear(currentYear, false, true, wad, phaseRanges, phaseReductions);

        case 3:
          return threeYearsAgo / 6 * this.calculateReductionForGrantPoolYear(currentYear, true, false, wad, phaseRanges, phaseReductions) + twoYearsAgo / 3 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + oneYearAgo / 3 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + current / 6 * this.calculateReductionForGrantPoolYear(currentYear, false, true, wad, phaseRanges, phaseReductions);

        case 4:
          return fourYearsAgo / 8 * this.calculateReductionForGrantPoolYear(currentYear, true, false, wad, phaseRanges, phaseReductions) + threeYearsAgo / 4 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + twoYearsAgo / 4 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + oneYearAgo / 4 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + current / 8 * this.calculateReductionForGrantPoolYear(currentYear, false, true, wad, phaseRanges, phaseReductions);

        case 5:
          return fiveYearsAgo / 10 * this.calculateReductionForGrantPoolYear(currentYear, true, false, wad, phaseRanges, phaseReductions) + fourYearsAgo / 5 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + threeYearsAgo / 5 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + twoYearsAgo / 5 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + oneYearAgo / 5 * this.calculateReductionForGrantPoolYear(currentYear, false, false, wad, phaseRanges, phaseReductions) + current / 10 * this.calculateReductionForGrantPoolYear(currentYear, false, true, wad, phaseRanges, phaseReductions);

        default:
          return 0;
      }
    },
    getYearPart: function getYearPart(offset) {
      var yearPart;

      switch (offset) {
        case -5:
          yearPart = 'FiveYearsAgo';
          break;

        case -4:
          yearPart = 'FourYearsAgo';
          break;

        case -3:
          yearPart = 'ThreeYearsAgo';
          break;

        case -2:
          yearPart = 'TwoYearsAgo';
          break;

        case -1:
          yearPart = 'OneYearAgo';
          break;

        case 0:
          yearPart = 'CurrentYear';
          break;

        case 1:
          yearPart = 'OneYearAhead';
          break;

        case 2:
          yearPart = 'TwoYearsAhead';
          break;

        case 3:
          yearPart = 'ThreeYearsAhead';
          break;

        case 4:
          yearPart = 'FourYearsAhead';
          break;

        case 5:
          yearPart = 'FiveYearsAhead';
          break;
      }

      return yearPart;
    },
    potentialBackstopCompensation: function potentialBackstopCompensation(currentYearOffset, wad, sponsorType) {
      var _this4 = this;

      // The potential backstop compensation should be placed the year after the grant ends.
      var offset = currentYearOffset - wad;
      var coreYearPart = this.getYearPart(offset - 1);
      var coreSuccesses = 0;
      var sponsorPart;

      switch (sponsorType) {
        case this.currentUser.sponsorTypeNih:
          sponsorPart = 'Nih';
          break;

        case this.currentUser.sponsorTypeOtherFed:
          sponsorPart = 'OtherFed';
          break;

        case this.currentUser.sponsorTypeFFT:
          sponsorPart = 'FedFlowThru';
          break;

        case this.currentUser.sponsorTypeNonFed:
          sponsorPart = 'NonFed';
          break;

        case this.currentUser.sponsorTypeSponsor5:
          sponsorPart = 'Sponsor5';
          break;

        case this.currentUser.sponsorTypeSponsor6:
          sponsorPart = 'Sponsor6';
          break;

        case this.currentUser.sponsorTypeSponsor7:
          sponsorPart = 'Sponsor7';
          break;
      }

      var prefix = offset > 0 ? 'projectedCoreSuccesses' : 'model.projection.successes'; // Retrieve the correct successes pool

      if (coreYearPart) {
        coreSuccesses = this.get("".concat(prefix).concat(coreYearPart).concat(sponsorPart, "Directs"));
      }

      var fiscalStartMonth = this.year.fiscalStartMonth;
      var coreGrantStartYear = offset + this.year.fiscalYear;
      var coreGrantEndYear = coreGrantStartYear + wad;
      var coreGrantStart = moment([fiscalStartMonth - 6 < 1 ? coreGrantStartYear - 1 : coreGrantStartYear, (fiscalStartMonth - 6 < 1 ? 12 - fiscalStartMonth : fiscalStartMonth - 6) - 1]).startOf('month');
      var coreGrantEnd = moment([fiscalStartMonth + 5 > 12 ? coreGrantEndYear : coreGrantEndYear - 1, (fiscalStartMonth + 5 > 12 ? fiscalStartMonth + 5 - 12 : fiscalStartMonth + 5) - 1]).endOf('month');
      var coreGrantRange = moment.range(coreGrantStart, coreGrantEnd);
      var megaSuccesses;
      var year = this.year.fiscalYear + currentYearOffset;
      var megaYearPart = this.getYearPart(currentYearOffset);

      if (megaYearPart) {
        megaSuccesses = this.get("model.projection.megaGrantTotals".concat(megaYearPart).concat(sponsorPart, "Directs"));
      }

      if (!megaSuccesses) {
        megaSuccesses = 0;
      }

      var potentialBackstop = 0;
      var compensationReplacement = this.get("compensationReplacement".concat(sponsorPart));
      var currentYearStart = moment([year - 1, this.year.fiscalStartMonth - 1]).startOf('month');
      var currentYearEnd = moment([year, (this.year.fiscalStartMonth - 1 === 0 ? 12 : this.year.fiscalStartMonth - 1) - 1]).endOf('month');
      var currentYearRange = moment.range(currentYearStart, currentYearEnd);
      var phaseRanges = this.phaseRanges;
      var phaseDirectsReductions = [1, 2, 3].map(function (i) {
        return _this4.get("phase".concat(i, "ReductionsDirects")) / 100;
      });

      for (var i = 0; i <= 2; i++) {
        var range = phaseRanges[i]; // The loss of directs reduces the amount of

        var phaseDirectsReduction = 1 - phaseDirectsReductions[i];
        var coreOverlap = coreGrantRange.intersect(range);
        var coreMonthsOfReduction = 0;

        if (coreOverlap) {
          coreMonthsOfReduction = coreOverlap.diff('months');
        }

        potentialBackstop += coreMonthsOfReduction / (12 * wad) * compensationReplacement / 100 * coreSuccesses * phaseDirectsReduction;
        var megaRange = phaseRanges[i]; //.add(moment.range(phaseRanges[i].end.subtract(1, 'month'), phaseRanges[i].end.add(1, 'year')));

        var megaOverlap = currentYearRange.intersect(megaRange);
        var megaMonthsOfReduction = 0;

        if (megaOverlap) {
          megaMonthsOfReduction = megaOverlap.diff('months');
        }

        potentialBackstop += megaMonthsOfReduction / 12 * compensationReplacement / 100 * megaSuccesses * phaseDirectsReduction;
      }

      return potentialBackstop;
    },
    reductionMegaProjectedSpending: function reductionMegaProjectedSpending(mega, year, isDirects) {
      var currentYearStart = moment([year - 1, this.year.fiscalStartMonth - 1]).startOf('month');
      var currentYearEnd = moment([year, (this.year.fiscalStartMonth - 1 === 0 ? 12 : this.year.fiscalStartMonth - 1) - 1]).endOf('month');
      var currentYearRange = moment.range(currentYearStart, currentYearEnd);
      var reductionFactor = 0;
      var phaseRanges = this.phaseRanges;

      for (var i = 1; i <= 3; i++) {
        var range = phaseRanges[i - 1];
        var overlap = currentYearRange.intersect(range);
        var monthsOfReduction = 0;

        if (overlap) {
          monthsOfReduction = overlap.diff('months');
        }

        var phaseReduction = (isDirects ? this.get("phase".concat(i, "ReductionsDirects")) : this.get("phase".concat(i, "ReductionsIndirects"))) / 100;
        reductionFactor += monthsOfReduction / 12 * phaseReduction;
      }

      return mega * (1 - reductionFactor);
    },
    calculateReductionForGrantPoolYear: function calculateReductionForGrantPoolYear(currentYear, inPlaceFirstHalfYear, inPlaceLastHalfYear, wad, phaseRanges, phaseReductions) {
      var fiscalStartMonth = this.year.fiscalStartMonth; //Generate the range the grant pool is in place for the given year

      var grantStart = moment([currentYear - 1, fiscalStartMonth - 1]).startOf('month');
      var grantEnd = moment([currentYear, (fiscalStartMonth - 1 === 0 ? 12 : fiscalStartMonth - 1) - 1]).endOf('month');

      if (inPlaceFirstHalfYear) {
        grantStart = moment([currentYear - 1, fiscalStartMonth - 1]).startOf('month');
        grantEnd = moment([fiscalStartMonth + 5 > 12 ? currentYear : currentYear - 1, (fiscalStartMonth + 5 > 12 ? fiscalStartMonth + 5 - 12 : fiscalStartMonth + 5) - 1]).endOf('month');
      } else if (inPlaceLastHalfYear) {
        grantStart = moment([fiscalStartMonth - 6 < 1 ? currentYear - 1 : currentYear, (fiscalStartMonth - 6 < 1 ? 12 - fiscalStartMonth : fiscalStartMonth - 6) - 1]).startOf('month');
        grantEnd = moment([currentYear, (fiscalStartMonth - 1 === 0 ? 12 : fiscalStartMonth - 1) - 1]).endOf('month');
      }

      var grantRange = moment.range(grantStart, grantEnd);
      var reductionFactor = 0; // Determine if the set phase intersects when the grant is in place

      for (var i = 0; i < 3; i++) {
        var overlap = grantRange.intersect(phaseRanges[i]);
        var monthsOfReduction = 0;

        if (overlap) {
          monthsOfReduction = overlap.diff('months');
        } // Return the factor to reduce the given pool by


        reductionFactor += phaseReductions[i] * (monthsOfReduction / 12);
      }

      return 1 - reductionFactor;
    },
    tableModel: Ember.computed('revisedReducedCurrentFutureDirectsRow', 'revisedReducedCurrentFutureIndirectsRow', 'baselineCurrentFutureDirectsRow.ptsCurrentYear', 'baselineCurrentFutureIndirectsRow.ptsCurrentYear', 'varianceDirectsRow', 'varianceIndirectsRow', function () {
      return [this.baselineCurrentFutureDirectsRow, this.baselineCurrentFutureIndirectsRow, this.revisedReducedCurrentFutureDirectsRow, this.revisedReducedCurrentFutureIndirectsRow, this.varianceCurrentFutureDirectsRow, this.varianceCurrentFutureIndirectsRow];
    }),
    projectedReducedBaselineData: Ember.computed('revisedReducedCurrentFutureDirectsRow', 'revisedReducedCurrentFutureIndirectsRow', 'baselineCurrentFutureDirectsRow', 'baselineCurrentFutureIndirectsRow', function () {
      var revisedDirects = this.revisedReducedCurrentFutureDirectsRow;
      var revisedIndirects = this.revisedCurrentFutureIndirectsRow;
      var baselineDirects = this.baselineCurrentFutureDirectsRow;
      var baselineIndirects = this.baselineCurrentFutureIndirectsRow;
      return {
        'revisedDirects': [revisedDirects.ptsCurrentYear.toFixed(1), revisedDirects.ptsOneYearAhead.toFixed(1), revisedDirects.ptsTwoYearsAhead.toFixed(1), revisedDirects.ptsThreeYearsAhead.toFixed(1), revisedDirects.ptsFourYearsAhead.toFixed(1), revisedDirects.ptsFiveYearsAhead.toFixed(1), revisedDirects.ptsSixYearsAhead.toFixed(1), revisedDirects.ptsSevenYearsAhead.toFixed(1)],
        'revisedIndirects': [revisedIndirects.ptsCurrentYear.toFixed(1), revisedIndirects.ptsOneYearAhead.toFixed(1), revisedIndirects.ptsTwoYearsAhead.toFixed(1), revisedIndirects.ptsThreeYearsAhead.toFixed(1), revisedIndirects.ptsFourYearsAhead.toFixed(1), revisedIndirects.ptsFiveYearsAhead.toFixed(1), revisedIndirects.ptsSixYearsAhead.toFixed(1), revisedIndirects.ptsSevenYearsAhead.toFixed(1)],
        'baselineDirects': [baselineDirects.ptsCurrentYear.toFixed(1), baselineDirects.ptsOneYearAhead.toFixed(1), baselineDirects.ptsTwoYearsAhead.toFixed(1), baselineDirects.ptsThreeYearsAhead.toFixed(1), baselineDirects.ptsFourYearsAhead.toFixed(1), baselineDirects.ptsFiveYearsAhead.toFixed(1), baselineDirects.ptsSixYearsAhead.toFixed(1), baselineDirects.ptsSevenYearsAhead.toFixed(1)],
        'baselineIndirects': [baselineIndirects.ptsCurrentYear.toFixed(1), baselineIndirects.ptsOneYearAhead.toFixed(1), baselineIndirects.ptsTwoYearsAhead.toFixed(1), baselineIndirects.ptsThreeYearsAhead.toFixed(1), baselineIndirects.ptsFourYearsAhead.toFixed(1), baselineIndirects.ptsFiveYearsAhead.toFixed(1), baselineIndirects.ptsSixYearsAhead.toFixed(1), baselineIndirects.ptsSevenYearsAhead.toFixed(1)]
      };
    }),
    projectedReducedBaselineChartData: Ember.computed('projectedReducedBaselineData', 'year.fiscalYear', function () {
      var projectedPtsData = this.projectedReducedBaselineData;

      if (!projectedPtsData) {
        return null;
      }

      var fiscalYear = parseInt(this.get('year.fiscalYear'));
      var years = [];

      for (var i = 0; i < 8; i++) {
        years.push(fiscalYear + i);
      }

      return {
        labels: years,
        datasets: [{
          label: 'Indirects - Baseline',
          data: projectedPtsData['baselineIndirects'],
          stack: "Baseline",
          backgroundColor: '#F1BE48',
          borderWidth: 0
        }, {
          label: 'Directs - Baseline',
          data: projectedPtsData['baselineDirects'],
          stack: "Baseline",
          backgroundColor: '#71C5E8',
          borderWidth: 0
        }, {
          label: 'Indirects - Revised',
          data: projectedPtsData['revisedIndirects'],
          stack: "Revised",
          backgroundColor: '#CBA943',
          borderWidth: 0
        }, {
          label: 'Directs - Revised',
          data: projectedPtsData['revisedDirects'],
          stack: "Revised",
          backgroundColor: '#499BBF',
          borderWidth: 0
        }]
      };
    }),
    baselineCurrentFutureDirectsRow: Ember.computed('revisedCurrentYearDirects', 'revisedOneYearAheadDirects', 'revisedTwoYearsAheadDirects', 'revisedThreeYearsAheadDirects', 'revisedFourYearsAheadDirects', 'revisedFiveYearsAheadDirects', 'revisedSixYearsAheadDirects', 'revisedSevenYearsAheadDirects', 'model.projection', 'currentUser.team.schoolProjectionAdjustmentDirects', 'selectedGrantType', 'selectedRecruitmentOption', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'selectedSponsor', function () {
      var model = this.model.projection;
      var shrinkage = this.get('currentUser.team.shrinkage') / 100;
      var adjustmentDirects = 0;
      var selectedGrantType = this.selectedGrantType;

      if (model.get('sector') === null && model.get('department.id') === undefined && selectedGrantType !== GRANT_TYPE_MEGA && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        adjustmentDirects = this.get('currentUser.team.schoolProjectionAdjustmentDirects') / 1000000;
      }

      var data = [this.revisedCurrentYearDirects * (1 - shrinkage) + adjustmentDirects, this.revisedOneYearAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedTwoYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedThreeYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedFourYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedFiveYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedSixYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedSixYearsAheadDirects * (1 - shrinkage) + adjustmentDirects];
      var directs = this.addRecruitmentData(data, 'spending', 0, true);
      return {
        type: 'Baseline',
        directsOrIndirects: 'Directs',
        ptsCurrentYear: directs[0],
        ptsOneYearAhead: directs[1],
        ptsTwoYearsAhead: directs[2],
        ptsThreeYearsAhead: directs[3],
        ptsFourYearsAhead: directs[4],
        ptsFiveYearsAhead: directs[5],
        ptsSixYearsAhead: directs[6],
        ptsSevenYearsAhead: directs[7]
      };
    }),
    baselineCurrentFutureIndirectsRow: Ember.computed('revisedCurrentYearIndirects', 'revisedOneYearAheadIndirects', 'revisedTwoYearsAheadIndirects', 'revisedThreeYearsAheadIndirects', 'revisedFourYearsAheadIndirects', 'revisedFiveYearsAheadIndirects', 'revisedSixYearsAheadIndirects', 'revisedSevenYearsAheadIndirects', 'model.projection', 'currentUser.team.schoolProjectionAdjustmentIndirects', 'selectedGrantType', 'selectedRecruitmentOption', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'selectedSponsor', function () {
      var model = this.model.projection;
      var adjustmentIndirects = 0;
      var shrinkage = this.get('currentUser.team.shrinkage') / 100;
      var selectedGrantType = this.selectedGrantType;

      if (model.get('sector') === null && model.get('department.id') === undefined && selectedGrantType !== GRANT_TYPE_MEGA && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        adjustmentIndirects = this.get('currentUser.team.schoolProjectionAdjustmentIndirects') / 1000000;
      }

      var data = [this.revisedCurrentYearIndirects * (1 - shrinkage) + adjustmentIndirects, this.revisedOneYearAheadIndirects * (1 - shrinkage) + adjustmentIndirects, this.revisedTwoYearsAheadIndirects * (1 - shrinkage) + adjustmentIndirects, this.revisedThreeYearsAheadIndirects * (1 - shrinkage) + adjustmentIndirects, this.revisedFourYearsAheadIndirects * (1 - shrinkage) + adjustmentIndirects, this.revisedFiveYearsAheadIndirects * (1 - shrinkage) + adjustmentIndirects, this.revisedSixYearsAheadIndirects * (1 - shrinkage) + adjustmentIndirects, this.revisedSixYearsAheadIndirects * (1 - shrinkage) + adjustmentIndirects];
      var indirects = this.addRecruitmentData(data, 'spending', 0, false);
      return {
        type: '',
        directsOrIndirects: 'Indirects',
        ptsCurrentYear: indirects[0],
        ptsOneYearAhead: indirects[1],
        ptsTwoYearsAhead: indirects[2],
        ptsThreeYearsAhead: indirects[3],
        ptsFourYearsAhead: indirects[4],
        ptsFiveYearsAhead: indirects[5],
        ptsSixYearsAhead: indirects[6],
        ptsSevenYearsAhead: indirects[7]
      };
    }),
    revisedReducedCurrentFutureDirectsRow: Ember.computed('revisedReducedCurrentYearDirects', 'revisedReducedOneYearAheadDirects', 'revisedReducedTwoYearsAheadDirects', 'revisedReducedThreeYearsAheadDirects', 'revisedReducedFourYearsAheadDirects', 'revisedReducedFiveYearsAheadDirects', 'revisedReducedSixYearsAheadDirects', 'model.projection', 'currentUser.team.schoolProjectionAdjustmentDirects', 'selectedGrantType', 'selectedRecruitmentOption', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'selectedSponsor', function () {
      var model = this.model.projection;
      var shrinkage = this.get('currentUser.team.shrinkage') / 100;
      var adjustmentDirects = 0;
      var selectedGrantType = this.selectedGrantType;

      if (model.get('sector') === null && model.get('department.id') === undefined && selectedGrantType !== GRANT_TYPE_MEGA && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        adjustmentDirects = this.get('currentUser.team.schoolProjectionAdjustmentDirects') / 1000000;
      }

      var data = [this.revisedReducedCurrentYearDirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedOneYearAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedTwoYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedThreeYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedFourYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedFiveYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedSixYearsAheadDirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedSixYearsAheadDirects * (1 - shrinkage) + adjustmentDirects];
      var directs = this.addRecruitmentData(data, 'spending', 0, true);
      return {
        type: 'Revised',
        directsOrIndirects: 'Directs',
        ptsCurrentYear: directs[0],
        ptsOneYearAhead: directs[1],
        ptsTwoYearsAhead: directs[2],
        ptsThreeYearsAhead: directs[3],
        ptsFourYearsAhead: directs[4],
        ptsFiveYearsAhead: directs[5],
        ptsSixYearsAhead: directs[6],
        ptsSevenYearsAhead: directs[7]
      };
    }),
    revisedReducedCurrentFutureIndirectsRow: Ember.computed('revisedReducedCurrentYearIndirects', 'revisedReducedOneYearAheadIndirects', 'revisedReducedTwoYearsAheadIndirects', 'revisedReducedThreeYearsAheadIndirects', 'revisedReducedFourYearsAheadIndirects', 'revisedReducedFiveYearsAheadIndirects', 'revisedReducedSixYearsAheadIndirects', 'model.projection', 'currentUser.team.schoolProjectionAdjustmentDirects', 'selectedGrantType', 'selectedRecruitmentOption', 'recruitsJunior.@each.value', 'attritionJunior.@each.value', 'recruitsSenior.@each.value', 'attritionSenior.@each.value', 'selectedSponsor', function () {
      var model = this.model.projection;
      var shrinkage = this.get('currentUser.team.shrinkage') / 100;
      var adjustmentDirects = 0;
      var selectedGrantType = this.selectedGrantType;

      if (model.get('sector') === null && model.get('department.id') === undefined && selectedGrantType !== GRANT_TYPE_MEGA && this.selectedSponsor === SPONSOR_TYPE_ALL) {
        adjustmentDirects = this.get('currentUser.team.schoolProjectionAdjustmentIndirects') / 1000000;
      }

      var data = [this.revisedReducedCurrentYearIndirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedOneYearAheadIndirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedTwoYearsAheadIndirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedThreeYearsAheadIndirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedFourYearsAheadIndirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedFiveYearsAheadIndirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedSixYearsAheadIndirects * (1 - shrinkage) + adjustmentDirects, this.revisedReducedSixYearsAheadIndirects * (1 - shrinkage) + adjustmentDirects];
      var indirects = this.addRecruitmentData(data, 'spending', 0, true);
      return {
        type: '',
        directsOrIndirects: 'Indirects',
        ptsCurrentYear: indirects[0],
        ptsOneYearAhead: indirects[1],
        ptsTwoYearsAhead: indirects[2],
        ptsThreeYearsAhead: indirects[3],
        ptsFourYearsAhead: indirects[4],
        ptsFiveYearsAhead: indirects[5],
        ptsSixYearsAhead: indirects[6],
        ptsSevenYearsAhead: indirects[7]
      };
    }),
    varianceCurrentFutureDirectsRow: Ember.computed('revisedReducedCurrentFutureDirectsRow', 'baselineCurrentFutureDirectsRow', function () {
      var revisedDirectsRow = this.revisedReducedCurrentFutureDirectsRow;
      var baselineDirectsRow = this.baselineCurrentFutureDirectsRow;

      if (revisedDirectsRow && baselineDirectsRow) {
        return {
          type: 'Variance',
          directsOrIndirects: 'Directs',
          ptsCurrentYear: revisedDirectsRow.ptsCurrentYear - baselineDirectsRow.ptsCurrentYear,
          ptsOneYearAhead: revisedDirectsRow.ptsOneYearAhead - baselineDirectsRow.ptsOneYearAhead,
          ptsTwoYearsAhead: revisedDirectsRow.ptsTwoYearsAhead - baselineDirectsRow.ptsTwoYearsAhead,
          ptsThreeYearsAhead: revisedDirectsRow.ptsThreeYearsAhead - baselineDirectsRow.ptsThreeYearsAhead,
          ptsFourYearsAhead: revisedDirectsRow.ptsFourYearsAhead - baselineDirectsRow.ptsFourYearsAhead,
          ptsFiveYearsAhead: revisedDirectsRow.ptsFiveYearsAhead - baselineDirectsRow.ptsFiveYearsAhead,
          ptsSixYearsAhead: revisedDirectsRow.ptsSixYearsAhead - baselineDirectsRow.ptsSixYearsAhead,
          ptsSevenYearsAhead: revisedDirectsRow.ptsSevenYearsAhead - baselineDirectsRow.ptsSevenYearsAhead
        };
      }

      return {};
    }),
    varianceCurrentFutureIndirectsRow: Ember.computed('revisedReducedCurrentFutureIndirectsRow', 'baselineCurrentFutureIndirectsRow', function () {
      var revisedIndirectsRow = this.revisedReducedCurrentFutureIndirectsRow;
      var baselineIndirectsRow = this.baselineCurrentFutureIndirectsRow;

      if (revisedIndirectsRow && baselineIndirectsRow) {
        return {
          type: '',
          directsOrIndirects: 'Indirects',
          ptsCurrentYear: revisedIndirectsRow.ptsCurrentYear - baselineIndirectsRow.ptsCurrentYear,
          ptsOneYearAhead: revisedIndirectsRow.ptsOneYearAhead - baselineIndirectsRow.ptsOneYearAhead,
          ptsTwoYearsAhead: revisedIndirectsRow.ptsTwoYearsAhead - baselineIndirectsRow.ptsTwoYearsAhead,
          ptsThreeYearsAhead: revisedIndirectsRow.ptsThreeYearsAhead - baselineIndirectsRow.ptsThreeYearsAhead,
          ptsFourYearsAhead: revisedIndirectsRow.ptsFourYearsAhead - baselineIndirectsRow.ptsFourYearsAhead,
          ptsFiveYearsAhead: revisedIndirectsRow.ptsFiveYearsAhead - baselineIndirectsRow.ptsFiveYearsAhead,
          ptsSixYearsAhead: revisedIndirectsRow.ptsSixYearsAhead - baselineIndirectsRow.ptsSixYearsAhead,
          ptsSevenYearsAhead: revisedIndirectsRow.ptsSevenYearsAhead - baselineIndirectsRow.ptsSevenYearsAhead
        };
      }

      return {};
    }),
    // Research Backstop
    compensationReplacementCurrentYearNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementNih', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(-1, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementCurrentYearOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementOtherFed', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(-1, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementCurrentYearFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementFedFlowThru', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(-1, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementCurrentYearNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementNonFed', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(-1, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementCurrentYearSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor5', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(-1, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementCurrentYearSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor6', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(-1, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementCurrentYearSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor7', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(-1, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementCurrentYearDirects: Ember.computed('compensationReplacementCurrentYearNihDirects', 'compensationReplacementCurrentYearOtherFedDirects', 'compensationReplacementCurrentYearFedFlowThruDirects', 'compensationReplacementCurrentYearNonFedDirects', 'compensationReplacementCurrentYearSponsor5Directs', 'compensationReplacementCurrentYearSponsor6Directs', 'compensationReplacementCurrentYearSponsor7Directs', 'model.projection', function () {
      // The effects don't start until FY 2021
      if (this.year.fiscalYear <= 2020) {
        return 0;
      }

      return this.compensationReplacementCurrentYearNihDirects + this.compensationReplacementCurrentYearOtherFedDirects + this.compensationReplacementCurrentYearFedFlowThruDirects + this.compensationReplacementCurrentYearNonFedDirects + this.compensationReplacementCurrentYearSponsor5Directs + this.compensationReplacementCurrentYearSponsor6Directs + this.compensationReplacementCurrentYearSponsor7Directs;
    }),
    compensationReplacementOneYearAheadNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementNih', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(0, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementOneYearAheadOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementOtherFed', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(0, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementOneYearAheadFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementFedFlowThru', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(0, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementOneYearAheadNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementNonFed', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(0, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementOneYearAheadSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor5', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(0, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementOneYearAheadSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor6', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(0, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementOneYearAheadSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor7', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(0, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementOneYearAheadDirects: Ember.computed('compensationReplacementOneYearAheadNihDirects', 'compensationReplacementOneYearAheadOtherFedDirects', 'compensationReplacementOneYearAheadFedFlowThruDirects', 'compensationReplacementOneYearAheadNonFedDirects', 'compensationReplacementOneYearAheadSponsor5Directs', 'compensationReplacementOneYearAheadSponsor6Directs', 'compensationReplacementOneYearAheadSponsor7Directs', function () {
      return this.compensationReplacementOneYearAheadNihDirects + this.compensationReplacementOneYearAheadOtherFedDirects + this.compensationReplacementOneYearAheadFedFlowThruDirects + this.compensationReplacementOneYearAheadNonFedDirects + this.compensationReplacementOneYearAheadSponsor5Directs + this.compensationReplacementOneYearAheadSponsor6Directs + this.compensationReplacementOneYearAheadSponsor7Directs;
    }),
    compensationReplacementTwoYearsAheadNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementNih', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(1, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementTwoYearsAheadOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementOtherFed', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(1, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementTwoYearsAheadFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementFedFlowThru', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(1, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementTwoYearsAheadNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementNonFed', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(1, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementTwoYearsAheadSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor5', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(1, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementTwoYearsAheadSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor6', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(1, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementTwoYearsAheadSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'compensationReplacementSponsor7', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(1, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementTwoYearsAheadDirects: Ember.computed('compensationReplacementTwoYearsAheadNihDirects', 'compensationReplacementTwoYearsAheadOtherFedDirects', 'compensationReplacementTwoYearsAheadFedFlowThruDirects', 'compensationReplacementTwoYearsAheadNonFedDirects', 'compensationReplacementTwoYearsAheadSponsor5Directs', 'compensationReplacementTwoYearsAheadSponsor6Directs', 'compensationReplacementTwoYearsAheadSponsor7Directs', function () {
      return this.compensationReplacementTwoYearsAheadNihDirects + this.compensationReplacementTwoYearsAheadOtherFedDirects + this.compensationReplacementTwoYearsAheadFedFlowThruDirects + this.compensationReplacementTwoYearsAheadNonFedDirects + this.compensationReplacementTwoYearsAheadSponsor5Directs + this.compensationReplacementTwoYearsAheadSponsor6Directs + this.compensationReplacementTwoYearsAheadSponsor7Directs;
    }),
    compensationReplacementThreeYearsAheadNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNih', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(2, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementThreeYearsAheadOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'compensationReplacementOtherFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(2, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementThreeYearsAheadFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'compensationReplacementFedFlowThru', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(2, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementThreeYearsAheadNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNonFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(2, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementThreeYearsAheadSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor5', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(2, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementThreeYearsAheadSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor6', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(2, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementThreeYearsAheadSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor7', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(2, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementThreeYearsAheadDirects: Ember.computed('compensationReplacementThreeYearsAheadNihDirects', 'compensationReplacementThreeYearsAheadOtherFedDirects', 'compensationReplacementThreeYearsAheadFedFlowThruDirects', 'compensationReplacementThreeYearsAheadNonFedDirects', 'compensationReplacementThreeYearsAheadSponsor5Directs', 'compensationReplacementThreeYearsAheadSponsor6Directs', 'compensationReplacementThreeYearsAheadSponsor7Directs', function () {
      return this.compensationReplacementThreeYearsAheadNihDirects + this.compensationReplacementThreeYearsAheadOtherFedDirects + this.compensationReplacementThreeYearsAheadFedFlowThruDirects + this.compensationReplacementThreeYearsAheadNonFedDirects + this.compensationReplacementThreeYearsAheadSponsor5Directs + this.compensationReplacementThreeYearsAheadSponsor6Directs + this.compensationReplacementThreeYearsAheadSponsor7Directs;
    }),
    compensationReplacementFourYearsAheadNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNih', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(3, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementFourYearsAheadOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'compensationReplacementOtherFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(3, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementFourYearsAheadFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'compensationReplacementFedFlowThru', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(3, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementFourYearsAheadNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNonFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(3, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementFourYearsAheadSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor5', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(3, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementFourYearsAheadSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor6', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(3, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementFourYearsAheadSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor7', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(3, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementFourYearsAheadDirects: Ember.computed('compensationReplacementFourYearsAheadNihDirects', 'compensationReplacementThreeoYearsAheadOtherFedDirects', 'compensationReplacementFourYearsAheadFedFlowThruDirects', 'compensationReplacementFourYearsAheadNonFedDirects', 'compensationReplacementFourYearsAheadSponsor5Directs', 'compensationReplacementFourYearsAheadSponsor6Directs', 'compensationReplacementFourYearsAheadSponsor7Directs', function () {
      return this.compensationReplacementFourYearsAheadNihDirects + this.compensationReplacementFourYearsAheadOtherFedDirects + this.compensationReplacementFourYearsAheadFedFlowThruDirects + this.compensationReplacementFourYearsAheadNonFedDirects + this.compensationReplacementFourYearsAheadSponsor5Directs + this.compensationReplacementFourYearsAheadSponsor6Directs + this.compensationReplacementFourYearsAheadSponsor7Directs;
    }),
    compensationReplacementFiveYearsAheadNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNih', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(4, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementFiveYearsAheadOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'compensationReplacementOtherFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(4, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementFiveYearsAheadFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'compensationReplacementFedFlowThru', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(4, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementFiveYearsAheadNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNonFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(4, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementFiveYearsAheadSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor5', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(4, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementFiveYearsAheadSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor6', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(4, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementFiveYearsAheadSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor7', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(4, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementFiveYearsAheadDirects: Ember.computed('compensationReplacementFiveYearsAheadNihDirects', 'compensationReplacementFiveYearsAheadOtherFedDirects', 'compensationReplacementFiveYearsAheadFedFlowThruDirects', 'compensationReplacementFiveYearsAheadNonFedDirects', 'compensationReplacementFiveYearsAheadSponsor5Directs', 'compensationReplacementFiveYearsAheadSponsor6Directs', 'compensationReplacementFiveYearsAheadSponsor7Directs', function () {
      return this.compensationReplacementFiveYearsAheadNihDirects + this.compensationReplacementFiveYearsAheadOtherFedDirects + this.compensationReplacementFiveYearsAheadFedFlowThruDirects + this.compensationReplacementFiveYearsAheadNonFedDirects + this.compensationReplacementFiveYearsAheadSponsor5Directs + this.compensationReplacementFiveYearsAheadSponsor6Directs + this.compensationReplacementFiveYearsAheadSponsor7Directs;
    }),
    compensationReplacementSixYearsAheadNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNih', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(5, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementSixYearsAheadOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'compensationReplacementOtherFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(5, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementSixYearsAheadFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'compensationReplacementFedFlowThru', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(5, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementSixYearsAheadNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNonFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(5, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementSixYearsAheadSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor5', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(5, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementSixYearsAheadSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor6', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(5, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementSixYearsAheadSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor7', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(5, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementSixYearsAheadDirects: Ember.computed('compensationReplacementSixYearsAheadNihDirects', 'compensationReplacementSixYearsAheadOtherFedDirects', 'compensationReplacementSixYearsAheadFedFlowThruDirects', 'compensationReplacementSixYearsAheadNonFedDirects', 'compensationReplacementSixYearsAheadSponsor5Directs', 'compensationReplacementSixYearsAheadSponsor6Directs', 'compensationReplacementSixYearsAheadSponsor7Directs', function () {
      return this.compensationReplacementSixYearsAheadNihDirects + this.compensationReplacementSixYearsAheadOtherFedDirects + this.compensationReplacementSixYearsAheadFedFlowThruDirects + this.compensationReplacementSixYearsAheadNonFedDirects + this.compensationReplacementSixYearsAheadSponsor5Directs + this.compensationReplacementSixYearsAheadSponsor6Directs + this.compensationReplacementSixYearsAheadSponsor7Directs;
    }),
    compensationReplacementSevenYearsAheadNihDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNih', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNih');
      return this.compensationReplacementDirects(6, wad, this.currentUser.sponsorTypeNih, true);
    }),
    compensationReplacementSevenYearsAheadOtherFedDirects: Ember.computed('currentUser.team.weightedAverageDurationOtherFed', 'phaseRanges', 'compensationReplacementOtherFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationOtherFed');
      return this.compensationReplacementDirects(6, wad, this.currentUser.sponsorTypeOtherFed, true);
    }),
    compensationReplacementSevenYearsAheadFedFlowThruDirects: Ember.computed('currentUser.team.weightedAverageDurationFft', 'phaseRanges', 'compensationReplacementFedFlowThru', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationFft');
      return this.compensationReplacementDirects(6, wad, this.currentUser.sponsorTypeFFT, true);
    }),
    compensationReplacementSevenYearsAheadNonFedDirects: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementNonFed', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationNonFed');
      return this.compensationReplacementDirects(6, wad, this.currentUser.sponsorTypeNonFed, true);
    }),
    compensationReplacementSevenYearsAheadSponsor5Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor5', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor5');
      return this.compensationReplacementDirects(6, wad, this.currentUser.sponsorTypeSponsor5, true);
    }),
    compensationReplacementSevenYearsAheadSponsor6Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor6', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor6');
      return this.compensationReplacementDirects(6, wad, this.currentUser.sponsorTypeSponsor6, true);
    }),
    compensationReplacementSevenYearsAheadSponsor7Directs: Ember.computed('currentUser.team.weightedAverageDurationNih', 'phaseRanges', 'compensationReplacementSponsor7', 'phase1ReductionsDirects', 'phase2ReductionsDirects', 'phase3ReductionsDirects', 'model.projection', function () {
      var wad = this.get('currentUser.team.weightedAverageDurationSponsor7');
      return this.compensationReplacementDirects(6, wad, this.currentUser.sponsorTypeSponsor7, true);
    }),
    compensationReplacementSevenYearsAheadDirects: Ember.computed('compensationReplacementSevenYearsAheadNihDirects', 'compensationReplacementSevenYearsAheadOtherFedDirects', 'compensationReplacementSixYearsAheadFedFlowThruDirects', 'compensationReplacementSixYearsAheadNonFedDirects', 'compensationReplacementSixYearsAheadSponsor5Directs', 'compensationReplacementSixYearsAheadSponsor6Directs', 'compensationReplacementSixYearsAheadSponsor7Directs', function () {
      return this.compensationReplacementSevenYearsAheadNihDirects + this.compensationReplacementSevenYearsAheadOtherFedDirects + this.compensationReplacementSevenYearsAheadFedFlowThruDirects + this.compensationReplacementSevenYearsAheadNonFedDirects + this.compensationReplacementSevenYearsAheadSponsor5Directs + this.compensationReplacementSevenYearsAheadSponsor6Directs + this.compensationReplacementSevenYearsAheadSponsor7Directs;
    }),
    compensationReplacementDirects: function compensationReplacementDirects(currentYearOffset, wad, sponsorType) {
      return this.potentialBackstopCompensation(currentYearOffset, wad, sponsorType);
    },
    compensationReplacementData: Ember.computed('compensationReplacementCurrentYearDirects', 'compensationReplacementOneYearAheadDirects', 'compensationReplacementTwoYearsAheadDirects', 'compensationReplacementThreeYearsAheadDirects', 'compensationReplacementFourYearsAheadDirects', 'compensationReplacementFiveYearsAheadDirects', 'compensationReplacementSixYearsAheadDirects', 'selectedCompensationReplacementSponsor', 'compensationReplacementNih', 'compensationReplacementOtherFed', 'compensationReplacementFedFlowThru', 'compensationReplacementNonFed', function () {
      var selectedSponsor = this.selectedCompensationReplacementSponsor;
      var sponsorString = '';

      switch (selectedSponsor) {
        case this.currentUser.sponsorTypeNih:
          sponsorString = 'Nih';
          break;

        case this.currentUser.sponsorTypeOtherFed:
          sponsorString = 'OtherFed';
          break;

        case this.currentUser.sponsorTypeFFT:
          sponsorString = 'FedFlowThru';
          break;

        case this.currentUser.sponsorTypeNonFed:
          sponsorString = 'NonFed';
          break;

        case this.currentUser.sponsorTypeSponsor5:
          sponsorString = 'Sponsor5';
          break;

        case this.currentUser.sponsorTypeSponsor6:
          sponsorString = 'Sponsor6';
          break;

        case this.currentUser.sponsorTypeSponsor7:
          sponsorString = 'Sponsor7';
          break;
      }

      var totalsPerYear = [this.get("compensationReplacementCurrentYear".concat(sponsorString, "Directs")), this.get("compensationReplacementOneYearAhead".concat(sponsorString, "Directs")), this.get("compensationReplacementTwoYearsAhead".concat(sponsorString, "Directs")), this.get("compensationReplacementThreeYearsAhead".concat(sponsorString, "Directs")), this.get("compensationReplacementFourYearsAhead".concat(sponsorString, "Directs")), this.get("compensationReplacementFiveYearsAhead".concat(sponsorString, "Directs")), this.get("compensationReplacementSixYearsAhead".concat(sponsorString, "Directs")), this.get("compensationReplacementSevenYearsAhead".concat(sponsorString, "Directs"))];

      var cumulativeSum = function (sum) {
        return function (value) {
          return sum += value;
        };
      }(0);

      var cumulative = totalsPerYear.map(cumulativeSum);
      return {
        "totalsPerYear": totalsPerYear,
        "cumulative": cumulative
      };
    }),
    compensationReplacementChartData: Ember.computed('compensationReplacementData', 'year.fiscalYear', function () {
      var compensationReplacementData = this.compensationReplacementData;

      if (!compensationReplacementData) {
        return null;
      }

      var fiscalYear = parseInt(this.get('year.fiscalYear'));
      var years = [];

      for (var i = 0; i < 8; i++) {
        years.push(fiscalYear + i);
      }

      return {
        labels: years,
        datasets: [{
          label: 'Annual',
          data: compensationReplacementData["totalsPerYear"],
          backgroundColor: '#F1BE48',
          borderWidth: 0
        }, {
          label: 'Cumulative',
          data: compensationReplacementData['cumulative'],
          backgroundColor: '#71C5E8',
          borderWidth: 0
        }]
      };
    }),
    actions: {
      phaseStartChanged: function phaseStartChanged(num, value) {
        this.set("phase".concat(num, "Start"), value);
        Ember.run.debounce(this, this.saveSettings, 250);
      },
      phaseEndChanged: function phaseEndChanged(num, value) {
        this.set("phase".concat(num, "End"), value);
        Ember.run.debounce(this, this.saveSettings, 250);
      },
      phaseReductionsDirectsChanged: function phaseReductionsDirectsChanged(num, value) {
        value = value ? value : 0;
        this.set("phase".concat(num, "ReductionsDirects"), parseInt(value));
        Ember.run.debounce(this, this.saveSettings, 250);
      },
      phaseReductionsIndirectsChanged: function phaseReductionsIndirectsChanged(num, value) {
        value = value ? value : 0;
        this.set("phase".concat(num, "ReductionsIndirects"), parseInt(value));
        Ember.run.debounce(this, this.saveSettings, 250);
      },
      phaseRecoveryDirectsChanged: function phaseRecoveryDirectsChanged(num, value) {
        value = value ? value : 0;
        this.set("phase".concat(num, "RecoveryDirects"), parseInt(value));
        Ember.run.debounce(this, this.saveSettings, 250);
      },
      phaseRecoveryIndirectsChanged: function phaseRecoveryIndirectsChanged(num, value) {
        value = value ? value : 0;
        this.set("phase".concat(num, "RecoveryIndirects"), parseInt(value));
        Ember.run.debounce(this, this.saveSettings, 250);
      },
      compensationReplacementSponsorChanged: function compensationReplacementSponsorChanged(value) {
        this.set('selectedCompensationReplacementSponsor', value);
        Ember.run.debounce(this, this.saveSettings, 250);
      },
      compensationReplacementChanged: function compensationReplacementChanged(type, value) {
        switch (type) {
          case this.currentUser.sponsorTypeNih:
            this.set('compensationReplacementNih', value);
            break;

          case this.currentUser.sponsorTypeOtherFed:
            this.set('compensationReplacementOtherFed', value);
            break;

          case this.currentUser.sponsorTypeFFT:
            this.set('compensationReplacementFedFlowThru', value);
            break;

          case this.currentUser.sponsorTypeNonFed:
            this.set('compensationReplacementNonFed', value);
            break;

          case this.currentUser.sponsorTypeSponsor5:
            this.set('compensationReplacementSponsor5', value);
            break;

          case this.currentUser.sponsorTypeSponsor6:
            this.set('compensationReplacementSponsor6', value);
            break;

          case this.currentUser.sponsorTypeSponsor7:
            this.set('compensationReplacementSponsor7', value);
            break;
        }

        Ember.run.debounce(this, this.saveSettings, 250);
      },
      toggleForecastModifierHelp: function toggleForecastModifierHelp() {
        this.toggleProperty('isShowingForecastModifierHelp');
      },
      toggleResearchBackstopHelp: function toggleResearchBackstopHelp() {
        this.toggleProperty('isShowingResearchBackstopHelp');
      },
      projectionFilterOptionChanged: function projectionFilterOptionChanged(option) {
        var _this5 = this;

        if (option.value === 'All Sectors') {
          this.store.query('projection', {}).then(function (projections) {
            if (projections.content.length > 0) {
              var projection = projections.objectAt(0);

              _this5.set('model.projection', projection);
            }
          });
        } else if (option.isGroupName) {
          this.store.query('projection', {
            'sector': option.value
          }).then(function (projections) {
            if (projections.content.length > 0) {
              var projection = projections.objectAt(0);

              _this5.set('model.projection', projection);
            }
          });
        } else {
          this.store.query('projection', {
            'department_id': option.id
          }).then(function (projections) {
            if (projections.content.length > 0) {
              var projection = projections.objectAt(0);

              _this5.set('model.projection', projection);
            }
          });
        }

        this.set('selectedFilterOption', option);
        this.saveSettings();
      }
    }
  });

  _exports.default = _default;
});