define("b3i-analytics-web-app/constants/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mapResponseErrors: function mapResponseErrors(response) {
      var errors = [];

      if (!response) {
        return ["Unknown error. Please try again later"];
      }

      if (response.error) {
        if (response.error === 'invalid_grant') {
          return ["Your username and password combination is not correct. Please try again."];
        }
      }

      if (response.isAdapterError) {
        errors = response.errors;
      } else {
        if (response.responseJSON) {
          if (response.responseJSON.errors) {
            errors = response.responseJSON.errors;
          } else {
            for (var key in response.responseJSON) {
              if (response.responseJSON.hasOwnProperty(key)) {
                errors.push(response.responseJSON[key]);
              }
            }

            return [].concat.apply([], errors);
          }
        } else if (response.payload) {
          if (response.payload.errors) {
            errors = response.payload.errors;
          }
        } else {
          return ["Server error. Please try again later"];
        }
      }

      return errors.map(function (error) {
        return error.detail;
      });
    },
    presenceError: '{description} is a required field.'
  };
  _exports.default = _default;
});