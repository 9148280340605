define("b3i-analytics-web-app/templates/components/table-comparative-departments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CH0zTIqH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"table-base\",[],[[\"@table\",\"@isLoading\",\"@onColumnClick\",\"@sortValuePath\",\"@tableActions\",\"@emptyTableMessage\"],[[34,0],[34,1],[30,[36,2],[[32,0],\"onColumnClick\"],null],[34,3],[30,[36,5],null,[[\"onDepartmentClicked\"],[[30,[36,2],[[32,0],[35,4]],null]]]],[34,6]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"table\",\"isLoading\",\"action\",\"sortValuePath\",\"onDepartmentClicked\",\"hash\",\"emptyTableMessage\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/table-comparative-departments.hbs"
    }
  });

  _exports.default = _default;
});