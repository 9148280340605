define("b3i-analytics-web-app/mixins/open-print-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    printThis: Ember.inject.service(),
    actions: {
      openPrintDialog: function openPrintDialog() {
        window.print();
      },
      printAction: function printAction() {
        var selector = '.printArea';
        var options = {
          printDelay: 500,
          canvas: true
        };
        this.printThis.print(selector, options);
      }
    }
  });

  _exports.default = _default;
});