define("b3i-analytics-web-app/templates/components/cell-percentage-non-bold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y8zDtFcr",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"formattedValue\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-percentage-non-bold.hbs"
    }
  });

  _exports.default = _default;
});