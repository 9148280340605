define("b3i-analytics-web-app/components/table-projections-core-mega-future", ["exports", "b3i-analytics-web-app/mixins/table-common"], function (_exports, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    year: Ember.inject.service(),
    columns: Ember.computed('startYear', function () {
      //Use a static start year or use the FY for the school
      var startYear = this.startYear ? this.startYear : this.get('year.fiscalYear');
      return [{
        label: '',
        sortable: false,
        valuePath: 'type'
      }, {
        label: '',
        sortable: false,
        valuePath: 'directsOrIndirects'
      }, {
        align: 'right',
        label: "".concat(startYear),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsCurrentYear'
      }, {
        align: 'right',
        label: "".concat(startYear + 1),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsOneYearAhead'
      }, {
        align: 'right',
        label: "".concat(startYear + 2),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsTwoYearsAhead'
      }, {
        align: 'right',
        label: "".concat(startYear + 3),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsThreeYearsAhead'
      }, {
        align: 'right',
        label: "".concat(startYear + 4),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsFourYearsAhead'
      }, {
        align: 'right',
        label: "".concat(startYear + 5),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsFiveYearsAhead'
      }, {
        align: 'right',
        label: "".concat(startYear + 6),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsSixYearsAhead'
      }, {
        align: 'right',
        label: "".concat(startYear + 7),
        sortable: false,
        cellComponent: 'cell-money-one-decimal',
        valuePath: 'ptsSevenYearsAhead'
      }];
    }),
    // Add classes to projections table for styling
    rowObserver: Ember.observer('table.rows.@each', function () {
      var table = this.table;
      var rows = table.get('rows');
      rows.forEach(function (row, index) {
        var rowClass = '';

        if (index === 2 || index === 4) {
          rowClass += ' row-border-top';
        }

        Ember.set(row, 'classNames', rowClass);
      });
    }).on('init')
  });

  _exports.default = _default;
});