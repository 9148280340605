define("b3i-analytics-web-app/transforms/number-not-null", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.NumberTransform.extend({
    serialize: function serialize(deserialized) {
      var transformed;

      if (Ember.isEmpty(deserialized)) {
        return 0;
      } else {
        transformed = Number(deserialized);
        return !isNaN(transformed) ? transformed : null;
      }
    }
  });

  _exports.default = _default;
});