define("b3i-analytics-web-app/components/table-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onScrolledToBottom: function onScrolledToBottom() {
        if (this.onScrolledToBottom) {
          this.onScrolledToBottom();
        }
      },
      onColumnClick: function onColumnClick(column) {
        if (this.onColumnClick) {
          this.onColumnClick(column);
        }
      }
    }
  });

  _exports.default = _default;
});