define("b3i-analytics-web-app/controllers/app/departments", ["exports", "b3i-analytics-web-app/mixins/open-print-dialog", "b3i-analytics-web-app/mixins/permissions-check"], function (_exports, _openPrintDialog, _permissionsCheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_openPrintDialog.default, _permissionsCheck.default, {
    currentUser: Ember.inject.service('current-user'),
    appController: Ember.inject.controller('application'),
    ajax: Ember.inject.service(),
    currentRouteObserver: Ember.observer('appController.currentPath', function () {
      var currentRouteName = this.get('appController.currentPath');

      if (currentRouteName === 'app.departments.index') {
        this.set('departmentName', null);
        this.set('piName', null);
        this.set('isDepartmentsPage', true);
      } else if (currentRouteName === 'app.departments.department') {
        this.set('piName', null);
        this.set('isDepartmentsPage', false);
      } else {
        this.set('isDepartmentsPage', false);
      }
    }).on('init'),
    actions: {
      exportDataClicked: function exportDataClicked() {
        var result = Ember.RSVP.defer();
        this.send('exportData', result);
        return result.promise;
      }
    }
  });

  _exports.default = _default;
});