define("b3i-analytics-web-app/components/table-potential-to-spend", ["exports", "b3i-analytics-web-app/utils/highlight-pts-cells", "b3i-analytics-web-app/mixins/table-common"], function (_exports, _highlightPtsCells, _tableCommon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var subColumnWidth = '90px';

  var _default = Ember.Component.extend(_tableCommon.default, {
    year: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    isMega: false,
    columns: Ember.computed('modelLength', 'extra.includePlanPts', function () {
      var includePlanPts;

      if (this.extra) {
        includePlanPts = this.extra.includePlanPts;
      }

      return [{
        sortable: false,
        component: 'column-at-risk',
        classNames: 'column-no-padding',
        subColumns: [{
          label: "".concat(this.modelLength === undefined ? 0 : this.modelLength, " ").concat(this.displayType),
          valuePath: 'cellLinkData',
          width: '150px',
          cellComponent: 'cell-link',
          cellClassNames: 'highlight-root-cell',
          classNames: 'investigator-column'
        }]
      }, {
        label: "".concat(this.get('year.fiscalYearTwoYearsAgo'), " Actuals"),
        sortable: false,
        width: '200px',
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: 'actualsTwoYearsAgoDirects',
          cellComponent: 'cell-money',
          width: subColumnWidth,
          cellClassNames: 'column-group-left'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: 'actualsTwoYearsAgoIndirects',
          cellComponent: 'cell-money',
          width: subColumnWidth
        }]
      }, {
        label: "".concat(this.get('year.lastFiscalYear'), " Actuals"),
        sortable: false,
        width: '200px',
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: 'actualsOneYearAgoDirects',
          cellComponent: 'cell-money',
          width: subColumnWidth,
          cellClassNames: 'column-group-left actuals'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: 'actualsOneYearAgoIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'actuals',
          width: subColumnWidth
        }]
      }, {
        label: "".concat(this.get('year.fiscalYear'), " Potential Excluding Pending Proposals"),
        width: '350px',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: includePlanPts ? 'ptsWithPlanCurrentYearDirects' : 'ptsCurrentYearDirects',
          cellComponent: 'cell-money',
          width: subColumnWidth,
          cellClassNames: 'column-group-left current-year-directs'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: includePlanPts ? 'ptsWithPlanCurrentYearIndirects' : 'ptsCurrentYearIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right current-year-indirects',
          width: subColumnWidth
        }]
      }, {
        label: "".concat(this.get('year.fiscalYear') + 1, " Potential Excluding Pending Proposals"),
        width: '350px',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: includePlanPts ? 'ptsWithPlanOneYearAheadDirects' : 'ptsOneYearAheadDirects',
          cellComponent: 'cell-money',
          width: subColumnWidth,
          cellClassNames: 'column-group-left one-year-directs'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: includePlanPts ? 'ptsWithPlanOneYearAheadIndirects' : 'ptsOneYearAheadIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right one-year-indirects',
          width: subColumnWidth
        }]
      }, {
        label: "".concat(this.get('year.fiscalYear') + 2, " Potential Excluding Pending Proposals"),
        width: '350px',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: includePlanPts ? 'ptsWithPlanTwoYearsAheadDirects' : 'ptsTwoYearsAheadDirects',
          cellComponent: 'cell-money',
          width: subColumnWidth,
          cellClassNames: 'column-group-left two-year-directs'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: includePlanPts ? 'ptsWithPlanTwoYearsAheadIndirects' : 'ptsTwoYearsAheadIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right two-year-indirects',
          width: subColumnWidth
        }]
      }, {
        label: "".concat(this.get('year.fiscalYear') + 3, " Potential Excluding Pending Proposals"),
        width: '350px',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: includePlanPts ? 'ptsWithPlanThreeYearsAheadDirects' : 'ptsThreeYearsAheadDirects',
          cellComponent: 'cell-money',
          width: subColumnWidth,
          cellClassNames: 'column-group-left two-year-directs'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: includePlanPts ? 'ptsWithPlanThreeYearsAheadIndirects' : 'ptsThreeYearsAheadIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right two-year-indirects',
          width: subColumnWidth
        }]
      }, {
        label: "".concat(this.get('year.fiscalYear') + 4, " Potential Excluding Pending Proposals"),
        width: '350px',
        sortable: false,
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: includePlanPts ? 'ptsWithPlanFourYearsAheadDirects' : 'ptsFourYearsAheadDirects',
          cellComponent: 'cell-money',
          width: subColumnWidth,
          cellClassNames: 'column-group-left two-year-directs'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: includePlanPts ? 'ptsWithPlanFourYearsAheadIndirects' : 'ptsFourYearsAheadIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right two-year-indirects',
          width: subColumnWidth
        }]
      }];
    }),
    extraObserver: Ember.observer('extra', 'model', function () {
      var extra = this.extra;

      if (extra) {
        if (extra.nearTermDecline) {
          var table = this.table;
          var rows = table.get('rows');

          if (rows.length > 0) {
            (0, _highlightPtsCells.default)(rows, extra.nearTermDecline, extra.includePlanPts); //Highlight the institutional total row

            if (this.displayType === "Investigators") {
              this.addDarkBackgroundToRow(rows[0]);
              this.addDarkBackgroundToRow(rows[1]);
              this.addDarkBackgroundToRow(rows[2]);
            } else {
              this.addDarkBackgroundToRow(rows[0]);
            }

            var includePlanPts = extra.includePlanPts;

            if (includePlanPts) {
              for (var i = 0; i < this.model.length; i++) {
                var row = rows[i]; // Highlight rows for pis that have plans when filter is enabled

                if (!includePlanPts) {
                  Ember.set(row, 'classNames', row.get('classNames').replace('row-pi-grant-changed', ''));
                } else {
                  if (row.get('plan')) {
                    Ember.set(row, 'classNames', "".concat(row.get('classNames'), " row-pi-grant-changed"));
                  } else {
                    Ember.set(row, 'classNames', row.get('classNames').replace('row-pi-grant-changed', ''));
                  }
                }
              }
            }
          }
        }

        if (extra.modelLength) {
          this.set('modelLength', extra.modelLength);
        }
      }
    }).on('init'),
    addDarkBackgroundToRow: function addDarkBackgroundToRow(row) {
      var rowClassNames = row.get('classNames');
      rowClassNames[0] += ' dark-grey-bg row-border-bottom';
      row.set('classNames', rowClassNames);
    }
  });

  _exports.default = _default;
});