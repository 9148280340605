define("b3i-analytics-web-app/controllers/sandbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    startRange: Ember.computed(function () {
      return {
        'min': [0],
        'max': [1000]
      };
    }),
    sliderValue: 30,
    maxValue: 180,
    rangePercent: Ember.computed('rangeValue', 'maxValue', function () {
      var rangeValue = this.rangeValue;
      var max = this.maxValue;
      return Math.floor(rangeValue / max * 100);
    }),
    ptsTableColumns: Ember.computed(function () {
      return [{
        label: "".concat(this.numAtRisk, " at risk of decline"),
        sortable: false,
        subColumns: [{
          label: "".concat(this.modelLength, " Departments"),
          valuePath: 'name',
          width: '250px',
          cellComponent: 'cell-link',
          cellClassNames: 'highlight-root-cell'
        }]
      }, {
        label: "".concat(this.get('year.lastFiscalYear'), " Actuals"),
        sortable: false,
        width: '300px',
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: 'actuals1YearAgoDirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-left'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: 'actuals1YearAgoIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right'
        }]
      }, {
        label: "".concat(this.get('year.fiscalYear'), " Projected Excluding Pending Proposals"),
        width: '400px',
        subColumns: [{
          align: 'right',
          label: 'Directs',
          valuePath: 'ptsCurrentYearDirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-left'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: 'ptsCurrentYearIndirects',
          cellComponent: 'cell-money'
        }, {
          align: 'right',
          label: 'Directs Change',
          valuePath: 'directsChange',
          cellComponent: 'cell-money-directs-change'
        }, {
          align: 'right',
          label: 'Indirects Change',
          valuePath: 'indirectsChange',
          cellComponent: 'cell-money-indirects-change',
          cellClassNames: 'column-group-right'
        }]
      }, {
        label: 'Pending',
        subColumns: [{
          align: 'right',
          label: 'Proposals',
          valuePath: 'pendingCurrentYearTotal',
          cellClassNames: 'column-group-left',
          width: '80px'
        }, {
          align: 'right',
          label: 'Directs',
          valuePath: 'pendingCurrentYearDirects',
          cellComponent: 'cell-money',
          width: '120px'
        }, {
          align: 'right',
          label: 'Indirects',
          valuePath: 'pendingCurrentYearIndirects',
          cellComponent: 'cell-money',
          cellClassNames: 'column-group-right',
          width: '120px'
        }]
      }];
    }),
    ptsTableModel: [{
      name: 'My Department',
      actuals1YearAgoDirects: 1699599,
      actuals1YearAgoIndirects: 665255,
      ptsCurrentYearDirects: 1,
      ptsCurrentYearIndirects: 754220,
      directsChange: 1,
      indirectsChange: 88965,
      pendingCurrentYearTotal: 16,
      pendingCurrentYearDirects: 12567769,
      pendingCurrentYearIndirects: 5370402
    }, {
      name: 'My Department',
      actuals1YearAgoDirects: 1699599,
      actuals1YearAgoIndirects: 665255,
      ptsCurrentYearDirects: 1887662,
      ptsCurrentYearIndirects: 754220,
      directsChange: 188063,
      indirectsChange: 88965,
      pendingCurrentYearTotal: 16,
      pendingCurrentYearDirects: 12567769,
      pendingCurrentYearIndirects: 5370402
    }, {
      name: 'My Department',
      actuals1YearAgoDirects: 1699599,
      actuals1YearAgoIndirects: 665255,
      ptsCurrentYearDirects: 1887662,
      ptsCurrentYearIndirects: 754220,
      directsChange: 188063,
      indirectsChange: 88965,
      pendingCurrentYearTotal: 16,
      pendingCurrentYearDirects: 12567769,
      pendingCurrentYearIndirects: 5370402
    }],
    ptsTableExtra: {
      directsChange: 100,
      indirectsChange: 100,
      nearTermDecline: 20
    },
    options: ['All', 'Core', 'Mega'],
    summaryCardModel: {
      actuals1YearAgoTotal: 862,
      actuals1YearAgoDirects: 277908671,
      actuals1YearAgoIndirects: 108143388,
      ptsCurrentYearDirects: 253540055,
      ptsCurrentYearIndirects: 101876731,
      pendingCurrentYearTotal: 1095,
      pendingCurrentYearDirects: 929654005,
      pendingCurrentYearIndirects: 436899976,
      pendingCurrentYearTotalNoMega: 1073,
      pendingCurrentYearDirectsNoMega: 732138090,
      pendingCurrentYearIndirectsNoMega: 353022349,
      endingGrantsTotal: 778,
      endingGrantsDirects: 360603824,
      endingGrantsIndirects: 147000208
    },
    isShowingModal: false,
    grantTypeOptions: ['All Grants', 'Core Grants', 'Mega Grants'],
    chartData: {
      labels: ['2013', '2014', '2015', '2016', '2017', '2018', '2020', '2021', '2022'],
      datasets: [{
        label: 'Directs',
        data: [887, 987, 1130, 1080, 980, 1080, 1010, 1000, 1000],
        backgroundColor: '#71C5E8',
        borderWidth: 0
      }, {
        label: 'Indirects',
        data: [415, 500, 530, 490, 415, 500, 490, 470, 470, 470],
        backgroundColor: '#F1BE48',
        borderWidth: 0
      }]
    },
    chartOptions: {
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontColor: '#889CAC',
            fontFamily: 'Lato',
            fontStyle: 'bold'
          },
          gridLines: {
            drawTicks: true,
            zeroLineWidth: 2
          }
        }],
        xAxes: [{
          ticks: {
            fontColor: '#889CAC',
            fontFamily: 'Lato',
            fontStyle: 'bold'
          }
        }]
      },
      legend: {
        position: 'top',
        labels: {
          boxWidth: 12,
          fontColor: '#889CAC',
          fontFamily: 'Lato',
          fontStyle: 'bold'
        }
      },
      tooltips: {
        backgroundColor: '#FAFBFB',
        bodyFontColor: '#416477',
        bodyFontFamily: 'Lato',
        bodySpacing: 6,
        footerMarginBottom: 20,
        mode: 'index',
        multiKeyBackground: '#FAFBFB',
        titleFontColor: '#416477',
        titleMarginBottom: 12,
        xPadding: 12,
        yPadding: 12
      }
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      submitAsyncButton: function submitAsyncButton() {
        return new Ember.RSVP.Promise(function (resolve) {
          Ember.run.later(function () {
            resolve();
          }, 2000);
        });
      }
    }
  });

  _exports.default = _default;
});