define("b3i-analytics-web-app/templates/components/cell-money-one-decimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UuJla6L6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[30,[36,1],[[35,0],\"red\"],null]],[12],[1,[30,[36,3],[[35,2]],[[\"precision\"],[1]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isNegative\",\"if\",\"value\",\"format-money\",\"showValue\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-money-one-decimal.hbs"
    }
  });

  _exports.default = _default;
});