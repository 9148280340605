define("b3i-analytics-web-app/components/projections-controls-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service('current-user'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('open2018Options', ['Prior Yr', '2 Yr Avg', '3 Yr Avg']);
      this.set('2018RevisedOptions', ['Stay', 'YTD', 'Revert']);
      this.set('2019TransitionOptions', ['Stay', 'Split', 'Revert']);
    },
    sponsorExists: Ember.computed('sponsorType', function () {
      var sponsors = this.currentUser.sponsorlist;

      if (sponsors.includes(this.sponsorType)) {
        return true;
      } else {
        return false;
      } // return true;

    }),
    notSelectedSponsor: Ember.computed('selectedSponsor', 'sponsorType', function () {
      return this.selectedSponsor !== this.sponsorType && this.selectedSponsor !== 'All Sponsor Types';
    }),
    actions: {
      openOptionChanged: function openOptionChanged(value) {
        if (this.openOptionChanged) {
          this.openOptionChanged(value);
        }
      },
      revisedOptionChanged: function revisedOptionChanged(value) {
        if (this.revisedOptionChanged) {
          this.revisedOptionChanged(value);
        }
      },
      transitionOptionChanged: function transitionOptionChanged(value) {
        if (this.transitionOptionChanged) {
          this.transitionOptionChanged(value);
        }
      },
      oneYearAheadDirectsChanged: function oneYearAheadDirectsChanged(value) {
        if (this.oneYearAheadDirectsChanged) {
          this.oneYearAheadDirectsChanged(value / 100);
        }
      },
      oneYearAheadIndirectsChanged: function oneYearAheadIndirectsChanged(value) {
        if (this.oneYearAheadIndirectsChanged) {
          this.oneYearAheadIndirectsChanged(value / 100);
        }
      },
      twoYearsAheadDirectsChanged: function twoYearsAheadDirectsChanged(value) {
        if (this.twoYearsAheadDirectsChanged) {
          this.twoYearsAheadDirectsChanged(value / 100);
        }
      },
      twoYearsAheadIndirectsChanged: function twoYearsAheadIndirectsChanged(value) {
        if (this.twoYearsAheadIndirectsChanged) {
          this.twoYearsAheadIndirectsChanged(value / 100);
        }
      },
      threeYearsAheadDirectsChanged: function threeYearsAheadDirectsChanged(value) {
        if (this.threeYearsAheadDirectsChanged) {
          this.threeYearsAheadDirectsChanged(value / 100);
        }
      },
      threeYearsAheadIndirectsChanged: function threeYearsAheadIndirectsChanged(value) {
        if (this.threeYearsAheadIndirectsChanged) {
          this.threeYearsAheadIndirectsChanged(value / 100);
        }
      },
      fourYearsAheadDirectsChanged: function fourYearsAheadDirectsChanged(value) {
        if (this.fourYearsAheadDirectsChanged) {
          this.fourYearsAheadDirectsChanged(value / 100);
        }
      },
      fourYearsAheadIndirectsChanged: function fourYearsAheadIndirectsChanged(value) {
        if (this.fourYearsAheadIndirectsChanged) {
          this.fourYearsAheadIndirectsChanged(value / 100);
        }
      }
    }
  });

  _exports.default = _default;
});