define("b3i-analytics-web-app/components/table-compensation-coverage", ["exports", "b3i-analytics-web-app/mixins/table-common", "moment"], function (_exports, _tableCommon, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableCommon.default, {
    year: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        label: 'Category',
        valuePath: 'category',
        sortable: false,
        cellClassNames: 'column-group-right'
      }, {
        label: 'ID',
        sortable: false,
        valuePath: 'grantId'
      }, {
        label: 'Start',
        sortable: false,
        valuePath: 'startDate',
        format: function format(value) {
          if (value !== undefined) {
            return (0, _moment.default)(value).format('MM/DD/YY');
          }

          return '';
        }
      }, {
        label: 'End',
        sortable: false,
        valuePath: 'endDate',
        format: function format(value) {
          //For total row
          if (value === 'Coverage') {
            return 'Coverage';
          }

          return (0, _moment.default)(value).format('MM/DD/YY');
        },
        cellClassNames: 'column-group-right coverage-total'
      }, {
        label: 'Current %',
        cellComponent: 'cell-percentage',
        sortable: false,
        valuePath: 'annual',
        align: 'right'
      }, {
        label: 'Future %',
        cellComponent: 'cell-percentage',
        sortable: false,
        valuePath: 'future',
        align: 'right'
      }, {
        label: "".concat(this.get('year.fiscalYear')),
        cellComponent: 'cell-percentage',
        sortable: false,
        valuePath: 'coverageCurrentYear',
        align: 'right'
      }, {
        label: "".concat(this.get('year.fiscalYear') + 1),
        cellComponent: 'cell-percentage',
        sortable: false,
        valuePath: 'coverageOneYearAhead',
        align: 'right'
      }, {
        label: "".concat(this.get('year.fiscalYear') + 2),
        cellComponent: 'cell-percentage',
        sortable: false,
        valuePath: 'coverageTwoYearsAhead',
        align: 'right'
      }, {
        label: "".concat(this.get('year.fiscalYear') + 3),
        cellComponent: 'cell-percentage',
        sortable: false,
        valuePath: 'coverageThreeYearsAhead',
        align: 'right'
      }];
    }),
    // Add classes to projections table for styling
    rowObserver: Ember.observer('table', function () {
      var table = this.table;
      var rows = table.get('rows');
      var length = table.get('rows.length');
      rows.forEach(function (row, index) {
        if (index === length - 1) {
          Ember.set(row, 'classNames', 'row-grey-bg row-border-top');
        }
      });
    }).on('init')
  });

  _exports.default = _default;
});