define("b3i-analytics-web-app/mixins/investigator-projections-route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupPlan: function setupPlan(controller, model) {
      var isController = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      controller.set('recruitment', this.get('currentUser.team.recruitment'));

      if (isController) {
        //We need the projection object for the calculated Y1:Y2 ratios
        this.store.findAll('projection').then(function (proj) {
          var projections = proj.objectAt(0);
          controller.updateProjection(projections);
        });
      } //Set initial settings from backend


      if (model.plan && Object.keys(model.plan).length > 0) {
        var pending = model.plan.pending; // Find proposals and set the funding option

        var proposalDupes = [];
        model.proposals.forEach(function (proposal) {
          if (proposal.status === 'pending') {
            var plans = pending.filter(function (pendingPlan) {
              return pendingPlan.grant_id === proposal.grantId;
            });

            if (plans.length > 1) {
              // Try to find the unique plan by date
              plans = plans.filter(function (pendingPlan) {
                return pendingPlan.start_date === (0, _moment.default)(proposal.startDate).format('YYYY-MM-DD') && pendingPlan.end_date === (0, _moment.default)(proposal.endDate).format('YYYY-MM-DD');
              });

              if (plans.length > 1) {
                // Try to find the unique plan by directs / indirects
                plans = plans.filter(function (pendingPlan) {
                  if (pendingPlan.hasOwnProperty("directs")) {
                    return pendingPlan.directs === proposal.directs && pendingPlan.indirects === proposal.indirects;
                  }
                });
              }
            }

            if (plans.length === 1) {
              var plan = plans[0];
              proposal.set('funded', plan.resp);
              proposal.set('effort', plan.effort);
            } else if (plans.length >= 1) {
              if (!proposalDupes.includes(proposal.grantId)) {
                //Search through the successes again and find all duplicates
                var duplicateProposals = model.proposals.filter(function (p) {
                  return p.grantId === proposal.grantId && p.startDate.format('YYYY-MM-DD') === proposal.startDate.format('YYYY-MM-DD') && p.endDate.format('YYYY-MM-DD') === proposal.endDate.format('YYYY-MM-DD') && p.directs === proposal.directs && p.indirects === proposal.indirects;
                }); // It doesnt matter which success is matched up with which plan since directs and indirectd are equal.
                // We just need to make sure all plan responses are recorded

                for (var i = 0; i < plans.length; i++) {
                  var thePlan = plans[i];

                  if (i < duplicateProposals.length) {
                    var theProposal = duplicateProposals[i];
                    theProposal.set('renewProjection', thePlan.resp);
                    theProposal.set('effort', thePlan.effort);
                  }
                }

                proposalDupes.push(proposal.grantId);
              }
            }
          }
        }); // Find successes and find the renewal option

        var active = model.plan.active; // Find proposals and set the funding option

        var successesDupes = [];
        model.successes.forEach(function (success) {
          if (success.isActive) {
            var plans = active.filter(function (activePlan) {
              return activePlan.grant_id === success.grantId;
            });

            if (plans.length > 1) {
              // Try to find the unique plan by date
              plans = plans.filter(function (activePlan) {
                return activePlan.start_date === (0, _moment.default)(success.startDate).format('YYYY-MM-DD') && activePlan.end_date === (0, _moment.default)(success.endDate).format('YYYY-MM-DD');
              });

              if (plans.length > 1) {
                // Try to find the unique plan by directs / indirects
                plans = plans.filter(function (activePlan) {
                  if (activePlan.hasOwnProperty("directs")) {
                    return activePlan.directs === success.directs && activePlan.indirects === success.indirects;
                  }
                });
              }
            }

            if (plans.length === 1) {
              var plan = plans[0];
              success.set('renewProjection', plan.resp);
              success.set('effort', plan.effort);
            } else if (plans.length >= 1) {
              if (!successesDupes.includes(success.grantId)) {
                //Search through the successes again and find all duplicates
                var duplicateSuccesses = model.successes.filter(function (s) {
                  return s.grantId === success.grantId && s.startDate.format('YYYY-MM-DD') === success.startDate.format('YYYY-MM-DD') && s.endDate.format('YYYY-MM-DD') === success.endDate.format('YYYY-MM-DD') && s.directs === success.directs && s.indirects === success.indirects;
                }); // It doesnt matter which success is matched up with which plan since directs and indirectd are equal.
                // We just need to make sure all plan responses are recorded

                for (var i = 0; i < plans.length; i++) {
                  var thePlan = plans[i];

                  if (i < duplicateSuccesses.length) {
                    var theSuccess = duplicateSuccesses[i];
                    theSuccess.set('renewProjection', thePlan.resp);
                    theSuccess.set('effort', thePlan.effort);
                  }
                }

                successesDupes.push(success.grantId);
              }
            }
          }
        });
        var future = model.plan.future;
        controller.set('selectedContinueSubmittingOption', future.will_submit ? 'Will' : 'Will Not');

        if (future.will_submit) {
          controller.set('submissionLevelValue', future.submission_rate * 100);
          controller.set('selectedTargetHitRateOption', "".concat(future.target_hit_rate * 100, "%"));
        } // Update the future calculations


        if (isController) {
          controller.send("grantProjectionChanged");
        }
      }
    }
  });

  _exports.default = _default;
});