define("b3i-analytics-web-app/constants/sponsor-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    NIH: 'NIH',
    FFT: 'Fed Flow Thru',
    NonFed: 'Non Fed',
    OtherFed: 'Other Fed',
    Sponsor5: 'Sponsor 5',
    Sponsor6: 'Sponsor 6',
    Sponsor7: 'Sponsor 7'
  };
  _exports.default = _default;
});