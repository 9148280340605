define("b3i-analytics-web-app/templates/app/insights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A28ajLOK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pi-header navbar-top-margin navbar-top-margin\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"Insights\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-controls\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"secondary\"],[4,[38,0],[[32,0],\"printAction\"],null],[12],[2,\"Print\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pi-navigation\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"link-to\",[[24,0,\"nav-item\"]],[[\"@route\"],[\"app.insights.core-detail\"]],[[\"default\"],[{\"statements\":[[2,\"Core Detail\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[8,\"link-to\",[[24,0,\"nav-item\"]],[[\"@route\"],[\"app.insights.comparative-proposals-successes\"]],[[\"default\"],[{\"statements\":[[2,\"Comparative Successes & Proposals\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"container app insights-padding\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isNotDepartment\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/app/insights.hbs"
    }
  });

  _exports.default = _default;
});