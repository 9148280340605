define("b3i-analytics-web-app/models/recruitment", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    proposalNihkJuniorAvgCount: (0, _model.attr)('number'),
    proposalNihrJuniorAvgCount: (0, _model.attr)('number'),
    proposalOtherFedJuniorAvgCount: (0, _model.attr)('number'),
    proposalFftJuniorAvgCount: (0, _model.attr)('number'),
    proposalNonFedJuniorAvgCount: (0, _model.attr)('number'),
    proposalSponsor5JuniorAvgCount: (0, _model.attr)('number'),
    proposalSponsor6JuniorAvgCount: (0, _model.attr)('number'),
    proposalSponsor7JuniorAvgCount: (0, _model.attr)('number'),
    proposalTotalJuniorAvgCount: Ember.computed('proposalNihkJuniorAvgCount', 'proposalNihrJuniorAvgCount', 'proposalOtherFedJuniorAvgCount', 'proposalFftJuniorAvgCount', 'proposalNonFedJuniorAvgCount', 'proposalSponsor5JuniorAvgCount', 'proposalSponsor6JuniorAvgCount', 'proposalSponsor7JuniorAvgCount', function () {
      return this.proposalNihkJuniorAvgCount + this.proposalNihrJuniorAvgCount + this.proposalOtherFedJuniorAvgCount + this.proposalFftJuniorAvgCount + this.proposalNonFedJuniorAvgCount + this.proposalSponsor5JuniorAvgCount + this.proposalSponsor6JuniorAvgCount + this.proposalSponsor7JuniorAvgCount;
    }),
    proposalNihkJuniorAvgTotal: (0, _model.attr)('number'),
    proposalNihrJuniorAvgTotal: (0, _model.attr)('number'),
    proposalOtherFedJuniorAvgTotal: (0, _model.attr)('number'),
    proposalFftJuniorAvgTotal: (0, _model.attr)('number'),
    proposalNonFedJuniorAvgTotal: (0, _model.attr)('number'),
    proposalSponsor5JuniorAvgTotal: (0, _model.attr)('number'),
    proposalSponsor6JuniorAvgTotal: (0, _model.attr)('number'),
    proposalSponsor7JuniorAvgTotal: (0, _model.attr)('number'),
    proposalTotalJuniorAvgTotal: Ember.computed('proposalNihkJuniorAvgTotal', 'proposalNihrJuniorAvgTotal', 'proposalOtherFedJuniorAvgTotal', 'proposalFftJuniorAvgTotal', 'proposalNonFedJuniorAvgTotal', 'proposalSponsor5JuniorAvgTotal', 'proposalSponsor6JuniorAvgTotal', 'proposalSponsor7JuniorAvgTotal', function () {
      return this.proposalNihkJuniorAvgTotal + this.proposalNihrJuniorAvgTotal + this.proposalOtherFedJuniorAvgTotal + this.proposalFftJuniorAvgTotal + this.proposalNonFedJuniorAvgTotal + this.proposalSponsor5JuniorAvgTotal + this.proposalSponsor6JuniorAvgTotal + this.proposalSponsor7JuniorAvgTotal;
    }),
    modifiedHitRateNihkJunior: (0, _model.attr)('number'),
    modifiedHitRateNihrJunior: (0, _model.attr)('number'),
    modifiedHitRateOtherFedJunior: (0, _model.attr)('number'),
    modifiedHitRateFftJunior: (0, _model.attr)('number'),
    modifiedHitRateNonFedJunior: (0, _model.attr)('number'),
    modifiedHitRateSponsor5Junior: (0, _model.attr)('number'),
    modifiedHitRateSponsor6Junior: (0, _model.attr)('number'),
    modifiedHitRateSponsor7Junior: (0, _model.attr)('number'),
    modifiedHitRateAvgJunior: Ember.computed('modifiedHitRateNihkJunior', 'modifiedHitRateNihrJunior', 'modifiedHitRateOtherFedJunior', 'modifiedHitRateFftJunior', 'modifiedHitRateNonFedJunior', 'modifiedHitRateSponsor5Junior', 'modifiedHitRateSponsor6Junior', 'modifiedHitRateSponsor7Junior', function () {
      return (this.modifiedHitRateNihkJunior + this.modifiedHitRateNihrJunior + this.modifiedHitRateOtherFedJunior + this.modifiedHitRateFftJunior + this.modifiedHitRateNonFedJunior + this.modifiedHitRateSponsor5Junior + this.modifiedHitRateSponsor6Junior + this.modifiedHitRateSponsor7Junior) / 8; // Assuming this should be divided by 8 now (prev was 5)
    }),
    juniorTerminatedAvgCount: (0, _model.attr)('number'),
    successNihkJuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successNihrJuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successOtherFedJuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successFftJuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successNonFedJuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successSponsor5JuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successSponsor6JuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successSponsor7JuniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successJuniorTransferredOutAvgDirects: (0, _model.attr)('number'),
    successJuniorTransferredOutAvgIndirects: (0, _model.attr)('number'),
    proposalNihkSeniorAvgCount: (0, _model.attr)('number'),
    proposalNihrSeniorAvgCount: (0, _model.attr)('number'),
    proposalOtherFedSeniorAvgCount: (0, _model.attr)('number'),
    proposalFftSeniorAvgCount: (0, _model.attr)('number'),
    proposalNonFedSeniorAvgCount: (0, _model.attr)('number'),
    proposalSponsor5SeniorAvgCount: (0, _model.attr)('number'),
    proposalSponsor6SeniorAvgCount: (0, _model.attr)('number'),
    proposalSponsor7SeniorAvgCount: (0, _model.attr)('number'),
    proposalNihkSeniorAvgTotal: (0, _model.attr)('number'),
    proposalNihrSeniorAvgTotal: (0, _model.attr)('number'),
    proposalOtherFedSeniorAvgTotal: (0, _model.attr)('number'),
    proposalFftSeniorAvgTotal: (0, _model.attr)('number'),
    proposalNonFedSeniorAvgTotal: (0, _model.attr)('number'),
    proposalSponsor5SeniorAvgTotal: (0, _model.attr)('number'),
    proposalSponsor6SeniorAvgTotal: (0, _model.attr)('number'),
    proposalSponsor7SeniorAvgTotal: (0, _model.attr)('number'),
    modifiedHitRateNihkSenior: (0, _model.attr)('number'),
    modifiedHitRateNihrSenior: (0, _model.attr)('number'),
    modifiedHitRateOtherFedSenior: (0, _model.attr)('number'),
    modifiedHitRateFftSenior: (0, _model.attr)('number'),
    modifiedHitRateNonFedSenior: (0, _model.attr)('number'),
    modifiedHitRateSponsor5Senior: (0, _model.attr)('number'),
    modifiedHitRateSponsor6Senior: (0, _model.attr)('number'),
    modifiedHitRateSponsor7Senior: (0, _model.attr)('number'),
    seniorTerminatedAvgCount: (0, _model.attr)('number'),
    successNihkSeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successNihrSeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successOtherFedSeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successFftSeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successNonFedSeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successSponsor5SeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successSponsor6SeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successSponsor7SeniorTransferredInAvgTotal: (0, _model.attr)('number'),
    successSeniorTransferredOutAvgDirects: (0, _model.attr)('number'),
    successSeniorTransferredOutAvgIndirects: (0, _model.attr)('number')
  });

  _exports.default = _default;
});