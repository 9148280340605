define("b3i-analytics-web-app/templates/app/departments/principal-investigator/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qTO06Rm1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container app\"],[12],[2,\"\\n  \"],[8,\"table-wrapper\",[],[[\"@label\"],[\"Proposal History\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"table-proposal-history\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"tableModel\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/app/departments/principal-investigator/history.hbs"
    }
  });

  _exports.default = _default;
});