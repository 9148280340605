define("b3i-analytics-web-app/utils/percentiles-from-quartile", ["exports", "b3i-analytics-web-app/constants/tiers"], function (_exports, _tiers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = percentilesFromQuartile;

  function percentilesFromQuartile(selectedQuartile) {
    var percentiles = {};

    switch (selectedQuartile) {
      case _tiers.default.QUARTILE_OPTION_TOP_10:
        percentiles.percentile_begin = 90;
        break;

      case _tiers.default.QUARTILE_OPTION_TOP_25:
        percentiles.percentile_begin = 75;
        break;

      case _tiers.default.QUARTILE_OPTION_MID_TIER:
        percentiles.percentile_begin = 25;
        percentiles.percentile_end = 75;
        break;

      case _tiers.default.QUARTILE_OPTION_LOW_TIER:
        percentiles.percentile_end = 25;
        break;
    }

    return percentiles;
  }
});