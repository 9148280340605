define("b3i-analytics-web-app/utils/convert-to-fiscal-month", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertToFiscalMonth;

  function convertToFiscalMonth(month, fiscalStartMonth) {
    if (month >= fiscalStartMonth) {
      return month - (fiscalStartMonth - 1);
    } else {
      return 12 - (fiscalStartMonth - month - 1);
    }
  }
});