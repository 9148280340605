define("b3i-analytics-web-app/templates/components/cell-money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7bMeQ8UW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[30,[36,1],[[35,0],\"red\"],null]],[12],[2,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isNegative\",\"if\",\"formattedValue\",\"showValue\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-money.hbs"
    }
  });

  _exports.default = _default;
});