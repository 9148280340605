define("b3i-analytics-web-app/templates/components/select-grouped-dropdown-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LHUUtZIE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"dropdown-wrapper\"],[12],[2,\"\\n\"],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[34,3]],[2,\":\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"select-grouped-dropdown\",[],[[\"@label\",\"@selectedOption\",\"@optionChanged\",\"@triggerClass\",\"@optionsClass\",\"@options\"],[[34,3],[34,4],[34,5],[34,6],[34,7],[34,8]]],null],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[15,0,[31,[\"description-text \",[30,[36,1],[[35,0],\"warning\"],null]]]],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"descriptionTextWarning\",\"if\",\"descriptionText\",\"label\",\"selectedOption\",\"optionChanged\",\"triggerClass\",\"optionsClass\",\"options\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/select-grouped-dropdown-wrapper.hbs"
    }
  });

  _exports.default = _default;
});