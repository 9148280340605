define("b3i-analytics-web-app/components/light-table/cells/base", ["exports", "ember-light-table/components/cells/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _base.default;
    }
  });
});