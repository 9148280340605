define("b3i-analytics-web-app/utils/airtable-content-from-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = airtableContentFromRoute;

  function airtableContentFromRoute(content, routesArray, currentRoute) {
    var routePageOptions = routesArray;
    var filteredContent = [];
    routePageOptions.filter(function (option) {
      return currentRoute.includes(option.route);
    }).map(function (option) {
      if (currentRoute && currentRoute.includes(option.route) && !currentRoute.includes(option.excludeRoute)) {
        filteredContent = content[option.page];
      } else {
        filteredContent = null;
      }
    });
    return filteredContent;
  }
});