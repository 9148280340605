define("b3i-analytics-web-app/components/percent-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onValueChanged: function onValueChanged(value) {
        this.set('value', value);

        if (this.onValueChanged) {
          this.onValueChanged(value);
        }
      }
    }
  });

  _exports.default = _default;
});