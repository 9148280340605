define("b3i-analytics-web-app/templates/components/money-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p7vt3Op0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"money-input-wrapper\",[30,[36,2],[[35,1],\"--no-margin\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[12],[1,[34,0]],[2,\":\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[14,0,\"input-wrapper\"],[12],[2,\"\\n    \"],[1,[30,[36,9],[[35,4]],[[\"decimal\",\"options\",\"update\",\"disabled\"],[[35,8],[35,7],[30,[36,6],[[32,0],[30,[36,5],[[35,4]],null]],null],[35,3]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"label\",\"nomargin\",\"if\",\"disabled\",\"value\",\"mut\",\"action\",\"options\",\"decimal\",\"one-way-number-mask\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/money-text-input.hbs"
    }
  });

  _exports.default = _default;
});