define("b3i-analytics-web-app/templates/components/cell-dropdown-mega-grants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ciYja5a",
    "block": "{\"symbols\":[],\"statements\":[[8,\"select-dropdown\",[],[[\"@disabled\",\"@options\",\"@selectedOption\",\"@optionChanged\"],[[34,0,[\"extra\",\"disabled\"]],[34,1],[30,[36,4],[[30,[36,3],[[35,2],true],null],\"Yes\"],null],[30,[36,5],[[32,0],\"optionChanged\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"column\",\"options\",\"value\",\"eq\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-dropdown-mega-grants.hbs"
    }
  });

  _exports.default = _default;
});