define("b3i-analytics-web-app/constants/tiers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    QUARTILE_OPTION_TOP_10: 'Top 10%',
    QUARTILE_OPTION_TOP_25: 'Top 25%',
    QUARTILE_OPTION_MID_TIER: 'Mid Tier (26% - 74%)',
    QUARTILE_OPTION_LOW_TIER: 'Lower Tier (0% to 25%)'
  };
  _exports.default = _default;
});