define("b3i-analytics-web-app/utils/format-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatPercentage;

  function formatPercentage(percentage, precision) {
    if (percentage || percentage === 0) {
      if (isNaN(percentage)) {
        return percentage;
      } else {
        var value = percentage * 100;
        return value.toFixed(precision ? precision : 0);
      }
    }

    return null;
  }
});