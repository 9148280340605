define("b3i-analytics-web-app/utils/new-value-from-sponsors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = newValueFromSponsors;

  function newValueFromSponsors(displayValue, sponsors) {
    if (!sponsors) {
      return displayValue;
    }

    var sponsor = sponsors.filterBy('displayValue', displayValue);

    if (sponsor.length === 0) {
      return displayValue;
    } else {
      return sponsor[0].newValue;
    }
  }
});