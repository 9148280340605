define("b3i-analytics-web-app/templates/components/data-import-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YxkbNdsA",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[10,\"div\"],[14,0,\"data-import-message\"],[12],[2,\"\\n\"],[6,[37,2],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[10,\"strong\"],[14,0,\"success\"],[12],[2,\"All records imported successfully\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[10,\"strong\"],[14,0,\"error\"],[12],[1,[35,3,[\"number_of_rows_ignored\"]]],[2,\" total records ignored for the following reasons:\"],[13],[13],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[14,0,\"red\"],[12],[1,[32,1,[\"error\"]]],[2,\": \"],[1,[32,1,[\"rows\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[10,\"div\"],[15,0,[31,[\"ignored-rows \",[30,[36,2],[[35,1],\"show-more\"],null]]]],[12],[2,\"\\n      \"],[10,\"p\"],[12],[1,[34,7]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[12],[11,\"a\"],[4,[38,0],[[32,0],\"toggleShowMore\"],null],[12],[2,\"Show \"],[1,[30,[36,2],[[35,1],\"less\",\"more\"],null]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"showMore\",\"if\",\"meta\",\"invalidValues\",\"-track-array\",\"each\",\"ignoredRowsString\",\"showShowMore\",\"allRowsImported\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/data-import-message.hbs"
    }
  });

  _exports.default = _default;
});