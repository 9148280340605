define("b3i-analytics-web-app/templates/export-investigators/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ph215vSZ",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[10,\"div\"],[14,0,\"container app investigator-plans\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"investigator-plan\",[],[[\"@store\",\"@model\",\"@props\"],[[34,0],[32,1,[\"model\"]],[32,1,[\"props\"]]]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"page-break\"],[14,5,\"page-break-after: always; page-break-inside: avoid;\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"store\",\"model\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/export-investigators/index.hbs"
    }
  });

  _exports.default = _default;
});