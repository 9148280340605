define("b3i-analytics-web-app/templates/components/cell-mega-grant-variance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pnVODGX0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"value\"],[12],[1,[30,[36,4],[[35,3,[\"value\"]]],[[\"precision\"],[0]]]],[13],[2,\"\\n\"],[6,[37,1],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[[35,2],\"red\"],null],\" \",[30,[36,1],[[35,0],\"green\"],null],\" variance\"]]],[12],[1,[30,[36,1],[[35,0],\"+\"],null]],[1,[30,[36,4],[[35,3,[\"variance\"]]],[[\"precision\"],[0]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isPositive\",\"if\",\"isNegative\",\"value\",\"format-money\",\"showVariance\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-mega-grant-variance.hbs"
    }
  });

  _exports.default = _default;
});