define("b3i-analytics-web-app/models/projection", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    actualsOneYearAgoDirects: (0, _model.attr)('millions'),
    actualsOneYearAgoIndirects: (0, _model.attr)('millions'),
    actualsTwoYearsAgoDirects: (0, _model.attr)('millions'),
    actualsTwoYearsAgoIndirects: (0, _model.attr)('millions'),
    actualsThreeYearsAgoDirects: (0, _model.attr)('millions'),
    actualsThreeYearsAgoIndirects: (0, _model.attr)('millions'),
    actualsFourYearsAgoDirects: (0, _model.attr)('millions'),
    actualsFourYearsAgoIndirects: (0, _model.attr)('millions'),
    actualsFiveYearsAgoDirects: (0, _model.attr)('millions'),
    actualsFiveYearsAgoIndirects: (0, _model.attr)('millions'),
    coreConversionRatiosCurrentYearFedFlowThruDirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearFedFlowThruIndirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearNihDirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearNihIndirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearNonFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearNonFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearOtherFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearOtherFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearSponsor5Directs: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearSponsor5Indirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearSponsor6Directs: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearSponsor6Indirects: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearSponsor7Directs: (0, _model.attr)('number'),
    coreConversionRatiosCurrentYearSponsor7Indirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoFedFlowThruDirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoFedFlowThruIndirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoNihDirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoNihIndirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoNonFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoNonFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoOtherFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoOtherFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoSponsor5Directs: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoSponsor5Indirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoSponsor6Directs: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoSponsor6Indirects: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoSponsor7Directs: (0, _model.attr)('number'),
    coreConversionRatiosOneYearAgoSponsor7Indirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoFedFlowThruDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoFedFlowThruIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoNihDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoNihIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoNonFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoNonFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoOtherFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoOtherFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoSponsor5Directs: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoSponsor5Indirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoSponsor6Directs: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoSponsor6Indirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoSponsor7Directs: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearsAgoSponsor7Indirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoFedFlowThruDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoFedFlowThruIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoNihDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoNihIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoNonFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoNonFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoOtherFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoOtherFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoSponsor5Directs: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoSponsor5Indirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoSponsor6Directs: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoSponsor6Indirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoSponsor7Directs: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearsAgoSponsor7Indirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgFedFlowThruDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgFedFlowThruIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgNihDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgNihIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgNonFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgNonFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgOtherFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgOtherFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgSponsor5Directs: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgSponsor5Indirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgSponsor6Directs: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgSponsor6Indirects: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgSponsor7Directs: (0, _model.attr)('number'),
    coreConversionRatiosTwoYearAvgSponsor7Indirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgFedFlowThruDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgFedFlowThruIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgNihDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgNihIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgNonFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgNonFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgOtherFedDirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgOtherFedIndirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgSponsor5Directs: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgSponsor5Indirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgSponsor6Directs: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgSponsor6Indirects: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgSponsor7Directs: (0, _model.attr)('number'),
    coreConversionRatiosThreeYearAvgSponsor7Indirects: (0, _model.attr)('number'),
    coreProposalsYtdStartPercentageNih: (0, _model.attr)('number'),
    coreProposalsYtdStartPercentageOtherFed: (0, _model.attr)('number'),
    coreProposalsYtdStartPercentageFedFlowThru: (0, _model.attr)('number'),
    coreProposalsYtdStartPercentageNonFed: (0, _model.attr)('number'),
    coreProposalsYtdStartPercentageSponsor5: (0, _model.attr)('number'),
    coreProposalsYtdStartPercentageSponsor6: (0, _model.attr)('number'),
    coreProposalsYtdStartPercentageSponsor7: (0, _model.attr)('number'),
    coreProposalsYearEndNihDirects: (0, _model.attr)('millions'),
    coreProposalsYearEndNihIndirects: (0, _model.attr)('millions'),
    coreProposalsYearEndOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsYearEndOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsYearEndFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsYearEndFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsYearEndNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsYearEndNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsYearEndSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsYearEndSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsYearEndSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsYearEndSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsYearEndSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsYearEndSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearNihDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearNihIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdNihDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdNihIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearYtdSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalNihDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalNihIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsCurrentYearTotalSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdNihDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdNihIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoYtdSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoNihDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoNihIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsOneYearAgoDirects: Ember.computed('coreProposalsOneYearAgoFedFlowThruDirects', 'coreProposalsOneYearAgoNihDirects', 'coreProposalsOneYearAgoNonFedDirects', 'coreProposalsOneYearAgoOtherFedDirects', 'coreProposalsOneYearAgoSponsor5Directs', 'coreProposalsOneYearAgoSponsor6Directs', 'coreProposalsOneYearAgoSponsor7Directs', function () {
      return this.coreProposalsOneYearAgoFedFlowThruDirects + this.coreProposalsOneYearAgoNihDirects + this.coreProposalsOneYearAgoNonFedDirects + this.coreProposalsOneYearAgoOtherFedDirects + this.coreProposalsOneYearAgoSponsor5Directs + this.coreProposalsOneYearAgoSponsor6Directs + this.coreProposalsOneYearAgoSponsor7Directs;
    }),
    coreProposalsOneYearAgoIndirects: Ember.computed('coreProposalsOneYearAgoFedFlowThruIndirects', 'coreProposalsOneYearAgoNihIndirects', 'coreProposalsOneYearAgoNonFedIndirects', 'coreProposalsOneYearAgoOtherFedIndirects', 'coreProposalsOneYearAgoSponsor5Indirects', 'coreProposalsOneYearAgoSponsor6Indirects', 'coreProposalsOneYearAgoSponsor7Indirects', function () {
      return this.coreProposalsOneYearAgoFedFlowThruIndirects + this.coreProposalsOneYearAgoNihIndirects + this.coreProposalsOneYearAgoNonFedIndirects + this.coreProposalsOneYearAgoOtherFedIndirects + this.coreProposalsOneYearAgoSponsor5Indirects + this.coreProposalsOneYearAgoSponsor6Indirects + this.coreProposalsOneYearAgoSponsor7Indirects;
    }),
    coreProposalsTwoYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoNihDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoNihIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearsAgoDirects: Ember.computed('coreProposalsTwoYearsAgoFedFlowThruDirects', 'coreProposalsTwoYearsAgoNihDirects', 'coreProposalsTwoYearsAgoNonFedDirects', 'coreProposalsTwoYearsAgoOtherFedDirects', 'coreProposalsTwoYearsAgoSponsor5Directs', 'coreProposalsTwoYearsAgoSponsor6Directs', 'coreProposalsTwoYearsAgoSponsor7Directs', function () {
      return this.coreProposalsTwoYearsAgoFedFlowThruDirects + this.coreProposalsTwoYearsAgoNihDirects + this.coreProposalsTwoYearsAgoNonFedDirects + this.coreProposalsTwoYearsAgoOtherFedDirects + this.coreProposalsTwoYearsAgoSponsor5Directs + this.coreProposalsTwoYearsAgoSponsor6Directs + this.coreProposalsTwoYearsAgoSponsor7Directs;
    }),
    coreProposalsTwoYearsAgoIndirects: Ember.computed('coreProposalsTwoYearsAgoFedFlowThruIndirects', 'coreProposalsTwoYearsAgoNihIndirects', 'coreProposalsTwoYearsAgoNonFedIndirects', 'coreProposalsTwoYearsAgoOtherFedIndirects', 'coreProposalsTwoYearsAgoSponsor5Indirects', 'coreProposalsTwoYearsAgoSponsor6Indirects', 'coreProposalsTwoYearsAgoSponsor7Indirects', function () {
      return this.coreProposalsTwoYearsAgoFedFlowThruIndirects + this.coreProposalsTwoYearsAgoNihIndirects + this.coreProposalsTwoYearsAgoNonFedIndirects + this.coreProposalsTwoYearsAgoOtherFedIndirects + this.coreProposalsTwoYearsAgoSponsor5Indirects + this.coreProposalsTwoYearsAgoSponsor6Indirects + this.coreProposalsTwoYearsAgoSponsor7Indirects;
    }),
    coreProposalsThreeYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoNihDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoNihIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearsAgoDirects: Ember.computed('coreProposalsThreeYearsAgoFedFlowThruDirects', 'coreProposalsThreeYearsAgoNihDirects', 'coreProposalsThreeYearsAgoNonFedDirects', 'coreProposalsThreeYearsAgoOtherFedDirects', 'coreProposalsThreeYearsAgoSponsor5Directs', 'coreProposalsThreeYearsAgoSponsor6Directs', 'coreProposalsThreeYearsAgoSponsor7Directs', function () {
      return this.coreProposalsThreeYearsAgoFedFlowThruDirects + this.coreProposalsThreeYearsAgoNihDirects + this.coreProposalsThreeYearsAgoNonFedDirects + this.coreProposalsThreeYearsAgoOtherFedDirects + this.coreProposalsThreeYearsAgoSponsor5Directs + this.coreProposalsThreeYearsAgoSponsor6Directs + this.coreProposalsThreeYearsAgoSponsor7Directs;
    }),
    coreProposalsThreeYearsAgoIndirects: Ember.computed('coreProposalsThreeYearsAgoFedFlowThruIndirects', 'coreProposalsThreeYearsAgoNihIndirects', 'coreProposalsThreeYearsAgoNonFedIndirects', 'coreProposalsThreeYearsAgoOtherFedIndirects', 'coreProposalsThreeYearsAgoSponsor5Indirects', 'coreProposalsThreeYearsAgoSponsor6Indirects', 'coreProposalsThreeYearsAgoSponsor7Indirects', function () {
      return this.coreProposalsThreeYearsAgoFedFlowThruIndirects + this.coreProposalsThreeYearsAgoNihIndirects + this.coreProposalsThreeYearsAgoNonFedIndirects + this.coreProposalsThreeYearsAgoOtherFedIndirects + this.coreProposalsThreeYearsAgoSponsor5Indirects + this.coreProposalsThreeYearsAgoSponsor6Indirects + this.coreProposalsThreeYearsAgoSponsor7Indirects;
    }),
    coreProposalsTwoYearAverageFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageNihDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageNihIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsTwoYearAverageSponsor7Indirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageFedFlowThruDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageFedFlowThruIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageNihDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageNihIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageNonFedDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageNonFedIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageOtherFedDirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageOtherFedIndirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageSponsor5Directs: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageSponsor5Indirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageSponsor6Directs: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageSponsor6Indirects: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageSponsor7Directs: (0, _model.attr)('millions'),
    coreProposalsThreeYearAverageSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantAssumedRenewReplaceCurrentYearCount: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceCurrentYearDirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceCurrentYearIndirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceOneYearAheadCount: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceOneYearAheadDirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceOneYearAheadIndirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceTwoYearsAheadCount: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceTwoYearsAheadDirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceTwoYearsAheadIndirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceThreeYearsAheadCount: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceThreeYearsAheadDirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceThreeYearsAheadIndirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceFourYearsAheadCount: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceFourYearsAheadDirects: (0, _model.attr)('number'),
    megaGrantAssumedRenewReplaceFourYearsAheadIndirects: (0, _model.attr)('number'),
    megaGrantHistoryStartOneYearAgo: (0, _model.attr)(),
    megaGrantHistoryStartTwoYearsAgo: (0, _model.attr)(),
    megaGrantHistoryStartThreeYearsAgo: (0, _model.attr)(),
    megaGrantHistoryStartFourYearsAgo: (0, _model.attr)(),
    megaGrantHistoryStartFiveYearsAgo: (0, _model.attr)(),
    megaGrantHistorySubmitTwoYearsAgo: (0, _model.attr)(),
    megaGrantHistorySubmitThreeYearsAgo: (0, _model.attr)(),
    megaGrantHistorySubmitFourYearsAgo: (0, _model.attr)(),
    megaGrantHistorySubmitFiveYearsAgo: (0, _model.attr)(),
    megaGrantHistorySubmitSixYearsAgo: (0, _model.attr)(),
    megaGrantAverageDirects: Ember.computed('megaGrantHistoryStartOneYearAgo', 'megaGrantHistoryStartTwoYearsAgo', 'megaGrantHistoryStartThreeYearsAgo', 'megaGrantHistoryStartFourYearsAgo', 'megaGrantHistoryStartFiveYearsAgo', function () {
      var megaGrantHistoryStartOneYearAgo = this.megaGrantHistoryStartOneYearAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartTwoYearsAgo = this.megaGrantHistoryStartTwoYearsAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartThreeYearsAgo = this.megaGrantHistoryStartThreeYearsAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartFourYearsAgo = this.megaGrantHistoryStartFourYearsAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartFiveYearsAgo = this.megaGrantHistoryStartFiveYearsAgo || {
        directs: 0,
        funded: 0
      };
      var directs = megaGrantHistoryStartOneYearAgo['directs'] + megaGrantHistoryStartTwoYearsAgo['directs'] + megaGrantHistoryStartThreeYearsAgo['directs'] + megaGrantHistoryStartFourYearsAgo['directs'] + megaGrantHistoryStartFiveYearsAgo['directs'];
      var count = megaGrantHistoryStartOneYearAgo['funded'] + megaGrantHistoryStartTwoYearsAgo['funded'] + megaGrantHistoryStartThreeYearsAgo['funded'] + megaGrantHistoryStartFourYearsAgo['funded'] + megaGrantHistoryStartFiveYearsAgo['funded'];

      if (count === 0) {
        return 0;
      }

      return directs / count;
    }),
    megaGrantAverageIndirects: Ember.computed('megaGrantHistoryStartOneYearAgo', 'megaGrantHistoryStartTwoYearsAgo', 'megaGrantHistoryStartThreeYearsAgo', 'megaGrantHistoryStartFourYearsAgo', 'megaGrantHistoryStartFiveYearsAgo', function () {
      var megaGrantHistoryStartOneYearAgo = this.megaGrantHistoryStartOneYearAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartTwoYearsAgo = this.megaGrantHistoryStartTwoYearsAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartThreeYearsAgo = this.megaGrantHistoryStartThreeYearsAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartFourYearsAgo = this.megaGrantHistoryStartFourYearsAgo || {
        directs: 0,
        funded: 0
      };
      var megaGrantHistoryStartFiveYearsAgo = this.megaGrantHistoryStartFiveYearsAgo || {
        directs: 0,
        funded: 0
      };
      var indirects = megaGrantHistoryStartOneYearAgo['indirects'] + megaGrantHistoryStartTwoYearsAgo['indirects'] + megaGrantHistoryStartThreeYearsAgo['indirects'] + megaGrantHistoryStartFourYearsAgo['indirects'] + megaGrantHistoryStartFiveYearsAgo['indirects'];
      var count = megaGrantHistoryStartOneYearAgo['funded'] + megaGrantHistoryStartTwoYearsAgo['funded'] + megaGrantHistoryStartThreeYearsAgo['funded'] + megaGrantHistoryStartFourYearsAgo['funded'] + megaGrantHistoryStartFiveYearsAgo['funded'];

      if (count === 0) {
        return 0;
      }

      return indirects / count;
    }),
    megaGrantsFiveYearHitRate: (0, _model.attr)('percent'),
    megaGrantActiveAverageDirects: (0, _model.attr)('number'),
    megaGrantActiveAverageIndirects: (0, _model.attr)('number'),
    megaGrantPendingCount: (0, _model.attr)('number'),
    megaGrantPendingDirects: (0, _model.attr)('number'),
    megaGrantPendingIndirects: (0, _model.attr)('number'),
    megaGrantConversionRequiredDirects: (0, _model.attr)('percent'),
    megaGrantConversionRequiredIndirects: (0, _model.attr)('percent'),
    megaGrantsHedgeProjectionDirects: (0, _model.attr)('number'),
    megaGrantsHedgeProjectionIndirects: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentDirects: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentIndirects: (0, _model.attr)('number'),
    megaGrantPendingOneYearAheadCount: (0, _model.attr)('number'),
    megaGrantPendingOneYearAheadDirects: (0, _model.attr)('number'),
    megaGrantPendingOneYearAheadIndirects: (0, _model.attr)('number'),
    megaGrantConversionRequiredOneYearAheadDirects: (0, _model.attr)('percent'),
    megaGrantConversionRequiredOneYearAheadIndirects: (0, _model.attr)('percent'),
    megaGrantsHedgeProjectionOneYearAheadDirects: (0, _model.attr)('number'),
    megaGrantsHedgeProjectionOneYearAheadIndirects: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentOneYearAheadDirects: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentOneYearAheadIndirects: (0, _model.attr)('number'),
    megaGrantPendingCountWithAccrual: (0, _model.attr)('number'),
    megaGrantPendingDirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantPendingIndirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantConversionRequiredDirectsWithAccrual: (0, _model.attr)('percent'),
    megaGrantConversionRequiredIndirectsWithAccrual: (0, _model.attr)('percent'),
    megaGrantsHedgeProjectionDirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantsHedgeProjectionIndirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentDirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentIndirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantPendingOneYearAheadCountWithAccrual: (0, _model.attr)('number'),
    megaGrantPendingOneYearAheadDirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantPendingOneYearAheadIndirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantConversionRequiredOneYearAheadDirectsWithAccrual: (0, _model.attr)('percent'),
    megaGrantConversionRequiredOneYearAheadIndirectsWithAccrual: (0, _model.attr)('percent'),
    megaGrantsHedgeProjectionOneYearAheadDirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantsHedgeProjectionOneYearAheadIndirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentOneYearAheadDirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantsHedgeAdjustmentOneYearAheadIndirectsWithAccrual: (0, _model.attr)('number'),
    megaGrantYtdSuccessesDirects: (0, _model.attr)('number'),
    megaGrantYtdSuccessesIndirects: (0, _model.attr)('number'),
    megaGrantYtdSuccessesCount: (0, _model.attr)('number'),
    megaGrantOneYearAheadSuccessesDirects: (0, _model.attr)('number'),
    megaGrantOneYearAheadSuccessesIndirects: (0, _model.attr)('number'),
    megaGrantOneYearAheadSuccessesCount: (0, _model.attr)('number'),
    megaGrantTotalsCurrentYearDirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadDirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoNihDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoNihIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsCurrentYearSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAheadSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAheadSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAheadSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsFourYearsAheadSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsFiveYearsAheadSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsSixYearsAheadSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsOneYearAgoSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsTwoYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    megaGrantTotalsThreeYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    megaGrantPendingAccrualCurrentYear: (0, _model.attr)('number'),
    megaGrantPendingAccrualNextYear: (0, _model.attr)('number'),
    proposalVarianceYearToDateFedFlowThruDirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateFedFlowThruIndirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateNihDirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateNihIndirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateNonFedDirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateNonFedIndirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateOtherFedDirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateOtherFedIndirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateSponsor5Directs: (0, _model.attr)('number'),
    proposalVarianceYearToDateSponsor5Indirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateSponsor6Directs: (0, _model.attr)('number'),
    proposalVarianceYearToDateSponsor6Indirects: (0, _model.attr)('number'),
    proposalVarianceYearToDateSponsor7Directs: (0, _model.attr)('number'),
    proposalVarianceYearToDateSponsor7Indirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateFedFlowThruDirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateFedFlowThruIndirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateNihDirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateNihIndirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateNonFedDirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateNonFedIndirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateOtherFedDirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateOtherFedIndirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateSponsor5Directs: (0, _model.attr)('number'),
    successRatioVarianceYearToDateSponsor5Indirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateSponsor6Directs: (0, _model.attr)('number'),
    successRatioVarianceYearToDateSponsor6Indirects: (0, _model.attr)('number'),
    successRatioVarianceYearToDateSponsor7Directs: (0, _model.attr)('number'),
    successRatioVarianceYearToDateSponsor7Indirects: (0, _model.attr)('number'),
    successesEightYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesEightYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesEightYearsAgoNihDirects: (0, _model.attr)('millions'),
    successesEightYearsAgoNihIndirects: (0, _model.attr)('millions'),
    successesEightYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    successesEightYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesEightYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesEightYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesEightYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesEightYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesEightYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesEightYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesEightYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesEightYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoNihDirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoNihIndirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesSevenYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesSevenYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesSevenYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesSevenYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesSixYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesSixYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesSixYearsAgoNihDirects: (0, _model.attr)('millions'),
    successesSixYearsAgoNihIndirects: (0, _model.attr)('millions'),
    successesSixYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    successesSixYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesSixYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesSixYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesSixYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesSixYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesSixYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesSixYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesSixYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesSixYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoNihDirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoNihIndirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesFiveYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesFiveYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesFiveYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesFiveYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesFourYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesFourYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesFourYearsAgoNihDirects: (0, _model.attr)('millions'),
    successesFourYearsAgoNihIndirects: (0, _model.attr)('millions'),
    successesFourYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    successesFourYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesFourYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesFourYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesFourYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesFourYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesFourYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesFourYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesFourYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesFourYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoNihDirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoNihIndirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesThreeYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesThreeYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesThreeYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesThreeYearsAgoDirects: Ember.computed('successesThreeYearsAgoFedFlowThruDirects', 'successesThreeYearsAgoNihDirects', 'successesThreeYearsAgoNonFedDirects', 'successesThreeYearsAgoOtherFedDirects', 'successesThreeYearsAgoSponsor5Directs', 'successesThreeYearsAgoSponsor6Directs', 'successesThreeYearsAgoSponsor7Directs', function () {
      return this.successesThreeYearsAgoFedFlowThruDirects + this.successesThreeYearsAgoNihDirects + this.successesThreeYearsAgoNonFedDirects + this.successesThreeYearsAgoOtherFedDirects + this.successesThreeYearsAgoSponsor5Directs + this.successesThreeYearsAgoSponsor6Directs + this.successesThreeYearsAgoSponsor7Directs;
    }),
    successesThreeYearsAgoIndirects: Ember.computed('successesThreeYearsAgoFedFlowThruIndirects', 'successesThreeYearsAgoNihIndirects', 'successesThreeYearsAgoNonFedIndirects', 'successesThreeYearsAgoOtherFedIndirects', 'successesThreeYearsAgoSponsor5Indirects', 'successesThreeYearsAgoSponsor6Indirects', 'successesThreeYearsAgoSponsor7Indirects', function () {
      return this.successesThreeYearsAgoFedFlowThruIndirects + this.successesThreeYearsAgoNihIndirects + this.successesThreeYearsAgoNonFedIndirects + this.successesThreeYearsAgoOtherFedIndirects + this.successesThreeYearsAgoSponsor5Indirects + this.successesThreeYearsAgoSponsor6Indirects + this.successesThreeYearsAgoSponsor7Indirects;
    }),
    successesTwoYearsAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoNihDirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoNihIndirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoNonFedDirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesTwoYearsAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesTwoYearsAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesTwoYearsAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesTwoYearsAgoDirects: Ember.computed('successesTwoYearsAgoFedFlowThruDirects', 'successesTwoYearsAgoNihDirects', 'successesTwoYearsAgoNonFedDirects', 'successesTwoYearsAgoOtherFedDirects', 'successesTwoYearsAgoSponsor5Directs', 'successesTwoYearsAgoSponsor6Directs', 'successesTwoYearsAgoSponsor7Directs', function () {
      return this.successesTwoYearsAgoFedFlowThruDirects + this.successesTwoYearsAgoNihDirects + this.successesTwoYearsAgoNonFedDirects + this.successesTwoYearsAgoOtherFedDirects + this.successesTwoYearsAgoSponsor5Directs + this.successesTwoYearsAgoSponsor6Directs + this.successesTwoYearsAgoSponsor7Directs;
    }),
    successesTwoYearsAgoIndirects: Ember.computed('successesTwoYearsAgoFedFlowThruIndirects', 'successesTwoYearsAgoNihIndirects', 'successesTwoYearsAgoNonFedIndirects', 'successesTwoYearsAgoOtherFedIndirects', 'successesTwoYearsAgoSponsor5Indirects', 'successesTwoYearsAgoSponsor6Indirects', 'successesTwoYearsAgoSponsor7Indirects', function () {
      return this.successesTwoYearsAgoFedFlowThruIndirects + this.successesTwoYearsAgoNihIndirects + this.successesTwoYearsAgoNonFedIndirects + this.successesTwoYearsAgoOtherFedIndirects + this.successesTwoYearsAgoSponsor5Indirects + this.successesTwoYearsAgoSponsor6Indirects + this.successesTwoYearsAgoSponsor7Indirects;
    }),
    successesOneYearAgoFedFlowThruDirects: (0, _model.attr)('millions'),
    successesOneYearAgoFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesOneYearAgoNihDirects: (0, _model.attr)('millions'),
    successesOneYearAgoNihIndirects: (0, _model.attr)('millions'),
    successesOneYearAgoNonFedDirects: (0, _model.attr)('millions'),
    successesOneYearAgoNonFedIndirects: (0, _model.attr)('millions'),
    successesOneYearAgoOtherFedDirects: (0, _model.attr)('millions'),
    successesOneYearAgoOtherFedIndirects: (0, _model.attr)('millions'),
    successesOneYearAgoSponsor5Directs: (0, _model.attr)('millions'),
    successesOneYearAgoSponsor5Indirects: (0, _model.attr)('millions'),
    successesOneYearAgoSponsor6Directs: (0, _model.attr)('millions'),
    successesOneYearAgoSponsor6Indirects: (0, _model.attr)('millions'),
    successesOneYearAgoSponsor7Directs: (0, _model.attr)('millions'),
    successesOneYearAgoSponsor7Indirects: (0, _model.attr)('millions'),
    successesOneYearAgoDirects: Ember.computed('successesOneYearAgoFedFlowThruDirects', 'successesOneYearAgoNihDirects', 'successesOneYearAgoNonFedDirects', 'successesOneYearAgoOtherFedDirects', 'successesOneYearAgoSponsor5Directs', 'successesOneYearAgoSponsor6Directs', 'successesOneYearAgoSponsor7Directs', function () {
      return this.successesOneYearAgoFedFlowThruDirects + this.successesOneYearAgoNihDirects + this.successesOneYearAgoNonFedDirects + this.successesOneYearAgoOtherFedDirects + this.successesOneYearAgoSponsor5Directs + this.successesOneYearAgoSponsor6Directs + this.successesOneYearAgoSponsor7Directs;
    }),
    successesOneYearAgoIndirects: Ember.computed('successesOneYearAgoFedFlowThruIndirects', 'successesOneYearAgoNihIndirects', 'successesOneYearAgoNonFedIndirects', 'successesOneYearAgoOtherFedIndirects', 'successesOneYearAgoSponsor5Indirects', 'successesOneYearAgoSponsor6Indirects', 'successesOneYearAgoSponsor7Indirects', function () {
      return this.successesOneYearAgoFedFlowThruIndirects + this.successesOneYearAgoNihIndirects + this.successesOneYearAgoNonFedIndirects + this.successesOneYearAgoOtherFedIndirects + this.successesOneYearAgoSponsor5Indirects + this.successesOneYearAgoSponsor6Indirects + this.successesOneYearAgoSponsor7Indirects;
    }),
    successesCurrentYearFedFlowThruDirects: (0, _model.attr)('millions'),
    successesCurrentYearFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesCurrentYearNihDirects: (0, _model.attr)('millions'),
    successesCurrentYearNihIndirects: (0, _model.attr)('millions'),
    successesCurrentYearNonFedDirects: (0, _model.attr)('millions'),
    successesCurrentYearNonFedIndirects: (0, _model.attr)('millions'),
    successesCurrentYearOtherFedDirects: (0, _model.attr)('millions'),
    successesCurrentYearOtherFedIndirects: (0, _model.attr)('millions'),
    successesCurrentYearSponsor5Directs: (0, _model.attr)('millions'),
    successesCurrentYearSponsor5Indirects: (0, _model.attr)('millions'),
    successesCurrentYearSponsor6Directs: (0, _model.attr)('millions'),
    successesCurrentYearSponsor6Indirects: (0, _model.attr)('millions'),
    successesCurrentYearSponsor7Directs: (0, _model.attr)('millions'),
    successesCurrentYearSponsor7Indirects: (0, _model.attr)('millions'),
    successesYtdFedFlowThruDirects: (0, _model.attr)('millions'),
    successesYtdFedFlowThruIndirects: (0, _model.attr)('millions'),
    successesYtdNihDirects: (0, _model.attr)('millions'),
    successesYtdNihIndirects: (0, _model.attr)('millions'),
    successesYtdNonFedDirects: (0, _model.attr)('millions'),
    successesYtdNonFedIndirects: (0, _model.attr)('millions'),
    successesYtdOtherFedDirects: (0, _model.attr)('millions'),
    successesYtdOtherFedIndirects: (0, _model.attr)('millions'),
    successesYtdSponsor5Directs: (0, _model.attr)('millions'),
    successesYtdSponsor5Indirects: (0, _model.attr)('millions'),
    successesYtdSponsor6Directs: (0, _model.attr)('millions'),
    successesYtdSponsor6Indirects: (0, _model.attr)('millions'),
    successesYtdSponsor7Directs: (0, _model.attr)('millions'),
    successesYtdSponsor7Indirects: (0, _model.attr)('millions'),
    sector: (0, _model.attr)('string'),
    yoneYtwoDerivativeRatiosNihDirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosNihIndirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosOtherFedDirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosOtherFedIndirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosFedFlowThruDirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosFedFlowThruIndirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosNonFedDirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosNonFedIndirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosSponsor5Directs: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosSponsor5Indirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosSponsor6Directs: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosSponsor6Indirects: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosSponsor7Directs: (0, _model.attr)('number'),
    yoneYtwoDerivativeRatiosSponsor7Indirects: (0, _model.attr)('number'),
    department: (0, _model.belongsTo)('department'),
    team: (0, _model.belongsTo)('team')
  });

  _exports.default = _default;
});