define("b3i-analytics-web-app/templates/components/dropdown-wrapper-with-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lhRCwtxC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"dropdown-wrapper with-display-value\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[1,[34,0]],[2,\":\"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"select-wrapper\"],[12],[2,\"\\n    \"],[8,\"select-dropdown\",[],[[\"@disabled\",\"@options\",\"@multiValues\",\"@selectedOption\",\"@optionChanged\"],[[34,1],[34,2],[34,3],[34,4],[34,5]]],null],[2,\"\\n    \"],[10,\"span\"],[14,0,\"display-value\"],[12],[1,[34,6]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"label\",\"disabled\",\"options\",\"multiValues\",\"selectedOption\",\"optionChanged\",\"displayValue\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/dropdown-wrapper-with-value.hbs"
    }
  });

  _exports.default = _default;
});