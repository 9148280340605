define("b3i-analytics-web-app/utils/pts-ammortization", ["exports", "moment", "b3i-analytics-web-app/utils/convert-to-fiscal-month"], function (_exports, _moment, _convertToFiscalMonth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = ptsAmmortization;

  function ptsAmmortization(grant, year, isDirects, fiscalMonth, nceAndCarryover, shrinkage, sponsorDiscount) {
    var startMonth = grant.get('startFiscalMonth');
    var startYear = grant.get('startFiscalYear');
    var startDate = (0, _moment.default)(grant.get('startDate'));
    var endDate = (0, _moment.default)(grant.get('endDate')); //Factor in the nce

    var extendedMonths = nceAndCarryover;
    var extendedDuration = endDate.diff(startDate, 'days') / 30.5 + extendedMonths; //Extend the end date

    var extendedEndDate = endDate.add(_moment.default.duration({
      'months': extendedMonths
    }));
    var extendedEndYear = extendedEndDate.month() + 1 >= fiscalMonth ? extendedEndDate.year() + 1 : extendedEndDate.year();
    var extendedEndMonth = (0, _convertToFiscalMonth.default)(extendedEndDate.month() + 1, fiscalMonth);
    var amount = isDirects ? grant.get('directs') : grant.get('indirects'); //Reduce the projected success amount by the shrinkage and sponsor discount

    var successAmount = amount * (1 - shrinkage / 100) * (1 - sponsorDiscount / 100);
    var result = 0;

    if (year === startYear) {
      result = successAmount * (13 - startMonth) / extendedDuration;
    } else if (startYear < year && year < extendedEndYear) {
      result = successAmount * 12 / extendedDuration;
    } else if (year === extendedEndYear) {
      result = successAmount * extendedEndMonth / extendedDuration;
    }

    return result;
  }
});