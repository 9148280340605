define("b3i-analytics-web-app/templates/components/percent-input-threshold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N6sAbpV8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[12],[1,[34,0]],[2,\":\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[15,0,[31,[\"input-wrapper highlight-\",[34,2]]]],[12],[2,\"\\n  \"],[8,\"input\",[[16,0,[34,6]],[16,\"placeholder\",0]],[[\"@key-up\",\"@disabled\",\"@focus-in\",\"@max\",\"@maxlength\",\"@name\",\"@type\",\"@value\"],[[30,[36,3],[[32,0],\"onValueChanged\"],null],[34,4],[34,5],\"100\",\"2\",[34,7],\"number\",[34,8]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"label\",\"if\",\"highlightValue\",\"action\",\"disabled\",\"focusIn\",\"class\",\"name\",\"value\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/percent-input-threshold.hbs"
    }
  });

  _exports.default = _default;
});