define("b3i-analytics-web-app/templates/components/cell-edit-institution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gxfzre0F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cell-edit-membership\"],[12],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"deleteAction\"],null],[12],[2,\"Delete\"],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"toggleEditModal\"],null],[12],[2,\"Edit\"],[13],[2,\"\\n  \"],[11,\"a\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"viewAction\"],null],[12],[2,\"View\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/cell-edit-institution.hbs"
    }
  });

  _exports.default = _default;
});