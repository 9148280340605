define("b3i-analytics-web-app/components/modal-delete-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      deleteAction: function deleteAction() {
        var user = this.user;
        this.deleteAction(user);
      }
    }
  });

  _exports.default = _default;
});