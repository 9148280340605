define("b3i-analytics-web-app/mixins/summary-data-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Mixin.create({
    //Dummy row to show the totals
    totalData: Ember.computed('meta', function () {
      var meta = this.meta;

      if (!meta) {
        return null;
      }

      return this.getTotalRowData(meta, 'total');
    }),
    totalRow: Ember.computed('totalData', 'meta', function () {
      if (!this.totalData || !this.meta) {
        return null;
      }

      return Ember.Object.create(_objectSpread({
        cellLinkData: {
          id: 0,
          name: 'Total',
          link: null
        }
      }, this.totalData));
    }),
    //Dummy row to show the institutional totals
    institutionalTotalData: Ember.computed('meta', function () {
      var meta = this.meta;

      if (!meta) {
        return null;
      }

      return this.getTotalRowData(meta, 'institutional_total');
    }),
    getTotalRowData: function getTotalRowData(meta, prefix) {
      //Check if the predix works on one key
      if (!("".concat(prefix, "_actuals_two_years_ago_directs") in meta)) {
        return null;
      }

      return {
        actualsTwoYearsAgoDirects: meta["".concat(prefix, "_actuals_two_years_ago_directs")],
        actualsTwoYearsAgoIndirects: meta["".concat(prefix, "_actuals_two_years_ago_indirects")],
        actualsOneYearAgoDirects: meta["".concat(prefix, "_actuals_one_year_ago_directs")],
        actualsOneYearAgoIndirects: meta["".concat(prefix, "_actuals_one_year_ago_indirects")],
        ptsCurrentYearDirects: meta["".concat(prefix, "_pts_current_year_directs")],
        ptsCurrentYearIndirects: meta["".concat(prefix, "_pts_current_year_indirects")],
        ptsOneYearAheadDirects: meta["".concat(prefix, "_pts_one_year_ahead_directs")],
        ptsOneYearAheadIndirects: meta["".concat(prefix, "_pts_one_year_ahead_indirects")],
        ptsTwoYearsAheadDirects: meta["".concat(prefix, "_pts_two_years_ahead_directs")],
        ptsTwoYearsAheadIndirects: meta["".concat(prefix, "_pts_two_years_ahead_indirects")],
        ptsThreeYearsAheadDirects: meta["".concat(prefix, "_pts_three_years_ahead_directs")],
        ptsThreeYearsAheadIndirects: meta["".concat(prefix, "_pts_three_years_ahead_indirects")],
        ptsFourYearsAheadDirects: meta["".concat(prefix, "_pts_four_years_ahead_directs")],
        ptsFourYearsAheadIndirects: meta["".concat(prefix, "_pts_four_years_ahead_indirects")],
        ptsWithPlanCurrentYearDirects: meta["".concat(prefix, "_pts_with_plan_current_year_directs")],
        ptsWithPlanCurrentYearIndirects: meta["".concat(prefix, "_pts_with_plan_current_year_indirects")],
        ptsWithPlanOneYearAheadDirects: meta["".concat(prefix, "_pts_with_plan_one_year_ahead_directs")],
        ptsWithPlanOneYearAheadIndirects: meta["".concat(prefix, "_pts_with_plan_one_year_ahead_indirects")],
        ptsWithPlanTwoYearsAheadDirects: meta["".concat(prefix, "_pts_with_plan_two_years_ahead_directs")],
        ptsWithPlanTwoYearsAheadIndirects: meta["".concat(prefix, "_pts_with_plan_two_years_ahead_indirects")],
        ptsWithPlanThreeYearsAheadDirects: meta["".concat(prefix, "_pts_with_plan_three_years_ahead_directs")],
        ptsWithPlanThreeYearsAheadIndirects: meta["".concat(prefix, "_pts_with_plan_three_years_ahead_indirects")],
        ptsWithPlanFourYearsAheadDirects: meta["".concat(prefix, "_pts_with_plan_four_years_ahead_directs")],
        ptsWithPlanFourYearsAheadIndirects: meta["".concat(prefix, "_pts_with_plan_four_years_ahead_indirects")],
        pendingAllYearsDirects: meta["".concat(prefix, "_pending_proposals_directs")],
        pendingAllYearsIndirects: meta["".concat(prefix, "_pending_proposals_indirects")]
      };
    },
    institutionalTotalRow: Ember.computed('institutionalTotalData', 'meta', function () {
      if (!this.institutionalTotalData || !this.meta) {
        return [];
      }

      return Ember.Object.create(_objectSpread({
        cellLinkData: {
          id: 0,
          name: 'Institutional Total',
          link: null
        }
      }, this.institutionalTotalData));
    }),
    generatePercentageData: function generatePercentageData(totalData, subsetData) {
      var percentageData = {};
      Object.keys(subsetData).forEach(function (key) {
        var subsetValue = subsetData[key];
        var totalValue = totalData[key];
        var avgValue = 0;

        if (totalValue > 0) {
          avgValue = subsetValue / totalValue;
        }

        percentageData[key] = avgValue;
      });
      percentageData.is_percentage = true;
      return percentageData;
    },
    //Dummy row to show the percent total
    percentageRow: Ember.computed('totalData', 'institutionalTotalData', function () {
      if (!this.totalData || !this.institutionalTotalData || !this.meta || !this.meta.pagination) {
        return null;
      }

      var percentageData = this.generatePercentageData(this.institutionalTotalData, this.totalData);
      return Ember.Object.create(_objectSpread({
        cellLinkData: {
          id: 0,
          name: 'Percentage of Total',
          link: null
        }
      }, percentageData));
    })
  });

  _exports.default = _default;
});