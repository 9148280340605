define("b3i-analytics-web-app/templates/components/modal-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0riEXeVP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"modal-wrapper\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"top-bar\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"a\"],[24,6,\"\"],[24,0,\"cancel\"],[4,[38,1],[[32,0],[30,[36,1],[[32,0],[35,0]],null]],null],[12],[2,\"Close\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"span\"],[14,0,\"title\"],[12],[1,[34,3]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"close\",\"action\",\"if\",\"title\"]}",
    "meta": {
      "moduleName": "b3i-analytics-web-app/templates/components/modal-wrapper.hbs"
    }
  });

  _exports.default = _default;
});